import { Injectable               	} from '@angular/core';
import { CommonsService             } from 'src/app/demo/service/commons.service';
import { ProviderHTXService } from './htx.service';
import { IProviderService 			} from './iproviderservice';
import { ProviderWTRService } from './wtr.service';

@Injectable()
export class ProviderServiceController {		
	pageInfo 	= { services: {} };
	
  	constructor(
		private commons 	: CommonsService,
		private htx			: ProviderHTXService,
		private wtr			: ProviderWTRService
	){
		this.htx.init(this);
		this.wtr.init(this);
	}

	public registerProviderService($info){
		if(undefined==this.pageInfo.services){ 
			this.pageInfo.services = {}
		}
		this.pageInfo.services[$info.id] = $info.service;
	}

	getProviderService($id){
		let provider;
		// if(undefined==this.pageInfo.services){ return undefined; }
		// return this.pageInfo.services[$id];

		// Get hardcoded providers
		// TO REMOVE !
		switch(parseInt($id)){		
			case 1		:	provider = this.htx;	break;
			case 15		:	provider = this.wtr; 	break;			
		}

		// console.log("[getProviderService] Provider",provider);
		return provider;
	}
}