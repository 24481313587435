import { CompanyService 				} from './../../service/database/company.service';
import { AggregatorsService 			} from './../../service/database/aggregator.service';
import { CommonsService 				} from '../../service/commons.service';
import { FlightService 					} from '../../service/flight.service';
import { EntityService 					} from '../../service/entity.service';
import { MessageService 				} from 'primeng/components/common/messageservice';
import { OnInit,
		 Component,
		 ViewChild,
		 ViewEncapsulation,
		 ElementRef,
		 platformCore,
		 HostListener					} from '@angular/core';
import { groupsCols 					} from './columns/groups.columns';
import { fleetCols 						} from './columns/fleet.columns';
import { driversCols 					} from './columns/drivers.columns';
import { filters, zonesFilter 			} from './data/filters';
import { toggleFullScreen 				} from './fn/fullscreen';
import { filterEntity 					} from './fn/filters';
import { providerRestrictionFields 		} from './data/filters';
import { StorageService 				} from '../../service/storageservice';
import { help 							} from './data/help';
import { mainView,
		 calendarInfo,
		 colorInfo,
		 heightInfo,
		 controlPanels,
		 execParams,
		 servicesGrid,
		 transportersCalendarFakeItem,
		 transporterAssignOptions		} from './data/info';
import { FomentoService 				} from 'src/app/demo/service/fomento/fomento.service';
import { servicesCols 					} from './columns/services.columns';
import { SimpleFirebaseService 			} from '../../service/database/simplefirebase.service';
import { ServiceFiltersService 			} from '../../service/serviceFilters/service-filters.service';
import { ImporterService 				} from '../../service/importer/importer.service';
import { TransportService 				} from '../../service/transports/transports.service';
import { bookingsCols 					} from './columns/bookings.columns';
import { mappedPlates 					} from './data/plates';
import { tabs							} from './data/tabs';
import { ProviderServiceController 		} from 'src/app/services/providers/controller.service';
import { request } from 'http';
import { ignoreElements } from 'rxjs/operators';
import { SWITCH_CHANGE_DETECTOR_REF_FACTORY__POST_R3__ } from '@angular/core/src/change_detection/change_detector_ref';
import { BreadcrumbModule } from 'primeng/primeng';
import { ivyEnabled } from '@angular/core/src/ivy_switch';
import { removeSummaryDuplicates } from '@angular/compiler';
import { CSVService } from '../../service/csv.service';
import { data } from '../mapconfig/mapcheckdistances/data/info';

interface marker {
	lat				: number;
	lng				: number;
	label?			: string;
	draggable		: boolean;
}

interface Calendar {
	value			: any,
	date			: string,
	last			: string
};

@Component({
    styleUrls		: ['./transports.component.scss'],
	templateUrl		: './transports.component.html',
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})

export class TransportsComponent implements OnInit
{
	// F2 opens SEARCH
	// HostListener makes some event trigger action
	@HostListener('document:keydown', ['$event'])
    keypress(e: KeyboardEvent) { this.keypressed(e.key); }

	@ViewChild('calendarVehicles'	)	calendarVehicles: ElementRef;
	@ViewChild('calendarDrivers'	)	calendarDrivers	: ElementRef;
	@ViewChild('bookingsGrid'		)	bookingsGrid	: any;
	@ViewChild('servicesGrid'		) 	servicesGrid	: any;
	@ViewChild("search"				)	searchInput		: any;

	pageInfo			: any 		= { fullScreen: false, export: [] };
	userInfo			: any		= {};
	zoom				: number 	= 10;
	lat					: number	= 39.638464;
	lng					: number	= 3.003831;
	providers			: any 		= {};
	markers				: marker[] 	= [
		{ lat: 39.575505, lng: 2.652774, label: 'Headquarters', draggable: true 	}
	];
	mainView			: any		= mainView;

	// calendar			: Calendar 	= <Calendar>{ last: '', date : "2023-08-01" };
	calendar			: Calendar 	= <Calendar>{ last: '' };
	calendarFullView	: boolean	= true;
	entities			: any[]		= [ "groups", "fleet", "drivers" ];
	bookings			: any 		= { cols: bookingsCols, filters: {}, data: [] };
	groups      		: any 		= { cols: [], filters: {}};
	services			: any		= { cols: [], filters: {}, items: []};
	fleet				: any 		= { cols: [], filters: {}, draggableSet: [] };
	drivers				: any 		= { cols: [], filters: {}, draggableSet: [] };
	transporters		: any 		= { cols: [], filters: {}, draggableSet: [] };
	transportTypes		: any 		= { cols: [], filters: {}, draggableSet: [] };
	vehicleTypes		: any[]		= [];
	arrivals			: any		= { draggableSet: [] };
	departures			: any		= { draggableSet: [] };

	ownFleet			: any[]		= [];
	ownPlates			: any		= { draggableSet: [] };

	driversCalendar		: any 		= { rows: [{ empty: true, items: [] }]};
	transportersCalendar: any 		= { rows: [{ empty: true, items: [] }]};
	companies			: any		= { items: [], selected: [] };
	draggedItem			: any;
	chartData			: any;
	linkable			: any 		= {};

	plainServices		: any[]		= [];
	transportsCalendar	: any 		= { rows	: [{ empty: true, items: [] }],
										tmpRows	: [{ emtpy: true, items: []	}]
									};


	rowData				: any 		= { bookingsGrid: {}, servicesGrid: {}};

	transporterApiCode = {
		"transunion"	: 1,
		"avantcab"		: 2,
		"canaryshuttle"	: 3
	};

    constructor(
		private commons					: CommonsService,
		private entityService			: EntityService,
		private aggregatorCtrl			: AggregatorsService,
		private companyService			: CompanyService,
		private storageCtrl				: StorageService,
		private fomentoService			: FomentoService,
		private serviceFiltersService	: ServiceFiltersService,
		private importerService			: ImporterService,
		private CsvService				: CSVService,
		private firebaseService			: SimpleFirebaseService,
		private transportService		: TransportService,
		private providerServiceCtrl		: ProviderServiceController
	){
		this.staticInit();
	}

	keypressed($key){
		switch($key){
			case 'Enter'	: this.doEnter();		break;
			case 'F2'		: this.doF2(); 			break;
			case 'Escape'	: this.doEscape();		break;
		}
	}

	doF2(){
		this.doAction('search','show',{});
	}

	doEscape(){
		if(this.pageInfo.search.show){
			this.doAction("search","hide",{});
		} else {
			this.pageInfo.search_customer = undefined;
		}
	}

	doEnter(){
		if(this.pageInfo.search.show){
			this.doAction("search",'exec',{});
		}
	}

	async ngOnInit(){ await this.init(); }
	
	ngAfterViewInit(){
		this.doSubscribeToTransportersRequests();
	}

	ngAfterViewChecked(){
		// pageInfo.timeRangeValues.initTime
		this.pageInfo.init_service_time	= this.getInfo("init_service_time",{});
	}

	// Subscrite to destination transporters we work with
	async doSubscribeToTransportersRequests(){
		// Get DMC destination transporters
		let dmc 								= this.commons.userInfo.currentDmc.id;
		let destination							= this.commons.userInfo.currentDestination.id;
		const dmcTransportersPath				= "/dmcs/"+dmc+"/destinations/"+destination+"/transporters";
		this.pageInfo.transportersDayRequests 	= {};
		this.calendar.date						= this.calendar.date || this.commons.getToday("YYYY-MM-DD");
		
		this.firebaseService.subscribeCol(dmcTransportersPath,transporters=>{
			console.log("transporters",transporters);

			// Subscribe to each transporter on current date
			(transporters||[]).forEach(async transporter=>{
				let transporterDatePath	= dmcTransportersPath+"/"+transporter.id+"/requests/"+this.calendar.date;				
				(await this.firebaseService.subscribeEntityDoc(transporterDatePath))
					.subscribe(info=>{
						if(!info || !info["items"]){ return false; }
						this.pageInfo.transportersDayRequests[transporter.id] = { 
							id		: 	transporter.id, 
							name	: 	transporter.name, 
							items	: 	info["items"].map(item=>{
											item.pending = true;
											return item;
										})
						};
					})
			})
		});
	}

	async init()	{		
		this.pageInfo.empties 				= 	{
			bookings: [],
			services: []
		};
		this.pageInfo.tabs					=	tabs;
		this.pageInfo.filterFomentoByTime	= 	true;
		this.pageInfo.table 				=	{
			height: '65vh',
			border: '1px solid #f0f0f0',
			rowExpansionWidth: '85vw'
		};

		this.pageInfo.search 				= 	{
			show	: 	false,
			content	: 	"",
			fields	: 	[
				"reference",
				"customer"
			]
		};
				
		// Subscribe to Importer Service
		this.importerService.eventEmitter.subscribe($info=>{
			switch($info.action){
				case "persist"	:	
					this.commons.generateToast("IMPORTER","Booking persisted","info");
					// this.commons.generateToast("IMPORTER","Booking persisted "+$info["succeded"]+". Error "+$info["errors_qty"],"info");
					console.log($info);
					break;
			}
		});

		// Subscribe to CSV importer
		this.CsvService.eventEmitter.subscribe($info=>{ this.doImportedPickups($info); });
		
		this.pageInfo.dialogs				= {
			"splitGroup"	: { params: {}, openned	: false }
		};

		this.pageInfo.currencySign			= "€";

		this.pageInfo.filters 				= filters;
		this.arrivals.container				= null;
		this.departures.container			= null;
		this.arrivals.transports 			= [];
		this.departures.transports			= [];

		this.pageInfo.transporter_options	= transporterAssignOptions;

		this.companyService.subscribeDestination(
				this.commons.userInfo.currentDmc.id, 
				this.commons.userInfo.currentDestination.id)
			.subscribe(data => {
				this.pageInfo.destinationInfo		= data.payload.data()
				// this.bookingsService.setInfo('destinationRef', data.payload.ref);
				// this.bookingsService.setInfo('lodging_alias', data.payload.data()['lodging_alias'] || {})
				// this.pageInfo.destinationRef	=	data.payload.ref;
				// this.pageInfo.lodging_alias 		= data.payload.data()['lodging_alias'] || {};
				this.loadEntities();
				this.initFilters();
			}
		);

		this.generateMenuCols("groups");
		this.generateMenuCols("services");

		this.companies					= 	await this.commons.translateRecursively	({
												items	: 	[
													{ label: "Transportes Transunion",	value: 1 	},
													{ label: "Transportes Comas", 		value: 2 	}
												],
												selected: "all"
											});
		this.pageInfo.elem 				= 	document.documentElement;

	}

	async staticInit()									{
		this.pageInfo.debug					=	true;
		this.pageInfo.bookingsButtons		= 	{ showErrors: false };
		this.pageInfo.uploadedFiles			= 	[];
		this.pageInfo.serviceFilters		= 	this.serviceFiltersService.getServiceFilters();
		this.pageInfo.selectedRoutes		= 	{	items: [], bookingPanelOpen: false };
		this.pageInfo.providerRestrictions	= 	providerRestrictionFields.map(item=>{ item["label"] = this.commons.getTranslate(item.name); return item; });
		this.pageInfo.wheel					= 	{ xFactor: 2, yFactor: 1, sliderFactor: 50 };
		this.pageInfo.noData				= 	{
													header	: {
														title	: "_WELCOME_COMPOSITOR_TITLE",
														message	: "_WELCOME_COMPOSITOR_DESC",
													},
													items	: [
														{ icon	: "bus",			message	: "_WELCOME_COMPOSITOR_ITEM_1" },
														{ icon	: "calendar",		message	: "_WELCOME_COMPOSITOR_ITEM_2" },
														{ icon	: "hand-o-right",	message	: "_WELCOME_COMPOSITOR_ITEM_3" },
														{ icon	: "floppy-o",		message	: "_WELCOME_COMPOSITOR_ITEM_4" }
													]
												};
		this.pageInfo.help					= 	help;
		this.pageInfo.timeRangeValues		= 	{
													min			: 0,
													max			: 23,
													values		: [ 0, 23 ],
													initTime	: 0
												};
		this.pageInfo.entities				= 	await this.commons.translateRecursively({
													strategies	:	[ 	{ name: '_STRATEGY_SOFT'		},
																		{ name: '_STRATEGY_STANDARD'	},
																		{ name: '_STRATEGY_AGGRESSIVE'	}
																	]
													},
													{ children: 'strategies', label: 'name' }
												);
		this.pageInfo.execParams			=	{ ...execParams, entities: this.pageInfo.entities };
		this.pageInfo.controlPanels 		=	controlPanels;
		this.pageInfo.height 				= 	heightInfo;
		this.pageInfo.colors				=	colorInfo;
		this.pageInfo.calendar				=	calendarInfo;
		this.pageInfo.servicesGrid			=	servicesGrid;
		this.pageInfo.servicesGrid.cols		=	await Promise.resolve(this.commons.translateRecursively(servicesCols, { label: 'header' }));

		this.pageInfo.rowData				= 	{};
		this.pageInfo.servicesVehicle		= 	{};

		// Set initial scale factor
		this.setScale(this.pageInfo.calendar.scaleFactor);

		this.pageInfo.export	= {
			items			: [
				{ label: 'CSV', icon: 'pi pi-refresh', command: () => { this.export('csv') }},
				{ label: 'PDF', icon: 'pi pi-refresh', command: () => { this.export('pdf') }},
			]
		}

		this.pageInfo.panels	= {		transporters	: false		};
	}

	setScale($value)					{	this.pageInfo.calendar.scaleFactor	= $value;
											this.pageInfo.calendar.scaleX		= this.pageInfo.calendar.scaleFactorXInitial * this.pageInfo.calendar.scaleFactor;
											this.pageInfo.calendar.scaleY		= this.pageInfo.calendar.scaleFactorYInitial * this.pageInfo.calendar.scaleFactor;
											this.pageInfo.calendar.buttons.scaleButtons.items.forEach(item=>item.value=parseInt(item.name)==this.pageInfo.calendar.scaleFactor);
										}

	export($format,$entity?,$info?)		{
		if(undefined==$entity){
			this.commons.generateToast("Export","Generating "+$format+" for "+this.pageInfo.calendar.view.selected,"success");
			return;
		}
		let calendar;
		switch($entity){
			case "services"		:	let header = [
										"_DIRECTION",
										"_NAME",
										"_VEHICLE",
										"_TYPE",
										"_DATE",
										"_TIME",
										"_AREA",
										"_ZONE",
										"_LODGINGS",
										"_BOOKINGS"
									];
									let items = this.getFilteredEntity('services',{ type: 'grid' })
													.map(item=>{
														let service = {};
														service["direction"	]	= item.direction;
														service["name"		]	= item.name;
														service["vehicle"	]	= item.vehicle;
														service["type"		]	= item.type;

														switch(item.direction){
															case "arrival":
																service["date"]	= item.date;
																service["time"] = item.pickupTime;
																break;
															case "departure":
																service["date"]	= item.date;
																service["time"] = item.pickupTime;
																break;
														}

														service["area"		]	= item.area;
														service["zone"		]	= item.zone;
														service["bookings"	]	= (item.bookings||[]).join(',');
														service["hotels"	]	= (item.hotels	||[]).join(',');

														return service;
													});

									items = [ header.map(h=>this.commons.getTranslate(h)), ...items ];

									switch($format){
										case "csv"	: this.commons.export("csv",items,"TMT_Services_"+this.commons.getToday('YYYYMMDD')); break;
									}
									break;

			case "transporter"	:	calendar = this.getTransportersCalendar();
									console.log("[export] Transporter calendar", calendar);
									switch($format){
										case "csv"	: 	alert("CSV"); break;
										case "xls"	: 	alert("XLS"); break;
										case "xml"	: 	alert("XML"); break;
										case "pdf"	: 	alert("PDF"); break;
									}
									break;

			case "driver"		:	calendar = this.getDriversCalendar();
									console.log("[export] Driver calendar", calendar);
									break;

			case "fomento"		:	console.log("[export] Transport info", $info);
									switch($format){
										case "csv"	: 	this.fomentoService.sendServices({
															// data	: this.getTransportsCalendar(),
															data	: this.getTransportsCalendarGroups(),
															filter	: this.pageInfo.filterFomentoByTime
														});
														break;
									}
									break;
			}
	}

	async loadEntities() 				{
		await this.load('drivers');
		await this.load('fleet');
		await this.load('transporters');
		await this.load("providers");
		// await this.load("zones");

		// await this.load('groups');
		// await this.mockCalendarCall('20221022');
		this.calendar.date = this.commons.getToday('YYYY-MM-DD');
	}

	generateTransportFromGroup(type)	{}
	clearContainer(type)				{}

	setInfo($entity, $params){
		switch($entity){
			case "group"		:	switch($params.action){
				case "toggle"	:	$params.item.open = $params.item.open?false:true; break;
			}
		}
	}

	/**
	 * get info related to transporter
	 * @param $data
	 * @returns
	 */
	getTransporterInfo($data){
		let $info = $data.item;
		switch($info.rowData["transporter_status"]){
			default					:
			case "_NOT_SENT"		:	return "gray";
			case "_PENDING"			:	return "orange";
			case "_PENDING_ERROR"	:	return "crimson";
			case "_ACCEPTED"		:	return "forestgreen";
			case "_REJECTED"		:	return "crimson";
			case "_CANCELLED"		:	return "crimson";
			case "_DONE"			:	return "green";
		}
	}

	getInfo($entity, $params){
		switch($entity){
			case "time_in_min"			:	// "04:00" > 240
				return parseInt($params["value"].slice(0,2))*60+parseInt($params["value"].slice(3,2));

			case "min_in_time"			:	// 240 > "04:00"
				const hour = $params["value"]/60;
				const min  = $params["value"]%60; 
				return hour.toString().padStart(2,'0')+min.toString().padStart(2,'0');				

			case "hour_in_time"			:	// 4 > "04:00"
				return $params["value"].toString().padStart(2,'0')+":00";				

			case "initTime"				:
				this.pageInfo.timeRangeValues.initTime = Math.max(	
					this.pageInfo.timeRangeValues.min,
					Math.min(	
						this.pageInfo.timeRangeValues.max,
						this.pageInfo.timeRangeValues.initTime + Math.ceil($params["event"].deltaY/this.pageInfo.wheel.sliderFactor)
					)
				);
				return this.pageInfo.timeRangeValues.initTime;
				break;

			case "init_service_time"	:				
				// Get from destination
				if(undefined!==this.commons.userInfo.currentDestination.init_service_time){
					return this.commons.userInfo.currentDestination.init_service_time;
				}
				// Get from commons
				if(undefined==this.pageInfo.timeRangeValues.initTime){
					return this.commons.pageInfo.init_service_time || "00:00";
				}
				// Get from page
				return this.pageInfo.timeRangeValues.initTime.toString().padStart(2,'0')+":00";				

			case "init_service_time_min":
				if(undefined==this.pageInfo.timeRangeValues.initTime){
					return this.commons.pageInfo.init_service_time_min*60 || 0;					
				}
				return this.pageInfo.timeRangeValues.initTime*60;

			case "bookings"				:
				switch($params.type){
					case "color":
						switch($params.col.field){
							case "transporter_status"	: return $params.rowData.transporter_pending?"crimson":"";
						}
						break;
					case "has_errors":
						if($params.rowData.zone==undefined){ return true; }
						return false;
				}
				break;

			case "empties"		:
				switch($params.type){
					case "bookings"	: return this.pageInfo.empties.bookings || [];
				}
				break;

			case "rowData"		: 	switch($params.type){
					case "statusColor"	:
						if($params.rowData["assigned"]			){ return "purple"; }
						if($params.rowData['verified']=='yes'	){ return 'green';	}
						return 'gray';

					case "statusTransporterColor"	:
					return this.getTransporterInfo({ type: 'color', item: $params })
				}
				break;

			case "transporter"	:
				switch($params.type){
					case "filter_color"			:	return $params.item.forced?"crimson":"forestgreen";
					case "options"				:	return this.pageInfo.transporter_options;
				}
				break;

			case "grid"			:	switch($params.type){
										case 'cols'	: return $params["items"];
									}
									break;

			case "time"			:	switch($params.type){
										case "min_in_time"			:
											let time = $params.item;
											let hour = parseInt(time)/60;
											let min  = parseInt(time)%60;
											return hour.toString().padStart(2,'0')+":"+min.toString().padStart(2,'0');
									}
									break;

			case "importer"		:	switch($params.type){
										case "color"				:	return $params.item.forced?"crimson":"forestgreen";
										case "active"				:	return this.pageInfo.importerFilters.filter(filter=>filter.active);
									}
									break;

			case "calendar"		:	switch($params.type){
										case "transportsCalendar"	:	switch($params.query){
																			case "hasItems"		:	return this.transportsCalendar.rows.some(row=>(row.items||[]).length>0);
																		}
																		break;
										case "tabs"					: 	return this.pageInfo.calendar.view.items;
									}
									break;

			case "service"		:
				switch($params.type){
					case "external_service_status_color":
						switch($params.item.status){
							default				:
							case "_NOT_SEND"	:	return "gray";
							case "_PENDING"		:	return "orange";
							case "_ACCEPTED"	:	return "forestgreen";
							case "_REJECTED"	:	return "crimson";
						}
						break;
					case "bookings"				:	switch($params["sort"]){
														default				:
														case "arrival_time"		: return ($params["item"]["bookingsInfo"]||[]).sort((a,b)=>a.init_timestamp>=b.init_timestamp?1:-1);
														case "departure_time"	: return ($params["item"]["bookingsInfo"]||[]).sort((a,b)=>a.pickupTime_timestamp>=b.pickup_timestamp?1:-1);
													}
													break;

					case "_pillow_color"		:	return "rgba(255,255,0,0.3)";
					case "_pillow_color"		:	return "linear-gradient(0, lightyellow 0%, yellow 50%, lightyellow 100%)";
					case "_pillow_color"		:	return "transparent";
					case "pillow_color"			:	return "crimson";
					case "pillow_border"		:	return $params.item.pillow==0?"":"0px solid crimson";
					case "pillow_width"			:	return "1rem";
					case "pillow_height"		:	return ($params.item.pillow*this.pageInfo.calendar.scaleY)+"px";
					// case "pillow_width"			:	return ($params.item.pillow*this.pageInfo.calendar.scaleX)+"px";
					case "assignedColor"		:	let is_private  = $params.item.private ?"private":"shuttle";
													let is_assigned = $params.item.assigned?"assignedPax":"notAssignedPax";
													return is_private+"_"+is_assigned;
					case "filters"				:	return this.pageInfo.serviceFilters;
					case "filter_color"			:	return $params.item.forced?"crimson":"forestgreen";
					case "filter_report_color"	:	const exec = this.commons.pageInfo.lastServiceFiltersExec;
													let filter = (exec||[]).find(item=>item.filter==$params["item"].name);
													if(filter){
														return filter.status?"forestgreen":"Crimson";
													}
													break;
					case "active_filters"		:	return this.pageInfo.serviceFilters.filter(filter=>filter.active);
					case "is_assigned"			:	let assigned = false;
													assigned = this.transportsCalendar.rows.some(row=>{
														if(row.empty){ return false; }
														let foo = (row.items||[]).some(item=>item.id==$params["service"].id);
														return foo;
													});
													return assigned;
				}
				break;

			case "tab"			:	let cls 	= this.pageInfo.calendar.view.selected==$params["tab"].name?'selected':'';
									switch($params["tab"]["name"])	{
										case "drivers"		:	$params["tab"].disabled = false;
																// if((this.getDriversCalendar()|| [] ).length==0)	{
																// 	$params["tab"].disabled = true	; cls+=' disabled';
																// } else	{	$params["tab"].disabled = false	;	}
																break;
										case "transporters"	:
											$params["tab"].disabled = false;
											// if((this.getTransportersCalendar('nonEmpty') || [] ).length==0)	{
											// 	$params["tab"].disabled = true	; cls+=' disabled';
											// } else	{	$params["tab"].disabled = false	;	}
											break;
									}
									return cls;

			case "langs"		:	switch($params["type"])	{	case "icon"			    :	return "/assets/layout/icons/flags/"+$params["lang"]+".png";	}	break;

			case "groups"		:	switch($params)			{
										case "noItems"			: 	return ((this.groups||{}).count || 0 )==0;
									}
									break;
			case "group"		:	let group = $params["group"];
									if(undefined==group){
										console.log("NO GROUP");
									}
									switch($params["type"]){
										case "bookings"			:	switch(group.direction){
																		case "arrival":
																			return group.bookingsInfo.sort((a,b)=>a.arrival_Time>b.arrival_Time?1:-1);
																		case "departure":
																			return group.bookingsInfo.sort((a,b)=>a.departure_PickupTime>b.departure_PickupTime?1:-1);
																	}
																	break;
										case "is_empty"			:	return group.empty || (group.bookings||[]).length==0;
										case "flag"				:	return "/assets/layout/icons/flags/es.png";
										case "pax"				:	return group["bookings"]
																		.filter(item=>{
																			switch($params["assigned"]){
																				default		:	return true;
																				case true	:	return item.assgigned;
																				case false	:	return !item.assigned;
																			}
																		})
																		.reduce((acc,item)=>acc+parseInt(item.pax),0);
										case "firstTime"		:	return group["bookings"].reduce((min,item)=>min<item.time?min:item.time,100000);
										case "lastTime"			:	return group["bookings"].reduce((max,item)=>max>item.time?max:item.time,0);
										case "timeRange"		:	return group["bookings"].reduce((range,item)=>{
																		range['min'] = range.min<item.init_timestamp?range.min:item.init_timestamp;
																		range['max'] = range.max>item.init_timestamp?range.max:item.init_timestamp;
																		range["diff"]= (range.max-range.min)/60;
																		return range;
																	},{min:1000000000000,max:0})['diff'];
									}
									break;

			case "formatRange"	:	
				return ($params["value"]<10?'0':'')+$params["value"]+":00";

			case "timeRange"	:	let value = this.pageInfo.timeRangeValues.values[$params];
									return (value<10?'0':'')+value+":00";

			case "driver"		:	switch($params["attribute"])	{
										case "thumbnail"		:	return $params["driver"]["avatar"] || "/assets/layout/images/drivers/0.png";
										case "thumbnailFromId"	:	const emptyDriver	= "/assets/layout/images/drivers/0.png";
																	if(undefined==$params["item"]){ return emptyDriver; }
																	let driver 			= this.drivers.data.find(driver=>driver.id==$params["item"]["id"]);
																	return driver && driver["avatar"]?driver["avatar"]:emptyDriver;
									}
									break;

			case "provider"		:	switch($params.type){
										case "serviceColor"		:	switch(parseInt($params.provider)){
																		case 1	:	return "blue";
																		case 2	:	return "forestgreen";
																		default	:	return "orange";
																	}
																	break;

										case "color"			:	switch(parseInt($params.provider)){
																		case 1	:	return "blue";
																		case 2	:	return "forestgreen";
																		default	:	return "orange";
																	}
																	break;

										case "restrictions"		:	let providerData = ( this["providers"].data || [] ).find(item=>item.selected) || {};
																	return this.pageInfo.providerRestrictions.map(item=>{
																		item.value = undefined==providerData["restrictions"]?item.default:providerData["restrictions"][item.name];
																		return item;
																	});

										case "selected"			:	return this["providers"].selected || {};

										case "selecteds"		:	return this["providers"].data.filter(item=>item.selected);

										case "_isSelect	ed"		:	if( null == this.providers.selected ){ return false; }
																	return this.providers.selected.id== $params.provider.id;

										case "isSelected"		:	return $params.provider.selected;

									}
			case "transferCol"	:	switch($params){
										case "height"				:	const v = this.pageInfo.calendar; return v.scaleOffset+(v.scaleFactorInitial*v.scaleRange*v.scaleFactor)+'px';
										case "serviceOnOverBorder"	:	return "10px solid red";
									}
			case "exec"			:	switch($params){
										case "params"			:	return this.pageInfo.execParams;
									}
		}
	}

	async initFilters()				{	
		// Zone filtering
		this.pageInfo.zonesFilter			= zonesFilter;
		this.pageInfo.zonesFilter.items		= ( this["providers"].selected || {}).zones;
		this.pageInfo.zonesFilter.selected	= "";
	}

	getFilterButtonClass($filter,$item)	{	if ( $filter.multiple )	{	return $filter.selected==$item.name?'selected':'';		}
											else 					{	return $item.value?'selected':'';						}
										}

	/**
	 * after any calendar update checks with plates are still assigned
	 */
	assignPlates($params){
		let fleet 		= this.fleet.draggableSet 		|| [];
		let transports	= this.transportsCalendar.rows 	|| [];

		switch($params["type"]){
			case "nonEmpty":	// First unassign all plates
								fleet.forEach(type => {
									(type.vehicles||[]).forEach(item=>{
										item.status="unassigned";
									})
								});

								// Finally assign only plates with real services
								transports.forEach(row=>{
									fleet.forEach( type => {
										if(null==type.vehicles){ return; }
										let current = type.vehicles.find(item=>item.plate==row.plate);
										if(undefined!==current){
											current.status="assigned";
										}
									});
								})
								break;

			case "plate"	:	fleet.forEach( type => {
									if(null==type.vehicles){ return; }
									let current = type.vehicles.find(item=>item.plate==$params["plate"]);
									if(undefined!==current){
										current.status=$params["status"];
									}
								});
		}
	}

	remove($type,$info={},$event=null){

		if($event){ $event.cancelBubble=true; }

		switch($type){
			case "calendarRow"		:	$info["row"]["empty"	] 	= true;
										$info["row"]["assigned"	]	= false;

										delete $info["row"]["items"];

										this.remove('calendarEmptyRows');
										break;

			case "calendarEmptyRows":	// Remove empty row
										this.transportsCalendar.rows = this.transportsCalendar.rows.filter(item=>item.empty || (item.items||[]).length>0);
										// ASsgin plates with services row
										this.assignPlates({ type: "nonEmpty" });
										break;

			case "calendarVehicle":		this.assignPlates({ type: "plate", plate: $info["row"].plate, status: "unassigned" });
										$info["row"].plate 	= undefined;

										($info["row"].items||[]).forEach(group=>{
											group.plate		= undefined;
											(group.bookingsInfo||[]).forEach(bi=>{
												bi.plate	= undefined;
											});
										})

										this.toggle('vehicle_plate_options',$info["row"],false);
										break;

			case "calendarVehicleType":	($info["row"].items||[]).forEach(group=>{
											group.assigned	= false;
											group.row		= undefined;
											group.vehicle 	= undefined;
											group.plate		= undefined;
											(group.bookingsInfo||[]).forEach(bi=>{
												bi.vehicle	= undefined;
												bi.plate	= undefined;
											});
										})

										// Remove frome calendar
										this.transportsCalendar.rows = this.transportsCalendar.rows.filter(row=>row.name!=$info["row"].name);

										// Update ownFleet item
										let vehicle = this.ownFleet.find(i=>i.name==$info["row"].code);
										if(vehicle){
											vehicle.assigned = false;
										}
										break;

			case "calendarTransport":	if(undefined!=$info["row"]){
											$info["row"].items 		= $info["row"].items.filter(group=>group.name!=$info["item"].name);
											$info["item"].assigned 	= false;
											$info["item"].vehicle	= undefined;
											$info["item"].plate		= undefined;
											($info["item"].bookingsInfo||[]).forEach(bi=>{
												bi.vehicle	= undefined;
												bi.plate	= undefined;
												bi.assigned	= false;
											});
										}
										break;

			default					:	alert("[remove] type "+$type+" not allowed");
		}
	}

	getServicesByTime($slot="hour"):any{
		let plain_services = [], services = {}, factor;
		switch($slot){
			default:
			case "hour":	factor = 60; break;
		}

		this.getFilteredEntity('groups').forEach(group=>{
			// plain_services = [ ...plain_services, ...group.transports ];
			plain_services.push(group);
		});

		let services_by_key = plain_services.reduce((o,item)=>{
			let hour 			= Math.trunc(parseInt(item.init)/factor);
			let formatted_hour 	= (hour>9)?hour+":00":"0"+hour+":00";
			// o[formatted_hour]	= {
			// 	hour	: formatted_hour,
			// 	items	: [...((o[formatted_hour]||{}).items||[]), item]
			// };

			if(item.vehicle){
				item.assigned = true;
			}

			// Plain booking info into service
			switch(item.private){
				case true:	if((item.bookings||[]).length>0){
								let booking			= item.bookings[0];
								item.customer		= booking.customer;
								item.area			= booking.area;
								item.airport		= booking.airport;
								item.flight			= booking.flight;
								item.reference		= booking.reference;
								item.time			= booking.time;
							}
							break;
			}

			if(undefined==o[formatted_hour]){
				o[formatted_hour]	= {
					hour	: formatted_hour,
					items	: []
				}
			}
			o[formatted_hour].items.push(item);
			return o;
		},{});

		services = Object.keys(services_by_key).reduce((a,key)=>{
			a.push(services_by_key[key]);
			return a;
		},[])

		return services;
	}

	// -----------------------------------------------------------------------------------
	// RENDERING METHODS
	// -----------------------------------------------------------------------------------

	areaEditor($me, $type, $col, $items){	alert('AREA EDITOR'); }
	getFieldEditor($col) 				{ 	return $col.editor ? $col.editor : 'input'; }
	getRendererType($type) 				{	
		switch ($type) {
			case 'imported'		: return (type, col, items) => this.importedRenderer(type, col, items);
		}
	}

	getRenderer($type, $col, $items) 	{
		// console.log("[getRenderer]",$type,$col,$items);
		return $col.renderer
			? this.getRendererType($col.renderer)($type, $col, $items)
			: this.defaultRenderer($type, $col, $items);
	}

	getRendererNew($params)				{
		let $type 	= $params["type"	];
		let $col	= $params["col"		];
		let $items	= $params["items"	];
		let $name	= $params["name"	];

		// console.log("[getRenderer]",$name,$type,$col,$items);
		return $col.renderer
			? this.getRendererType($col.renderer)($type, $col, $items)
			: this.defaultRenderer($type, $col, $items);
	}

	// getRenderer($type, $col, $items) 	{	return this.defaultRenderer($type, $col, $items);	}

	defaultRenderer($type, $col, $items){
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	:
				return Array.isArray($items[$col.field])
						?$items[$col.field].join(",")
						:$items[$col.field];
		}
	}

	defaultStyleRenderer($col) 			{	return {
												'width'		: $col.width ? $col.width : '',
												'text-align': $col.align ? $col.align : ''
											};
										}

	importedRenderer($type, $col, $items){
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	:
				switch($items[$col.field]){
					case false	:	return "";
					case true	:	return "<i class='fa fa-times'></i>";
				}
		}
	}

	onSort() 							{ //console.log("onsort");
										}

	// -----------------------------------------------------------------------------------
	// END RENDERING METHODS
	// -----------------------------------------------------------------------------------

	/**
	 * Retrieve services from group depending on status
	 *
	 * @param item
	 * @param status
	 * @returns
	 */
	getGroupServices(group,status:any='all'){
		let services = group.transports
						.filter(item=>{
							switch(status){
								case "pending"	: return !item.assigned;
								default			: return true;
							}
						})
						.map(service=>{
							service.type		= group.type;
							service.zone 		= group.zone;
							service.firstTime	= group.firstTime;
							service.lastTime	= group.lastTime;
							service.pax			= group.pax>service.seats?service.seats:group.pax;
							// service.pax			= item.pax-service.seats>0?item.pax-service.seats:0;
							return service;
						});

		return services;
	}

	/**
	 * Retrieve ervicses by type and status
	 *
	 * @param status
	 * @returns
	 */
	setServices(){
		let services = [];
		// console.log("SERVICES VEHICLE",this.pageInfo.servicesVehicle);
		this.getFilteredEntity('groups').forEach(group=>{
			services =	[ 	...services,
							...(group.transports||[])
								.map(service=>{
									// "id", "vehicle", "time", "direction", "from", "to", "pax"
									// service.vehicle 	= group.name,
									service.type			= group.type;
									service.zone 			= group.zone;
									service.firstTime		= group.firstTime;
									service.lastTime		= group.lastTime;
									service.pax				= group.pax>service.seats?service.seats:group.pax;
									service.time			= group.firstTime,
									service.direction 		= group.direction;
									service.area			= service.area || group.area;
									service.zone			= service.zone || group.zone;
									service.private			= group.private;

									if((group["bookings"]||[]).length>0){
										let booking				= group["bookings"][0];
										service.location 		= booking.accommodation;
										service.accommodation	= booking.accommodation;
									}

									if(service.vehicle){
										service.vehicle_plate	= service.vehicle.plate;
										service.vehicle_code	= service.vehicle.code;
									} else if(service.id){
										service.vehicle			= this.pageInfo.servicesVehicle[service.id];
									}
									if(service.vehicle){
										service.vehicle_plate	= service.vehicle.plate;
										service.vehicle_code	= service.vehicle.code;
									} else {
										service.vehicle			= {};
										service.vehicle_plate	= null;
										service.vehicle_code	= null;
									}
									let booking 			= service.bookings[0];

									switch(group.private){
										case true		: 	// PRIVATE
															if((group["bookings"].length>0)){
																service.area = group["bookings"][0].area;
															}
															break;
										case false		:	// SHUTTLES
									}

									switch(service.direction){
										case "arrival"	: 	service.from 		= booking.airport;
															service.to			= service.area;
															break;
										case "departure": 	service.from 		= service.area;
															service.to			= booking.airport;
															service.PickupTime	= group.PickupTime;
															break;
									}
									return service;
								})
						];
		});

		this.services.items					= services;
		this.pageInfo.servicesGrid.total 	= (services||[]).length;
		this.pageInfo.servicesGrid.paginate	= services;
	}

	/**
	 * get services
	 *
	 * @param $info
	 * @returns
	 */
	getServices($info){
		let services = (this.groups.data||[])
			.filter(item=>{
				switch($info.assignation_type){
					case "assigned"		:	return item.transporter!=undefined;
					case "unassigned"	: 	return item.transporter==undefined;
					default				:
					case "all"			:	return true;
				}
			})
			.map(item=>{
				switch(item.direction){
					case "arrival"	: item.time = item.firstDateTime; 										break;
					case "departure": item.time = item.departure_PickupDateTime.format("YYYY-MM-DD HH:mm");	break;
				}
				switch(item.private){
					case true		: item.type = "private";			break;
					case false		: item.type = "shuttle";			break;
				}

				item.bookingsInfo = [];

				item.bookings.forEach(b=>{
					let bi = this.bookings.data.find(item=>item.reference==b);
					if(undefined!=bi){
						item.bookingsInfo.push(bi);
					}
				})

				item.hotels = item.bookingsInfo.map(item=>{
					switch(item.direction){
						case "arrival"	: return item.arrival_Location;
						case "departure": return item.departure_Location;
						default			: console.log("Wrong direction",item.direction);
					}
				});

				return item;
			});

		// Filter by external transporter if set
		if(this.pageInfo.selectedExternal){
			services = services.filter(s=>s.transporter==this.pageInfo.selectedExternal);
		}

		return services.sort((a,b)=>a.time>=b.time?1:-1);
	}

	 generateTransportsCalendar(){
		// return false;
		return this.transportsCalendar.rows.map((row,index)=>{
			let offset 	= 0;
			let prev;
			row.index	= index;
			row.items 	= (row.items||[])
							.sort((a,b)=>(a.init>b.init)?1:-1)
							.map((item,index)=>{
								item.pillow = this.serviceFiltersService.getPillow(prev,item) || 0;
								return item;
							})
							.map((item,index)=>{
								if(!item.preassigned){
									item.remove 		= false;
									if(undefined!=prev){
										if(prev.end>(item.init-item.pillow)){
											item.remove = true;		// Marked to be removed
										}
									}
									prev = item;
								}
								return item;
							})
							.map(item=>{
								let booking = item.bookings[0];
								item.offset 		= item.init - offset;
								item.size			= item.end  - item.init;
								offset				= item.end;
								return item;
							}) || [];

			// Remove dynamic turns on empty rows
			if((row.items||[]).length==0){
				row.init_timestamp	= undefined;
				row.init 			= undefined;
				row.end_timestamp	= undefined;
				row.end				= undefined;
			}

			// Remove all non gapped elements
			row.items.forEach(item=>{
				if(item.remove){
					this.remove("calendarTransport",{ item: item, row: row });
					item.remove = false;
				}
			})
			return row;
		})
	 }

	/**
	 * get calendar of services
	 *
	 * @param $type
	 * @returns
	 */
	getTransportsCalendar($type='all',$info={}){

		let generatedRows = this.generateTransportsCalendar();
		let rows;

		switch($type){
			case "all"		:	rows = generatedRows;									break;
			case "empty"	:	rows = generatedRows.filter((item,index)=>index==0);	break;
			case "nonEmpty"	:	rows = generatedRows.filter((item,index)=>index>0);

								// Retrieve rows and empty rows with at least 15 elements
								// to avoid empty screen
								const minRows 	= 15;
								const emptyRows	= Math.max(0,minRows-rows.length);

								let order	= 	this.pageInfo.calendar.buttons.servicesButtons.selectors.order.selected;

								switch(order){
									case "service_order_by_first":
										rows = rows.sort((a,b)=>{
											let a_first = a.items[0];
											let b_first = b.items[0];
											if(undefined == a_first || undefined == a_first.pickupTime ){ return -1; }
											if(undefined == b_first || undefined == b_first.pickupTime ){ return  1; }
											return a_first.pickupTime >= b_first.pickupTime ?1:-1;
										});
										break;
									case "service_order_by_name":
										rows = rows.sort((a,b)=>a.name>=b.name?1:-1);
										break;
								}

								rows = [ ...rows, ...Array.from(Array(emptyRows).keys()).map(item=>({ empty: true, seats: 0 })) ];
								return rows;
								break;

			default			:	return [];
		}

		return rows.sort((a,b)=>{
			if( a.empty &&  b.empty	){ return  1; 	}
			if( a.empty && !b.empty	){ return -1; 	}
			if(!a.empty &&  b.empty	){ return  1;	}
			return (a.name>b.name?1:-1);
		});
	}

	getTransportsCalendarGroups(){
		let items = [];
		return this.transportsCalendar.rows;
		// this.transportsCalendar.rows.forEach(row=>{
		// 	row.items.forEach(group=>{
		// 		items.push(group);
		// 	});
		// });
		return items;
	}

	getDriversCalendar()					{	return this.driversCalendar.rows.filter(item=>this.drivers.data.some(driver=>undefined!==item.driver?driver.id==item.driver.id:false));	}

	getTransportersCalendar($mode="full")	{
		let items 		= [];
		let services 	= [];

		switch($mode){
			case "real"			:
				// transportersCalendarFakeItem.items = (transportersCalendarFakeItem.items||[])
				// 	.map((item:any)=>{
				// 		switch(item.type){
				// 			case "private": break;
				// 			case "shuttle": break;
				// 		}
				// 		item = this.updateExternalServicesAction(item);
				// 		return item;
				// 	});

				// this.pageInfo.transportersCalendar = [ transportersCalendarFakeItem ];
				this.pageInfo.transportersCalendar = [];

				services = this.getFilteredEntity('services',{ type: 'grid' });
				(this.getFilteredEntity("transporters")||[]).forEach(transporter=>{
					let info = {};
					if(transporter.name=="_INNER_FLEET"){ return false; }
					info["transporter"]			= transporter;
					info["transporter"].name	= transporter.name;
					info["items"]				= (services||[])
													.filter(service=>service.transporter==transporter.name)
													.map((item:any)=>{
														switch(item.type){
															case "private": break;
															case "shuttle": break;
														}
														item = this.updateExternalServicesAction(item);
														return item;
													});
					this.pageInfo.transportersCalendar.push(info);
				})
				return this.pageInfo.transportersCalendar;

			case "nonEmpty"		:
				items = 	this.transportersCalendar.rows.filter(transporter=>{
								if(transporter.empty				){ return false; }
								if((transporter.items||[]).length==0){ return false; }
								transporter.items = (transporter.items||[]).filter(item=>{
									if(item.assigned==false			){ return false; }
									if(item.empty==true				){ return false; }
									if((item.items||[]).lenght==0	){ return false; }
									return true;
								})
								return true;
							});
				break;

			case "full"		:
				services = this.getFilteredEntity('services',{ type: 'grid' });
				this.pageInfo.transportersCalendar = [];
				services
					.filter(service=>service.transporter!="_INNER_FLEET")	// Remove fleet
					.forEach(service=>{
						if(service.transporter==undefined){
							service.transporter = service.vehicle;
						}
						let transporter = this.pageInfo.transportersCalendar.find(item=>item.transporter.name==service.transporter);
						if(!transporter){
							transporter	= {
								transporter : {
									name	: service.transporter
								},
								items 	: []
							};
							this.pageInfo.transportersCalendar.push(transporter);
						}
						transporter.items.push(service);
					})
				return this.pageInfo.transportersCalendar;
		}
		// console.log("[getTransportersCalendar] items",items);
		return items;
	}

	generateDriversCalendar()				{
		let driverSet				= this["drivers"].draggableSet;
		let driverRows 				= {};
		this.driversCalendar.rows 	= [];

		return false;

		// Generate driver rows
		this.transportsCalendar.rows.forEach(row =>	{
			(row.items||[]).filter(item=>item.driver!==undefined).forEach(item=>{
				if(undefined===driverRows[item.driver.id]){ driverRows[item.driver.id] = []; }
				driverRows[item.driver.id].push(item);
			});
		});

		// Generate array from driver keys

		Object.keys(driverRows).forEach(driver=>{
			let info = driverSet.filter(current=>current.id==driver);
			this.driversCalendar.rows.push({
				driver 	: info[0],
				// driver	: driverRows[driver][0]["driver"],		// HACK ! Change to previous line once calendar drivers come from Firebase
				items	: driverRows[driver]
			});
		});

		// Filter each driver rows by time
		this.driversCalendar.rows.forEach(row=>{
			row.items = row.items.sort((a,b)=>a.firstTime>b.firstTime?1:-1);
		})

		this.driversCalendar.rows = this.driversCalendar.rows.map(row=>{
			let offset 	= 0;
			row.items 	= row.items.map(item=>{
				item.init 	= item.firstTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
				item.end 	= item.lastTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
				item.offset = item.init - offset;
				item.size	= item.end  - item.init;
				offset		= item.end;
				return item;
			});
			return row;
		});
	}

	generateTransportersCalendar()			{
		this.transportersCalendar.rows	= this.transporters.draggableSet
												// .filter(item=>item.name!="_INNER_FLEET")
												.map(transporter=>	({
													transporter	: transporter,
													items		: this.transportsCalendar.rows.filter(item=>item.transporter==transporter.name)
												}));
	}

	getTabInfo($type,$tab,$panel,$params={})	{	switch($type){
														case "select"	:	if( !this.getTabInfo('disabled',$tab,$panel) ){ $panel.selected = $tab.name; }; break;
														case "disabled"	:	if(undefined==$tab["isDisabled"]){ return false; }
																			return this.isDisabled($tab["isDisabled"],$tab);
														case "selected"	:	return $panel["selected"]==$tab["name"]?'selected':'';
													}
												}

	getVehicleRowBackground($params){
		let item = $params["item"];
		if(item["row"] && item["time"]){
			let row		= item["row"];
			let time 	= parseInt(item["time"])*60;
			let disabled = false;
			if(time<row.init){
				if(row.end>24*60){	// Wrap around day
					let initHour 	= (row.end/60)%24;
					disabled 		= !(time<(initHour*60));
				} else {
					disabled = true;
				}
			}
			if(time>=row.end ){ disabled = true; }
			return disabled?"rgba(255,0,0,0.05)":"";
		}
	}

	getItemInfo(type,info,item:any={})	{
		const scaleFactorXInitial 	= this.pageInfo.calendar.scaleFactorXInitial;
		const scaleFactorYInitial 	= this.pageInfo.calendar.scaleFactorYInitial;
		const scaleFactor			= this.pageInfo.calendar.scaleFactor;
		const scaleX 				= this.pageInfo.calendar.scaleX;
		const scaleY 				= this.pageInfo.calendar.scaleY;

		switch(type){
			case "timer"		:
				switch(info)	{
					case "height"			: 	return ((this.pageInfo.calendar.minutHeight*60*scaleY)-1)+'px';
					case "background"		:	return this.getVehicleRowBackground({ item: item });
				}
				break;
			case "transfer"		:
				switch(info)	{
					case "width"			:	return this.pageInfo.calendar.itemWidth;
					case "width_options"	:	return this.pageInfo.calendar.itemOptionsWidth;
					case "width_hour"		:	return (this.pageInfo.calendar.minutWidth*60*scaleX)+'px';
					case "width_item"		:	return this.max(item.size*scaleX,this.pageInfo.calendar.itemMinWidth)+'px';
					case "height"			:	return this.max(item.size*scaleY,this.pageInfo.calendar.itemMinHeight)+'px';
					case "offsetY"			:	return ((item.init - ( item.pillow || 0 )) * scaleY )+'px';
					case "offsetX"			:	return ((item.init - ( item.pillow || 0 )) * scaleX )+'px';
					case "zindex"			:	return item.zindex || 0;
					case "transporters"		:	return this.companies.items;
					case "options"			:	return [
															[ 1, "shuttle", 55, 15 ],
															[ 2, "shuttle", 40, 10 ],
															[ 3, "shuttle", 30,  8 ],
															[ 4, "shuttle", 20, 25 ]
														];
					case "transporter"		:	return item["item"]["transporter"];
					case "transportType"	:	return item["item"]["name"];
				}
				break;

			case "zones"		:
				switch(info)	{
					case "selected"			:
						if( this.pageInfo.zonesFilter.selected == undefined ){ return ''; }
						if( this.pageInfo.zonesFilter.selected == "" 		){ return ''; }
						let selected = (this.pageInfo.zonesFilter.selected || "").toLowerCase()==(item["zone"] || "").toLowerCase();
						return selected?'zone-selected':'zone-not-selected';
				}
				break;
		}
	}

	min(a,b)					{ return a>=b?b:a; }
	max(a,b)					{ return a>=b?a:b; }

	async saveSolution(){
		this.commons.generateToast("INFO","_PERSISTING_SERVICES","info");

		try {
			this.saveServices(false); // Not publish;
			this.publish("drivers"		);
			this.publish("transporters"	);

			this.commons.generateToast("SUCCESS","_OK_PERSISTING_SERVICES","info");
			return true;

		}catch($e){
			this.commons.generateToast("ERROR","_ERROR_PERSISTING_SERVICES","error");
			console.log("[saveSolution]",$e["message"])
			return false;
		}
	}

	/**
	 * generate groups, set vehicles and put services
	 */
	 private async loadSolution()
	 {
		this["bookings"].spinner = true;
		await this.commons.generateToast("_SOLUTION","_LOADING_SOLUTION","info");
		// await this.commons.generateWaitToast("_SOLUTION","_LOADING_SOLUTION","info");
		this.generateGroups({ mode: "load" });
		this.generateInitRows({ mode: "imported", clear: false });
		this.generateCalendars({ entity: "groups", generator: "clear", mode: "imported" });
		this["bookings"].spinner = false;
	}

// DRAG AND DROP SUPPORT ----------------------------------------------------------------

	dragStart(type,event,item,extra={})	{
		let group;

		switch(type){
			case "booking_group":	this.draggedItem 		= { group: undefined, item: item };
									break;

			case "booking_group":	group = extra["group"];
									// if(this.tabPanel.selected!="custom"){
									// 	this.commons.generateToast("_ERROR","_PROPOSAL_NO_DRAGGABLE","error");
									// 	return false;
									// }
									// group.zone				= group.name==="unassigned"?item.zone:group.zone;
									this.draggedItem 		= { group: group, item: item };
									group.zone				= group.name==="unassigned"?item.zone:group.zone;
									break;

			case "compositor"	:
									// if( undefined !== item.qty && item.qty<=0 ){ return false }
									this.draggedItem 				= item;
									this.draggedItem.source			= extra["source"]?extra["source"]:undefined;
									break;

			case "booking"		:	this.draggedItem				= item; break;
			case "transfer"		:	this.draggedItem				= item; break;
			case "transporter"	:	this.draggedItem 				= item;	break;

			case "transportType":	this.draggedItem 				= item["type"];
									this.draggedItem.transporter	= item["transporter"]["name"];
									break;

			case "vehicle"		:	this.draggedItem				= item.item;
									this.draggedItem.type			= item.type;
									this.draggedItem.transporter	= item.transporter;
									break;

			case "plate"		:	this.draggedItem				= item["item"];
									this.draggedItem.type			= item.type;
									break;

			case "external"		:	this.draggedItem				= item["item"];
									this.draggedItem.type			= item.type;
									break;

		}

		this.draggedItem.to	= type;
	}

	dragEnd(type,event,extra={})		{
		switch(type){
			default				:
			case "plate"		:	this.draggedItem = null; break;
			case "vehicle"		:	this.draggedItem = null; break;
			case "group"		:	this.draggedItem = null; break;
			case "compositor"	:	if(extra["group"]){ extra["group"].open = false; } break;
			case "transporter"	:	break;
			case "transfer"		:	console.log("TRANSFER");
									break;
		}
	}

	dropBooking(transfer){
		if(undefined==transfer){ return false; }
		let success = 	this.doActionBooking('add2Group',{ 
							group	: transfer,
							booking	: this.draggedItem.booking 
						});
		if(success)	{	
			this.doAction('booking','removeFromGroup',	{ group: this.draggedItem.group, 	booking: this.draggedItem.booking });
		}
	}

	dropVehicle(row){
		if(row.empty){ return false; }

		// Check size
		if(this.draggedItem.seats<row.items.reduce((max,item)=>max>item.pax?max:item.pax,0)){
			this.commons.generateToast('_ERROR','_TRANSPORT_TYPE_TOO_SMALL','error');
			// return false;
		}

		switch(this.draggedItem.type){
			case "vehicle"	:
				if(undefined!=this.draggedItem.plate)	{
					if( row.seats>this.draggedItem.seats){
						this.commons.generateToast('_ERROR','_VEHICLE_TOO_SMALL','error');
						return false;
					}
				}
				else if(undefined!=row.plate)			{
					this.commons.generateToast("ERROR","_REMOVE_PLATE_FIRST","error"); return false;
				}

				if(this.draggedItem.status=="assigned")	{
					this.commons.generateToast("ERROR","_VEHICLE_ALREADY_ASSIGNED",	"error");
					return false;
				}

				if(row.code=="000_NEW"){
					let alreadyAssigned = this.transportsCalendar.rows.find(item=>item.code==this.draggedItem.code);
					if(alreadyAssigned){
						this.commons.generateToast("_ERROR","_VEHICLE_ALREADY_ASSIGNED","error");
						return false;
					}
					row.name				= this.draggedItem.code;
					row.code				= row.name;
				}

				// item["vehicle"].transporter	= item["transporter"]["name"	];
				// item["vehicle"].type			= item["type"		]["type"	];
				// item["vehicle"].seats		= item["type"		]["seats"	];
				// item["vehicle"].name			= item["type"		]["name"	];
				// item["vehicle"].alias		= item["type"		]["name"	];

				row.type		= this.draggedItem.transportType || this.draggedItem.code || this.draggedItem.type;
				row.transporter	= this.draggedItem.transporter.name;
				row.seats		= this.draggedItem.seats;
				// row.name		= row.type;
				// row.alias	= row.type;

				if(undefined!=this.draggedItem.plate)	{
					row.plate 				= this.draggedItem.plate;
					this.draggedItem.status	= "assigned";
				}

								break;

			default			:
			case "plate"	:
				row.plate = this.draggedItem.name;
				break;
		}

		// Check if row has already something assigned
		// if( undefined===row.seats )				{ 	this.commons.generateToast('_ERROR','_AT_LEAST_ONE_ITEM_NEEDED','error'); return false; }

		// Clear prev plate
		// this.fleet.draggableSet.forEach(type=>{ ((type.vehicles||[]).find(current=>current.plate==row.plate)||{})["status"]="unassigned" });
		// Clear entities
		if(null!=this.fleet.draggableSet){
			(this.fleet.draggableSet||[]).forEach( type => {
				if(null==type.vehicles){ return; }
				Object.keys(type.vehicles).forEach(vehicleId=>{
					if(type.vehicles[vehicleId].plate == row.plate){
						type.vehicles[vehicleId].status = "unassigned";
					}
				});
			});
		}

		// Update service plate
		(row.items||[]).forEach(service=>{
			service.vehicle	= row.name;		// Double check
			service.plate 	= row.plate;
			(service.bookingsInfo||[]).forEach(bi=>{
				bi.plate 	= row.plate;
				bi.vehicle	= row.name;		// Double check
			})
		})
	}

	/**
	 * drop group into vehicle row inside transportsCalendar
	 * @param row
	 */
	async dropOnCalendarTransports($params)	{
		let result;
		let row		= $params["row"];
		let group 	= $params["group"] || this.draggedItem;
		let gap 	= this.findCalendarGap({ group: group, row: row, showError: true });

		if(row.name=="000_NEW"){
			this.commons.generateToast("_ERROR","_SET_VEHICLE_BEFORE_DROP_ITEMS","error");
			return false;
		}

		this.pageInfo.droppedElementsOnCalendarTransports = this.pageInfo.droppedElementsOnCalendarTransports || 1;

		console.log("[dropOnCalendarTranports] Drop On Calendar Transports",this.pageInfo.droppedElementsOnCalendarTransports,row,group);

		if(!gap["success"])	{
			switch(gap["error"]){
				case "_TARGET_TRANSPORT_NOT_SEATS":
					this.pageInfo.dialogs.splitGroup.params = {
						row		: row,
						group	: group
					};
					result	= this.splitGroupToFitInVehicle(true);
					if(!result["success"]){ return false; }
					group 	= result["new_group"];
					break;
				default	: return false;
			}
		}

		// REMOVE from previous row
		switch(group.assigned){
			case true:
				if(group.vehicle && group.vehicle!=row.name){
					let currentRow = this.transportsCalendar.rows.find(item=>item.name==group.vehicle);
					if(currentRow){
						let items 			= currentRow.items.filter(item=>item.name!=group.name);
						console.log("Current Row from dragged item before",(currentRow.items||[]).length,"after",(items||[]).length);
						currentRow.items 	= items;
					} else {
						// Normally, it was assigned to external or none
						// this.commons.generateToast("_ERROR","_GROUP_HAS_NO_VEHICLE_DEFINED","error");
						// return false;
					}
				}
				break;
		}

		// INSERTIN into new one
		group.assigned 	= true;
		group.vehicle	= row.name;
		if(row.plate		){ group.plate 			= row.plate; 		}
		if(row.transporter	){ group.transporter	= row.transporter;	}

		(group.bookingsInfo||[]).forEach(bi=>{
			bi.vehicle = row.name;
			if(row.plate		){ bi.plate			= row.plate;		}
			if(row.transporter	){ bi.transporter	= row.transporter;	}
		});
		row.items.push(group);

		this.updateRowTurn({ row: row });

		return true;
	}

	/**
	 * update vehicle row turn based on dynamic or static turns from config
	 * @param $params
	 */
	updateRowTurn($params){
		let row					= $params["row"];
		let mode				= "dynamic";
		let turn_duration_min	= this.commons.pageInfo.vehicles
									?this.commons.pageInfo.vehicles.turn_duration_min
									:660;

		if(this.commons.userInfo.currentDestination && this.commons.userInfo.currentDestination.turn_duration_min){
		 	turn_duration_min	= parseInt(this.commons.userInfo.currentDestination.turn_duration_min);
		}

		row.items				= row.items || [];

		switch(mode){
			case "dynamic":
				row.items 			= row.items.sort((a,b)=>a.init_timestamp>=b.init_timestamp?1:-1);
				if(row.items.length>0){
					row.init_timestamp	= row.items[0].init_timestamp;
					row.end_timestamp	= row.init_timestamp + turn_duration_min*60;
					row.init			= row.items[0].init;
					row.end				= row.init + turn_duration_min;
				}
				break;
		}
	}

	dropDriver(service,driver)				{
		service.driver 	= driver;
		let item 		= (this.services.items||[]).find(item=>item.id==service.transportId);
		if(item){
			item.driver 		= driver;
			item.driver_name	= driver.name;
			item.driver_code 	= driver.code;
		}
	}

	dropOnCalendarDriver(row,transfer)		{	transfer.driver = row.driver;	}
	dropOnCalendarTransporter(row,transfer)	{	transfer.driver = row.driver;	}

	isValidDrop(item,group) 			{
		let originZone 	= item.group.name==="unassigned"?item.item.zone:item.group.zone;
		let targetZone	= group.zone;

		// if(group.name===item.group.name)	{ return false;	}
		// if(group.name==="unassigned")		{ return true;  }
		// if(targetZone==="EMPTY GROUP")		{ group.zone=item.group.zone; return true; }
		// if(targetZone!==originZone)			{ return false;	}

		// return this.checkProviderRestrictions({ item: item, group: { bookings: [ ...group.bookings, item.item ] } });
		return true;
	}

	/**
	 * remove group from pool
	 *
	 * @param $group
	 */
	removeGroup($group)						{
		this.groups.data	= this.groups.data.filter(item=> item.name!==$group.name );
		this.bookings.data.filter(b=>b.group==$group.name).forEach(b=>b.group=undefined);
	}

	/**
	 * move booking between groups
	 *
	 * @param group
	 * @param event
	 * @returns boolean
	 */
	private dropGroup(group,event):boolean {

		if(undefined==this.draggedItem	){ return false; }
		if(undefined==group				){ return false; }

		console.group("Drop Group");
		console.log("Moving from group "+this.draggedItem.item.group.name+" to "+group.name);

		let origin_group 		= this.groups.data.find(item=>item.name==this.draggedItem.item.group);
		let destination_group	= group;
		let bookingInfo			= this.draggedItem.item;

		if(!origin_group		){ this.commons.generateToast("_ERROR","_NO_ORIGIN_GROUP", 		"error"); return false; }
		if(!destination_group	){ this.commons.generateToast("_ERROR","_NO_DESTINATION_GROUP", "error"); return false; }
		if(!bookingInfo			){ this.commons.generateToast("_ERROR","_NO_BOOKING_INFO", 		"error"); return false; }

		// Check zones
		if(destination_group.zone!="_NO_ZONE"){
			if(origin_group.zone!=destination_group.zone){
				this.commons.generateToast("_ERROR","_NO_SAME_ZONES", "error");
				return false;
			}
		}

		if(origin_group.direction!=destination_group.direction){
			this.commons.generateToast("_ERROR","_NO_SAME_DIRECTION", "error");
			return false;
		}

		// Check fit in time
		// let timeGap = this.transportService.checkGroupGap({
		// 	group 		: destination_group,
		// 	booking		: this.draggedItem,
		// 	bookings	: this.bookings.data
		// });

		// if(!timeGap){
		// 	this.commons.generateToast("_ERROR","_NO_FIT_IN_TIME", "error");
		// 	return false;
		// }

		// Move item from origin to destination group
		if(this.draggedItem) {
			// Assggn to destination group
			destination_group.bookings.push(bookingInfo.reference);
			destination_group.bookingsInfo.push(bookingInfo);
			bookingInfo.group = destination_group.name;

			// Remove from origin group and remove if empty
			if(origin_group){
				origin_group.bookings		= origin_group.bookings.filter(b=>b!==bookingInfo.reference );
				origin_group.bookingsInfo	= origin_group.bookingsInfo.filter(b=>b.reference!==bookingInfo.reference);

				if(origin_group.bookings.length==0){ this.removeGroup(origin_group); }
			}

			this.draggedItem 				= null;
		}

		// Calculate paxes
		destination_group.pax = this.calculateGroupPax(destination_group);
		destination_group.empty = (destination_group.bookings||[]).length>0;

		// Calculate paxes
		if(origin_group){
			origin_group.pax = this.calculateGroupPax(origin_group);
			origin_group.empty = (origin_group.bookings||[]).length>0;
		}

		let info;

		// Update origin group init time based on new bookings set
		origin_group.bookingsInfo.forEach(booking=>{
			info = this.transportService.setGroupFirstTime({
				info	: origin_group,
				booking	: booking,
				date	: this.calendar.date
			});
		});
		origin_group = info;

		// Update destinato group init time based on new bookings set
		destination_group.bookingsInfo.forEach(booking=>{
			info = this.transportService.setGroupFirstTime({
				info	: destination_group,
				booking	: booking,
				date	: this.calendar.date
			});
		});
		destination_group = info;

		console.groupEnd();

		return true;
	}

	/**
	 * calculate group pax upon group booking pax
	 *
	 */
	private calculateGroupPax($group){
		return $group.bookingsInfo.reduce((pax,item)=>{
			pax += parseInt(item.pax);
			return pax;
		},0);
	}

	async drop($type,event,extra={})			{
		console.group("Drop");
		console.log("Event",$type,event);
		console.log("DraggedItem",this.draggedItem);
		console.groupEnd();

		let item 	= {};
		let response;

		if(this.draggedItem) {
			switch($type){
				case "transportSelect"		:	// Reorder transport selection
												if(this.draggedItem.position==event.position){ return false; }
												let tmp						= this.draggedItem.position;
												this.draggedItem.position 	= event.position;
												event.position				= tmp;
												extra["target"]				= extra["target"].sort((a,b)=>a.position>b.position?1:-1);
												break;

				case "driver"				:	this.dropDriver(extra["item"],this.draggedItem);
												this.generateDriversCalendar();
												break;

				case "calendar_driver"		:	this.dropOnCalendarDriver(extra["row"],this.draggedItem);
												this.generateDriversCalendar();
												break;

				case "calendar_transporter"	:	this.dropOnCalendarTransporter(extra["row"],this.draggedItem);
												this.generateTransportersCalendar();
												break;

				case "calendar"				:	response = this.dropOnCalendarTransports({ row: extra["row"] });
												if(response){
													this.generateDriversCalendar();
													this.generateTransportersCalendar();
												}
												break;

				case "vehicles"				:	this.dropVehicle(extra["row"]);
												this.generateDriversCalendar();
												this.generateTransportersCalendar();
												break;

				case "arrivalGroup"			:	this.arrivals.container	 = this.draggedItem;				break;

				case "departureGroup"		:	this.departures.container= this.draggedItem;				break;

				case "arrivalTransport"		:	if(this.arrivals.container===null	){ await this.commons.generateToast('_ERROR','Set an arrival group first',	'error'); return false;	}
												if(this.draggedItem.qty <= 0		){ await this.commons.generateToast('_ERROR','Drop not allowed',			'error'); return false;	}

												item = {
													pax		: this.draggedItem.pax,
													qty		: this.draggedItem.qty,
													name	: this.draggedItem.name
												};

												this.arrivals.transports		= [ ...this.arrivals.transports, item ];
												this.fleet.draggableSet 		= this.fleet.draggableSet.map(item=>{
																					if( item.id === this.draggedItem.id )	{ item.qty -= item.qty>0?1:0;							}
																					if( item.qty <= 0)						{ item.qty = 0; item.color = this.pageInfo.colors.empty;}
																					return item;
																				});
												break;

				case "departureTransport"	:	if(this.departures.container===null	){ await this.commons.generateToast('_ERROR','Set a departure group first',	'error'); return false;	}
												if(this.draggedItem.qty <= 0		){ await this.commons.generateToast('_ERROR','Drop not allowed',			'error'); return false;	}

												item = {
													pax		: this.draggedItem.pax,
													qty		: this.draggedItem.qty,
													name	: this.draggedItem.name
												};

												this.departures.transports		= [ ...this.departures.transports, item ];
												this.fleet.draggableSet 		= this.fleet.draggableSet.map(item=>{
																					if( item.id === this.draggedItem.id )	{ item.qty -= item.qty>0?1:0; 							}
																					if( item.qty <= 0)						{ item.qty = 0; item.color = this.pageInfo.colors.empty;}
																					return item;
																				});
												break;

				case "booking"				:	this.dropBooking(extra["transfer"]); break;

				case "service"				:	
					switch(this.draggedItem.type){
						case "external": this.dropExternalToService({ name: this.draggedItem.name, row: extra["row"]}); break;
					}
					break;
			}
		}
		this.draggedItem = null;
	}

	selectServices($info){
		switch(this.pageInfo.assignTransporterMode){
		}
	}

	/**
	 * drop external transporter to service
	 */
	assignExternalToService($info){

		let $current	= $info.item;
		let errors		= 0;
		let services	= this.getServices({ type: 'grid', assignation_type: "all" })
								.filter(s=>s.selected);

		console.log("SERVICES",services);

		services.forEach(service=>{
			if(!this.checkServicesStatus(service)){ 
				errors++;
				return false; 
			}
			
			// Not update if transporter is already assigned
			if(service.transporter!=$current.name){			
				service.transporter = $current.name;
			}

			// Clear vehicle
			service.vehicle		= undefined;

			// Update service info related with transporter
			switch(service.transporter_status){
				case undefined:	service.transporter_status = "_NOT_SEND"; break;	
			}

			// Update Booking info related with transporter
			(service.bookingsInfo||[]).forEach(bi=>{
				bi.vehicle 				= undefined;
				bi.transporter			= $current.name;
				bi.transporter_status	= service.transporter_status;
			})
		});

		if(errors>0){
			// this.commons.generateToast("_ERROR","_DROP_TRANSPORTER_NOT_ALLOWED","error");		
		}
	}


	/**
	 * drop external transporter to service
	 */
	dropExternalToService($current){

		let services;

		// Get last item selected
		// PENDING ! Group selection, only one item from group
		let option = this.pageInfo.transporter_options.reduce((s,item)=>{
			s = item.selected?item.name:s;
			return s;
		},"assign_current");

		switch(option){
			case "assign_empties":
				services = this.getServices({ type: 'grid', assignation_type: "unassigned" 	});
				break;

			case "assign_all":
				services = this.getServices({ type: 'grid', assignation_type: "all" 		});
				break;

			case "assign_current":
				if(!this.checkServicesStatus($current.row)){ return false; }
			
				$current.row.vehicle 		= undefined;
				$current.row.transporter	= $current.name;

				// Update service info related with transporter
				switch($current.row.transporter_status){
					case undefined:	$current.row.transporter_status = "_NOT_SEND"; break;	
				}
				
				($current.row.bookingsInfo||[]).forEach(bi=>{
					bi.vehicle 				= undefined;
					bi.transporter			= $current.name;
					bi.transporter_status	= $current.row.transporter_status;
				})
				return;
		}

		services.forEach(service=>{
			if(!this.checkServicesStatus(service)){ return false; }
			// Not update if transporter is already assigned
			if(service.transporter==$current.name){
				return false;
			}
			service.transporter = $current.name;
			service.vehicle		= undefined;

			// Update service info related with transporter
			switch(service.transporter_status){
				case undefined:	service.transporter_status = "_NOT_SEND"; break;	
			}

			// Update Booking info related with transporter
			(service.bookingsInfo||[]).forEach(bi=>{
				bi.vehicle 				= undefined;
				bi.transporter			= $current.name;
				bi.transporter_status	= service.transporter_status;
			})
		});
	}

	checkServicesStatus($service){
		let status = true;
		switch($service.transporter_status){
			case "_ACCEPTED"	:
			case "_PENDING"		: status = false; 					break;
			case "_REJECTED"	: $service.status = "_NOT_SEND"; 	break;
		}		
		return status;
	}

	findCalendarGap($params){
		// return this.findCalendarGapV1($item,$row,$showError);
		// return this.findCalendarGapV2($item,$row,$showError);
		return this.findCalendarGapV3($params);
	}

	/**
	 * Find gap inside row comparing inner and outter limits
	 * It could be improved adding extra params as rest times and other
	 *
	 * @param item
	 * @param row
	 * @returns
	 */
	 findCalendarGapV3($params)
	 {
		let row 		= $params["row"			];
		let group		= $params["group"		];
		let showError	= $params["showError"	];

		// if(row.items.length==0){ row.itemsreturn 0; }
		let result = this.serviceFiltersService.applyActiveFilters({ item: group, row: row, date: this.calendar.date });

		if(!result["success"]){
			if(showError)	{
				this.commons.generateToast('_ERROR', result["error"],'error');
			}
		}

		if(showError){
			 console.log("[Filters] Report",result["report"]);
			 this.commons.pageInfo.lastServiceFiltersExec = result["report"];
			 setTimeout(()=>{
				 delete this.commons.pageInfo.lastServiceFiltersExec;
			 },3000);
		}

		return result;
	}


	/**
	 * partition group to fit into vehicle
	 * @param $params
	 */
	splitGroupToFitInVehicle($accepted){
		if(!$accepted){
			this.commons.generateToast("_ERROR","_SPLIT_GROUP_CANCELLED","error");
			this.pageInfo.dialogs.splitGroup.openned = true;
			return { success: false };
		}

		let $params		= this.pageInfo.dialogs.splitGroup.params;
		let row 		= $params["row"			];
		let group		= $params["group"		];

		if(row.items.some(item=>item.name==group.name)){
			return { success: false, message: "_GROUP_ALREADY_EXISTS_ON_VEHICLE" }
		}
		// STEPS
		// 0. Only for shuttles
		// 1. Create new group of SEATS paxes
		//		1.1  Renaming as <group>_<vehicle>
		// 		1.2  Select bookings to fit and move to new group
		//		1.3  Remove bookings from old group
		//		1.4. Decrease paxes in old group

		if(group.private){
			this.commons.generateToast("_ERROR","_SPLIT_GROUP_NOT_FOR_PRIVATES","error");
			this.pageInfo.dialogs.splitGroup.openned = true;
			return { success: false };
		}

		let new_group:any	= 	{
			name			: group.name+" "+row.name,
			pax				: 0,
			bookings		: [],
			bookingsInfo	: [],
			vehicle			: group.vehicle,
			area			: group.area,		// Has no sense for shuttle
			zone			: group.zone,
			to				: group.to,
			direction		: group.direction,
			type			: group.type,
			date			: group.date,
			provider		: group.provider,
			routeTime		: group.routeTime,
			solution		: group.solution,
			private			: group.private,
			waitTime		: group.waitTime,
			open			: false,
			assigned		: false,
			pillow			: 0
		}

		switch(group.direction){
			case "arrival"		: new_group.arrival_Date 	= group.arrival_Date; 	break;
			case "departure"	: new_group.departure_Date	= group.departure_Date;	break;
		}

		group.bookings.forEach(bookingRef=>{
			let booking = this.getEntity("booking",{ item: bookingRef });
			if((new_group.pax+booking.pax)<=row.seats){
				// Add bookings to new group
				new_group.bookings.push(bookingRef);
				new_group.bookingsInfo.push(booking);
				new_group.pax		+= booking.pax;
				// Remove from old group
				group.bookings 	 	= group.bookings.filter(b=>b!=bookingRef);
				group.bookingsInfo	= group.bookingsInfo.filter(bi=>bi.reference!=bookingRef);
				group.pax			-= booking.pax;
			}
		});

		if(new_group.pax==0){
			this.commons.generateToast("_ERROR","_NO_BOOKING_FIT_INTO_ROW","error");
			return { success: false};
		}

		new_group = this.transportService.generateShuttleGroup({ group: new_group });
		this.groups.data.push(new_group);

		return {
			success		: true,
			new_group	: new_group,
			old_group	: group
		}
	}

	/**
	 * Find gap inside row comparing inner and outter limits
	 * It could be improved adding extra params as rest times and other
	 *
	 * @param item
	 * @param row
	 * @returns
	 */
	findCalendarGapV2(item,row,showError)
	{
		if(row.items.length==0){ return 0; }

		// let result = row.items.reduce((found,current)=>{
		// 	if(item.init<current.init && item.end>current.init	){ return false; }	// Left out
		// 	if(item.init<current.end  && item.end>current.end	){ return false; }	// Right out
		// 	if(item.init>=current.init && item.end<=current.end	){ return false; }	// Inner out
		// 	// if(item.init>current.end ){ return found; }			// In from right limit
		// 	// if(item.end <current.init){ return found; }			// In from left limit
		// 	return found;
		// },true);

		let result = this.serviceFiltersService.applyActiveFilters({ item: item, row: row });
		if(!result["success"]){
			if(showError)	{ 	this.commons.generateToast('_ERROR', result["error"],'error'); 	}
		}
		if(showError){
			console.log("[Filters] Report",result["report"]);
			this.commons.pageInfo.lastServiceFiltersExec = result["report"];
			setTimeout(()=>{
				delete this.commons.pageInfo.lastServiceFiltersExec;
			},3000);
		}
		return result["success"]?1:-1;
	}

	findCalendarGapV1(item,row,showError){
		// Set if first item
		if(row.items.length==0){ return 0; }

		// let init = item.firstTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
		// let end 	= item.lastTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));

		// Check boundaries
		if(item.end  < row.items[0].init){ return 0; }
		if(item.init > row.items[row.items.length-1].end){ return row.items.length; }

		// Find item to compare with
		let i = 1; while( i < row.items.length && item.end >= row.items[i].init ){ i++; }

		if( i >= row.items.length ){ return -1; }		// Reached last item
		return ( item.init > row.items[i-1].end )?i:-1;	// Inside gap item[i-1] and item[i]
	}

	async calendarChange()			{
		this.calendar.value.setHours(12);
		this.calendar.date	= this.calendar.value.toISOString().split('T')[0];
		console.log("CALENDAR",this.calendar);
		if(this.calendar.date!==this.calendar.last){
			this.calendar.last 				= this.calendar.date;
			this.pageInfo.current_timestamp	= undefined;
		}
		this.doSubscribeToTransportersRequests();
	}

	async mockCalendarCall($date)	{	this.calendar.date = $date;
										// await this.load('groups');
									}

	getCalendarTimes($mode="simple"){
		switch($mode){
			case "simple"	: 	return [ ...Array.from(Array(24).keys()).map(hour=>(hour<10?'0':'')+hour+":00"), ...Array.from(Array(4).keys()).map(hour=>'0'+hour+":00") ]
			case "complex"	:	return Array.from(Array(28).keys()).map(item=>item.toString());
		}
	}

	/**
	 * get current own fleet and externals
	 * @param $params
	 * @returns
	 */
	getActiveFleet($params={}):any[]{
		let items;
		$params["min"]	= $params["min"] || 1;

		switch($params["mode"]){
			case "vehicles_and_plates"	:	items = $params["items"];
											break;

			case "transporter"			:	//return undefined==this.fleet.draggableSet ? [] : this.fleet.draggableSet.filter(item=>item.qty>0).filter(item=>item.seats>=$params["min"]);
											items = ($params["transporter"].fleet||[])
													.filter(item=>item.qty>0)
													.sort((a,b)=>a.code>=b.code?1:-1);
											break;

			default             		:
			case "transportType"		:	//return undefined==this.fleet.draggableSet ? [] : this.fleet.draggableSet.filter(item=>item.qty>0).filter(item=>item.seats>=$params["min"]);
											switch($params["empty"]){
												case false	: 	items = this.transportTypes.draggableSet.filter(item=>item.qty>0);
												default		:
												case true	:	items = this.transportTypes.draggableSet;
											}
											// return items.forEach(item=>{
											// 	item.vehicles = items.vehicles.sort((a,b)=>a.plate>b.plate?1:-1);
											// 	return item;
											// });
											break;

			case "vehicles"				:	items = $params["transporter"].fleet
														.find(item=>item["transportType"]==$params["type"])["vehicles"]
														.filter(item=>{
															switch($params["empty"]){
																case "false":	return item.qty>0;
																default		:	return true;
															}
														});
											break;
		}

		switch($params["order"]){
			case "vehicle"	: items = items.sort((a,b)=>a.vehicle>=b.vehicle?-1:1); break;
		}

		return items;
	}

	getItemFleetVehicles(item)		{	return item.open ? item.vehicles: [];	}

	setViewMode(selected)			{	switch(selected){
											case "editable"		: this.calendarFullView   = false; 	break;
											case "big"			: this.calendarFullView   = true; 	break;
											case "full"			: this.calendarFullView   = true; 	break;
										}
									}
	toggle($type,$item=null,$status=undefined){
		switch($type){
			case "booking"				:	$item.expanded				= $item.expanded?false:true; break;
			case "provider"				:	this.providers.data 		= this.providers.data.map(item => { item.selected = item.id == $item.id; return item; }); break;
			case "transporters"			:	this.pageInfo.panels.transporters	= true; break;
			case "itemFleet"			:	$item.open					= $status!=undefined?status:($item.open==true?false:true);	break;
			case "itemCalendar"			:	$item.open					= $status!=undefined?status:($item.open==true?false:true);
											$item.zindex				= $status!=undefined?status:($item.open?1:0);
											break;
			case "fullscreen"			:	toggleFullScreen(this); break;
			case "service"				:	// If every transport is assigned just close or not open
											// if(($item.transports||[]).every(item=>item.assigned))	{	$item.open = false;			}
											// else 													{	this.toggle("group",$item);	}
											this.toggle("group",$item);
											break;
			case "group"				:	$item.open					= $status!=undefined?status:($item.open==true?false:true);	break;
			case "fleet"				:	this.fleet.open				= $status!=undefined?status:(this.fleet.open==1?0:1);
											this.fleet.draggableSet 	= this.fleet.draggableSet.map(item=>{ item.open = this.fleet.open; return item; });
											break;
			case "drivers"				:	this.drivers.open			= $status!=undefined?status:(this.drivers.open==true?false:true); 				break;
			case "vehicle_type_options"	:	$item.vehicle_type_options	= $status!=undefined?status:($item.vehicle_type_options==true?false:true); 		break;
			case "vehicle_plate_options":	$item.vehicle_plate_options	= $status!=undefined?status:($item.vehicle_plate_options==true?false:true); 	break;
		}
	}

	validate($type,$item)			{	switch($type){
											case "upgradeItemSellRequest":		$item.open = false; break;
											case "upgradeItemSellProcess":		$item.open = false; this.commons.generateToast('Ticket','Your selling has been emmited','info'); break;
											case "upgradeItemSellCancel":		$item.open = false; break;
										}
									}

	generateMenuCols($entity) {
		switch($entity){
			case "services"	: this[$entity].cols = servicesCols;	break;
			case "groups"	: this[$entity].cols = groupsCols; 		break;
			case "fleet"	: this[$entity].cols = fleetCols; 		break;
			case "drivers"	: this[$entity].cols = driversCols;		break;
		}
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
	}

	getEntity($entity,$info){
		switch($entity){
			case "booking"	: return this.bookings.data.find(b=>b.reference == $info.item );
		}
	}

	getFilteredBookings($info){
		let directions_selected	= 	this.pageInfo.controlPanels.transfers.direction.items
										.filter	(item => item.value	)
										.map	(item => item.name	);

		let bookings = this.bookings.data
			.filter	(item=>{
				let found = directions_selected.some(dir=>dir==item.direction);
				return found
			})
			.map((item,index)=>{
				item.id = index+1;
				return item;
			})
			// .sort((a,b)=>a.group>b.group?1:-1)
			;

		if($info["filters"]){
			if ($info["filters"].some(item=>item=="not_grouped")){
				bookings = bookings.filter(item=>item.group==undefined);
			}
		}

		if(this.pageInfo.bookingsButtons.showErrors){
			bookings = bookings.filter(item=>undefined!=item.errors && item.errors.length>0);
		}

		bookings	= bookings.sort((a,b)=>a.group>b.group?1:-1);

		// Add empty rows
		// const max_empty_lines	= 20;
		// const bookings_length	= (bookings||[]).length;
		// const empty_qty_len		= bookings_length<max_empty_lines?max_empty_lines-bookings_length:0;
		// this.pageInfo.empties.bookings  = [...Object.keys(Array(empty_qty_len))].map(item=>{
		// 	let new_item = {
		// 		is_empty	: true
		// 	}
		// 	return item;
		// });

		// return [ ...bookings, ...this.pageInfo.empties.bookings ];
		return bookings;
	}

	getFilteredGroups($info){
		let selected_direction	= 	this.pageInfo.controlPanels.transfers.direction.items
							.filter	(item => item.value	)
							.map	(item => item.name	);

		let selected_pendings	= 	this.pageInfo.controlPanels.transfers.pendings.items
									.filter	(item => item.value	)
									.map	(item => item.name	);

		let groups_assigned		= 	this.pageInfo.calendar.buttons.group_assigned.items
									.filter	(item => item.value	)
									.map	(item => item.name	);

		if((this.groups.data||[]).length==0){ return [];}

		return this.groups.data
				.filter	(item=>{
					return selected_direction.some(dir=>dir==item.direction);
				})
				.filter (item=>{
					if(item.zone=="_NO_ZONE")		{	return true; }
					if(this.pageInfo.selectedZone)	{	return item.zone == this.pageInfo.selectedZone;	}
					else 							{	return true;									}
				} )
				.filter (item=>{
					switch($info.shared){
						default				: return true;
						case "private"		: return  item.private;
						case "shuttle"		: return !item.private;
					}
				})
				.filter(item=>{
					switch($info.type){
						case "assigned"				: return  item.assigned;
						case "unassigned"			: return !item.assigned;
					}
					return true;
				})
				.filter(item=>{
					if(!groups_assigned.some(dir=>dir=="assigned")){
						if(item.assigned){ return true; }
					}
					if(!groups_assigned.some(dir=>dir=="unassigned")){
						if(!item.assigned){ return true; }
					}
					return true;
				})
				.sort	((a,b)=>(a.init_timestamp>b.init_timestamp?1:-1))
				.map	(item=>{
					item.open 			= item.open || false;
					item.bookingsInfo	= [];
					(item.bookings||[]).forEach(reference=>{
						let booking = this.bookings.data.find(item=>item.reference==reference);
						if(booking){
							item.bookingsInfo.push(booking);
						}
					});
					return item;
				})
				.filter	(item=>{
					switch($info.direction){
						case "arrival"		: return item.direction=="arrival"	; break;
						case "departure"	: return item.direction=="departure"; break;
					}
					return true;
				})
				.filter (item=>{
					if(!selected_pendings.some(dir=>dir=="external_pendings")){
						if(item.vehicle){
							let vehicle = this.ownFleet.find(v=>v.name==item.vehicle);
							if(!vehicle		){
								return false;	// Remove external pending
							}
						}
					}
					if(!selected_pendings.some(dir=>dir=="own_pendings")){
						if(item.vehicle){
							let vehicle = this.ownFleet.find(v=>v.name==item.vehicle);
							if(vehicle		){
								return false; 	// Remove own pendings
							}
						}
					}
					if(!selected_pendings.some(dir=>dir=="rest_pendings")){
						if(!item.vehicle	){ return false; }
					}
					return true;
				})
				.sort((a,b)=>a.init>=b.init?1:-1)
				;
	}

	getFilteredEntity($entity,$info:any={}){
		let selected;
		let results;
		let plates;

		switch($entity)	{
			case "calendar"			:
				switch($info.type){
					case "transporters"	: return this.getTransportersCalendar($info.mode||"full");
				}
				break;
			case "plates"			:	return this.pageInfo.plates;
			case "zones"			:	return	this.pageInfo.zones;
			case "group_bookings"	:	return 	this.bookings.data
													.filter(item=>{
														switch($info["shared"]){
															default			: 	return true;
															case "private"	: 	return item["shared"] == "private";
															case "shuttle"	:	return item["shared"] == "shuttle";
														}
													})
													.filter	(item=>{
														switch($info["assigned"]){
															default			:	return true;
															case true		:	return  item.assigned;
															case false		:	return !item.assigned;
														}
													});

			case "bookings"			:	return this.getFilteredBookings($info);
			case "groups"			:	return this.getFilteredGroups($info);
			case "services"			:	return this.getFilteredServices($entity,$info);

			case "drivers"			:	let drivers = this.drivers.data;
										switch($info["sort"]){
											case "vehicle"	: drivers = drivers.sort((a,b)=>a.vehicle>=b.vehicle?1:-1);
										}
										return drivers;
										break;

			case "providers"		:	return (this.providers.data||[]).sort((a,b)=>parseInt(a.id)>=parseInt(b.id)?1:-1);

			case "groups_digest"	:	
				return (this.groups.data||[]).filter(item=>{
					switch($info.type){
						case "externals_unassigned"	: {
								if(item.assigned){ return false; }
								if(!item.vehicle){ return false; }
								let vehicle = this.ownFleet.find(v=>v.name==item.vehicle);
								if(vehicle		){ return false; }
								return !item.assigned;
							}
						break;
						case "owns_unassigned"		: {
								if(item.assigned){ return false; }
								if(!item.vehicle){ return false; }
								let vehicle = this.ownFleet.find(v=>v.name==item.vehicle);
								if(!vehicle		){ return false; }
								return !item.assigned;
							}
							break;
						case "rest_unassigned"		: {
								if(item.assigned){ return false; }
								if(item.vehicle	){ return false; }
								return !item.assigned;
							}
							break;
						case "assigned"				: return  item.assigned;
						case "unassigned"			: return !item.assigned;
					}
					return true;
				})
				.filter	(item=>{
					switch($info.direction){
						case "arrival"		: return item.direction=="arrival"	; break;
						case "departure"	: return item.direction=="departure"; break;
					}
					return true;
				});


			case "selectedRoutes"	:	return this.pageInfo.selectedRoutes.items.sort((a,b)=>a.init>b.init?1:-1);

			case "transporters"		:	
				switch($info.type){
					case "own_fleet"	:	
						if(undefined==this.transporters){ return []; }
						return (this.transporters.data||{}).filter(t=>t.name=="_INNER_FLEET");

					case "externals"	:	
						let items = this.transporters.data.filter(t=>t.name!="_INNER_FLEET");
						switch($info.has_api){
							case true	:	return items.filter(t=> t.has_api);
							case false	:	return items.filter(t=>!t.has_api);
							default		:	return items;
						}
						break;
					default				:	return (this.transporters||[]).draggableSet;
				}
				break;
		}
	}

	private getFilteredServices($entity,$info){
		let services;

		switch($info.type){
			case "grid"			: 	services = this.getServices({ type: 'grid', show_unassigned: true });	break;
			case "by_time"		: 	services = this.getServicesByTime($info.slot || 'hour');				break;
			case "from_group"	: 	services = this.getGroupServices($info.group,$info.status);				break;
			default				: 	services = this.getServices({ type: 'list', status: $info.status });
		}

		let items = [];

		switch($info.mode){
			case "unassigned"				:	items = services.map(service=>{
													service.items = service.items.filter(item=>!item.assigned);
													return service;
												});

			case "simple_unassigned"		:	items = services.filter(service=>!service.assigned)
														.map((item,idx)=>{item.index = idx; return item; });

			case "simple_unassigned_length"	:	items = services.filter(service=>!service.assigned)
																.filter(service=>{
																	switch($info.direction){
																		case "arrival"	: return service.direction == "arrival";
																		case "departure": return service.direction == "departure";
																		return true;
																	}
																})
																.length;

			case "simple_assigned"			:	items = services.filter(service=>service.assigned)
																.map((item,idx)=>{item.index = idx; return item; });

			case "simple_assigned_length"	:	items = services.filter(service=>service.assigned)
																.filter(service=>{
																	switch($info.direction){
																		case "arrival"	: return service.direction == "arrival";
																		case "departure": return service.direction == "departure";
																		return true;
																	}
																})
																.length;
			default							:	items = services;
		}

		// Mix with transporter requests if needed
		items = this.mergeServicesWithTransportersRequests(items);
		this.services.count = (items||[]).length;

		return items;
	}

	/**
	 * merge transporters requests updates with services
	 */
	private mergeServicesWithTransportersRequests($items){
		let requests = [];

		this.pageInfo.transportersDayRequests = this.pageInfo.transportersDayRequests || {};

		// Mix all requests into one list
		Object.keys(this.pageInfo.transportersDayRequests).forEach(transporterId=>{
			requests = [ ...requests, ...this.pageInfo.transportersDayRequests[transporterId].items ];
		})
		
		requests.forEach(request=>{
			let service = $items.find(item=>item.name==request.name);
			if(service && request.pending){
				if(request.transporter			){ service.transporter 			= request.transporter; 			}
				if(request.transporter_status	){ service.transporter_status 	= request.transporter_status; 	}

				// Update also bookings
				(service.bookingsInfo||[]).forEach(bi=>{
					if(request.transporter			){ bi.transporter			= request.transporter;			}
					if(request.transporter_status	){ bi.transporter_status	= request.transporter_status;	}
				})

				// mark request as merged only if service already exists, not do it again
				request.pending = false;
			}
		})
		return $items;
	}

	/**
	 * persist bookings with service info
	 */
	private async saveServicesIntoBookings($info?)
	{
		let bookings 		= ($info||[].length>0)?$info:this.bookings.data;
		const currentDate	= this.calendar.date;

		this.commons.generateToast("_BOOKINGS","_SAVING_BOOKINGS","info");
		
		let data = {
			date		: 	currentDate,
			items		:	bookings.map(item=>{
							
				if(item.real_reference){
					switch(item.direction){
						case "arrival"	:	item.arrival_Reference 		= item.reference; 	break;
						case "departure":	item.departure_Reference	= item.reference;	break;
					}
					item.reference	= item.real_reference;					
					item.direction	= "both";					
				}

				// Save info
				if(undefined==item.vehicle && undefined!=item.group){
					let group = this.groups.data.find(group=>group.name==item.group);
					if(group){
						if(group.vehicle					){ item.vehicle 				= group.vehicle; 					}
						if(group.driver						){ item.driver					= group.driver;						}
						if(group.plate						){ item.plate					= group.plate; 						}
						if(group.transporter				){ item.transporter				= group.transporter; 				}
						
						if(group.departure_PickupTime		){ 
							item.departure_PickupTime			= group.departure_PickupTime;		
						}						
						if(group.departure_PickupLocation	){ 
							item.departure_PickupLocation		=	group.departure_PickupLocation;							 
						}
						if(group.arrival_Transporter_Status){
							item.arrival_Transporter_Status 	=	group.arrival_Transporter_Status;
						}						
						if(group.departure_Transporter_Status){
							item.departure_Transporter_Status 	=	group.departure_Transporter_Status;
						}
					}
				}

				let has_arrival 	= false;
				let has_departure	= false;

				switch(item.direction){
					case "arrival"		:	has_arrival 	= true; break;
					case "departure"	:	has_departure	= true;	break;
					case "both"			:	has_arrival		= true;
											has_departure	= true;
											break;
				}

				if(has_arrival){
					item["arrival_Group"				]	= item.group;
					item["arrival_Vehicle"				]	= item.vehicle;
					item["arrival_Driver"				]	= item.driver;
					item["arrival_Plate"				]	= item.plate;
					item["arrival_Transporter"			]	= item.arrival_Transporter 			||	item.transporter_id;
					item["arrival_Transporter_Name"		]	= item.arrival_Transporter_Name		|| 	item.transporter_name;
					item["arrival_Transporter_Status"	]	= item.arrival_Transporter_Status	|| 	item.transporter_status;
					item["arrival_Fomento_Id"			]	= item.fomento_id;
					item["arrival_Fomento_Status"		]	= item.fomento_status;
					if(item.reference_service){
						item["arrival_Reference_Service"]	= item.reference+"_arrival";						
					}
					// Save last transporter status for queries
					if(item["arrival_Date"]==currentDate){
						item.transporter_status				= item.arrival_Transporter_Status;
					}
				}
				
				if(has_departure){
					item["departure_Group"				]	= item.group;
					item["departure_Vehicle"			]	= item.vehicle;
					item["departure_Driver"				]	= item.driver;
					item["departure_Plate"				]	= item.plate;
					item["departure_Transporter"		]	= item.departure_Transporter		|| 	item.transporter_id;
					item["departure_Transporter_Name"	]	= item.departure_Transporter_Name	|| 	item.transporter_name;
					item["departure_Transporter_Status"	]	= item.departure_Transporter_Status	||	item.transporter_status;
					item["departure_Fomento_Id"			]	= item.fomento_id;
					item["departure_Fomento_Status"		]	= item.fomento_status;
					if(item.reference_service){
						item["departure_Reference_Service"]	= item.reference+"_departure";
					}
					// Save last transporter status for queries
					if(item["departure_Date"]==currentDate){					
						item.transporter_status				= item.departure_Transporter_Status;
					}
				}

				return item;
			})
		};

		console.log(data);

		return this.entityService.postJSON(
			this.entityService.getUrl("update_bookings_assembly"),
			JSON.parse(JSON.stringify(data))
		);
	}


	/** Clear published services */
	async clearPublishedServices(){

		let dmc			= this.commons.userInfo.currentDmc.id;
		let destination	= this.commons.userInfo.currentDestination.id;
		let docPath 	= "/dmcs/"+dmc+"/destinations/"+destination+"/solutions/"+this.calendar.date;
		let response;

		try {
			response = await this.firebaseService.deleteDoc(docPath);
			console.log("[clearPublishedServices] Response",response);
			this.commons.generateToast("SUCCESS","_OK_PERSISTING_SERVICES","info");
			return true;
		}catch($e){
			this.commons.generateToast("ERROR","_ERROR_PERSISTING_SERVICES","error");
			this.commons.generateToast("ERROR",$e.message,"error");
			console.log("[clearPublishedService] Error",$e.message);
			return false;
		}
	}

	async saveServices($public=false){

		if(!$public){
			return this.saveServicesIntoBookings();
		}

		this.commons.generateToast("INFO","_PERSISTING_SERVICES","info");

		let endpoint	= $public?"/services":"/saved_services";
		let dmc			= this.commons.userInfo.currentDmc.id;
		let destination	= this.commons.userInfo.currentDestination.id;
		let path 		= "/dmcs/"+dmc+"/destinations/"+destination+"/solutions/"+this.calendar.date+endpoint;
		let response;

		try {
			let vehicles = this.transportsCalendar.rows
								.map(item=>item.name)
								.filter(v=>v!=undefined);

			await this.firebaseService.setDoc(path,{
				id		: "vehicles_"+this.calendar.date,
				items	: vehicles
			});

			response = Promise.all((vehicles||[]).map(async item=>{
				let vehicleInfo = this.transportsCalendar.rows.find(v=>v.name==item);
				if(vehicleInfo){
					vehicleInfo.items.forEach(group=>{
						group.bookingsInfo = [];
						group.bookings.forEach(b=>{
							let bi = this.bookings.data.find(item=>item.reference==b);
							if(undefined!=bi){
								group.bookingsInfo.push(bi);
							}
						})
					})
					console.log("[publish services] Saving vehicle",item);
					await this.firebaseService.setDoc(path,{
						id		: "vehicle_"+item,
						row		: JSON.parse(JSON.stringify(vehicleInfo))
					});
				} else {
					console.log("[publish services] Vehicle",item.name," not found");
				}
			}));

			this.commons.generateToast("SUCCESS","_OK_PERSISTING_SERVICES","info");
			return true;

		}catch($e){
			this.commons.generateToast("ERROR","_ERROR_PERSISTING_SERVICES","error");
			console.log("[saveSolution]",$e["message"]);
			return false;
		}
	}

	async copyToClipboard($info){
		try {
			if(navigator && navigator["clipboard"]){
				await Promise.resolve(navigator["clipboard"].writeText($info.value));
				this.commons.generateToast("_CLIPBOARD","_COPY_TO_CLIPBOARD_SUCCESS","info");
			} else {
				new Error();
			}
		}catch(e){
			this.commons.generateToast("_CLIPBOARD","_COPY_TO_CLIPBOARD_ERROR","error");
		}
	}

	async publish($type,$info:any={}){
		let response, items;
		switch($type){
			case "clear"				:	this.clearPublishedServices();	break;
			case "services"				:	this.saveServices(true); 		break;
			case "all"					:	alert("PUBLISH ALL"); 			break;
			case "transportersCalendar" :   this.commons.generateToast("INFO","_PUBLISHED_TRANSPORTER_CALENDAR","info");
											items = ($info.items||[]).map(item=>{
												let inner = item.items[0];
												return {
													name		: item.name,
													date		: this.calendar.date,
													bookings	: inner.bookings,
													pax			: inner.pax,
													seats		: inner.seats,
													driver		: inner.driver 		|| "_NO_DRIVER",
													zone		: inner.zone 		|| "_NO_ZONE",
													startTime	: inner.firstTime	|| "00:00",
													endTime		: inner.lastTime	|| "23:59",
													direction	: inner.type		|| "_NO_DIRECTION",
													vehicleInfo	: item.vehicleInfo	|| "_NO_VEHICLE_INFO",
													routeRef	: inner.routeRef	|| "_NO_ROUTE_REF"
												}
											});

											response = await this.transportService.generateTransporterCalendar({
												dmcId			: this.commons.userInfo.currentDmc.id,
												destinationId	: this.commons.userInfo.currentDestination.id,
												transporterId	: $info["transporter"]["id"],
												// date			: this.commons.getNow('Y-MM-DD'),
												date			: this.calendar.date,
												info			: { id				: this.calendar.date,
																	items			: items
																}
											});

											console.log("[publish] DriverCalendar",response);
											break;

			case "driversCalendar"		:	this.commons.generateToast("INFO","_PUBLISHED_DRIVERS_CALENDAR","info");
											items = ($info.items||[]).map(item=>{
												let inner = item.items[0];
												return {
													name		: item.name,
													date		: this.calendar.date,
													bookings	: inner.bookings,
													pax			: inner.pax,
													seats		: inner.seats,
													driver		: inner.driver 		|| "_NO_DRIVER",
													zone		: inner.zone 		|| "_NO_ZONE",
													startTime	: inner.firstTime	|| "00:00",
													endTime		: inner.lastTime	|| "23:59",
													direction	: inner.type		|| "_NO_DIRECTION",
													vehicleInfo	: item.vehicleInfo	|| "_NO_VEHICLE_INFO",
													routeRef	: inner.routeRef	|| "_NO_ROUTE_REF"
												}
											});

											response = this.transportService.generateDriverCalendar({
												dmcId			: this.commons.userInfo.currentDmc.id,
												destinationId	: this.commons.userInfo.currentDestination.id,
												info			: {}
											});

											console.log("[publish] DriverCalendar",response);
											break;
		}
	}

	isDisabled($type,$item)				{	
		switch($type){
			case "transferBookings"	:	return !this.pageInfo.selectedRoutes.bookingPanelOpen; break;
		}
	}

	addGroup($type){
		if(this.groups.data){
			this.groups.data.unshift({
				name			: "_NEW_GROUP",
				bookings		: [],
				direction		: $type,
				type			: $type,
				date			: this.calendar.date,
				area			: "_NO_AREA",
				zone			: "_NO_ZONE",
				private 		: false,
				provider		: 1,
				solution		: 1,
				pax				: 0
				// arrival_Date	: this.calendar.date,
				// end				: 1255
				// end_timestamp	: 1683226500
				// init			: 1225
				// init_timestamp	: 1683224700
				// pickupDateTime	: "2023-05-04 20:25"
				// pickupTime		: "20:25"
				// routeTime		: 30
				// waitTime		: 30
			})
		}
	}

	private addNewVehicleToCalendar(){
		let alreadyNewVehicle = this.transportsCalendar.rows.find(item=>item.code=="000_NEW");
		if(alreadyNewVehicle){
			this.commons.generateToast("_VEHICLE","_VEHICLE_ALREADY_IN_CALENDAR","error");
			return false;
		}
		this.transportsCalendar.rows.push({
			name			: "000_NEW",
			code			: "000_NEW",
			items			: [],
			transporter		: "_INNER_FLEET"
		});
	}

	private addVehicleToCalendar($params){
		let item = $params["item"];

		let current = this.transportsCalendar.rows.find(row=>row.name==item.code);
		if(current){
			this.commons.generateToast("_VEHICLE","_VEHICLE_ALREADY_IN_CALENDAR","error");
			return false;
		}

		this.transportsCalendar.rows.push({
			name			: item.code,
			code			: item.code,
			items			: [],
			plate			: item.plate,
			seats			: item.seats,
			transporter		: "_INNER_FLEET"
		});
	}

	async getFomentoServiceInfo(){
		let groups = this.getFilteredEntity('services',{ type: 'grid' });

		let params 		= {
			dmc			: this.commons.userInfo.dmc.id,
			destination	: this.commons.userInfo.currentDestination.id,
			action		: "get_service_info",
			items		: groups.map(item=>item.name)
		};

		this.commons.generateToast("_FOMENTO","_FOMENTO_GET_ID","info");
		this.commons.generateToast("ERROR","_CHANGES_PENDING","error");

		console.log("[getFomentoServiceInfo] params", params);

		await this.entityService.postJSON(
			this.entityService.getUrl("fomento_proxy"),
			JSON.parse(JSON.stringify(params))
		)
		.then(response => {
			console.log("[saveServicesIntoBookings] response success",response);
			if(response["success"]){
				(response["items"]||[]).forEach(item=>{
					let group = groups.find(g=>g.name==item.name);
					if(undefined!=group){
						group.fomento_id 		= item.fomento_id;
						group.fomento_status	= item.fomento_status;

						// Save id and status to all bookings
						group.bookingsInfo.forEach(bi=>{
							bi.fomento_id		= group.fomento_id;
							bi.fomento_status	= group.fomento_status;
						})
					}
				});
			}
		}).catch(response => {
			console.log("[saveServicesIntoBookings] response error",response);
		})
	}

	toggleRow($item) 				{ this.toggleDT($item); 						}
	expandRow($item) 				{ this.toggleDT($item); 						}
	collapseRow($item) 				{ this.toggleDT($item); 						}

	/**
	 * actions to take once we open row with expander
	 *
	 * @param $item
	 * @returns
	 */
	toggleDT($item) 				{
		console.log($item);
		if (undefined === $item) { return false; }
		if (undefined == this[$item.table].expandedRowKeys[$item.id]) {
			this[$item.table].expandedRowKeys 				= [];
			this[$item.table].expandedRowKeys[$item.id] 	= 1;
			this.rowData[$item.table] 						= $item;
		} else {
			this[$item.table].expandedRowKeys 				= [];
		}
	}

	private async changeExternalServiceStatus($info){

		$info.type		= "send_item";
		// $info.items		= [ $info.item ];

		switch($info.item.action){
			case "_ACTION_SEND"	: this.doTransporterActionSend($info);	break;
			case "_ACTION_CLEAR": this.doTransporterActionClear($info); break;
		}
	}

	/**
	 * send service to transporter
	 */
	async doTransporterActionSend($info)
	{
		let status				= "_PENDING";

		let $type				= $info.type;
		let $items				= [];
		let response;
		let transporter;

		let transporters 		= this.getFilteredEntity('transporters', { type: 'externals' });
		let transporterItems 	= {};

		// Process all items per transporter
		($info.items||[]).forEach(item=>{
			if(item.transporter!=undefined){	
				if(undefined==transporterItems[item.transporter]){
					transporterItems[item.transporter] = {
						dirty	: false,
						items 	: []	
					};
				}		
				switch(item.transporter_status){
					// case "_SENT"	:
					case "_NOT_SEND":	
						if(item.selected){					
							item.status 			= status;
							item.to_send			= true;							
							item.transporter_status = status;
							item.bookingsInfo.forEach(b=>{
								b.transporter_status = status;
							})	
							transporterItems[item.transporter].dirty = true;
						}
						break;
					default:
						item.to_send = false;
						break;
				}

				// Only items to be sent
				if(item.to_send){
					transporterItems[item.transporter].items.push(item);
				}
			}
		});

		let transporterNames 	= Object.keys(transporterItems) || [];
		let currentBookings		= [];

		// Generate per transporter entry and send
		for ( transporter of transporterNames ){

			let transporterInfo = transporters.find(t=>t.name==transporter);
			if(undefined==transporterInfo){
				this.commons.generateToast("_ERROR","_NO_TRANSPORTER_FOUND","error");
				return false;
			}

			let trItems 	= transporterItems[transporter];

			// If not items to send just return
			if(!trItems.dirty){
				continue;
			}

			let transporterData:any 	= await this.firebaseService.getDocData(transporterInfo.transporter_ref);
			transporterInfo.api_code 	= transporterInfo.api_code 	||	transporterData.api_code;	
			transporterInfo.has_api		= transporterInfo.has_api	||	transporterData.has_api;					

			if(!transporterData.id_remote){	
				transporterInfo.id_remote = this.transporterApiCode[transporterData.api_code];					
			}

			let info				= {
				items		: 	trItems.items || [],
				transporter	: 	{
					id			: transporterInfo.id,
					id_remote	: transporterInfo.id_remote,
					has_api		: transporterInfo.has_api,
					api_code	: transporterInfo.api_code,
					name		: transporterInfo.name,
					to			: transporterInfo.to,
					ref			: transporterInfo.transporter_ref
				}
			};

			if(info.items.length==0	){ this.commons.generateToast("_ERROR","_NO_ITEMS_TO_SEND",		"error"); return false; }
			if(!info.transporter	){ this.commons.generateToast("_ERROR","_NO_TRANSPORTER_FOUND",	"error"); return false; }

			console.log("SEND TRANSPORTER REQUEST", transporter, info);
			
			// Process each transporter service
			info.items.forEach(service=>{
				(service.bookingsInfo||[]).forEach(bi=>{
					bi.transporter			= info.transporter.id_remote;
					bi.transporter_name		= transporter;
					bi.transporter_id 		= info.transporter.id_remote;
					bi.transporter_status	= "_SENT";
	
					let has_arrival 		= false;
					let has_departure		= false;

					switch(bi.direction){
						case "arrival"	:	
							has_arrival 	= true; 
							break;

						case "departure": 	
							has_departure	= true; 
							break;

						case "both"		:	
							has_arrival		= true;
							has_departure	= true;
							break;
					}
					
					if(has_arrival){
						if(bi.arrival_Date==this.calendar.date){
							bi.arrival_Transporter			= bi.transporter;							
							bi.arrival_Transporter_Name		= transporter;
							switch(service.arrival_Transporter_Status){
								case "_PENDING"	:
								case "_ACCEPTED": 	
								case "_NOTIFIED":	break;
								default			:	service.arrival_Transporter_Status = "_SENT"; break;
							}
						}
					}

					if(has_departure){
						if(bi.departure_Date==this.calendar.date){
							bi.departure_Transporter			= bi.transporter;
							bi.departure_Transporter_Name		= transporter;
							switch(service.departure_Transporter_Status){
								case "_PENDING"	:
								case "_ACCEPTED": 	
								case "_NOTIFIED":	break;
								default			:	
									service.departure_Transporter_Status 	= "_SENT"; 
									service.transporter_status				= service.departure_Transporter_Status; 
									break;
							}
						}
					}

					currentBookings.push(bi);
				})
	
				service.transporter 		= transporter;
				switch(service.transporter_status){
					case "_PENDING"	:
					case "_ACCEPTED": 	
					case "_NOTIFIED":	break;
					default			:	service.transporter_status = "_SENT"; break;
				}
			});

			// Persist current transporter bookings
			// BEFORE sending to API
			let response = await this.saveServicesIntoBookings(currentBookings.filter(b=>b.transporter_name=transporter));
			console.log("[saveServicesIntoBookings] Response",response);

			const has_api = info.transporter.has_api || info.transporter.to=="api";
			switch(has_api){
				case false	: 	this.sendServicesToTransporterExternal(info);	break;
				case true	:	this.sendServicesToTransporterAPI(info); 		break;
			}			
		}

		// Finally save solution
		// if(transporterNames.length>0){
		// 	this.saveServicesIntoBookings(currentBookings);
		// }		

	}

	private async sendServicesToTransporterExternal($info)
	{
		// let item				= $info.item;
		let transporter			= $info.transporter;
		let parsedItem;

		try {
			let info:any = {
				id				: $info.id,
				items			: $info.items.filter(item=>{
									switch(item.status){
										case "_NOT_SEND": return false;
										default			: return true;
									}
								  }),
				transporter		: {
					id		: transporter.id,
					name	: transporter.name,
				}
			}
			parsedItem 				= JSON.parse(JSON.stringify(info));
			parsedItem.id			= this.calendar.date;

			// Save into Transporter
			const transporterDocPath	= transporter.ref+"/requests";
			await this.firebaseService.setDoc(transporterDocPath,parsedItem);

			// Save into Dmc destination
			info.dmc				= this.commons.userInfo.currentDmc.id;
			info.destination		= this.commons.userInfo.currentDestination.id;

			const dmcDocPath = "/dmcs/"+info.dmc+"/destinations/"+info.destination+"/transporters/"+transporter.id+"/requests";
			await this.firebaseService.setDoc(dmcDocPath,parsedItem);
						
		} catch(e){
			console.log("[sendServicesToTransporterExternal] Error",e);
		}
	}

	/**
	 * remove service transporter to be able to assign to another one
	 * @param $info
	 */
	doTransporterActionClear($info){
		let $item			= $info.item;
		let $transporter	= $info.transporter;
		let response;

		this.commons.generateToast("_INFO","_NOT_SEND","info");
		// $item.status = "_NOT_SEND";
		$transporter.items = $transporter.items.filter(item=>item!=$item);
	}

	private updateExternalServicesAction($info){
		$info.status = $info.status || "_NOT_SEND";
		switch($info.status){
			case "_NOT_SEND"	: $info.action = "_ACTION_SEND"; 	break;
			case "_PENDING"		: $info.action = "_ACTION_NONE"; 	break;
			case "_ACCEPTED"	: $info.action = "_ACTION_NONE"; 	break;
			case "_REJECTED"	: $info.action = "_ACTION_CLEAR"; 	break;
		}
		return $info;
	}

	/**
	 * Persist Booking
	 * @param $item
	 */
	 async setBookingProviderSent($info) 		{
		let $booking 	= $info.booking;
		let $transporter= $info.transporter;

		let response 	= await this.entityService.postJSON(
			this.entityService.getUrl("booking_update_transporter"),
			{
				reference			: $booking,
				transporter			: $transporter,
				transporter_status	: "_SENT"
			}
		)

		console.log("[setBookingProviderPending] booking",$booking," transporter",$transporter);
		console.log("[setBookingProviderPending] Response",response);

		return response;
	}

	/**
	 * send service to transportar with API
	 * @param $items
	 * @returns
	 */
	async sendServicesToTransporterAPI($info)
	{
		// Filter services to send
		$info.items = $info.items.filter(item=>item.to_send);

		if(($info.items||[]).length==0){
			this.commons.generateToast("_ERROR","_NO_ITEMS_TO_SEND","error");
			return false;
		}
		
		// Send marked bookings to provider
		let moment 				= this.commons.getMoment();
		const url				= this.entityService.getUrl("bookings_send_assigned");
		const dmcId				= this.commons.userInfo.currentDmc.id;
		
		let transporterData:any = await this.firebaseService.getDocData($info.transporter.ref);
		if(!transporterData){
			this.commons.generateToastError("_TRANSPORTER_DATA_NOT_FOUND");
			return false;
		}
		
		const api_code			= 		transporterData.api_code 		||	transporterData.name;
		const destinationId		= 		transporterData.destination	
									||	this.commons.userInfo.currentDestination.dbid
									||	this.commons.userInfo.currentDestination.id;
		
		// Get control values
		const controls			=	this.pageInfo.controlPanels.control.buttons.find(item=>item.name=="controls");
		const execCommand		=	controls.items.find(item=>item.name=="exec_command"	);
		const environment		=	controls.items.find(item=>item.name=="environment"	);
		
		const params 			= {
			transporter	: api_code,
			destination : destinationId,
			dmc			: dmcId,
			date		: moment(this.calendar.value).format('YYYY-MM-DD'),
			// offset		: '0',
			offset		: '1',	// For next day reservation ( before 04:00 )
			directions	: "both",
			exec		: execCommand.selected=="yes"?true:false,
			environment	: environment.selected
		};

		console.log("url",url,"params",params);

		await this.entityService.postJSON(url, params)
			.then(response => {
				this["bookings"].spinner = false;
				if(response["success"]==false){
					this.commons.generateComplexToast({
						title	: "Booking assignation", 
						content	: [ "_ERROR", response["error"] ],
						type	: "error"
					});
				} else {
					this.commons.generateToast("Booking assignation", "_BOOKINGS_ASSIGNATION_SUCCESS", "success");
				}
				return true;
			}).catch(response => {
				this["bookings"].spinner = false;
				let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
				this.commons.generateComplexToast({
					title	: "Booking assignation", 
					content	: [ "_ERROR", response["error"] ],
					type	: "error"
				});
			});
	}

	/**
	 * Remove External services
	 */
	clearServicesExternalTransporters(){
		let services = this.getFilteredEntity('services',{ type: 'grid' });
		services
			.filter(service=>{
				if(undefined==service.transporter || service.transporter==""){
					return false;
				}
				switch(service.transporter){
					case "_INNER_FLEET"	: return false;
					default				: return true;
				}
			})
			.filter(service=>service.selected)
			.forEach(service=>{
				service.transporter 		= undefined;
				service.transporter_status 	= undefined;
				service.vehicle				= undefined;

				(service.bookingsInfo||[]).forEach(b=>{
					b.transporter			= undefined;
					b.transporter_status	= undefined;
					b.vehicle				= undefined;
					let has_arrival 		= false;
					let has_departure		= false;

					switch(b.direction){
						case "arrival"	: 	has_arrival 	= true; break;
						case "departure":	has_departure 	= true; break;
						case "both":
							if(b.arrival_Date==this.calendar.date	){ has_arrival 	= true; }
							if(b.departure_Date==this.calendar.date	){ has_departure= true; }
							break;
					}

					if(has_arrival){
						b.arrival_Transporter 			= undefined;
						b.arrival_Transporter_Name		= undefined;
						b.arrival_Transporter_Status	= undefined;
					}

					if(has_departure){
						b.departure_Transporter			= undefined;
						b.departure_Transporter_Name	= undefined;
						b.departure_Transporter_Status	= undefined;
					}
				})
			});
	}

	/**
	 * do actions related to service, own and externals
	 */
	private doServiceAction($info){
		switch($info.action){
			case "send_selected_pending_services"			: 
				let services = this.getServices({ type: 'grid', assignation_type: "all" });
									// .filter(service=>service.selected)
									// .filter(service=>service.transporter_status=="_NOT_SEND");
				this.doTransporterActionSend({ type: 'send_item', items: services });					
				break;

			case "select_all_services"				: 
		 		this.getServices({ type: 'grid', assignation_type: "all" })
					.forEach(service=>{ service.selected = true; });
				break;

			case "unselect_all_services"			: 
				this.getServices({ type: 'grid', assignation_type: "all" })
					.forEach(service=>{ service.selected = false; });
				break;

			case "clear_transporters"				: 
				this.getServices({ type: 'grid', assignation_type: "all" })
					.filter	(service=>{ service.transporter })
					.forEach(service=>{ 
						service.selected = false; 
					});
				break;

			case "click"							: 
				switch(this.pageInfo.controlPanels.externals.modes.selected){
					case "assign": 
						this.assignExternalToService({ item: $info.item }); 
						break;

					case "filter": 
						if($info.item.name==this.pageInfo.selectedExternal){
							this.pageInfo.selectedExternal = undefined;
						} else {
							this.pageInfo.selectedExternal = $info.item.name;
						}
						break;
				}
				break;			
		}
	}

	doImportPickups(){
		this.importerService.onUpload("services", {});
	}

	doImportedPickups($info){
		let line 		= [];
		let data 		= [];
		// let services 	= this.getFilteredEntity('services',{ type: 'grid' });
		let services	= this.groups.data;

		switch($info.provider){
			case "avantcab"	:
			default			:	data = this.doFixAvantcabImportedPickups($info); break;
		}
	
		(data||[])
			.filter(item=>{
				return item[7]!="" && item[7]!=undefined;
			})
			.map(item=>{
				// Add 0 before hour under 10
				let time	= (item[7].match(/^\d{2}/)==null?'0':'')+item[7];
				// Get only hh:mm and remove ss
				time		= time.slice(0,5);
				const data 	= [ item[0], item[5], time ];
				return data;
			})
			.forEach(item=>{
				let booking = this.bookings.data.find(b=>b.reference==item[0]);
				if(booking!=null){
					// Update booking
					booking.departure_PickupTime 		= item[2];
					booking.departure_PickupLocation	= item[1];
					booking.transporter_Status			= "_ACCEPTED";

					switch(booking.direction){
						case "arrival":
							booking.arrival_Transporter_Status		= "_ACCEPTED";
							break;
						case "departure":
							booking.departure_Transporter_Status	= "_ACCEPTED";
							break;
					}

					// Update booking service
					let service = services.find(s=>s.name==booking.group);
					if(service!=null){
						service.departure_PickupTime 	= booking.departure_PickupTime;
						service.departure_PickupLocation= booking.departure_PickupLocation;
						service.transporter_status		= "_ACCEPTED";
						switch(service.direction){
							case "arrival":
								service.arrival_Transporter_Status		= "_ACCEPTED";
								break;
							case "departure":
								service.departure_Transporter_Status	= "_ACCEPTED";
								break;
						}
					}
				}
			});
		
		this.saveServicesIntoBookings();
		this.pageInfo.import_pickups_sidebar = false;
	}

	/**
	 * Avantcab file split line due to a problem
	 * with flight. We have to join every 2 lines 
	 * and merge last field first line with first field second line
	 * 
	 * @param $info 
	 */
	doFixAvantcabImportedPickups($info){
		let data 	= [];
		let line	= [];
		($info.data||[]).forEach(($entry,$index)=>{
			if($index==0){ return; }
			switch($index%2){
				case 1: line = $entry; break;
				case 0: $entry.forEach(($item,$index)=>{
							switch($index){
								case 0	:	line[line.length-1] += " "+$item;	break;
								default	:	line.push($item);						
							}
						});
						data.push(line);
						break;
			}
		})
		return data;
	}

	/**
	 * 
	 */
	savePickups(){
		this.commons.generateToast("_INFO","_SAVE_PICKUPS","info");
	}

	/**
	 * notify pickup time and location back to origin
	 * 
	 */
	 private notifyPickups(){ 
		this.commons.generateToast("_INFO","_NOTIFY_PICKUPS","info");

		// 1. Get all bookings with status ACCEPTED and pickupTime and Location
		let bookings = this.bookings.data.filter(b=>{
			if(b.direction!="departure"						){ return false; }
			if(b.departure_Transporter_Status!="_ACCEPTED"	){ return false; }
			if(!b.departure_PickupTime						){ return false; }
			if(!b.departure_PickupLocation					){ return false; }
			return true;
		});

		if((bookings.length)==0){
			console.log("No pickups to notify");
		} else {
			console.log("Bookings to send pickup",bookings);
		}
		
		bookings.forEach(async b=>{ 
			await this.notifyPickup(b);
		});
	}

	/**
	 * notify departure pickup to provider
	 */
	async notifyPickup($item)	{

		await this.entityService.postJSON(
			this.entityService.getUrl("booking_pickup"),
			{ 	
				reference	: $item["reference"],
				time		: $item["departure_PickupTime"],
				location	: $item["departure_PickupLocation"]
			}
		).then(response => {
			this.commons.generateToast("Booking validation", "Booking updated", "success");
			return true;

		}).catch(response => {
			let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
			this.commons.generateToast("Booking validation", errorStr, "error");
		});
	}

	doActionServices($action,$info){
		switch($action){
			case "toggle_services"			:
				this.pageInfo.servicesToggle = this.pageInfo.servicesToggle?false:true;
				if(this.pageInfo.servicesToggle){
					this.doServiceAction({ action: "select_all_services" });		
				} else {
					this.doServiceAction({ action: "unselect_all_services" });	
				}
				break;

			case "send_all_pending_services"	:
				$info.type = "all_news";
				this.doTransporterActionSend($info);
				break;

			case "import"						:
				$info.format = "csv";
				this.importerService.onUpload("services", $info);
				break;

			case "save_pickups"					:
				this.savePickups();
				break;

			case "import_pickups"				:
				this.CsvService.onUpload("pickups", $info);
				break;
		}
	}

	doActionExternal($action,$info){
		this.doServiceAction({ action: $action, item: $info.item });
	}

	doActionTable($action,$info){
		switch($action){
			case "click":
				switch($info.type){
					case "row"	:	$info.rowData.selected = $info.rowData.selected ? false: true;
									break;
				}
				if(undefined!=$info.col){
					switch($info.col.field){
						case "reference": this.copyToClipboard($info); 	break;
					}
				}
				break;
		}
	}

	doActionSearch($action,$info){
		switch($action){
			case "autocomplete"	:	break;
			case "exec"			:	this.load("bookings_search");
									break;
			case "show"			:	this.pageInfo.search.show = true;
									if(this.searchInput){
										this.searchInput.nativeElement.focus();
									}
									break;
			case "hide"			:	this.pageInfo.search.show = false;
									this.pageInfo.search.content	= "";
									if(this.searchInput){
										this.searchInput.nativeElement.blur();
									}
									break;
		}
	}

	doActionRow($action,$info){
		switch($action){
			case "toggle":
				if($info.table){ $info.rowData.table = $info.table; }
				switch($info.rowData.table){
					case "bookingsGrid" : break;
					default				: this.toggleRow($info.rowData); break;
				}
				break;
			case "click":
				switch($info.col.field){
					case "reference"	: this.copyToClipboard({ value: $info.rowData[$info.col.field] }); break;
				}
				break;
		}
	}

	doActionCalendar($action,$info){
		switch($action){
			case "clear_selected": 	this.pageInfo.serviceSelected = undefined;
									break;
			case "remove_vehicle": 	this.remove($info.row.plate?'calendarVehicle':'calendarVehicleType',{ row: $info.row },$info.event);
									break;
		}
	}

	doActionZone($action,$info){
		switch($action){
			case "select": this.pageInfo.selectedZone = $info.item; break;
		}
	}

	doActionFomento($action,$info){
		switch($action){
			case "send"				:	this.fomentoService.sendServices({
											// data	: this.getTransportsCalendar(),
											data	: this.getTransportsCalendarGroups(),
											filter	: this.pageInfo.filterFomentoByTime
										});
		}
	}

	doActionTransfer($action,$info){
		switch($action)	{
			case "assign"			:	$info["item"].assigned	= $info["item"].assigned?false:true;
										$info["item"].status	= $info["item"].status || "_PENDING";
										break;
										
			case "changeStatus"		:	switch($info["item"].status){
											case "_PENDING"		: $info["item"].status = "_ACCEPTED"; 	break;
											case "_ACCEPTED"	: $info["item"].status = "_REJECTED"; 	break;
											case "_REJECTED"	: $info["item"].status = "_PENDING"; 	break;
										}
										break;
		}
	}

	doActionTab($action,$info){
		switch($action)	{
			case "select"			: 	if(!$info["tab"].disabled){	this.pageInfo.calendar.view.selected=$info["tab"].name;}	break;
		}
	}

	doActionControl($action,$info)
	{
		switch($action)	{
			case "scale"				:	this.setScale(parseInt($info["item"].name));	break;
			case "export"				:	this.export($info);							break;
			case "viewTransfer"			:	this.pageInfo.calendar.buttons["viewTransferButtons"].selected = $info["item"].name;	break;
			case "viewDriver"			:	this.pageInfo.calendar.buttons["viewDriverButtons"	].selected = $info["item"].name;	break;
			case "doButton"				:
				// this.commons.generateToast("_BUTTON_PRESSED",$info["item"].name,"info");
				switch($info["item"].name){
					case "toggle_pickups_import"				:
						this.pageInfo.import_pickups_sidebar = this.pageInfo.import_pickups_sidebar?false:true;
						break;
					case "send_selected_pending_services"		: 	this.doServiceAction({ action: $info.item.name });
																	break;
					case "clear_services_external_transporters"	:	this.clearServicesExternalTransporters();				break;
					case "get_fomento_info"						:	this.getFomentoServiceInfo();							break;
					case "toggle_lodgings"						:	this.pageInfo.show_lodgings = this.pageInfo.show_lodgings || true;	
																	break;
					case "notify_pickups"						:	this.notifyPickups();									break;
					case "export_services"						:	this.export("csv","services");							break;
					case "add_new_vehicle"						:	this.addNewVehicleToCalendar();							break;

					case "remove_unused_vehicles"				: 	this.removeUnusedVehicles();							break;
					case "set_available_vehicles"				: 	this.setAvailableVehicles();							break;
					case "set_first_services"					: 	this.setFirstServices();								break;

					case "set_services_drivers"					:	this.setServicesDrivers();								break;
					case "clear_services_drivers"				:	this.clearServicesDrivers();							break;

					case "set_services_plates"					:	this.setServicesPlates();								break;
					case "clear_services_plates"				:	this.clearServicesPlates();								break;

					case "add_arrival_group"					:	this.addGroup("arrival");								break;
					case "add_departure_group"					:	this.addGroup("departure");								break;
					case "load_solution"						:	this.loadSolution();									break;
					case "save_solution"						: 	this.saveSolution();									break;
					case "load_bookings"						:	this.loadBookings();									break;
					case "show_search"							:	this.doAction("search","show",{}); break;
					case "clear_groups"							:	this.clearServices();
																	this.clearGroups();
																	break;
					case "clear_vehicles"						:	this.clearBookingVehicles();							break;
					case "clear_plates"							:	this.clearPlates();										break;
					case "clear_drivers"						:	this.clearDrivers();									break;
					case "clear_transporters"					:	this.clearTransporters();								break;
					case "generate_groups"						:	this.generateGroups();									break;
					case "set_vehicles"							:	this.generateInitRows({ mode: "full", clear: false });	break;
					case "generate_services"					:	this.generateServices(); 								break;
					case "clear_row_vehicles"					:	this.clearRowVehicles(); 								break;
					case "clear_unused_vehicles"				:	this.clearVehiclesNotUsed();							break;
					case "clear_services"						:	this.clearServices(); 									break;
					case "publish"								:	this.publish("services");								break;
					case "publish_clear"						:	this.publish("clear");									break;
					case "fomento"								:	
						this.fomentoService.sendServices({
							data	: this.getTransportsCalendarGroups(),
							filter	: this.pageInfo.filterFomentoByTime
						});
						break;
				}
				break;
			
			// Buttons toggling
			case "transferMode"				:	this.doAction("button","toggle",$info);		break;					
			case "servicePartition"			:	this.doAction("button","toggle",$info);		break;
			case "service_order"			:	this.doAction("button","toggle",$info);		break;
			case "transferType"				:	this.doAction("button","toggle",$info);		break;
			case "execMode"					:	this.doAction("button","toggle",$info);		break;
			case "execCommand"				:	this.doAction("button","toggle",$info);		break;
			case "setEnvironment"			:	this.doAction("button","toggle",$info);		break;
			case "showOnlyVerifiedBookings"	:	this.doAction("button","toggle",$info);		break;
			case "showCancelledBookings"	:	this.doAction("button","toggle",$info);		break;
			case "transferMod"				:	this.doAction("button","toggle",$info);		break;
		}
	}

	doActionProvider($action,$info){
		switch($action)	{
			case "_toggle"			: 	
				if ( (this["providers"].selected || {}).id == $info["provider"].id ){ return false; }
				// Check DIRTY data
				if( ["arrivals","departures","fleet"].reduce((status,item)=>{
					let orig 	= this[item].initialData;
					let current = JSON.stringify(this[item].draggableSet);
					let idem	= orig==current;
					return status || !idem;
				},false)
				){
					this.commons.generateToast("ERROR","_CHANGES_PENDING","error");
					// return false;
				}

				this["providers"].selected = $info["provider"];
				//this.load("groups");
				break;

			case "toggle"			:	
				let provider 		= (this.providers.data||[]).find(item=>item.id==$info.provider.id);
				if(undefined==provider){ return false; }
				provider.selected 	= provider.selected?false:true;
				break;
		}
	}

	doActionWheel($action,$info)
	{
		switch($info["type"]){
			case "X"				:	if(undefined==$info["event"]){ return false; }
										$info["event"].currentTarget.scrollLeft += $info["event"].deltaY * this.pageInfo.wheel.xFactor;
										$info["event"].preventDefault();
										$info["event"].stopPropagation();
										break;

			case "Y"				:	if(undefined==$info["event"]){ return false; }
										$info["event"].stopPropagation();
										break;

			case "slider"			:	
				$info["event"].stopPropagation();
				this.getInfo("initTime",{ event: $info["event"] });						
				if(undefined==$info["event"]){ return false; }
				break;
		}
	}

	doActionButton($action,$info)
	{
		switch($action){
			case "select_pending_services"	:
			case "select_all_services"		:
			case "unselect_all_services"	:
			case "clear_transporters"		:	this.doServiceAction({ action: $action }); break;
			case "reload"					:	this.loadEntities();							break;
			case "save"						:	this.saveSolution();							break;
			case "zones"					:	
				this.pageInfo.zonesFilter.selected = this.pageInfo.zonesFilter.selected==$info["name"]?'':$info["name"];
				break;
			case "toggle"			:	
				if( undefined==$info["button"] || undefined==$info["item"] ){ return false; 		}
				if( $info["button"].multiple )	{
					// Check there is at least MIN_SELECTED_ITEMS
					if( 	$info["button"].minSelected
						&&	$info["item"].value
						&&	($info["button"].items.filter(item=>item.value)||[]).length<=$info["button"].minSelected
					){ return false;	}
					$info["item"].value 		= $info["item"].value?false:true;
				}
				else						{	
					$info["button"].selected 	= $info["item"].name;	
				}
				break;
		}
	}

	doActionPanel($action,$info)
	{
		switch($action){
			case "reload"			:	this.load("transporters");			break;
			case "save"				:	// Set operative values
										this["transporters"].data 			= this["transporters"].draggableSet.map(item=>item); break;
			case "close"			:	// Restore values
										// this["transporters"].draggableSet	= this["transporters"].data.map(item=>item);
										this.pageInfo.panels[$info] = false;
										break;
			case "toggle_shared"	:	$info["shared"]  = !$info["shared"];	break;
			case "toggle_private"	:	$info["private"] = !$info["private"];	break;
		}
	}

	doActionTransporter($action,$info)
	{
		switch($action){
			case "filter":	switch($info.action){
				case "toggle":	if($info.item.forced)	{ 	$info.item.selected = true; 							}
								else					{	$info.item.selected = $info.item.selected?false:true; 	}
								break;
				}
				break;
			case "togglePlates"			:	
				if( $info["transporter"] && $info["transporter"]["name"]=="_INNER_FLEET" ){
					$info["transporter"].open = $info["transporter"].open?false:true;
				}
				break;

			case "toggleTransportType"	:	
				if( $info["transportType"] && $info["transporter"] && $info["transporter"]["name"]=="_INNER_FLEET" ){
					$info["transportType"].open = $info["transportType"].open?false:true;
				}
				break;

			case "generateCalendar"     :   
				this.publish("transporters");
				break;
		}
	}

	doActionService($action,$info)
	{

		switch($action){
			case "change_external_service_status":
				this.changeExternalServiceStatus($info);
				break;

			case "remove_vehicle"	:	
				if($info.item){
					$info.item.vehicle 				= undefined;
					$info.item.transporter			= undefined;
					$info.item.transporter_status	= undefined;
					this.bookings.data
						.filter(bi=>bi.group==$info.item.name)
						.forEach(bi=>{
							bi.vehicle 				= undefined;
							bi.transporter 			= undefined;
							bi.transporter_status	= undefined;
						});
				}
				break;

			case "filter"			:	
				switch($info.action){
					case "toggle":	if($info.item.forced)	{ 	$info.item.selected = true; 							}
									else					{	$info.item.selected = $info.item.selected?false:true; 	}
									break;
				}
				break;

			case "remove"			:	
				this.remove('calendarTransport',{ item: $info.item, row: $info.row },$info.event);
				break;

			case "select"			:	
				if((this.pageInfo.selectedRoutes.items || []).length==0){
					this.pageInfo.selectedRoutes.zone	= undefined;
					this.pageInfo.selectedRoutes.type	= undefined;
				}
				this.pageInfo.selectedRoutes.zone	= undefined==this.pageInfo.selectedRoutes.zone	? $info.item.zone: this.pageInfo.selectedRoutes.zone;
				this.pageInfo.selectedRoutes.type	= undefined==this.pageInfo.selectedRoutes.type	? $info.item.type: this.pageInfo.selectedRoutes.type;

				if(this.pageInfo.selectedRoutes.zone!==	$info.item.zone	){	this.commons.generateToast("_ERROR","_ROUTE_SELECTED_ZONE_ERROR",		"error");	return false; 	}
				if(this.pageInfo.selectedRoutes.type!==	$info.item.type	){	this.commons.generateToast("_ERROR","_ROUTE_SELECTED_DIRECTION_ERROR",	"error");	return false;	}

				$info.item.selected = $info.item.selected?false:true;

				// Add items if selected, previous removing if already exists
				this.pageInfo.selectedRoutes.items	= ( this.pageInfo.selectedRoutes.items || [] ).filter(item=>item!=$info.item);
				this.pageInfo.selectedRoutes.items	= $info.item.selected?[ ...this.pageInfo.selectedRoutes.items, $info.item ]:this.pageInfo.selectedRoutes.items;

				this.doAction("service","check",{});
				break;

			case "clear"			:	
				this.pageInfo.selectedRoutes.items = [];
				this.doAction("service","check",{});
				break;

			case "check"			:	
				this.pageInfo.selectedRoutes.bookingPanelOpen = this.pageInfo.selectedRoutes.items.length>0;
				if(!this.pageInfo.selectedRoutes.bookingPanelOpen){ this.pageInfo.controlPanels.transfers.selected = "routes"; }
				break;
		}
	}

	doActionBooking($action,$info)
	{
		let proposed = {};
		
		switch($action){
			case "unassign_group"	:	
				$info["booking"	].assigned 		= false;
				$info["booking" ].group			= undefined;
				$info["group"	].bookings 		= $info["group"].bookings.filter(item=>item!=$info["booking"].reference);
				$info["group"	].bookingsInfo 	= $info["group"].bookingsInfo.filter(item=>item.reference!=$info["booking"].reference);
				$info["group"	].pax			= this.calculateGroupPax($info["group"]);
				$info["event"	].preventDefault();
				$info["event"	].stopPropagation();
				break;

			case "add2Group"		:	
				proposed["bookings"	]		= [ ...$info["group"]["bookings"], $info["booking"] ].sort((a,b)=>a.init>b.init?1:-1);
				proposed["pax"		]		= proposed["bookings"].reduce((total,item)=>(total+parseInt(item.pax)),0);

				// if(proposed["pax"]>$info["group"]["seats"]){
				if(proposed["pax"]>$info["group"]["row"]["seats"]){
					this.commons.generateToast("ERROR","_OVERFLOW_TRANSFER_SEATS","error");
					return false;
				}

				$info["group"]["bookings"] 	= proposed["bookings"];
				$info["group"]["pax"	]	= proposed["pax"];

				return true;


			case "removeFromGroup"	:	
				proposed["bookings"]		= $info["group"]["bookings"].filter(item=>item.reference!=$info["booking"].reference);
				proposed["pax"		]		= proposed["bookings"].reduce((total,item)=>(total+parseInt(item.pax)),0);

				$info["group"]["bookings"] 	= proposed["bookings"];
				$info["group"]["pax"	]	= proposed["pax"];

				return true;
		}
	}

	doActionDriver($action,$info)
	{
		switch($action){
			case "publish"			:	this.publish('driversCalendar'); 	break;
		}
	}

	doActionVehicle($action,$info){
		switch($action){
			case "generate_calendar":	
				this.commons.generateToast("_VEHICLE","_GENERATE_VEHICLE_CALENDAR","info");
				this.generateFleetCalendarFromVehicle({ vehicle: $info["item"].name });
				break;

			case "set_init_time"	:	
				this.setVehicleInitTime({ info: $info });	
				break;

			case "addToCalendar"	:	
				this.addVehicleToCalendar({ item: $info["item"] });
				break;
		}
	}

	/**
	 * execute action
	 *
	 * @param $type
	 * @param $action
	 * @param $info
	 * @returns
	 */
	doAction($type,$action,$info){
		switch($type){
			case "services"				:	this.doActionServices	($action,$info); 	break;
			case "external"				: 	this.doActionExternal	($action,$info); 	break;
			case "table"				:	this.doActionTable		($action,$info);	break;
			case "search"				:	this.doActionSearch		($action,$info);	break;
			case "row"					:	this.doActionRow		($action,$info);	break;
			case "calendar"				:	this.doActionCalendar	($action,$info);	break;
			case "zone"					:	this.doActionZone		($action,$info);	break;
			case "fomento"				:	this.doActionFomento	($action,$info);	break;
			case "transfer"				:	this.doActionTransfer	($action,$info);	break;
			case "tab"					:	this.doActionTab		($action,$info);	break;
			case "control"				:	this.doActionControl	($action,$info);	break;
			case "provider"				:	this.doActionProvider	($action,$info);	break;
			case "wheel"				:	this.doActionWheel		($action,$info);	break;
			case "button"				:	this.doActionButton		($action,$info);	break;
			case "panel"				:	this.doActionPanel		($action,$info);	break;
			case "transporter"			:	this.doActionTransporter($action,$info);	break;
			case "service"				:	this.doActionService	($action,$info);	break;
			case "booking"				:	this.doActionBooking	($action,$info);	break;
			case "driver"				:	this.doActionDriver		($action,$info);	break;
			case "vehicle"				:	this.doActionVehicle	($action,$info);	break;
		}
	}

	/**
	 * set vehicle session init depending on first service
	 * 
	 * @param $params 
	 * @returns 
	 */
	private setVehicleInitTime($params)
	{
		let $info = $params["info"];

		console.log("Item",$info["item"],"event",$info["event"]);
		
		let time = $info["item"]["session_init_time"];
		if(undefined==time){
			this.commons.generateToast("_VEHICLE","_SET_SESSION_INIT_TIME_NOT_FOUND","error");
			return false;
		}
		let sessionInit = this.commons.getDate(this.calendar.date+" "+time);
		if(undefined==sessionInit){
			this.commons.generateToast("_VEHICLE","_SET_SESSION_INIT_TIME_WRONG","error");
			return false;
		}
		let sessionEnd =  this.commons.addTimeToDate({
			"date" 	: sessionInit,
			"time"	: "630",
			"unit"	: "m"
		});
		if(undefined==sessionEnd){
			this.commons.generateToast("_VEHICLE","_SET_SESSION_END_TIME_WRONG","error");
			return false;
		}
		$info["item"]["sessionInit_timestamp"	] = sessionInit.unix();
		$info["item"]["sessionEnd_timestamp"	] = sessionEnd.unix();
		$info["item"]["session_end_time"		] = sessionEnd.format("HH:mm");
	}

	/**
	 * order entities by field and criteria
	 *
	 * @param $entity
	 * @param $field
	 * @param $order
	 * @returns
	 */
	 private order($entity,$field,$order){
		let cloned = [];

		// Clone entity
		$entity.forEach(item=>{	cloned.push(item); });

		cloned.sort((a,b)=>{
			let aValue = parseInt(a[$field]);
			let bValue = parseInt(b[$field]);
			switch($order){
				default		:
				case "desc"	: return aValue>bValue?-1:1;
				case "asc"	: return aValue>bValue?1:-1;
			}
		});

		console.log("[order] Cloned",cloned);
		return cloned;
	}

	/**
	 * clear vehicles with no driver
	 * @param $params
	 */
	clearVehiclesNotUsed($params={}){
		this.transportsCalendar.rows = (this.transportsCalendar.rows||[]).filter(row=>{
			if(row.empty){ return true; }
			return undefined!=row.driver;
		});
	}

	/**
	 * Remove all vehicles rows
	 * @param $params
	 */
	clearRowVehicles($params={}){
		this.transportsCalendar		=	{
			rows	: [{ empty: true, items: [] }], // Real rows assigned on calendar
			tmpRows	: [{ empty: true, items: [] }]	// Pending rows not assigned yet
		};
	}

	/**
	 * clear transportcalendar
	 * unassign all transports
	 * unassign all vehicles
	 */
	clearServices($params={}){
		this.transportsCalendar.rows.forEach(row=>{
			row.items.forEach(group=>{
				group.assigned	= false;
				group.vehicle 	= undefined;
				group.plate 	= undefined;
				(group.bookingsInfo||[]).forEach(bi=>{
					bi.assigned	= false;
					bi.vehicle 	= undefined;
					bi.plate	= undefined;
				})
			});
			row.items=[];
		});

		// Unassign vehicles
		if(null!=this.fleet.draggableSet){
			this.fleet.draggableSet.forEach( type => {
				if(null==type.vehicles){ return; }
				Object.keys(type.vehicles).forEach(vehicleId=>{
					type.vehicles[vehicleId].status = "unassigned";
				});
			});
		}

		this.bookings.data.forEach(item=>item.vehicle=undefined);
	}

	/**
	 * generate vehicle from db data
	 *
	 * @param $params
	 * @returns
	 */
	generateVehicle($params){
		let type	= $params["type"];
		let vehicle = $params["vehicle"];

		vehicle.typeId			= type.id;
		vehicle.typeCode	 	= type.code;
		vehicle.typeName		= type.name;
		vehicle.seats			= parseInt(type.seats);
		vehicle.pax				= parseInt(type.seats);
		vehicle.durationInHours	= 13;
		vehicle.durationInMin	= vehicle.durationInHours*60;

		if(vehicle.turn && (vehicle.turn||[]).some(item=>item=="turn_afternoon")){
			vehicle.init		= vehicle.init_time || this.commons.pageInfo.vehicles.turns.afternoon;
		} else {
			vehicle.init		= vehicle.init_time || this.commons.pageInfo.vehicles.turns.morning;
		}

		this.ownFleet.push(vehicle);

		if(vehicle.plate){
			let plate = this.ownPlates.draggableSet.find(item=>item.plate == vehicle.plate);
			if(!plate){
				this.ownPlates.draggableSet.push({
					plate		: vehicle.plate,
					assigned	: false
				});
			}
		}

		return vehicle;
	}

	async bookingsSearch($entity)
	{
		$entity 				= "bookings";
		this[$entity].spinner 	=	true;

		let response:any 		= await this.entityService.getRequest(
			"bookings_search",
			{
				token: this.pageInfo.search.content
			}
		);

		// Override bookings with search results
		this[$entity].data 		= 	response.data || [];
		this[$entity].count 	= 	this[$entity].data.length;
		this[$entity].total 	= 	this.entityService.getTotal("bookings_search") 	|| 0;

		this[$entity].spinner	= 	false;

		if(this[$entity].count==0){
			this.commons.generateToast("_INFO","_NO_RESULTS","info");
			return false;
		}

		let providers = {
			1	: "HTX",
			2	: "HOP",
			15	: "WTR"
		};

		const init_service_time		= 	this.getInfo("init_service_time",{});
		let calendar_date			= 	this.calendar.date;
		let nextDay 				= 	this.commons.nextDay(this.calendar.date).format("YYYY-MM-DD");

		// Direction normalizing fields
		let data					=	this.entityService.get($entity);

		this[$entity].data			=	this.normalizeBookings({
			data				: data,
			calendar_date		: calendar_date,
			nextDay				: nextDay,
			providers			: providers,
			init_service_time	: init_service_time,
			only_verified		: false
		});

		this[$entity].spinner		=	false;
		this.pageInfo.search.show	= 	false;
		this.pageInfo.loadingData	=	false;
	}
	// LOAD ENTITIES 	---------------------------------------------------------------------

	/**
	 * load all type of entities
	 *
	 * @param $entity
	 * @returns
	 */
	async load($entity) {
		let response 	= {};
		let realMode	= true;
        switch ($entity) {
			case 'bookings_search'	:
				this.bookingsSearch("bookings");
				return;

			case "zones"	:
				this.pageInfo.providerZones			=	this.commons.getEntity("provider_zones");
				// TAke HTX zones as Tourinia zones
				// TODO ! Generate Tourinia zones and map to each provider in WIZARD
				this.pageInfo.zones					=	this.pageInfo.providerZones[1];
				this.pageInfo.providerArea2zone		=	this.commons.getEntity("provider_area_2_zones");
				this.pageInfo.providerAreasMapped	=	this.commons.getEntity("provider_areas_mapped");
				let moreZones 						= 	this.commons.pageInfo.moreZones;
				this.pageInfo.providerAreasMapped[1]=	{ ...this.pageInfo.providerAreasMapped[1], ...moreZones };
				return;

			case "drivers"	:
				response					=	await this.transportService.getDrivers( this.commons.userInfo.currentDmc.id, this.commons.userInfo.currentDestination.id );
				if(!response["success"])	{ 	this.commons.generateToast("ERROR","_RESPONSE_ERROR","error"); return false; }
				this[$entity].data			=	(response["data"]||[]).filter(d=>d.active);
				this[$entity].newSet		=	[];
				break;


			case "fleet"	:		this.loadFleet($entity); break;
			case "transporters":	this.loadTransporters(); break;

			case "providers"	:
				this[$entity].full	= ( await this.aggregatorCtrl.getProviders() || []);
				this[$entity].data 	= ( await this.aggregatorCtrl.getAggregatorsFromDestination(this.commons.userInfo.currentDmc.id, this.commons.userInfo.currentDestination.id) )
										.filter(item=>{
											let provider	= this[$entity].full.find(fullItem=>fullItem["id"]==item["id"]);
											return provider?provider.active:false;
										})
										.map(item=> {
											let provider	= this[$entity].full.find(fullItem=>fullItem["id"]==item["id"]);
											item["logo"] 	= "/assets/layout/icons/providers/" + provider["thumbnail"] + ".png";
											return item;
										});
				// Cambar a la versión por destino, Linea ANTERIOR !!
				this[$entity].data	= ( await this.aggregatorCtrl.getProviders() || []);

				if(this[$entity].data.length==0){	this.commons.generateToast("_ERROR","_NO_PROVIDERS_FOUND_CONTACT_AGENT","error"); return false;	}
				if(this[$entity].data.length==1){	this[$entity].selected = this[$entity].data[0];	}

				// Select default provider
				this[$entity].selected 	= this[$entity].selected || this[$entity].data[0];
				this.initFilters();

				break;
		}

		filterEntity(this,$entity);
	}

	private async loadFleet($entity){
		let realMode = true;
		let response;

		if( realMode )	{	response					= 	await this.transportService.getFleet( this.commons.userInfo.currentDmc.id, this.commons.userInfo.currentDestination.id );
							if(!response["success"])	{ 	this.commons.generateToast("ERROR","_RESPONSE_ERROR","error"); return false; }
							this[$entity].data			=	response["data"];
		} else 			{
							await this.entityService.loadEntity($entity);
							this[$entity].data        	=	this.entityService.get($entity);
						}

		this[$entity].data.forEach(item=>{
			item.qty					= 	(item.vehicles || []).length;
		});

		this[$entity].draggableSet  = 	this[$entity].data.sort((a,b)=>(a.pax>b.pax)?-1:1);
		this[$entity].draggableSet	= 	this[$entity].draggableSet.map(item=>{
											// item.color = item.qty>0?this.pageInfo.colors.active:this.pageInfo.colors.empty;
											return item;
										});

		// Generate Own Fleet
		this[$entity].draggableSet
					.filter(item=>undefined!=item.vehicles && item.vehicles.length>0)
					.forEach(type=>{
						(type.vehicles||[]).forEach((vehicle=>{
							vehicle = this.generateVehicle({ type: type, vehicle: vehicle });
						}));
					});

		console.log("OWN FLEET",this.ownFleet);

		this.pageInfo.plates = [];
		this.ownFleet.forEach(item=>{
			if(item.plate){
				if(!this.pageInfo.plates.some(plate=>plate.name==item.plate)){
					this.pageInfo.plates.push({
						name 			: item.plate,
						pax	 			: item.pax
					});
				}
			}
		});

		this[$entity].assigned		= 	[];
	}

	async loadTransporters(){
		let $entity		= "transporters";
		let loadMode 	= "common";
		let response;

		response = await this.transportService.getTransporters( this.commons.userInfo.currentDmc.id, this.commons.userInfo.currentDestination.id );
		if(response["success"]){
			this.commons.userInfo.destination_transporters = response["data"];
		} else {
			this.commons.generateToast("_ERROR","_ERROR_LOADING_DESTINATION_TRANSPORTERS","error");
		}

		if(!response["success"])	{ 	this.commons.generateToast("ERROR","_RESPONSE_ERROR","error"); return false; }
		this[$entity].data        	= 	await Promise.all	(
											response["data"].map(async (item,index)=>{
												item.position				= index+1;
												item.name					= item.name;
												item.inner					= false;
												item.valoration				= Math.ceil(Math.random()*10);
												item.shared					= true;
												item.private				= false;
												item.vehicles				= Math.ceil(Math.random()*500);
												item.avg_vehicle_price		= Math.ceil(Math.random()*500);
												item.drivers				= Math.ceil(Math.random()*10);
												item.avg_driver_price		= Math.ceil(30+(Math.random()*20));
												item.assigned				= 0;

												item.issues					= Math.ceil(Math.random()*50);
												item.avg_response			= Math.ceil(Math.random()*60);

												item.fleet					= ( await this.transportService.getTypeFleetTransporter(item))["data"] || [];
												delete item.ref;
												return item;
											})
										);

		// Add inner fleet
		this[$entity].data.unshift({
			position				: 0,
			name					: "_INNER_FLEET",
			inner					: true,
			valoration				: 0,
			shared					: true,
			private					: true,
			vehicles				: this['fleet'].draggableSet.reduce((acc,item)=>{ return acc+item["qty"] },0),
			avg_vehicle_price		: 0,
			drivers					: 0,
			avg_driver_price		: 0,
			assigned				: 0,
			fleet					: this['fleet'].draggableSet,
			issues					: Math.ceil(Math.random()*50),
			avg_response			: Math.ceil(Math.random()*60)
		});

		// Generate Transport types from transporters
		let types = {};
		this[$entity].data.forEach(transporter=>{
			transporter.fleet.forEach(fleet=>{
				if(undefined==fleet.name){ return; }
				types[fleet.name]			=	types[fleet.name] || { seats: 0, qty: 0, items: [] };
				types[fleet.name].items 	=	[ ...types[fleet.name].items.filter(item=>item.id!=transporter.id), transporter ];
				types[fleet.name].qty	   +=	parseInt(fleet.qty);
				types[fleet.name].seats		= 	fleet.seats;
				types[fleet.name].id		=	fleet.id;
			});
		});

		console.log("TYPES",types);

		this.transportTypes.data 		= Object.keys(types).map( type => {
											return {
												id		: types[type].id,
												name	: type,
												items	: types[type].items,
												qty		: types[type].qty,
												seats	: types[type].seats
											}});

		console.log("TRANSPORT TYPES",this.transportTypes.data);

		this.transportTypes.draggableSet= this.transportTypes.data;

		// Copy by value into draggabletSet to rollback if necessary
		this[$entity].draggableSet  	= this[$entity].data.map(item=>{ return item });
	}

	/**
	 * generate services from loaded bookings
	 */
	generateServices(){
		// if(!this.getInfo("calendar",{ type: "transportsCalendar",query:"hasItems" })){
		// 	this.generateCalendars({ entity: "groups", generator: "clear", mode: "generate" });
		// } else {
		// 	this.generateCalendars({ entity: "groups", generator: "none",  mode: "generate" });
		// }
		this.generateFleetCalendarFromVehicles();
	}

	/**
	 * returns current selected providers
	 * @returns
	 */
	private getActiveProviders(){	return this.providers.data
														.filter	(item=>item.selected)
														.map	(item=>item.id);
	}

	/**
	 * load bookings
	 *
	 * @param $entity
	 * @returns
	 */
	 async loadBookings()
	 {
		if(!this.calendar.date){ return false; }
		let $entity					=	"bookings";
		this.pageInfo.loadingData	=	true;


		this.pageInfo.search.content=	"";

		const controls				=	this.pageInfo.controlPanels.control.buttons.find(item=>item.name=="controls");
		const execMode				=	controls.items.find(item=>item.name=="execMode"			);
		const transferType			=	controls.items.find(item=>item.name=="transferType"		);
		const transferMode			=	controls.items.find(item=>item.name=="transferMode"		);
		const partitioned			=	controls.items.find(item=>item.name=="servicePartition"	);
		const execCommand			=	controls.items.find(item=>item.name=="exec_command"		);
		const setEnvironment		=	controls.items.find(item=>item.name=="environment"		);
		const onlyVerified			=	controls.items.find(item=>item.name=="only_verified"	);
		const showCancelled			=	controls.items.find(item=>item.name=="cancelled"		);
		
		this.pageInfo.areas							= this.commons.userInfo.tourinia_resorts || [];
		this.pageInfo.provider_areas_to_tourinia 	= this.commons.userInfo.provider_mappings;
		this.pageInfo.providerAreasMapped			= this.commons.userInfo.provider_mappings;

		this[$entity].data			=	[];
		// let providersGroupData		=	[];

		let activeProviders			=	this.getActiveProviders();

		if( activeProviders.length==0)	{
			this.commons.generateToast("ERROR","_NO_PROVIDERS_SELECTED","error");
			this[$entity].spinner		=	false;
			this.pageInfo.loadingData	=	true;
			return false;
		}

		let params			= {
			offset			: 1
		};

		params["filters"]	= {
			transferType	: transferType.items.filter(item=>item.value).map(item=>item.name),
			// transferType	: [ "private"	, "shuttle" 					],
			direction		: [ "arrivals"	, "departures" 					],
			verified		: [ "verified"	, "not_verified" 				],
			// verified		: [ "verified"	 								],
			status			: [ "original"	, "rectified"	, "ammended"	],
			errors			: [ "errors"	, "not_errors" 					]
		}

		params["filters"]	= JSON.stringify(params["filters"]);

		console.log("PARAMS",params);

		// Clear all the rest
		this.clearGroups		({ message: false });
		this.clearServices		({ message: false });
		this.clearRowVehicles	({ message: false });
		this.clearDrivers		({ message: false });

		await Promise.all(activeProviders.map(async provider => {
			switch(provider){
				default	: params["startdate"] = this.calendar.date;	break;
			}
			await this.entityService.loadEntity(
				$entity,
				{
					...params,
					...{
						providers	: [ provider ],
						count		: 1,
						dmc			: this.commons.userInfo.currentDmc.id,
						destination	: this.commons.userInfo.currentDestination.dbid				
					}
				}
			)
		}));

		// Only for 1 provider
		// Change to allow multiple providers
		let providers = {
			1	: "HTX",
			2	: "HOP",
			15	: "WTR"
		};

		const init_service_time		= 	this.getInfo("init_service_time",{});
		let calendar_date			= 	this.calendar.date;
		let nextDay 				= 	this.commons.nextDay(this.calendar.date).format("YYYY-MM-DD");

		// Get already loaded bookings
		let data 					=	this.entityService.get($entity);
		// data						=	data.slice(0,50);

		// Direction normalizing fields
		this[$entity].data			=	this.normalizeBookings({
											data				: data,
											calendar_date		: calendar_date,
											nextDay				: nextDay,
											providers			: providers,
											init_service_time	: init_service_time,
											only_verified		: onlyVerified.selected=="yes"?true:false,
											show_cancelled		: showCancelled.selected=="yes"?true:false
										});

		this[$entity].count			=	(this[$entity].data||[]).length;											
		this[$entity].spinner		=	false;
		this.pageInfo.loadingData	=	false;

		this.commons.generateToast("Bookings","_BOOKINGS_LOADED","info");
	}

	private splitBothBookings($info):any[]
	{
		let bookings 		= $info.bookings;
		let calendar_date	= $info.calendar_date;
		let nextDay			= $info.nextDay;

		bookings.forEach(item=>{
			item.direction 		= item.direction || item.mode;
			item.real_direction	= undefined;
			switch(item.direction){
				case "arrival"	: return;
				case "departure": return;
				case "both"		:

					item.real_direction	= "both";
					item.has_arrival 	= item.arrival_Date	 == calendar_date || item.arrival_Date	== nextDay;
					item.has_departure	= item.departure_Date== calendar_date || item.departure_Date== nextDay;

					// Only arrival for current day
					if(item.has_arrival && !item.has_departure){
						item.real_reference		= item.reference;
						item.reference			= item.reference+"_arrival";
						item.reference_service	= item.reference;				
						item.direction 			= "arrival";
					}

					// Only departure for current day
					if(!item.has_arrival && item.has_departure){
						item.real_reference		= item.reference;
						item.reference			= item.reference+"_departure";
						item.reference_service	= item.reference;
						item.direction 			= "departure";
					}

					if(!item.has_arrival && !item.has_departure){
						console.log("[splitBothBookings] Booking with both direction but no one valid");
						return false;
					}

					// Arrival and departure for current day
					// Split into arrival and departure
					if(item.has_arrival && item.has_departure){
						// Create new arrival
						let arrival_item 					= JSON.parse(JSON.stringify(item));
						
						arrival_item.real_reference 		= arrival_item.reference;
						arrival_item.direction				= "arrival";
						arrival_item.reference				= arrival_item.reference+"_arrival";
						arrival_item.departure_PickupTime	= undefined;
						
						bookings.push(arrival_item);

						// Create new departure
						let departure_item	= JSON.parse(JSON.stringify(item));
						
						departure_item.real_reference 		= departure_item.reference;
						departure_item.direction			= "departure";
						departure_item.reference			= departure_item.reference+"_departure";
						
						bookings.push(departure_item);

						// Remove original item
						bookings = bookings.filter(b=>b.reference!=item.reference);
					}
					break;
			}
		});
		return bookings;
	}

	/**
	 * adapt booking upon conditions
	 */
	normalizeBookings($params)
	{
		let bookings 			= $params.data || [];
		let calendar_date		= $params.calendar_date;
		let nextDay				= $params.nextDay;
		let providers			= $params.providers;
		let init_service_time	= $params.init_service_time;
		let only_verified		= $params.only_verified;
		let show_cancelled		= $params.show_cancelled;
		let provider_mappings 	= this.commons.userInfo.provider_mappings 	|| {};
		
		// Remove not verified if needed
		bookings	= 	bookings.filter(b=>{
			switch(only_verified){
				case true	: return b.verified;
			}
			return true;
		});

		// Filter by provider
		// Move to Provider service
		bookings	= 	bookings.filter(b=>{
			// Not bypass unverified cause we want to filter by status even
			let providerService 	= this.providerServiceCtrl.getProviderService(b.provider);
			return providerService.filterBooking({ booking: b, show_cancelled: show_cancelled });
		});

		// Provider field changes and mappingsx
		bookings	=  	bookings.map(b=>{
			if(!only_verified && !b.verified){ return b; }
			let providerService 	= this.providerServiceCtrl.getProviderService(b.provider);
			return providerService.mapBooking(b);
		});

		// Split bookings with both directions on the same day
		bookings	= this.splitBothBookings({
						bookings		: bookings,
						calendar_date	: calendar_date,
						nextDay			: nextDay
					});

		const moment= this.commons.getMoment();

		bookings 	= bookings.map(item=>{
			
			let current_provider_mappings = provider_mappings[item.provider];

			if(!item.provider){
				console.log("[normalizeBookings] no provider for item",(item||{}).reference);
				return item;
			}

			item.booking_date = moment(item.date,"YYYYMMDD").format("YYYY-MM-DD");

			switch(item.direction)
			{
				case "arrival"	:
					// item.transporter_location_id	= item.arrival_canaryshuttle_lodging;
					item.date 						= item.arrival_Date;
					item.time						= item.arrival_Time;
					item.driver						= item.arrival_Driver;
					item.vehicle					= item.arrival_Vehicle;
					item.transporter				= item.arrival_Transporter_Name;
					item.transporter_status			= item.arrival_Transporter_Status;
					item.transporter_pending		= item.arrival_Transporter_Pending;
					item.plate						= item.arrival_Plate;
					item.group						= item.arrival_Group;
					item.area						= item.area || item.arrival_Area || item.arrival_To;
					item.initMoment					= this.commons.getDate(item.date+" "+item.time);
					item.init_timestamp				= item.initMoment.unix();
					item.fomento_id					= item.arrival_Fomento_Id;
					item.fomento_status 			= item.arrival_Fomento_Status;
					item.flight						= item.arrival_GatewayInfo;
					item.environment				= item.arrival_Environment;
					item.location					= item.arrival_Location;

					// Set Tourinia resort if not
					if(undefined==item.arrival_resort_tourinia){
						item.arrival_resort_tourinia = current_provider_mappings[item.area];
					}

					// Set municipality if not
					if(undefined==item.arrival_municipality){
						if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
							item.arrival_municipality 	= this.commons.userInfo.tourinia_resorts_2_areas[item.arrival_resort_tourinia];
						}
					}

					// Set zone if not
					if(item.arrival_municipality){
						item.arrival_Zone			= this.commons.userInfo.tourinia_areas_2_zones[item.arrival_municipality];
						item.zone					= this.commons.userInfo.tourinia_areas_2_zones[item.arrival_municipality];
					}
					break;

				case "departure":
					// item.transporter_location_id	= item.departure_canaryshuttle_lodging;
					item.date 						= item.departure_Date;
					item.time						= item.departure_Time;
					item.driver						= item.departure_Driver;
					item.transporter				= item.departure_Transporter_Name;
					item.transporter_status			= item.departure_Transporter_Status;
					item.transporter_pending		= item.departure_Transporter_Pending;
					item.vehicle					= item.departure_Vehicle;
					item.plate						= item.departure_Plate;
					item.group						= item.departure_Group;
					item.area						= item.area || item.departure_Area || item.departure_From;
					item.initMoment					= this.commons.getDate(item.date+" "+item.time);
					item.init_timestamp				= item.initMoment.unix();
					item.fomento_id					= item.departure_Fomento_Id;
					item.fomento_status 			= item.departure_Fomento_Status;
					item.flight						= item.departure_GatewayInfo;
					item.environment				= item.departure_Environment;
					item.location					= item.departure_Location;

					// Set Tourinia resort if not
					if(undefined==item.departure_resort_tourinia){
						item.departure_resort_tourinia = current_provider_mappings[item.area];
					}

					// Set municipality if not
					if(undefined==item.departure_municipality){
						if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
							item.departure_municipality = this.commons.userInfo.tourinia_resorts_2_areas[item.departure_resort_tourinia];
						}
					}

					// Set zone if not
					if(item.departure_municipality){
						item.departure_Zone			= this.commons.userInfo.tourinia_areas_2_zones[item.departure_municipality];
						item.zone					= this.commons.userInfo.tourinia_areas_2_zones[item.departure_municipality];
					}
					break;
			}

			item.provider_name		= providers[item.provider];
			return item;
		})

		// Filter by date
		bookings =	bookings.filter(item=>{
			switch(item.direction){
				case "arrival":
					item.location = item.arrival_Location;

					// TIME RANGE
					if(item.date == this.calendar.date 	&& item.arrival_Time<init_service_time){
						return false;
					}
					if(item.date == nextDay			 	&& item.arrival_Time>init_service_time){
						return false;
					}
					break;
					
				case "departure":
					item.location = item.departure_Location;
					// if(item.date == nextDay){
					if(item.date == nextDay && item.departure_Time>=init_service_time){
						return false;
					}
					break;
			}

			// Bypass if not verified
			// if(!only_verified && !item.verified){ return true; }

			return true;
		});

		// Get booking area
		bookings.forEach(b=>{

			// Bypass if not verified
			if(!only_verified && !b.verified){ return; }
			
			if(!b.provider){ return false; }
			switch(b.direction){
				// Is BOTH possible ?????
				case "both"		: break;
				case "arrival"	: b.area = b["arrival_To"	] || b["arrival_Area"	] || b["area"			]; break;
				case "departure": b.area = b["area"			] || b["departure_Area"	] || b["departure_From"	]; break;
			}
			b.tourinia_area = this.pageInfo.provider_areas_to_tourinia[b.provider][b.area];
	
			switch(b.shared){
				case "private":
					b.group_pax = b.pax;
					break;
			}
		});

		bookings =	bookings.filter(item=>{
			// Bypass if not verified
			if (only_verified) { return item.verified }
			return true;
		});

		return bookings;
	}

	createNewGroup($group){
		let newGroup 			= JSON.parse(JSON.stringify($group));

		newGroup.bookings 		= [];
		newGroup.bookingsInfo 	= [];
		newGroup.name			= this.transportService.generateGroupName({ group: newGroup });
		newGroup.assigned		= false;
		newGroup.vehicle		= undefined;
		newGroup.plate			= undefined;
		newGroup.pax			= 0;
		newGroup.vehicle		= undefined;

		this.groups.data.push(newGroup);
	}

	// HTXImportBookings($bookings){
	// 	this.persistHtx($bookings.filter(item=>item.agent=="HTX"));
	// }

	// async persistHtx(items){
	// 	await this.entityService.postJSON(
	// 		this.entityService.getUrl("htx_update_from_astra"),
	// 		{ items: JSON.parse(JSON.stringify(items)) }
	// 	)
	// 	.then(response => {
	// 		console.log("[HTX] response success",response);
	// 		return true;
	// 	}).catch(response => {
	// 		console.log("[HTXe] response error",response);
	// 	})
	// }

	getDriverFromPlate($plate){
		let vehicle = this.fleet.data.filter(item=>item.plate==$plate);
		if(vehicle){
			let driver  = this.drivers.data.filter(item=>item.vehicle==vehicle.code);
			if(driver){
				return driver.name;
			}
		}
		return undefined;
	}

	getZoneFromArea($area){
		if(undefined==$area){ return null; }
		let mappings	= this.pageInfo.providerAreasMapped[1];
		let found		= Object.keys(mappings).find(item=>{
			let a = item.toLowerCase().replace(' ','');
			let b = $area.toLowerCase().replace(' ','');
			return a == b;
		});
		return found?mappings[found]:null;
	}

	clearGroups($params={}){
		this.bookings.data.forEach(item=>item.group=undefined);
		this.groups.data 				= [];
		this.transportsCalendar.items 	= [];
		if($params["message"]){
			this.commons.generateToast("_GROUPS","_GROUPS_CLEARED","info");
		}
	}

	/**
	 * clear plates from bookings
	 */
	clearPlates($params={}){
		this.bookings.data.forEach(item=>item.plate=undefined);
		if($params["message"]){
			this.commons.generateToast("_GROUPS","_PLATES_CLEARED","info");
		}
	}

	removeUnusedVehicles(){
		this.commons.generateToast("_VEHICLES","_REMOVE_UNUSED_VEHICLES","info");
		(this.transportsCalendar.rows||[]).forEach(row=>{
			if(row.empty){ return; }
			if((row.items||[]).length==0){
				if(row.plate){
					this.remove('calendarVehicle',{ row: row }, null);
				}
				this.remove("calendarVehicleType", { row: row }, null);
			}
		})
	}

	setAvailableVehicles(){
		this.commons.generateToast("_VEHICLES","_SET_AVAILABLE_VEHICLES","info");
	}

	setAllVehicles(){
		this.commons.generateToast("_VEHICLES","_SET_ALL_VEHICLES","info");
	}

	/**
	 * set first services per each assigned row
	 */
	setFirstServices(){
		this.commons.generateToast("_SERVICES","_SET_FIRST_SERVICES","info");
	}

	/**
	 * set default driver per assigned vehicle
	 */
	setServicesDrivers(){
		this.commons.generateToast("_DRIVER","_SET_SERVICES_DRIVERS","info");
		(this.transportsCalendar.rows||[]).forEach(row=>{
			if(row.empty){ return; }
			let driver = this.drivers.data.find(driver=>driver.vehicle==row.name);
			if(driver){
				row.driver = driver.name;
			}
		});
	}

	/**
	 * clear assigned vehicles driver
	 */
	clearServicesDrivers(){
		this.commons.generateToast("_DRIVEr","_CLEAR_SERVICES_DRIVERS","info");
		(this.transportsCalendar.rows||[]).forEach(row=>{
			if(row.empty){ return; }
			row.driver = undefined;
		});
	}

	/**
	 * set default plate per assigned vehicle
	 */
	 setServicesPlates(){
		this.commons.generateToast("_PLATES","_SET_SERVICES_PLATES","info");
		(this.transportsCalendar.rows||[]).forEach(row=>{
			if(row.empty){ return; }
			let vehicle = this.ownFleet.find(vehicle=>vehicle.code==row.name);
			if(vehicle){
				row.plate = vehicle.plate;
			}
		});
	}

	/**
	 * clear assigned vehicles plate
	 */
	clearServicesPlates(){
		this.commons.generateToast("_PLATES","_CLEAR_SERVICES_PLATES","info");
		(this.transportsCalendar.rows||[]).forEach(row=>{
			if(row.empty){ return; }
			row.plate = undefined;
		});
	}

	/**
	 * clear services from bookings
	 */
	clearBookingVehicles($params={}){
		this.bookings.data.forEach(item=>item.vehicle=undefined);
		if($params["message"]){
			this.commons.generateToast("_GROUPS","_VEHICLES_CLEARED","info");
		}
	}

	/**
	 * clear drivers from bookings
	 */
	clearDrivers($params={}){
		this.bookings.data.forEach(item=>item.driver=undefined);
		if($params["message"]){
			this.commons.generateToast("_GROUPS","_DRIVERS_CLEARED","info");
		}
	}

	/**
	 * clear transporters from bookings
	 */
	 clearTransporters($params={}){
		this.bookings.data.forEach(b=>{
			[	
				"transporter",
				"transporter_status",
				"arrival_Transporter",
				"arrival_Transporter_Status",
				"departure_Transporter",
				"departure_Transporter_Status"
			]
			.forEach(token=>{
				b[token] = undefined;
			});
		});
		if($params["message"]){
			this.commons.generateToast("_GROUPS","_TRANSPORTERS_CLEARED","info");
		}
	}

	/**
	 * generate privates and shuttles groups
	 *
	 */
	generateGroups($params={}){
		$params["mode"]	= $params["mode"] || "all";

		// update requests status
		Object.keys(this.pageInfo.transportersDayRequests||{}).forEach(transporter=>{
			 (this.pageInfo.transportersDayRequests[transporter].items || [])
				.forEach(item=>{ 
					item.pending=true; 
				});
		});

		let privates 	= this.transportService.generatePrivateGroups({
			mode	: $params["mode"],
			mappings: this.pageInfo.providerAreasMapped[1],
			bookings: this.bookings.data,
			date	: this.calendar.date
		});

		const controls				=	this.pageInfo.controlPanels.control.buttons.find(item=>item.name=="controls");
		const transferMode			=	controls.items.find(item=>item.name=="transferMode"		);
		
		let maxItems = 10000000;

		switch(transferMode.selected){
			case "split":	maxItems = 1; break;
		}
		
		let shuttles 	= this.transportService.generateShuttleGroups({
			mode		: $params["mode"],
			mappings	: this.pageInfo.providerAreasMapped[1],
			bookings	: this.bookings.data,
			groups		: this.groups.data || [],
			date		: this.calendar.date,
			maxItems	: maxItems
		});

		console.log("PRIVATES",	privates	);
		console.log("SHUTTLES",	shuttles	);

		this.groups.data 			= [ ...privates["items"], ...shuttles["items"]];

		// Remove group when time is before service time ( default 03:00 )
		const init_service_time		= 	this.getInfo("init_service_time_min",{});
		this.groups.data			=	this.groups.data || [];

		// Check if group is able to be send
		this.groups.data			= 	this.groups.data.map(g=>{
											g.sendable = g.init>=init_service_time;	
											(g.bookingsInfo||[]).forEach(bi=>bi.sendable=g.sendable);
											return g;								
										});

		this.groups.data			=	this.groups.data.filter(g=>g.sendable);

		// this["arrivals"		] 		= { draggableSet: [] };
		// this["departures"	] 		= { draggableSet: [] };

		// Mark as preassigned group to avoid restrictions
		this.groups.data.forEach(group=>{
			switch($params["mode"]){
				case "load"	: group.preassigned = true;
				default		: group.preassigned = false;
			}
		});

		this.commons.generateToast("_GROUPS","_GROUPS_GENERATED","info");
	}

	/**
	 * load groups data from backend
	 *
	 * @param $entity
	 * @returns
	 */
	 async loadGroups($entity){

		if(!this.calendar.date){ return false; }

		this.pageInfo.loadingData	=	true;

		const controls				=	this.pageInfo.controlPanels.control.buttons.find(item=>item.name=="controls");
		const execMode				=	controls.items.find(item=>item.name=="execMode"			);
		const transferType			=	controls.items.find(item=>item.name=="transferType"		);
		const partitioned			=	controls.items.find(item=>item.name=="servicePartition"	)

		this[$entity].data			=	[];
		// let providersGroupData		=	[];

		let activeProviders			=	this.getActiveProviders();

		if( activeProviders.length==0)	{
			this.commons.generateToast("ERROR","_NO_PROVIDERS_SELECTED","error");
			return false;
		}

		let results = await Promise.all(activeProviders.map(async provider => {
			await this.entityService.loadEntity(
				$entity,
				{
					provider			: provider,
					date				: this.calendar.date,
					solution			: 1,
					solutionTransport	: 1,
					checkins			: false,
					transports			: true,
					bookings            : true,
					transfer_bookings	: true,
					vehicles			: true,
					drivers				: true,
					transferType		: transferType.items.filter(item=>item.value).map(item=>item.name)
				}
			)
		}));

		let providersGroupData = this.entityService.get($entity);

		this[$entity].data	= [{
			count		: 	( providersGroupData || [] ).reduce((qty,group)=>{
								return qty + group.count;
							},0),
			solution	: 	1,
			items		: 	( providersGroupData || [] ).reduce((a,group)=>{
								return [...a,...(group.items||[])]
							},[])
		}];

		this[$entity].spinner		=	false;
		this.pageInfo.loadingData	=	false;

		// Clear Transport Calendar
		this.clearServices();
	}

	/**
	 * Generate calendars and generate service, transporters and drivers calendar
	 *
	 * @param $entity
	 * @returns
	 */
	 generateCalendars($params){

		const controls				=	this.pageInfo.controlPanels.control.buttons.find(item=>item.name=="controls");
		const execMode				=	controls.items.find(item=>item.name=="execMode"			);
		// const transferType			=	controls.items.find(item=>item.name=="transferType"		);
		const partitioned			=	controls.items.find(item=>item.name=="servicePartition"	)

		let groupsQty				=	this[$params.entity].data.length;
		let groups					=	this[$params.entity].data;

		// Check solutions
		if(groupsQty==0){
			this.commons.generateToast("SOLUTIONS'","_NO_GROUPS_FOUND","error");
			this["arrivals"		]	= 	{ draggableSet: [] };
			this["departures"	]	= 	{ draggableSet: [] };
			// this.clearServices();
			return false;
		}

		switch($params.generator){
			case "clear"	:
				break;
		}

		this.processCalendar({
			execMode	: execMode.selected,
			groups		: groups,
			generator	: $params.generator,
			mode		: $params.mode
		});

		// this["arrivals"		].initialData	= JSON.stringify(this["arrivals"	].draggableSet||{});
		// this["departures"	].initialData	= JSON.stringify(this["departures"	].draggableSet||{});
		this["fleet"		].initialData	= JSON.stringify(this["fleet"		].draggableSet||{});

		// Clear selected routes
		// this.doAction("service","clear",{});

		// Generate driver calendar
		// this.generateDriversCalendar();
		// this.generateTransportersCalendar();

		this.commons.generateToast("_CALENDAR","_SERVICES_GENERATED","info");
	}

// --------------------------------------------------------------------------------------
// CALENDAR	FUNCTIONS BLOCK
// All functions related to calendar generation and management
// IMPROVE. Move to an external service and inject params
// --------------------------------------------------------------------------------------

	/**
	 * process all services to generate its calendar automatically
	 *
	 * @param $params
	 * @returns
	 */
	 private processServicesAuto($params){
		let groups 			= $params["groups"];
		let vehicleTypes	= [];

		console.log("[processTransportsAuto] groups", groups);

		groups.forEach(group=>{
			if(group.assigned){ return false; }
			let response = this.processServiceAuto({
				group			: group,
				vehicleTypes	: vehicleTypes
			});
			// console.log("[auto] Data",response);
			vehicleTypes 	= response["vehicleTypes"];
		})

		return {
			"vehicleTypes" : vehicleTypes
		};
	}

	/**
	 * process each service to be assigned to a vehicle or vehicle type
	 *
	 * @param $params
	 * @returns
	*/
	private processServiceAuto($params){
		let group			= $params["group"];
		let vehicleTypes	= $params["vehicleTypes"];
		let firstTime		= group["firstTime"];
		let lastTime		= group["lastTime"];
		let response;

		if(undefined!==group.vehicle){
			response 	= this.assignServiceToVehicle($params);
		 } else {
			response	= this.assignServiceToVehicleType($params);
		 }

		 return {
			"service"		: response["service"],
			"vehicleTypes"	: response["vehicleTypes"]
		 };
	}

	/**
	 * process each service to be assigned to a vehicle or vehicle type
	 *
	 * @param $params
	 * @returns
	 */
	 private _processServiceAuto($params){

		let group			= $params["group"];
		let service 		= $params["service"];
		let vehicleTypes	= $params["vehicleTypes"];
		let firstTime		= service["firstTime"];
		let lastTime		= service["lastTime"];
		let response;

		// Assing options
		// 	a) assign directly to vehilce
		//	b) assign to vehicle type
		if(undefined!==service.vehicle && undefined!==service.vehicle.id){
			alert("NOT ASSIGN TO VEHICLE NOW. LEGACY");
			response 	= this.assignServiceToVehicle($params);
		 } else {
			response	= this.assignServiceToVehicleType($params);
		 }

		 return {
			"service"		: response["service"],
			"vehicleTypes"	: response["vehicleTypes"]
		 };
	 }

	 /**
	  * assign service to a vehicletype with NO vehicle
	  * It happens when we don't have any plate for that type
	  * @param $params
	  * @returns
	  */
	 private assignServiceToVehicleType($params)
	 {
		let group			= $params["group"];
		let service 		= $params["service"];
		let vehicleTypes	= $params["vehicleTypes"];
		let firstTime		= service["firstTime"];
		let lastTime		= service["lastTime"];

		if(service.assigned){
			alert("[assignServiceToVehicleType] SERVICE ALREADY ASSIGNED");
			return {
				"vehicleTypes"	: vehicleTypes,
				"service"		: service
			}
		}

		// Insert new vehicleTypes
		if(undefined==vehicleTypes[service.id]){
			vehicleTypes[service.id] = {
				isVehicle	: false,
				// name		: service.name,
				seats			: service.seats,
				vehicle		: {
					id			: service.id,
					name		: service.name,
					type		: service.type
					// plate	: service.vehicle.plate,
					// seats	: service.vehicle.seats
				},
				items	: []
			};
		}

		vehicleTypes[service.id].items.push({
			id				: group.id,
			provider		: group.provider,
			groupId			: group.id,
			transportId		: service.id,
			item			: service.item,
			transport		: service,
			name			: group.name,
			zone			: group.zone,
			type			: group.type,
			pax				: service.pax,
			seats			: service.seats,
			firstTime		: firstTime,
			lastTime		: lastTime,
			driver			: service.driver,
			link			: service.link,
			bookings		: service.bookings || []
		});

		service.assigned 		= false;
		service.assigned_type	= 2;

		return {
			"vehicleTypes"	: vehicleTypes,
			"service"		: service
		}
	 }

	 /**
	  * assign service to concrete vehicle
	  *
	  * @param $params
	  * @returns
	  */
	 private assignServiceToVehicle($params)
	 {
		let group			= $params["group"];
		let service 		= $params["service"];
		let vehicleTypes	= $params["vehicleTypes"];
		let firstTime		= service["firstTime"];
		let lastTime		= service["lastTime"];

		if(service.assigned){
			alert("[assignServiceToVehicle] SERVICE ALREADY ASSIGNED");
			return {
				"vehicleTypes"	: vehicleTypes,
				"service"		: service
			}
		}

		// // FYI 		! REMOVED
		// // BEFORE 	! Assign by ehicle from BRAIN
		// // NOW		! Generated in generateRowFromVehicleTYpe()
		if(undefined==vehicleTypes[service.id]){
			if(undefined==vehicleTypes[service.vehicle.id]){
				service.vehicle.seats = service.seats;
				vehicleTypes[service.vehicle.id] = {
					isVehicle	: true,
					// vehicle		: {
					// 	id		: service.vehicle.id,
					// 	name	: service.name,
					// 	type	: service.type,
					// 	plate	: service.vehicle.plate,
					// 	seats	: service.vehicle.seats
					// },
					name		: service.name,
					items		: []
				};
			}
		}

		// vehicles[service.vehicle.id].items.push({

		// FYI !!!
		// Assign only by vehicletype nor vehicle
		vehicleTypes[service.id].items.push({
			id				: group.id,
			provider		: group.provider,
			groupId			: group.id,
			transportId		: service.id,
			item			: service.item,
			transport		: service,
			name			: group.name,
			zone			: group.zone,
			type			: group.type,
			pax				: service.pax,
			seats			: service.seats,
			// firstTime	: group.firstTime,
			// lastTime		: group.lastTime,
			// firstTime	: tservice.departure>=1440?"23:59":(firstHour<10?"0":"")+firstHour+":"+(firstMin<10?"0":"")+firstMin,
			// lastTime		: tservice.delivery >=1440?"23:59":(lastHour <10?"0":"")+lastHour +":"+(lastMin <10?"0":"")+lastMin,
			firstTime		: firstTime,
			lastTime		: lastTime,
			driver			: service.driver,
			link			: service.link,
			bookings		: service.bookings || []
		});

		service.assigned 		= true;
		service.assigned_type	= 1;

		return {
			"vehicleTypes"	: vehicleTypes,
			"service"		: service
		}
	}

	/**
	 * generate calendar row from vehicle type
	 * needed to make fleet calendar
	 *
	 * @param $row
	 * @param $type
	 * @returns
	 */
	private generateRowFromVehicleType($row,$type){

		if($row.empty) {
			console.log("[generateRowFromVehicleType] Error: empty row");
			return false;
		}

		if(!$row.vehicle) {
			console.log("[generateRowFromVehicleType] Error: row with no vehicle");
			return false;
		}

		// MARK VEHICLES ! Mark fleet vehicles as assigned
		if(undefined!==this.fleet.draggableSet){
			(this.fleet.draggableSet||[])
				.forEach	( type => ( type.vehicles || [] )
				.filter		(current=>current.id==$row)
				.forEach	(current=>current.status="assigned"));
		}

		let serviceRow	= {
			assigned	: false,
			name		: $row.vehicle.name,
			type		: $row.vehicle.name,
			alias		: "_ALIAS_"+$row.vehicle.name,
			seats		: $row.vehicle.seats,
			plate		: $row.vehicle.plate,
			items		: []
		};

		// Set row items
		serviceRow.items	= 	$row.items
								.sort((a,b)=>a.firstTime>b.firstTime?1:-1)	// Order by time
								.map((group,index)=>{
									group.index	= index;
									return group;
								});

		serviceRow.seats	=	serviceRow.items[0].pax;

		// Remove vehicle type to get back to GENERICS
		// this.remove('calendarVehicleType',{ row: serviceRow });

		// Set row as SECOND element
		// Which one is the FIRST ?
		this.transportsCalendar[$type].splice(1,0,serviceRow);
	}


	/**
	 * generate service calendar from data
	 * 	plain transport list from groups
	 *
	 *  FYI !! NO SE USA YA
	 * @param $params
	 */
	generateCalendarFromData($params)
	{
		let mode			= $params["execMode"];
		let groups			= $params["groups"];
		let clear			= $params["clear"];

		let vehicles		= [];	// Assigned to specific vehicles
		this.plainServices	= [];	// Clear services

		// Process all groups
		//	1. plain services
		//	2. generate transportscalendar.tmpRows
		//	3. generate fleet calendar
		// 	4. genetate transportscalendar.rows
		groups.forEach(group=>{
			group.init	= "0";
			group.end	= "0";

			group.transports.forEach(transport=>{

			let firstHour		= Math.trunc(transport.departure/60);
			let firstMin 		= transport.departure%60;
			let lastHour		= Math.trunc(transport.delivery/60);
			let lastMin 		= transport.delivery%60;
			let firstTime		= (firstHour<10?"0":"")+firstHour+":"+(firstMin<10?"0":"")+firstMin;
			let lastTime		= (lastHour <10?"0":"")+lastHour +":"+(lastMin <10?"0":"")+lastMin;

			//  let assigned		= this.getInfo("service", { type: "is_assigned", service: transport });
			let assigned = true;

			let service			= {
					id				: transport.id,
					// group			: group.name,
					provider		: group.provider,
					private			: group.private,
					area			: group.area,
					zone			: group.zone,
					assigned		: assigned,
					firstTime		: firstTime,
					lastTime		: lastTime,
					type			: transport.type,
					code			: transport.code,
					cost			: transport.cost,
					delivery		: transport.delivery,
					deliveryStr		: transport.deliveryStr,
					departure		: transport.departure,
					departureStr	: transport.departureStr,
					description		: transport.description,
					item			: transport.item,
					name			: transport.name,
					pax				: transport.pax,
					seats			: transport.seats,
					solution		: transport.solution,
					direction		: group.type
				};

				switch(group.type){
					case "departure":	service["PickupTime"] = group.PickupTime;
										break;
				}

				// service["service"	] = transport;
				// service["transport"	] = transport;
				service["bookings"	] = transport.bookings;
				service["group"		] = {
					id				: group.id,
					provider		: group.provider,
					private			: group.private,
					type			: group.type,
					area			: group.area,
					zone			: group.zone,
					date			: group.date,
					delivery		: group.delivery,
					departure		: group.departure,
					direction		: group.direction,
					durationInHours	: group.durationInHours,
					durationInMin	: group.durationInMin,
					end				: group.end,
					firstTime		: group.firstTime,
					init			: group.init,
					lastTime		: group.lastTime,
					name			: group.name,
					pax				: group.pax,
					solution		: group.solution
				};

				this.plainServices.push(service);
			})
		});
	}

	/**
	 * procsess calendar auto or manually
	 *
	 * @param $params
	 */
	processCalendar($params){

		let mode			= $params["execMode"];
		let groups			= $params["groups"];
		let clear			= $params["clear"];

		let vehicles		= [];	// Assigned to specific vehicles
		this.plainServices	= [];	// Clear services

		console.log("[prociessCalendar] plainServices",this.plainServices	);

		switch(mode){
			case "auto"		:
				// this.generateInitRows();
				this.assignImportedVehiclesToRows();

				switch($params["mode"]){
					case "imported"	:	console.log("[processCalendar] imported mode not generate calendar"); break;
					case "generate"	:	this.generateFleetCalendar({  generator: "clear",  mode: "vehicle" }); break;
				}
				break;

				console.log("[generateCalendarFromData] transportsCalendar",this.transportsCalendar );
				break;

			case "manual"	:	break;
		}
	}

	/**
	 * mock function to assign on creation plate to vehicle
	 *
	 * @param $params
	 * @returns
	 */
	getMappedPlate($params){
		let vehicle 	= $params["vehicle"];
		let plateFound 	= mappedPlates.find(item=>item[1]==vehicle.code);
		if(plateFound){
			let plate = this.ownFleet.find(v=>v.plate==plateFound[0]);
			if(plate){
				plate.assigned 	= true;
				plate.status	= "assigned";
			}
			return plateFound[0];
		}
		return undefined;
	}

	/**
	 * generate one row per fleet vehicle
	 *
	 */
	private generateInitRows($params){
		let mode 	= $params["mode"];
		let clear 	= $params["clear"];

		if(clear){
			console.log("[generateInitRows] clearing vehicle rows");
			this.transportsCalendar.rows = [{ empty: true, items: [] }];
		}

		let startOfDay	= this.commons.getDate(this.calendar.date+" 00:00:00").unix();

		// Assign plates if not
		this.bookings.data.forEach(booking=>{
			if(!booking.vehicle	){ return; }
			let vehicle = this.ownFleet.find(item=>item.code==booking.vehicle);
			if(!vehicle			){ return; }

			// set default plate
			if(!booking.plate){
				if(vehicle.plate){
					booking.plate = vehicle.plate;
				}
			} else {
				// update vehicle plate from booking
				if(booking.plate!=vehicle.plate){
					vehicle.plate = booking.plate;
				}
			}
		});

		switch(mode){
			case "full"		: 	this.ownFleet.forEach(vehicle=>{ this.setVehicleRow({
																		vehicle		: vehicle,
																		startOfDay	: startOfDay
																	});
													});

								this.commons.generateToast("_VEHICLES","_FULL_FLEET_GENERATED_ON_CALENDAR","info");
								break;

			case "imported"	:	let vehicles 	= [];
								let fleet		= this.ownFleet;
								this.bookings.data.forEach(booking=>{
									if(booking.vehicle){
										booking.vehicle = booking.vehicle.trim();
										let cleanVehicle = booking.vehicle;
										if(!vehicles.some(v=>v==cleanVehicle)){
											vehicles.push(cleanVehicle);
										}
									}
								});
								vehicles.filter	(item	=>fleet.some(v=>v.code==item))
										.forEach(vehicle=>{
											this.setVehicleRow({
												vehicle		: fleet.find(item=>item.code==vehicle),
												startOfDay	: startOfDay
											});
								});
								this.commons.generateToast("_VEHICLES","_IMPORTED_FLEET_GENERATED_ON_CALENDAR","info");
								break;
		}
	}

	private setVehicleRow($params){

		let $vehicle 	= $params["vehicle"];
		let startOfDay	= $params["stardOfDay"];


		if(this.transportsCalendar.rows.find(row=>row.name==$vehicle.code)){
			console.log("[setVehicleRow] Row already exist");
			return false;
		}

		// TODAY. Init and end times
		let vehicleInitDateTime 			= 	this.calendar.date+" "+$vehicle.init

		let vehicleInitMoment				= 	this.commons.getDate(vehicleInitDateTime);
		let vehicleEndMoment				= 	this.commons.addTimeToDate({
													date	: vehicleInitMoment,
													time	: $vehicle["durationInMin"],
													unit	: "m"
												});

		// YESTERDAY. Init adn end times
		let vehicelYesterdayInitMoment		= 	this.commons.addTimeToDate({
													date: vehicleInitMoment,
													time: -1,
													unit: "d"
												});

		let vehicleYesterdayEndMoment		= 	this.commons.addTimeToDate({
													date: vehicleEndMoment,
													time: -1,
													unit: "d"
												});

		let vehicleRow 	= {
			transporter				: "_INNER_FLEET",
			name					: $vehicle.code,			// COCHE <xx>
			code					: $vehicle.code,
			type					: $vehicle.type,
			seats					: $vehicle.seats,
			// plate					: this.getMappedPlate({ vehicle: $vehicle }),
			plate					: $vehicle.plate,
			alias 					: $vehicle.code,
			items					: []
		}

		let vehicleRowInit		= {
			turn					: $vehicle.turn || "turn_early_morning",

			init_str				: vehicleInitMoment.format("HH:mm"),
			init_timestamp			: vehicleInitMoment.unix(),
			init					: (vehicleInitMoment.unix()-startOfDay)/60,

			yesterday_init_str		: vehicleYesterdayEndMoment.format("HH:mm"),
			yesterday_init_timestamp: vehicelYesterdayInitMoment.unix(),
			// yesterday_init			: (vehicelYesterdayInitMoment.unix()-startOfYesterday)/60,

			end_str					: vehicleEndMoment.format("HH:mm"),
			end_timestamp			: vehicleEndMoment.unix(),
			end						: (vehicleEndMoment.unix()-startOfDay)/60,

			yesterday_end_str		: vehicleYesterdayEndMoment.format("HH:mm"),
			yesterday_end_timestamp	: vehicleYesterdayEndMoment.unix(),
			// yesterday_end			: (vehicleYesterdayEndMoment.unix()-startOfYesterday)/60,

			duration				: $vehicle.durationInMin
		}

		// Set vehicle as assigned
		this.fleet.draggableSet.forEach(type => {
			(type.vehicles||[]).forEach(item=>{
				if(item.code==$vehicle.code){
					item.status="assigned";
				}
			})
		});

		// console.log("[setVehicleRow] Creating vehicle row for ",vehicleRow.name);
		this.transportsCalendar.rows.splice(1,0,vehicleRow);
	}

	/**
	 * Assign already mapped vehicles to row with same vehicle name
	 *
	 */
	private assignImportedVehiclesToRows(){
		(this.groups.data||[]).forEach(group=>{
			if(undefined==group.vehicle	){ return; }
			if(group.assigned			){ return; }
			group.vehicle = group.vehicle.trim();
			let row = this.transportsCalendar.rows.find(row=>row.name==group.vehicle)
			if(row){
				group.assigned	= true;

				// Step 1. Set vehicle info from bookings
				group.bookings.forEach(b=>{
					let booking = this.bookings.data.find(bk=>bk.reference==b);
					if(booking){
						// Set booking from vehicle
						booking.vehicle = row.name;
						if(undefined==booking.plate 	&& undefined!=row.plate		){ booking.plate		= row.plate; 		}
						if(undefined!=row.transporter								){ booking.transporter	= row.transporter;	}

						// Set group from booking
						if(undefined==group.plate 		&& undefined!=booking.plate	){ group.plate			= booking.plate;	}
					}
				})

				// Step 2. Set vehicle info from group
				if(undefined==row.driver 		&& undefined!=group.driver		){ row.driver 		= group.driver; 	}
				if(undefined==row.plate 		&& undefined!=group.plate		){ row.plate 		= row.plate; 		}
				if(undefined==row.transporter 	&& undefined!=group.transporter	){ row.transporter	= group.transporter;}

				// Step 3. Set group info from vehicle
				if(undefined==group.driver		&& undefined!=row.driver		){ group.driver		= row.driver;		}
				if(undefined==group.plate		&& undefined!=row.plate			){ group.plate		= row.plate;		}
				if(undefined==group.transporter	&& undefined!=row.transporter	){ group.transporter= row.transporter;	}

				row.items.push(group);
			}
		});

		this.transportsCalendar.rows.forEach(row=>this.updateRowTurn({ row: row }));
	}

	private generateFleetCalendar($params){
		if(undefined==this.ownFleet || this.ownFleet.length==0){
			this.commons.generateToast("_ERROR","_NO_FLEET_FOUND","error");
			return false;
		}

		switch($params["generator"]){
			case "clear":
				switch($params["mode"]){
					case "vehicles": this.generateFleetCalendarFromVehicles(); break;
					case "services": this.generateFleetCalendarFromServices(); break;
				}
				break;
		}
	}


	/**
	 * generate own fleet calendar
	 * taking each service and trying to find best vehicle option
	 * unitl all been processed
	 * Non assigned services will be leave as NONASSIGNED
	 */
	private generateFleetCalendarFromServices()
	{
		let suitableRows;
		let suitableServices;
		let maxItems 		= 200;

		this.pageInfo.servicesVehicle = {};

		(this.groups.data||[]).forEach(group=>{

			// If group already assigned just exit
			if(group.assigned	){ return false; }

			// If group has vehicle assign if not and exit
			if(group.vehicle	){
				let vehicle = this.transportsCalendar.rows.find(row=>row.name==group.vehicle);
				if(vehicle){
					vehicle.items.push(group);
					return true;
				}
				return false;
			}

			// Real process !
			// Look for ASSIGNABLES vehicles
			let suitableRows = this.transportsCalendar.rows
									.filter	(row	=> { return !row.empty							})
									.filter	(row	=> { return !row.assigned						})
									.filter	(row	=> { return group.pax <= row.seats;				})
									.filter (row 	=> {
										if(row.seats>7 && group.pax<7){ return false; }
										return true;
									})
									;

			switch(group.private){
				case true	:	suitableRows = suitableRows.sort((a,b)=>a.seats>=b.seats?1:-1); break;
				default		:	suitableRows = suitableRows.sort((a,b)=>a.seats>=b.seats?-1:1); break;
			}

			let found = suitableRows.reduce((found,row)=>{
				if(found){ return found; }
				// console.log("GROUP",group,"ROW",row);
				let gap = this.findCalendarGap({ row: row, group: group, showError: false });
				if(gap["success"]){
					found 			= true;
					group.assigned 	= true;
					group.vehicle	= row.name;
					group.plate		= row.plate;
					// Update bookings vehicle
					group.bookings.forEach(item=>{
						let booking = this.bookings.data.find(b=>b.reference==item);
						if(booking){
							booking.vehicle = row.name;
							booking.plate	= row.plate;
						}
					})
					row.items.push(group);
				}
				return found;
			}, false);

			if(found){
				console.log("FOUND",group);
			} else {
				console.log("NOT FOUND",group);
			}
		})
	}

	/**
	 * generate own fleet calendar
	 * taking each vehicle and trying find best service option
	 * unitl all been processed
	 * Non assigned services will be leave as NONASSIGNED
	 */

	private generateFleetCalendarFromVehicles()
	{
		// Assign to calendar all preassigned services
		(this.groups.data||[]).forEach(group=>{
			if(group.assigned	){ return false; }
			if(group.vehicle	){
				let vehicle = this.transportsCalendar.rows.find(row=>row.name==group.vehicle);
				if(vehicle){
					vehicle.items.push(group);
					return true;
				}
				return false;
			}
		});

		this.commons.generateToast("_NOTIFY","_AVOIDING_EMPTY_VEHICLES_AND_NO_DRIVER","info");

		// Process and assign the rest of services
		// this.ownFleet.forEach(vehicle=>{
		this.transportsCalendar.rows.forEach(row=>{
			if(row.empty	){ return; }
			if(!row.driver	){ return; }
			this.generateFleetCalendarFromVehicle({ row: row });
		});
	}

	/**
	 * generate own fleet calendar
	 * taking vehicle and trying find best service option
	 * unitl all been processed
	 * Non assigned services will be leave as NONASSIGNED
	 */
	 private generateFleetCalendarFromVehicle($params)
	 {
		let vehicleName 		= 	$params["vehicle"];
		let maxItems 			= 	200;
		let suitableServices;
		// let initMorning		=	"04:00";
		// let endMorning		= 	"13:29";
		// let initAfternoon	=	"13:30";
		// let endAfternoon		=	"23:59";
		let vehicle;
		let row					=	$params["row"];

		try {
			// departure_DateTime				Moment
			// departure_PickupDateTime			Moment
			// departure_PickupTime				"08:25"
			// departure_PickupTime_timestamp	1693549500

			// console.log("[generateFleetCalendar] Available rows",availableRows);
			// this.pageInfo.servicesVehicle = {};

			if(row){
				vehicle			=	(this.ownFleet||[]).find(v=>v.code==row.name);
			} else {
				vehicle			=	(this.ownFleet||[]).find(v=>v.code==vehicleName);
				row				=	(this.transportsCalendar.rows||[]).find(row=>row.name==vehicleName);
			}

			if(undefined==vehicle	){ throw new Error("_VEHICLE_NOT_FOUND");			}
			if(undefined==row		){ throw new Error("_VEHICLE_ROW_NOT_FOUND");		}

			// 	PROCEDIMIENTO
			//
			//	0. Filtrar servicios por turno
			//	1. Coger 10 servicios cortos o largos con el turno concreto
			//  2. Filtrar por llegadas y salidas y generar su ciclo
			//	3. Seleccionar el mejor candidato
			//	4. Marcar ciclo corto o largo
			//	5. Repetir el proceso hasta que el primer servicio disponible no entre en el turno

			const maxElements			= 	10;
			let turnMorning				=	(vehicle.turn.some(t=>t=="turn_morning"		));
			let turnAfternoon			=	(vehicle.turn.some(t=>t=="turn_afternoon"	));

			let loopServices;
			let loopCycles				=	[];
			let selectedCycle;
			let moreServices			= 	true;
			let	next_service_distance 	= 	"any";
			let interAreaDistance		= 	0;
			let interAreaTimeInHours	= 	0;

			let originArea;
			let destinationArea;
			let originAreaCoords;
			let destinationAreaCoords;
			let availableServices		= [];

			console.log("[generateFleetCalendarFromVehicle] current timestamp", this.pageInfo.current_timestamp);

			let maxServices 			= 10;
			let count 					= 10;
			let cycleStatus				= "first";
			let response;

			if(undefined==row			){ throw new Error("_VEHICLE_NOT_FOUND");				}
			if(undefined==row.driver	){ throw new Error("_VEHICLE_DRIVER_NOT_FOUND");		}

			let driver = this.drivers.data.find(driver=>driver.name==row.driver);

			if(undefined==driver		){ throw new Error("_VEHICLE_DRIVER_NOT_FOUND");		}
			if(undefined==driver.turn	){ throw new Error("_VEHICLE_DRIVER_TURN_NOT_FOUND");	}

			if(undefined==this.commons.userInfo.currentDestination.turns){
				throw new Error("_DMC_DESTINATION_TURNS_NOT_FOUND");
			}

			const turns = this.commons.userInfo.currentDestination.turns;

			switch(driver.turn[0]){
				case "turn_early_morning"	:	row.turn_init = this.commons.getDate(this.calendar.date+" "+turns.early_morning	); break;
				case "turn_morning"			:	row.turn_init = this.commons.getDate(this.calendar.date+" "+turns.morning		); break;
				case "turn_afternoon"		:	row.turn_init = this.commons.getDate(this.calendar.date+" "+turns.afternoon		); break;
				case "turn_night"			:	row.turn_init = this.commons.getDate(this.calendar.date+" "+turns.night			); break;
			}

			row.turn_end = this.commons.addTimeToDate({
				"date" 	: row.turn_init,
				"time"	: "660",
				"unit"	: "m"
			});

			row.turn_init_timestamp = row.turn_init.unix();
			row.turn_end_timestamp	= row.turn_end.unix();

			console.log("Row turns",row.turn_init,row.turn_end);

			while(moreServices && --maxServices>0){
				switch(cycleStatus){
					case "first":	response			=	this.getVehicleAvailableServices({
																groups				: this.groups.data,
																current_timestamp 	: this.pageInfo.current_timestamp,
																row					: row,
																vehicle				: vehicle,
																status				: cycleStatus
															});
									cycleStatus			=	"second";
									break;

					case "second":	response			=	this.getVehicleAvailableServices({
																groups				: this.groups.data,
																current_timestamp 	: this.pageInfo.current_timestamp,
																row					: row,
																vehicle				: vehicle,
																status				: cycleStatus
															});
									cycleStatus			=	"rest";
									break;
					default:
					case "rest":	response			=	this.getVehicleAvailableServices({
																groups				: this.groups.data,
																current_timestamp 	: this.pageInfo.current_timestamp,
																row					: row,
																vehicle				: vehicle,
																status				: cycleStatus
															});
									break;
				}

				if(response["success"]==false){
					console.log("[generateFleetCalendarFromVehicle] Error retrieving available services");
					continue;
				}

				if(response["current_timestamp"]==undefined){
					console.log("[generateFleetCalendarFromVehicle] Error current timestamp");
					continue;
				}

				this.pageInfo.current_timestamp = response["current_timestamp"];
				availableServices				= response["available_services"];
				// maxServices						= (availableServices||[]).length;

				// Select best cycle
				response 		=	this.selectCycle({
										next_service_distance	: next_service_distance,
										max_elements			: maxElements,
										loop_services			: loopServices,
										available_services		: availableServices,
										row						: row,
										vehicle					: vehicle,
										more_services			: moreServices,
										count					: count
									});

				moreServices 			= 	response["more_services"		];
				next_service_distance	=	response["next_service_distance"];
			}
		} catch(e){
			this.commons.generateToast("_ERROR",e.message,"error");
			return false;
		}
	}

	/**
	 * get available services for current vehicle cycle
	 *
	 * @param $params
	 * @returns
	 */
	private getVehicleAvailableServices($params)
	{
		let response 				= { success: true, availableServices: [] };
		let interAreaDistance;
		let interAreaTimeInHours;
		let currentTimestamp		= $params["current_timestamp"];
		let row						= $params["row"];
		let vehicle					= $params["vehicle"];

		try {
			// All suitable services for this vehicle depending on turns
			let availableServices	=	($params["groups"]||[])
											.filter(g=>!g.assigned)
											.sort((a,b)=>a.init_timestamp>=b.init_timestamp?1:-1);

			switch($params["status"]){
				case "first":	
					availableServices	=	availableServices.filter(g=>{
												let gap = false;
												// if(g.init_timestamp>= row.turn_init_timestamp && g.end_timestamp <= row.turn_end_timestamp){
												if(g.init_timestamp>= row.turn_init_timestamp){
													gap = true;
												}
												return gap;
											});
					break;
			}

			if(currentTimestamp){
				availableServices	=	availableServices.filter(s=>s.init_timestamp>=currentTimestamp);
			}

			// Filter only services with gap
			if(row){
				availableServices	=	availableServices.filter(service=>{
					let gap = this.findCalendarGap({ row: row, group: service, showError: false });
					return gap["success"];
				});
			}

			if((availableServices||[]).length==0){
				throw new Error("No available services found");
			}

			response["available_services"]	= availableServices;
			const firstService:any 			= availableServices[0];

			// CALCULATE CURRENT TIMESTAMP IF NEEDED
			switch(firstService.direction){
				case "departure":

					// Calculate current time if not based on inter area distances
					// We have to substract distance from pickup time for departures
					if(undefined==currentTimestamp){
						// CT 		= 1stDepature - RouteTime ( inter_area_distance / speed )
						// speed 	= this.commons.average_speed_in_km ( 60km/h )
						currentTimestamp  			= firstService.init_timestamp;

						// Get inter area distance
						let originArea				= this.commons.userInfo.currentDestination.baseArea;
						let destinationArea			= this.pageInfo.provider_areas_to_tourinia[1][firstService.area];

						if(undefined==originArea || undefined==destinationArea){
							throw new Error("OriginArea or destinationArea not found. Aborting");
						}

						// let originAreaCoords		= this.pageInfo.areas.find(area=>area.name==originArea);
						// let destinationAreaCoords	= this.pageInfo.areas.find(area=>area.name==destinationArea);
						let originAreaCoords		= this.pageInfo.areas.find(area=>area==originArea);
						let destinationAreaCoords	= this.pageInfo.areas.find(area=>area==destinationArea);

						if(undefined==originAreaCoords || undefined==destinationAreaCoords){
							throw new Error("OriginAreaCoords or destinationAreaCoords not found. Aborting");							
						}

						interAreaDistance			= this.commons.getInterAreasDistance(originAreaCoords, destinationAreaCoords);
						interAreaTimeInHours		= interAreaDistance / this.commons.pageInfo.average_speed_in_km;

						currentTimestamp 		   -= Math.ceil(interAreaTimeInHours*60*60);
					}
					break;

				case "arrival"	:

					if(undefined==currentTimestamp){
						// current timestamp is service init timestamp, no calculation needed
						currentTimestamp 			= firstService.init_timestamp;
					}
					break;

				default			:	
					console.log("[generateFleetCalendarFromVehicle] no init current time. Aborting");
			}

			response["current_timestamp"] 	= currentTimestamp;

		} catch(e){
			this.commons.generateToast("_ERROR",e.message,"error");
			console.log("[getVehicleAvailableServices]",e.message);
			response["success"] 			= false;
		}

		return response;
	}

	/**
	 * select cycle upon aailable services
	 *
	 * @param $params
	 * @returns
	 */
	private selectCycle($params):any
	{
		let next_service_distance 	= $params["next_service_distance"];
		let maxElements				= $params["max_elements"];
		let loopServices			= $params["loop_services"];
		let availableServices		= $params["available_services"];
		let row						= $params["row"];
		let vehicle					= $params["vehicle"];
		let moreServices			= $params["more_services"];
		let count					= $params["count"];

		//	1. Coger N servicios
		// 		Obviamos si cortos o largos, eso se decide más abajo
		switch(next_service_distance){
			case "any"	:	loopServices = availableServices.slice(0,maxElements);
							break;

			case "long"	:	loopServices = availableServices.slice(0,maxElements);
							break;

			case "short":	loopServices = availableServices.slice(0,maxElements);
							break;
		}

		let loopCycles		= [];
		let selectedCycle	= undefined;

		// Loop until no more services fits into vehicle turn
		loopServices.forEach(service=>{
			switch(service.direction){
				case "departure":
					loopCycles = [
						...loopCycles,
						...this.generateDepartureCycle({
								row				: row,
								service			: service,
								vehicle			: vehicle,
								current_time	: this.pageInfo.current_timestamp
							})
						];
					break;

				case "arrival"	:
					loopCycles	= [
						...loopCycles,
						...this.generateArrivalCycle({
								row				: 	row,
								service			: 	service,
								candidates		: 	loopServices,
								vehicle			: 	vehicle,
								current_time	: 	this.pageInfo.current_timestamp
							})
					];
					break;
			}
		});

		loopCycles 		= (loopCycles||[]).sort((a,b)=>a.value>=b.value?1:-1);
		console.log("[generateFleetCalendarFromVehicle] cycles", loopCycles);

		if((loopCycles || []).length==0){
			console.log("[generateFleetCalendarFromVehicle] No loopcycles found");
			moreServices = false;
			return false;
		}

		// Get best cycle and assign to row
		selectedCycle			= loopCycles[0];

		let initService			= selectedCycle.services[0];
		let distance_service 	= initService.init_timestamp - this.pageInfo.current_timestamp;

		// Select next service opposite distance ( long>short or viceversa )
		if(distance_service>=this.commons.pageInfo.service_long_distance_min_time){
			next_service_distance = "short";
		} else {
			next_service_distance = "long";
		}

		// Insert all CYCLE services into VEHICLE ROW
		(selectedCycle.services||[]).forEach(service=>{
			// let gap = this.findCalendarGap({ row: row, group: service, showError: false });
			// if(gap["success"]){
				service.assigned 	= true;
				service.vehicle		= row.name;
				service.plate		= row.plate;
				// Update bookings vehicle
				service.bookings.forEach(item=>{
					let booking = this.bookings.data.find(b=>b.reference==item);
					if(booking){
						if(row.name			){ booking.vehicle 		= row.name;			}
						if(row.plate		){ booking.plate		= row.plate;		}
						if(row.driver		){ booking.driver		= row.driver;		}
						if(row.transporter	){ booking.transporter	= row.transporter;	}
					}
				})
				row.items.push(service);
				count--;
			// }
		});

		moreServices	= count>0;

		return {
			more_services 			: moreServices,
			next_service_distance	: next_service_distance
		}

	}


	/**
	 * generate complete cycle <origin,departure,origin>
	 * @param $params
	 */
	private generateDepartureCycle($params)
	{
		let service = $params["service"];
		let vehicle	= $params["vehicle"];
		let origin	= this.pageInfo.currentNode;
		let cycles	= [];

		let computed_cycle	=	this.computeCycle({
									services			: [ service ],
									type				: "departure",
									current_timestamp	: this.pageInfo.current_timestamp
								});

		cycles.push({
			services		: 	[ service ],
			value			:	computed_cycle
		});

		return cycles;
	}

	/**
	 * gemerate complete arrival cycle <PMI,destination,origin,PMI>
	 * @param $params
	 * @returns
	 */
	private generateArrivalCycle($params)
	{
		let service 	= $params["service"];
		let vehicle 	= $params["vehicle"];
		let candidates	= $params["candidates"];
		let next_distance;
		let cycles		= [];

		// Get best one as min valued
		let minValue:number=10000000;
		let selected;

		try {
			let computed_cycle	=	this.computeCycle({
										services			: [ service ],
										type				: "departure",
										current_timestamp	: this.pageInfo.current_timestamp
									});

			// Add empty return;
			cycles.push({
					services		: [ service ],
					value			: computed_cycle
			});

			// TODO !!!!
			// Evaluate all the rest candidates !!!!

			// this.pageInfo.current_timestamp  = firstService.init_timestamp;
			// let originArea					= this.commons.userInfo.currentDestination.baseArea;
			// let destinationArea				= this.pageInfo.provider_areas_to_tourinia[1][firstService.area];
			// let originAreaCoords				= this.pageInfo.areas.find(area=>area.name==originArea);
			// let destinationAreaCoords		= this.pageInfo.areas.find(area=>area.name==destinationArea);
			// interAreaDistance			 	= this.commons.getInterAreasDistance(originAreaCoords, destinationAreaCoords);
			// interAreaTimeInHours			 	= interAreaDistance / this.commons.pageInfo.average_speed_in_km
			// this.pageInfo.current_timestamp -= Math.ceil(interAreaTimeInHours*60*60);

				const serviceArea		= this.pageInfo.provider_areas_to_tourinia[1][service.area];
				// const serviceAreaCoords = this.pageInfo.areas.find(area=>area.name==serviceArea);
				const serviceAreaCoords = this.pageInfo.areas.find(area=>area==serviceArea);
				if(undefined==serviceAreaCoords){
					throw new Error("serviceAreaCoords not found. Aborting");
				}

				candidates = candidates
								.filter(c=>c.direction=="departure")
								.filter(c=>c.init_timestamp>=service.init_timestamp)
								.filter(c=>{
									// return true;

									// Check if time enough to arrive to departure location
									// Distance <destination_arrival_area,origin_departure_area>

									let candidateArea				= this.pageInfo.provider_areas_to_tourinia[1][c.area];
									// let candidateAreaCoords			= this.pageInfo.areas.find(area=>area.name==candidateArea);
									let candidateAreaCoords			= this.pageInfo.areas.find(area=>area==candidateArea);
									if(undefined==candidateAreaCoords){
										console.log("[getArrivalCycleCandidates] candidate area not found. Aborting");
										return false;
									}

									const interAreaDistance			= this.commons.getInterAreasDistance(serviceAreaCoords, candidateAreaCoords);
									const interAreaTimeInHours		= interAreaDistance / this.commons.pageInfo.average_speed_in_km;
									const interAreaTimeInSecs		= interAreaTimeInHours * 3600;
									const diffTime					= c.init_timestamp - service.end_timestamp;

									if(diffTime<=interAreaTimeInSecs){
										// Interareas time enough
										return true;
									}
									return false;
								})
								.sort((a,b)=>a.departure_PickupDateTime>=b.departure_DateTime?-1:1);

				console.log("[generateArrivalCycle] Service",service," Candidates",candidates);

				// CHECK IT !!!!!
				// If candidates passed test to return pickup first one
				if((candidates||[]).length>0){
					cycles.push({
						services		: 	[ service, candidates[0] ],
						value			: 	this.computeCycle({
												services		: [ service, candidates[0] ],
												type			: "arrival",
												current_time	: this.pageInfo.currentTime
											}),
						next_distance	: 	next_distance
					});
				}
		}catch(e){
			console.log("[generateArrivalCycle] Error",e.message);
		}

		return cycles;

	}

	getServiceBilling($service){
		switch($service.direction){
			case "arrival"	: break;
			case "departure": break;
		}
		return 50;
	}

	getServiceCost($service){
		switch($service.direction){
			case "arrival"	: break;
			case "departure": break;
		}
		return 10;
	}

	/**
	 * compute cycle value depending upon services, current_timestamp and cycle type
	 *
	 * @param $params
	 * @returns
	 */
	private computeCycle($params)
	{
		let services			= $params["services"];
		let service;
		let type				= $params["type"];
		let current_timestamp	= $params["current_timestamp"];
		let value 				= 50;

		// this.pageInfo.current_timestamp  = firstService.init_timestamp;
		// let originArea					= this.commons.userInfo.currentDestination.baseArea;
		// let destinationArea				= this.pageInfo.provider_areas_to_tourinia[1][firstService.area];
		// let originAreaCoords				= this.pageInfo.areas.find(area=>area.name==originArea);
		// let destinationAreaCoords		= this.pageInfo.areas.find(area=>area.name==destinationArea);
		// interAreaDistance			 	= this.commons.getInterAreasDistance(originAreaCoords, destinationAreaCoords);
		// interAreaTimeInHours			 	= interAreaDistance / this.commons.pageInfo.average_speed_in_km
		// this.pageInfo.current_timestamp -= Math.ceil(interAreaTimeInHours*60*60);

		switch($params["type"]){
			case "empty_arrival":	service = services[0];
									value 	= service.init_timestamp - current_timestamp;
									value  -= this.getServiceBilling(service);
									value  += this.getServiceCost(service);
									break;

			case "arrival"		:	service = services[0];
									value 	= service.init_timestamp - current_timestamp;
									value  -= this.getServiceBilling(service);
									value  += this.getServiceCost(service);
									break;

			case "departure"	:	service = services[0];
									value 	= service.init_timestamp - current_timestamp;
									value  -= this.getServiceBilling(service);
									value  += this.getServiceCost(service);
									break;
		}

		return value;
	}

	/**
	 * generate own fleet calendar
	 * taking vehicle and trying find best service option
	 * unitl all been processed
	 * Non assigned services will be leave as NONASSIGNED
	 */
	 private _generateFleetCalendarFromVehicle($params)
	 {
		let vehicle 		= 	$params["vehicle"];
		let availableRows 	= 	this.transportsCalendar.tmpRows || [];
		let allRows			= 	this.transportsCalendar.tmpRows	|| {};
		let maxItems		= 	4;

		let suitableRows;
		let suitableServices;

		console.log("[generateFleetCalendar] Available rows",availableRows);

		suitableRows		=	allRows
									.filter	(row	=> { return !row.empty							})
									.filter	(row	=> { return !row.assigned						})
									.filter	(row	=> { return vehicle.seats >= row.items[0].pax	});

		suitableRows		=	suitableRows.slice(0,maxItems);
		suitableServices	=	suitableRows.map(item=>item.items[0]);

		//Mark row as assigned
		// FYI! Here we mark as assigned !!!!
		allRows.forEach(row=>{
			if(undefined==row.items || row.items.length==0){ return false; }
			if(suitableServices.some(item=>item.groupId==row.items[0].groupId)){
				row.assigned = true;
			}
		})

		console.log("[generateFleetCalendar] Suitable rows for vehicle", vehicle.code, suitableServices);
		console.log("[generateFleetCalendar] All rows", allRows);

		// Generate entry
		let vehicleRow = {
			transporter	: "_INNER_FLEET",
			// assigned	: true,
			assigned	: false,
			name		: vehicle.code,
			items 		: suitableServices,
			seats		: vehicle.seats,
			plate		: vehicle.plate,
			alias 		: "ALIAS_3_"+vehicle.code,
			type		: vehicle.code
		}

		this.transportsCalendar.rows.splice(1,0,vehicleRow);
	}

	private generateNonFleetCalendar(){
		let remaining = this.transportsCalendar.tmpRows.filter(row=>{
			let found = (this.transportsCalendar.rows.items||[]).some(item=>item.groupId==row.items[0].groupId);
			return !found;
		});
		console.log("REMAINING",remaining);
	}
}