import { Http			 						} from "@angular/http";
import { CommonsService 						} from '../../service/commons.service';
import { EntityService 							} from '../../service/entity.service';
import { Component, OnInit, ViewEncapsulation	} from '@angular/core';
import { DragulaService 						} from "ng2-dragula";
import { FirebaseService 						} from './../../service/database/firebase.service';
import { StorageService 						} from './../../service/storageservice';
import { tabs 									} from "./data/tabs";

interface Calendar {
	value	: any,
	date	: string,
	last	: string
};

@Component({
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit
{
    locale      : string 	= 'es';    
	pageInfo	: any 		= {};
	bookings	: any		= {};
	bookings_htx_private:	any = {};
	bookings_htx_shared:	any = {};
	bookings_hoppa_private:	any = {};
	bookings_hoppa_shared:	any = {};
	
	calendar	: Calendar 	= <Calendar>{ last: '', value: new Date() };
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService, 
		private dragulaCtrl 	: DragulaService,
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService		
	){ 
		this.init(); 					
	}
    async ngOnInit() 	{ 
		await this.loadEntities();
	}
    
	async init()		{
		this.pageInfo.calendar			= {
			view 		: "month",
			dateFormat	: "dd.mm.yyyy"
		};

		this.pageInfo.tabs				= tabs;
		this.pageInfo.dataLoaded      	= false;	
		this.pageInfo.trends			= [
			{ id: 4, icon: "location",	name: "_BOOKINGS", 		count: "1243", 	value: "+3%" 	},
			{ id: 5, icon: "location",	name: "_SERVICES", 		count: "34/42", value: "80%" 	},
			{ id: 6, icon: "location",	name: "_TRANSPORTERS", 	count: "12/23", value: "52%" 	},
			{ id: 7, icon: "location",	name: "_DRIVERS", 		count: "15/15", value: "100%" 	},
			{ id: 8, icon: "orders",	name: "_PROVIDERS", 	count: "2/2", 	value: "100%" 	}
		];
		
		this.pageInfo.contacts			= [
			{ id: 1, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 2, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 3, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 4, name: "Emily Clark", email: "eclark@radeon.com", status: "online" },
			{ id: 5, name: "Emily Clark", email: "eclark@radeon.com", status: "online" }
		];	
		
		this.bookings	= {
			toggles	: [ "arrival", "departure" ],
			period	: {
				types	: [ 
					{ label: "_WEEK", 	value: "week", 	icon: 'fa fa-calendar' 	},
					{ label: "_MONTH",	value: "month", icon: 'fa fa-calendar' 	},
					{ label: "_YEAR", 	value: "year",	icon: 'fa fa-calendar' 	} 
				],
				selected: "month"
			}
		}

		// Translate
		this.bookings.period.types.forEach(async item=>{ item.label = await this.commons.getTranslate(item.label) });
		this.calendarChange();
	}	

    async loadEntities(){	this.pageInfo.dataLoaded = true;	}
		
	getFilteredEntity($params){
		let destination;
		let $entity = $params["entity"];

		switch($entity){
			case "bookings"	: 
		 		destination = this.getInfo('destination', { type: 'id'});
				if(!destination){ return []; }
				return this.bookings[destination] || [];
				break;

			case "days"		:	
				destination = this.getInfo('destination', { type: 'id'});
				if(!destination){ return []; }
				let name 	= $params["name"];
				
				let current	= this[name];
				if(!current){ return []; }
				
				let currentDestination = current[destination];
				if(!currentDestination	){ return []; }				
				
				if(!currentDestination.data	){ return []; } 
				return (currentDestination.data.days||[])
						.sort((a,b)=>a>b?1:-1);
		}
	}

	/**
	 * 
	 * @param $entity 
	 * @param $info 
	 * @returns 
	 */
	getInfo($entity,$info:any={}){
		switch($entity){
			case "destinations"	: return this.pageInfo.tabs.destinations;
			case "destination"	:
				switch($info.type){
					case "id"		:
						let selected	= this.pageInfo.tabs.destinations.selected;
						let current 	= this.pageInfo.tabs.destinations.items.find(el=>el.name==selected);
						if(!current){
							this.commons.generateToast("_ERROR","_NO_DESTINATION","error");
							return null;
						}
						this.pageInfo.currentDestination 	= current;
						this.pageInfo.currentDestinationId	= current.destination;
						return (current||{}).destination;

					case "selected"	:	
						return this.pageInfo.tabs.destinations.selected;
				}
		}
	}

	/**
	 * 
	 * @param $type 
	 * @param $action 
	 * @param $info 
	 */
	async doAction($type,$info:any={}){
		let proposed = {};
		let destination;

		switch($type){
			case "tab"					:	
				switch($info.action)	{
					case "select"			: 	
						if(!$info.tab.disabled){
							if($info.panel.selected!=$info.tab.name)
							{
								$info.panel.selected 	= $info.tab.name;								
								this.generateData();
							}
						}
						break;
				}
				break;
		}
	}

	private async generateData($params:any={}){
		let destination = this.getInfo('destination', { type: 'id'});
		if(!destination){ return; }
		await Promise.resolve(this.loadBookingsDigest({ destination: destination, provider: 1, name: "htx", 	type: "private" }));
		await Promise.resolve(this.loadBookingsDigest({ destination: destination, provider: 2, name: "hoppa", 	type: "private" }));
		await Promise.resolve(this.loadBookingsDigest({ destination: destination, provider: 1, name: "htx",		type: "shared" 	}));
		await Promise.resolve(this.loadBookingsDigest({ destination: destination, provider: 2, name: "hoppa", 	type: "shared" 	}));
		 
		[ 
			'bookings_htx_private',
			'bookings_htx_shared',
			'bookings_hoppa_private',
			'bookings_hoppa_shared',
		].forEach(token=>{
			this.generateCharts({ 
				type	: "bookings",
				data	: this[token][destination]
			});
		});
	}

	private async loadBookingsDigest($params:any={})
	{		
		const $entity 		= "bookings_"+$params["name"]+"_"+$params["type"];
		const $destination	= $params["destination"];

		console.log("[loadBookingsDigest] Params",$params);

		try {
			let response 	= 	await Promise.resolve(this.entityService.getRequest("bookings_digest",{ 
				startdate		: this.calendar.date, 
				offset			: 30,
				shared			: $params["type"],
				provider		: $params["provider"],
				destination		: $destination,
				show_cancels	: false												 
			}));

			this[$entity][$destination] = this.persistData({ data : response["data"] });

		}catch(e){
		}

		console.log("Booking digest for destination",$destination,"Data",this[$entity][$destination]);
	}

	private persistData($info){
		// let $entity 		= $info.entity;
		// let $destination	= $info.destination;
		let $data 			= $info.data;

		let info:any		= {
			data 				: {},
			days				: []
		}

		try {
			// if(!$entity			){ return; }
			// if(!$destination	){ return; }

			info		= {
				data				: $data			|| {},
				days				: $data["days"] || [],
				arrival_bookings	: Object.keys($data["arrival"	]||{}).map(day=>$data["arrival"		][day].bookings	),
				departure_bookings	: Object.keys($data["departure"	]||{}).map(day=>$data["departure"	][day].bookings	),
				arrival_pax			: Object.keys($data["arrival"	]||{}).map(day=>$data["arrival"		][day].pax		),
				departure_pax		: Object.keys($data["departure"	]||{}).map(day=>$data["departure"	][day].pax		),
				arrival_avg			: Object.keys($data["arrival"	]||{}).map(day=>$data["arrival"		][day].avg		),
				departure_avg		: Object.keys($data["departure"	]||{}).map(day=>$data["departure"	][day].avg		)
			};

			info.bookings_pending	= Math.ceil(Math.random()*info.arrival_bookings);

			// Compute aggregation
			info.arrival_bookings_total		= info.arrival_bookings		.reduce((acc,item)=>acc+item,0);
			info.departure_bookings_total	= info.departure_bookings	.reduce((acc,item)=>acc+item,0);
			info.arrival_pax_total			= info.arrival_pax			.reduce((acc,item)=>acc+item,0);
			info.departure_pax_total		= info.departure_pax		.reduce((acc,item)=>acc+item,0);

			info.arrival_avg_total			= info.arrival_avg			.reduce((acc,item)=>acc+item,0)/info.arrival_avg.length;
			info.departure_avg_total		= info.departure_avg		.reduce((acc,item)=>acc+item,0)/info.departure_avg.length;

			$data.totals = {};

			($data.days||[]).forEach(day=>{
				if(undefined==$data.arrival		[day]){ $data.arrival	[day] = { bookings: 0, pax: 0, avg: 0 }; }
				if(undefined==$data.departure	[day]){ $data.departure	[day] = { bookings: 0, pax: 0, avg: 0 }; }
				$data.totals[day] = {
					bookings 	: $data.arrival[day].bookings 	+ $data.departure[day].bookings,
					pax			: $data.arrival[day].pax 	 	+ $data.departure[day].pax
				}
			});

		} catch(e){
			this.commons.generateToast("_ERROR","_NO_DATA_FOR_DESTINATION","error");
		}
		
		return info;
	}

	/**
	 * process calendar date changes
	 */
	async calendarChange()
	{
		this.calendar.value.setHours(12);
		this.calendar.date	= this.calendar.value.toISOString().split('T')[0];

		switch(this.pageInfo.calendar.view){
			case "month":
			default		:
				// Set to 1st of month
				this.calendar.date	= [...this.calendar.date.split('-').splice(0,2),'01'].join('-');
				break;
		}

		console.log("CALENDAR",this.calendar);

		// Trigger only if date has changed
		if(this.calendar.date!==this.calendar.last){
			this.calendar.last 	= this.calendar.date;
			this.generateData({});
		}
	}

	async generateCharts($params){
		let $entity 	= $params["data"]	|| {};
		$entity.charts 	= $entity.charts	|| {};
		$entity.days	= $entity.days 		|| [];
		
		switch($params["type"]){
			
			case "bookings":

				$entity.charts["bookings"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_BOOKINGS'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrival_bookings 	},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departure_bookings 	}
					 ]
				});

				$entity.charts["pax"] = await this.generateChartByType({
					provider: $entity,
					title	: await this.commons.getTranslate('_PAX'),
					labels	: $entity.days.map(item=>item),
					datasets: [ 
						{ label: await this.commons.getTranslate("_ARRIVALS"), 	 color: '#59c429', data:	$entity.arrival_pax 		},
						{ label: await this.commons.getTranslate("_DEPARTURES"), color: 'crimson', data:	$entity.departure_pax 		}
					 ]
				});
				break;
		}
	}

	async generateChartByType($params){
		return { 
			line : {
				type	: "bar",
				options	: {
					title: {
						display	: false,
						text	: $params.title,
						fontSize: $params.titleSize || 20
					},
					legend: {	
						position: $params.legendPosition || 'bottom'
					}			
				},
				data 	: {
					labels	: $params["labels"],
					datasets: $params.datasets.map(dataset => ({
									label			: dataset.label,
									data			: dataset.data,
									steppedLine		: dataset.steppedLine 	|| false,
									tension			: dataset.tension 		|| 0,
									backgroundColor	: dataset.color,
									borderColor		: dataset.borderColor 	|| '#505050'								
								}))							
				}
			}
		};
	}
}