export const tabs 	= {
    destinations : {
        items	: [ 
            {   
                destination :   "1",  
                name        :   "mallorca", 		
                label       :   "_MALLORCA", 		
                icon        :   "bus"	 				
            },
            {   
                destination :   "QGBHKJzKHcGtoiVY5tVU",
                name        :   "tenerife",
                label       :   "_TENERIFE",
                icon        :   "bus"
            },
            {   
                destination :   "bHQhbcJkuC0qGfBXPJxp",
                name        :   "lanzarote",
                label       :   "_LANZAROTE",
                icon        :   "bus"
            },
            {   
                destination :   "FUE",  
                name        :   "fuerteventura",
                label       :   "_FUERTEVENTURA",
                icon        :   "bus"
            },
            {   
                destination :   "LPA",  
                name        :   "grancanaria",
                label       :   "_GRAN_CANARIA",
                icon        :   "bus"		
            }			
        ], 
        cols		: 2,
        selected	: 'mallorca'
    }
};