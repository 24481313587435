import { MapService 		} from 'src/app/demo/service/mapservice';
import { CommonsService 	} from './../commons.service';
import { FirebaseService 	} from './../database/firebase.service';
import { GoogleService 		} from './../google/google.service';
import { LodgingService 	} from 'src/app/demo/service/lodging/lodging.service';
import { Injectable      	} from '@angular/core';
import { EntityService 		} from '../entity.service';

@Injectable()
export class BookingsService {

    serviceInfo : any = {};

    constructor(
		private firebaseCtrl    : FirebaseService,
		private lodgingService  : LodgingService,
		private googleService   : GoogleService,
		private mapCtrl			: MapService,
		private commons			: CommonsService,
		private entityService	: EntityService
	){
		this.serviceInfo.parityFields	=	{
			location	:	{
				arrival_AddressInfo		:	'departure_AddressInfo',
				departure_AddressInfo	:	'arrival_AddressInfo',
				arrival_Location		:	'departure_Location',
				departure_Location		:	'arrival_Location'
			}
		}
		this.serviceInfo.providers			= this.commons.getEntity("providers");
		console.log("[BookingService] providers",this.serviceInfo.providers);
	}

	/**
	 * Try to correct the field and find a solution to return. 
	 * It check to our Database to find the place, instead get the info from google 
	 * and return the first suggestion most accurated
	 * 
	 * @param item item to fix
	 * @param data booking
	 * @param single if it is fixing just one booking or all
	 * @param skipPreProcess if the field is already normalized
	 */
    public async fixLocation(item,data,single?,skipPreProcess?){
		let suggestions;
		let fieldsToCheck = [];

		if(this.serviceInfo.lodging_alias[data[item['field']]]){
            this.updateLodgingFromAlias(this.serviceInfo.lodging_alias[data[item['field']]],item, data);
			return;
        }
        
		if(!skipPreProcess){
            if(!this.fixFormatText(data,item))                      {   return; }

            let getMinimalValue = this.getMinimalFactibleValue(data[item['field']],data,item); 
            if(!getMinimalValue.success)                            {   return; }
			
            suggestions	=	await this.googleService.getPlacePredictions(getMinimalValue.data);
            this.checkParityFields(data,item,fieldsToCheck);	
		}else{
			suggestions	=	await this.googleService.getPlacePredictions(data[item['field']]);	
		}
		
		
		if(suggestions.success && Array.isArray(suggestions.data) && suggestions.data.length <= 5){
			
			let firstSuggestion		=	await this.getFirstSuggestion(suggestions)
			
			if(firstSuggestion.success){
				let locationResolved	=	this.lodgingService.mountLodgingFromGoogle(firstSuggestion.data);
				this.updateLodgingInfo(	{	field 	: item['field'], 
											booking : data, 
											data 	: locationResolved}, 
											suggestions.data.length > 1 
												? {	field : item['field'], items : suggestions.data} 
												: null	
											);
				single ? this.commons.generateToast('_SUCCESS','_RESOLVED','success') : null;
			}else{
				this.setInfoToBooking('warnings',data,item['field'],'_NO_SUGGESTIONS')
				single ? this.commons.generateToast('_ERROR','_COULD_NOT_AUTOSOLVE','error') : null;
			}

		}else{
			fieldsToCheck.forEach(field=> { this.setInfoToBooking('warnings',data,item[field],'_NO_SUGGESTIONS');})
			fieldsToCheck.forEach(field =>{
				if(!this.lodgingService.checkLocation(data[item[field]],this.serviceInfo.lodging_alias).error){
					let indexError = data.errors.findIndex(el => el.field == item[field]);
					indexError > -1 ? data.errors.splice(indexError,1) : null;
				}
			})
			single ? this.commons.generateToast('_ERROR','_COULD_NOT_AUTOSOLVE','error') : null;
		}
	}
	
	/**
	 * Update booking fields depending on data
	 * 
	 * @param item 
	 * @param otherSuggestions 
	 */
	public updateLodgingInfo($item,$otherSuggestions?){
		let booking						=	$item.booking;
		const data						=	$item.data;
		const field						=	$item.field;
		const fieldsArrivalDirections	=	['arrival_Location','arrival_AddressInfo','arrival_To']
		const fieldsDepartureDirections	=	['departure_Location','departure_AddressInfo','departure_From'];
		const fieldsBothDirections		=	[...fieldsArrivalDirections,...fieldsDepartureDirections];

		if($otherSuggestions){
			this.setSuggestions(booking,$otherSuggestions['field'],$otherSuggestions['items'])
			this.setInfoToBooking('warnings',booking,$otherSuggestions['field'],'_OTHER_SUGGESTIONS');
		}

		switch(booking.direction){
			case 'both'		: 	if(booking.arrival_Location == booking.departure_Location){
									booking = this.normalizeArrivalFields(booking,data);
									booking = this.normalizeDepartureFields(booking,data);
									this.cleanMultipleInfoBooking(fieldsBothDirections,booking);
									booking.fixes['arrival_Location']		=	{ fixed : true, icon : 'fw fa-copy', action : 'displayOptionAlias', saveAction : 'saveAlias', cancelButton : true, cancelButtonIcon : 'fa fa-fw fa-close'};
									booking.fixes['dº']		=	{ fixed : true, icon : 'fw fa-copy', action : 'displayOptionAlias', saveAction : 'saveAlias', cancelButton : true, cancelButtonIcon : 'fa fa-fw fa-close'};
								} else {
									if(field.includes('arrival')){
										booking = this.normalizeArrivalFields(booking,data);
										this.cleanMultipleInfoBooking(fieldsArrivalDirections,booking);
										booking.fixes['arrival_Location']	=	{ fixed : true, icon : 'fw fa-copy', action : 'displayOptionAlias', saveAction : 'saveAlias', cancelButton : true, cancelButtonIcon : 'fa fa-fw fa-close'};
									}else{
										booking = this.normalizeDepartureFields(booking,data);	
										this.cleanMultipleInfoBooking(fieldsDepartureDirections,booking);
										booking.fixes['departure_Location']	=	{ fixed : true, icon : 'fw fa-copy', action : 'displayOptionAlias',saveAction : 'saveAlias', cancelButton : true, cancelButtonIcon : 'fa fa-fw fa-close'};
									}
								}
								break;

			case 'arrival'	:	this.normalizeArrivalFields(booking,data);
								this.cleanMultipleInfoBooking(fieldsArrivalDirections,booking);
								booking.fixes['arrival_Location']	=	{ fixed : true, icon : 'fw fa-copy', action : 'displayOptionAlias',saveAction : 'saveAlias', cancelButton : true, cancelButtonIcon : 'fa fa-fw fa-close'};
								break;

			case 'departure':	this.normalizeDepartureFields(booking,data);	
								this.cleanMultipleInfoBooking(fieldsDepartureDirections,booking);
								booking.fixes['departure_Location']	=	{ fixed : true, icon : 'fw fa-copy', action : 'displayOptionAlias', saveAction : 'saveAlias', cancelButton : true, cancelButtonIcon : 'fa fa-fw fa-close'};
								break;	
		}

		this.applyFixes(booking,data);
	}

	private applyFixes(booking,data){
		console.log("BOOKING FIXES",booking.fixes);
		Object.keys(booking.fixes).forEach(async fix=>{
			switch(fix){
				case "arrival_Location"		:
				case "departure_Location"	:
					if(!data.address_components){
						this.commons.generateToast("_ERROR","_GOOGLE_WRONG_DATA_FOUND","error");
						return false;
					}
					let response = await this.entityService.postJSON(
						this.entityService.getUrl('hotel_update'), 
						{
							location		: data.lodging_tourinia_name || data.lodging_google_name || data.booking.location,
							alias_name		: data.booking.location,
							alias_area		: data.booking.area,
							lng				: data.longitude,
							lat				: data.latitude,
							address			: data.address,
							zipcode			: data.address_components[6].long_name,		
							municipality	: data.municipality,
							region			: data.address_components[4].long_name,
							country			: data.address_components[5].long_name,
							dmc				: this.commons.userInfo.dmc.id,
							destination		: this.commons.userInfo.destination.id							
						}
					);
					console.log("RESPONSER",response);
					break;
				default:
					console.log("CAMPO NO VÁLIDO");
					break;					
			}
		});
		return booking.fixes;
	}

	/**
	 * apply rules per provider
	 * p.e. compute price
	 * 
	 * @param $booking 
	 * @returns 
	 */
	public applyBookingProviderActions($booking){
		let provider = this.serviceInfo.providers.find(item=>$booking["provider"]==item.id);
		if(undefined==provider){
			this.commons.generateToast("Error","_PROVIDER_NOT_FOUND","error");
			return false;
		}
		(provider.actions||[]).forEach(action=>{
			this.processAction(action,$booking);
		});
		return $booking;
	}

	public removeSuggestions(rowData,field)	{
		if(!rowData.suggestions || !rowData.suggestions[field]){ return }
		console.log('Removing suggestions', rowData,field);
		delete rowData.suggestions[field];
	}

	public setInfo(value, info)			{	this.serviceInfo[value] = info;	}
    
	public setInfoToBooking( typeInfo, booking, field, type, errorCode?){
		if(!field){ return; }
		booking[typeInfo] =	booking[typeInfo] || [];
		if(booking[typeInfo].find(el => el.field == field)){ return; }
		booking[typeInfo].push({field : field, type : type, errorCode : errorCode || null, class : typeInfo+'Item'});
	}

	/**
	 * Normalize the addressInfo and Location depending the bookings direction
	 * @param booking booking to normalize
	 */
	 public normalizeLocationFields(booking){
		let provider_areas_mapped 	= this.commons.getEntity("provider_areas_mapped");																							
		let provider				= booking["provider"];

		if(!booking	){ return }
		if(!provider){ return }

		// Google Lodgings check
		let lodgings_aliases= this.commons.userInfo.currentDestination.lodgings_aliases || {};
		let google_lodgings = this.commons.userInfo.currentDestination.google_lodgings || [];
		
		let currentGoogleAlias;

		switch(booking.direction){
			default				:	
				console.log('no direction found on booking', booking.id); 
				break;

			case	'arrival'	:	
				booking['arrival_Location']					=	booking['location'];
				booking['original_arrival_Location']		=	booking['arrival_Location'];	
				booking['arrival_AddressInfo']				=	booking['addressInfo'];
				booking['original_arrival_AddressInfo']		=	booking['arrival_AddressInfo'];
				if(undefined!==provider_areas_mapped){
					booking['arrival_Zone']					=	this.getZone(provider,provider_areas_mapped[provider],booking,"arrival");
				}
				booking['original_arrival_Zone']			=	booking['arrival_Zone'];
				booking['locationBinded']					=	false;

				// Google Alias
				currentGoogleAlias = lodgings_aliases[(booking.arrival_Location||"").toLowerCase()];
				if(currentGoogleAlias){
					let current = google_lodgings.some(item=>item.name==currentGoogleAlias)				
					booking.arrival_Location_Google = current.name;
					booking.arrival_Area_Google 	= current.area;
					booking.arrival_Address_Google	= current.address;
					booking.arrival_Latitude		= current.latitude;
					booking.arrival_longitude		= current.longitude;
				}

				break;

			case 	'departure'	:	
				booking['departure_Location']				=	booking['location'];
				booking['original_departure_Location']		=	booking['departure_Location'];
				booking['departure_AddressInfo']			=	booking['addressInfo'];
				booking['original_departure_AddressInfo']	=	booking['departure_AddressInfo'];
				if(undefined!==provider_areas_mapped){									
					booking['departure_Zone']				=	this.getZone(provider,provider_areas_mapped[provider],booking,"departure");
				}
				booking['original_departure_Zone']			=	booking['departure_Zone'];
				booking['locationBinded']					=	false;

				// Google Alias
				currentGoogleAlias = lodgings_aliases[(booking.departure_Location||"").toLowerCase()];
				if(currentGoogleAlias){
					let current = google_lodgings.some(item=>item.name==currentGoogleAlias)
					booking.departure_Location_Google = current.name;				
					booking.departure_Area_Google 	= current.area;
					booking.departure_Address_Google= current.address;
					booking.departure_Latitude		= current.latitude;
					booking.departure_longitude		= current.longitude;
				}
				break;

			case	'both'		:	
				// TO FIX
				// locationBinded is set to true cause we assign location, both arrival and  departure 
				// backend should send separately both fields
				booking['arrival_Location']					=	booking['location'];
				booking['original_arrival_Location']		=	booking['arrival_Location'];	
				booking['arrival_AddressInfo']				=	booking['addressInfo'];
				booking['original_arrival_AddressInfo']		=	booking['arrival_AddressInfo'];
				
				if(undefined!==provider_areas_mapped){									
					let arrivalZone = this.getZone(provider,provider_areas_mapped[provider],booking,"arrival");									
					if(arrivalZone){
						booking['arrival_Zone']					=	arrivalZone;
						booking['original_arrival_Zone']		=	arrivalZone;
					}
				}

				booking['departure_Location']				=	booking['location'];
				booking['original_departure_Location']		=	booking['departure_Location'];
				booking['departure_AddressInfo']			=	booking['addressInfo'];
				booking['original_departure_AddressInfo']	=	booking['departure_AddressInfo'];
				
				if(undefined!==provider_areas_mapped){									
					let departureZone =	this.getZone(provider,provider_areas_mapped[provider],booking,"departure");
					if(departureZone){ 
						booking['departure_Zone'] 				= 	departureZone;
						booking['original_departure_Zone']		=	departureZone;										
					}
				}	
				booking['locationBinded']					=	true;

				// Google Alias				
				currentGoogleAlias = lodgings_aliases[(booking.arrival_Location||"").toLowerCase()];
				if(currentGoogleAlias){
					let current = google_lodgings.some(item=>item.name==currentGoogleAlias)
					booking.arrival_Location_Google 	= current.name;		
					booking.arrival_Area_Google 		= current.area;
					booking.arrival_Address_Google		= current.address;
					booking.arrival_Latitude			= current.latitude;
					booking.arrival_longitude			= current.longitude;		
					booking.departure_Location_Google 	= current.name;
					booking.departure_Area_Google 		= current.area;
					booking.departure_Address_Google	= current.address;
					booking.departure_Latitude			= current.latitude;
					booking.departure_longitude			= current.longitude;
				}
				break;
		}
		// Merge arrival and departure zone
		booking["zone"]	 = booking["arrival_Zone"] || booking["departure_Zone"];
		return booking;
    }

    /**
	 * Remove concrete warning / error from a booking
	 * @param typeInfo Warning / Error
	 * @param booking 
	 * @param field Type of warning / error
	 */
	private removeInfoToBooking(typeInfo,booking,field){
		if(!booking[typeInfo]){ return;	}
		let indexField	=	booking[typeInfo].findIndex(el => el['field'] == field);
		indexField > -1	?	booking[typeInfo].splice(indexField,1) : null;
	}

	/**
	 * Remove the warnings and errors from booking
	 * @param listFields list fields from booking to remove
	 * @param booking booking 
	 */
	private cleanMultipleInfoBooking(listFields, booking){
		listFields.forEach(field =>{
			this.removeInfoToBooking('warnings',booking, field); // remove the warning if it has
			this.removeInfoToBooking('errors',	booking, field);
		})
	}

	private getZone(provider:number,areas,booking,direction){
		let zone, field;
		let providerStr = (typeof provider == "number")?provider.toString():provider;
		switch(direction){
			case "arrival"	:	zone = areas[booking["arrival_To"	]];	break;
			case "departure":	switch(provider.toString()){
									case "2"	: 	field = "departure_From";	break;
									default		:
									case "1"	: 									
									case "4"	: 	field = "departure_To";		
													break; 	
									// default		: 	this.commons.generateToast("Error", "provider Id "+provider+" not found","error"); break;
								}
								zone = areas[booking[field]];	break;
		}
		return zone;
	}

    /**
     * 
     * @param rowData booking
     * @param field field
     * @param items list suggestions
     */
    private setSuggestions(rowData,field,items){
		rowData.suggestions			=	rowData.suggestions || {};
		rowData.suggestions[field]	=	items;
	}
    
    private updateLodgingFromAlias(lodgingName, item, booking){
		let findPlace		=	this.serviceInfo.listLodgings.find(lodging => lodging.name == lodgingName);
		this.updateLodgingInfo({ field : item['field'], booking : booking,  data : findPlace });
	}

    private fixFormatText(data,item)			{
											data[item['field']]	=	this.lodgingService.fixFormatText(data[item['field']]);
											if(data[item['field']] == null){ this.setInfoToBooking('errors',data,item['field'],'_EMPTY_FIELD');	return false; }
											return true;			
										}

    private getMinimalFactibleValue(value,data,item){
											let currentSplitted;
											let minimal		=	this.lodgingService.getMinimalFactibleValue(value,currentSplitted);
											if(minimal.query == ""){ this.setInfoToBooking('errors',data,item['field'],'_EMPTY_FIELD');	return { success : false};}
											return {success : true, data : minimal.query};											
										}

    private checkParityFields(data, item, fieldsToCheck){
											if(data['locationBinded']){
												fieldsToCheck.push(this.serviceInfo.parityFields.location[item['field']],item['field']);
												data[this.serviceInfo.parityFields.location[item['field']]] = data[item['field']]; // assign the value paired from arrival / departure or reverse
											}else{
												fieldsToCheck.push(item['field']);
											}
										}

	private mountDetailsPlace(data)					{
		data.photo 			=	data.photos ? data.photos[0].getUrl() : null;
		data.latitude  	    =   data.geometry.location.lat();
		data.longitude 	    =  	data.geometry.location.lng();
		delete data.geometry;
		delete data.photos;
	}

	private async getFirstSuggestion(suggestions)	{
		let findPlace		=	this.serviceInfo.listLodgings.find(lodging => lodging.place_id == suggestions.data[0].place_id);
		let firstSuggestion	: any;
		
		if(findPlace){
			if(!this.serviceInfo.lodging_alias[findPlace.name]){ // FIX export to method
				this.serviceInfo.lodging_alias[findPlace.name]	=	findPlace.name;
				await this.firebaseCtrl.updateItemByRef(this.serviceInfo.destinationRef,{lodging_alias : this.serviceInfo.lodging_alias})
			}
			firstSuggestion	=	{ success : true, data : findPlace};
		}else{
			firstSuggestion	=	await this.googleService.getDetailsFromPlace(suggestions.data[0].place_id);
			if(firstSuggestion.success && firstSuggestion.data.types.find(el => el == 'establishment')){

				this.mountDetailsPlace(firstSuggestion.data);
				// await this.lodgingService.addNewLodging(firstSuggestion.data);
			}
		}
		return firstSuggestion;	
	}

	/**
	 * update arrival fields upon data, p.e. zone
	 * 
	 * @param $booking 
	 * @param $data 
	 * @returns 
	 */
	private normalizeArrivalFields($booking, $data){
		if(undefined==$data || undefined==$booking){ return; }
		$booking.arrival_Location		=	$data.name;
		$booking.arrival_AddressInfo	=	$data.formatted_address || $data.address;
		$booking.arrival_To				=	$data.area || $data.municipality || $data.vicinity;

		// Find zone from geolocation
		// $booking.arrival_Zone			=	this.mapCtrl.findPolygonByPoint(
		// 										{
		// 											lat : $data.latitude, 
		// 											lng : $data.longitude
		// 										}, 
		// 										this.serviceInfo.zonesDestination.data
		// 									);
		return $booking;
	}

	/**
	 * update departure fields upon data, p.e. zone
	 * 
	 * @param $booking 
	 * @param $data 
	 * @returns 
	 */
	private normalizeDepartureFields($booking,$data){
		if(undefined==$data || undefined==$booking){ return; }
		$booking.departure_Location		=	$data.name; 
		$booking.departure_AddressInfo	=	$data.formatted_address || $data.address;	
		$booking.departure_From			=	$data.area || $data.municipality || $data.vicinity;
		// $booking.departure_Zone			=	this.mapCtrl.findPolygonByPoint(
		// 										{
		// 											lat : $data.latitude, 
		// 											lng : $data.longitude
		// 										}, 
		// 										this.serviceInfo.zonesDestination.data
		// 									);
		return $booking;
	}

	private processAction($action,$booking){
		switch($action.name){
			case "compute_price":	if($action.value==true){
										$booking = this.computePrice($booking["provider"],$booking);
									}
									break;
		}
		return $booking;
	}

	private getVehicleTypePrice($prices,$vehicle,$from,$to){
		let price = ($prices||[]).find(price=>	price.From		== $from 	&& 
												price.To 		== $to 		&& 
												price.vehicle	== $vehicle
									);
		return (undefined==price || null==price)?0:price["Adults"];
	}

	private computePrice($provider,$booking){
		let vehicleType				= this.getVehicleMapping($provider,$booking.vehicle);
		if(vehicleType=="_NONE"){
			switch($booking["direction"]){
				case "arrival"		:	$booking["arrival_computed_price"	]	= 0; break;			
				case "departure"	:	$booking["departure_computed_price"	]	= 0; break;		
				case "both"			:	$booking["arrival_computed_price"	]	= 0;
										$booking["departure_computed_price"	]	= 0;
										$booking["computed_price"			]	= 0;
										break;
			}
			return false;
		}

		let from					= "PMI";
		let providerPrices			= {
			"privates"	: $provider.prices 			|| [],
			"shuttles"	: $provider.shuttlePrices 	|| []
		};

		switch($booking["shared"]){
			case "shuttle":	return false;
		}

		switch($booking["direction"]){
			case "arrival"		:	$booking["arrival_computed_price"]		= this.getVehicleTypePrice(providerPrices["privates"],vehicleType,from,$booking["arrival_To"]);
									break;
			
			case "departure"	:	$booking["departure_computed_price"]	= this.getVehicleTypePrice(providerPrices["privates"],vehicleType,from,$booking["departure_From"]);
									break;

			case "both"			:	$booking["arrival_computed_price"]		= this.getVehicleTypePrice(providerPrices["privates"],vehicleType,from,$booking["arrival_To"]);
									$booking["departure_computed_price"]	= this.getVehicleTypePrice(providerPrices["privates"],vehicleType,from,$booking["departure_From"]);
									$booking["computed_price"]				= $booking["arrival_computed_price"] + $booking["departure_computed_price"];
									break;
		}

		return $booking;
	}

	private getVehicleMapping($provider,$vehicle){
		let vehicleType = "_NONE";
		try {
			vehicleType	= ($provider.vehicleTypesMapping||[]).find(item=>item.origin==$vehicle);
			if(undefined==vehicleType || null==vehicleType){
				throw new Error("_NO_MAPPING_FOR_PROVIDER_VEHICLE_TYPE_FOUND");
			}
			console.log("Found vehicle",vehicleType);
		}catch(error){
			console.log("[bookingService::computePrice]",error);
		}
		return vehicleType
	}
}