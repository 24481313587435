import { CompanyService 			}	from './demo/service/database/company.service';
import { FirebaseService			}	from './demo/service/database/firebase.service';
import { StorageService 			}	from './demo/service/storageservice';
import { Component, 
		 Input, 
		 OnInit, 
		 AfterViewInit, 
		 ViewChild					}	from '@angular/core';
import { trigger, 
		 state, 
		 style, 
		 transition, 
		 animate					} from '@angular/animations';
import { Location					} from '@angular/common';
import { Router						} from '@angular/router';
import { MenuItem, ScrollPanel		} from 'primeng/primeng';
import { AppComponent				} from './app.component';

@Component({
    selector	: 'app-menu',
    templateUrl : './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, AfterViewInit {

    @Input() reset	: boolean;
    @Input() roles	: any;
    @Input() modules: any[]     =   [];
    userInfo        : any       =   {};
    model           : any[];

    @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

    constructor(
		public app			    : AppComponent, 
		public storage 		    : StorageService, 
        public firebaseCtrl     : FirebaseService,
        private companyService  : CompanyService,
	) {}

    async ngOnInit() {        
		console.log('ROLES FROM USER',      this.roles);
        console.log('MODULES FROM COMPANY', this.modules);        

        this.userInfo                   =   (this.storage.getItem('dmcSuite') ||{}).userInfo || null;
        this.model = [
            {
                label: 'Menu', icon: 'fa fa-fw fa-bars', _badge: 4, badgeStyleClass: 'green-badge',
                items: [
                    { label: 'Horizontal', 	icon: 'fa fa-fw fa-bars', command: event => this.app.menuMode = 'horizontal' 	},
                    { label: 'Static', 		icon: 'fa fa-fw fa-bars', command: event => this.app.menuMode = 'static' 		},
                    { label: 'Overlay', 	icon: 'fa fa-fw fa-bars', command: event => this.app.menuMode = 'overlay' 		},
                    { label: 'Popup', 		icon: 'fa fa-fw fa-bars', command: event => this.app.menuMode = 'popup' 		}
                ]
            },
            // {
            //     label: 'Landing',           icon: 'fa fa-fw fa-home', 	routerLink: ['/landing']
            // },
            {
                label     : '_DASHBOARD',           	
                icon      : 'fa fa-fw fa-tachometer',	
                routerLink: [ '/dashboard' ], 
                roles     : [ 'ROLE_ADMIN','ROLE_DMC_ADMIN' ]
            },
            {
                label     : '_DASHBOARD_AGGREGATOR',	
                icon      : 'fa fa-fw fa-tachometer',	
                routerLink: [ '/dashboard_aggregator'], 
                roles     : [ 'ROLE_AGGREGATOR_ADMIN', 'ROLE_ADMIN' ]
            }, 
            {
                label     : '_DASHBOARD_RESELLER',	
                icon      : 'fa fa-fw fa-tachometer',	
                routerLink: [ '/dashboard_reseller' ], 
                roles     : [ 'ROLE_RESELLER_ADMIN'  ]
            },       
            // {
            //     roles     : ['ROLE_ADMIN'],
            //     disabled  : false,
            //     label     : '_PROVIDERS', icon: 'fa fa-fw fa-file-o',
            //     items     : [
			// 		{ label: '_TRANSPORT_COMPANIES', 	icon: 'fa fa-fw fa-table', 		routerLink: ['/transport_companies'],	roles:  ['ROLE_ADMIN']	},
            //         { label: '_DRIVERS', 	            icon: 'fa fa-fw fa-table', 		routerLink: ['/drivers'],				roles:  ['ROLE_ADMIN'] 	},
            //         { label: '_INTEGRATIONS',			icon: 'fa fa-fw fa-server', 	routerLink: ['/providers'],  			roles:  ['ROLE_ADMIN']	}                    
            //     ]
			// },
			{
                roles     : [ 'ROLE_BOOKINGS','ROLE_DMC_ADMIN'],
                modules   : [ 'bookings' ],  
                label     : '_BOOKINGS', 
                icon      : 'fa fa-fw fa-th-list',
                items     : [
                    { 
						label     : '_BOOKINGS',
						icon      : 'fa fa-fw fa-table', 	
                      	routerLink: [ '/bookings' ],
                      	sections  : [ 'fix_bookings' ],
                      	roles     : [ 'ROLE_USER','ROLE_DMC_ADMIN' ]
                    }
                    // ,{ 
					// 	label     : '_IMPORTER', 		      	
					// 	icon      : 'fa fa-fw fa-table', 	
                    //   	routerLink: [ '/importer' ],
                    //   	roles     : [ 'ROLE_USER','ROLE_DMC_ADMIN', 'ROLE_DMC_IMPORTER' ]
                    // }
                    ,{ 
						label     : '_TABLE',	            
						icon      : 'fa fa-fw fa-cog', 		
						routerLink: [ '/table' ],
                        roles     : [ 'ROLE_USER','ROLE_DMC_ADMIN', 'ROLE_DMC_IMPORTER' ]
                   	}
                ]
            },
            {
				roles     : [ 'ROLE_ADMIN', 'ROLE_CHECKIN', 'ROLE_DMC_ADMIN' ],
                label     : '_WELCOME_SERVICE', icon: 'fa fa-fw fa-file-o',
                modules   : [ 'welcome_service' ], 
                items     : [
					{ label: '_CHECKINS', icon: 'fa fa-fw fa-table', 		routerLink: ['/checkins'], roles: ['ROLE_ADMIN','ROLE_DMC_ADMIN','ROLE_CHECKIN'] },
					{ label: '_TRACKING', icon: 'fa fa-fw fa-map-marker', 	routerLink: ['/map'		], roles: ['ROLE_ADMIN']				}
				]
            },                        
            // {
            //     disabled: false,
            //     label: 'Groups', icon: 'fa fa-fw fa-sitemap',
            //     items: [
            //         {label: 'Groups', icon: 'fa fa-fw fa-table', routerLink: ['/groups']}
            //     ]
            // },
            {   
                roles   :   [ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN' ],
                label   :   '_TRANSFERS', 
                icon    :   'fa fa-fw fa-bus',
                modules :   [ 'transport_service' ],
                items   : [
                    // { label: '_EXTERNALS',     			icon: 'fa fa-fw fa-table', 	    routerLink: ['/externals'                   ],  roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
                    // { label: '_GROUPS',     			icon: 'fa fa-fw fa-table', 	    routerLink: ['/transport_groups'            ],  roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
                    { label: '_COMPOSITOR',				icon: 'fa fa-fw fa-bus',	    routerLink: ['/transport_compositor'        ],  roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
                    // {label: '_DRIVERS',     			icon: 'fa fa-fw fa-user', 	    routerLink: ['/transport_drivers'           ],  roles:  [''ROLE_ADMIN','ROLE_DMC_ADMIN']					},
					// { label: '_CALENDARS',     			icon: 'fa fa-fw fa-calendar', 	routerLink: ['/transport_calendars'         ],  roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
					{ label: '_PRICING',   				icon: 'fa fa-fw fa-map', 	    routerLink:	['/transport_mappricing'		],  roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
					// { label: '_TABLE_PRICES',			icon: 'fa fa-fw fa-table', 	    routerLink:	['/list_prices'	                ],  roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
					// { label: '_ZONES_TRANSFERS',		icon: 'fa fa-fw fa-map-marker', routerLink:	['/manage_zones'				],  roles:  ['ROLE_USER','ROLE_DMC_ADMIN'   ]					},
                    
                    // LOS 3 SIGUIENTES SE HACEN DESDE EL DMC
                    // { label: '_VEHICLES', 				icon: 'fa fa-fw fa-table', 		routerLink: ['/vehicles'                    ],	roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
                    // { label: '_TRANSPORT_COMPANIES', 	icon: 'fa fa-fw fa-table', 		routerLink: ['/transport_companies'         ],	roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ]					},
                    // { label: '_DRIVERS', 	            icon: 'fa fa-fw fa-table', 		routerLink: ['/drivers'                     ],	roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN'  ] 	            	},
                ]
            },
            {
                roles   :   [ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN' ],                
                label   :   '_TOURS', 
                icon    :   'fa fa-fw fa-globe',
                modules :   [ 'tours_service' ],
                items   :  [
					{ label : '_CATALOG',               icon : 'fa fa-fw fa-cog',       routerLink: ['/tour_main'                   ],  roles:   ['ROLE_ADMIN','ROLE_DMC_ADMIN' ]                   },
					{ label: '_ZONES_PICKUPS',  		icon: 'fa fa-fw fa-map-marker', routerLink:	['/pickupzones'                 ],  roles:   ['ROLE_ADMIN','ROLE_DMC_ADMIN' ]                   }                                      
                ]
			},
            // {
            //     label   : '_STATISTICS', 
            //     icon    : 'fa fa-fw fa-area-chart',
            //     items   :  [
            //         { label : '_BOOKINGS', icon : 'fa fa-fw fa-cog', routerLink:['/chart_bookings'] }
            //     ]
			// },
            // {roles   :   ['ROLE_ADMIN'],
            //     disabled: false,
            //     label: 'Pricing', icon: 'fa fa-fw fa-dollar',
            //     items: [
                   
            //     ]
			// },
			// {
            //     label   : '_INVOICING', 
            //     icon    : 'fa fa-fw fa-dollar',
            //     items   :  [
            //         { label : '_INVOICING_COMPANY',	        icon : 'fa fa-fw fa-dollar', routerLink:['/invoicing_company'	    ]	},
            //         { label : '_INVOICING_PROVIDERS',		icon : 'fa fa-fw fa-dollar', routerLink:['/invoicing_providers'		]	},
            //         { label : '_INVOICING_TRANSPORTERS',	icon : 'fa fa-fw fa-dollar', routerLink:['/invoicing_transporters'	]	}                    
            //     ]
            // },
            {
                roles   :   [ 'ROLE_ADMIN','ROLE_DMC_ADMIN' ],
                label   : '_COMPANY', icon: 'fa fa-fw fa-cog',
                items   : [
                    { label : '_COMPANY_MAIN',         	icon: 'fa fa-fw fa-home', 		routerLink:	['/company_main'],			roles:  ['ROLE_ADMIN','ROLE_DMC_ADMIN']	},
                    // { label	: '_COMPANY_FLEET', 		icon: 'fa fa-fw fa-bus', 		routerLink: ['/company_fleet'],			roles:  ['ROLE_ADMIN']	},
                    { label	: '_COMPANY_FLEET', 		icon: 'fa fa-fw fa-bus', 		routerLink: ['/company_fleet'],			roles:  ['ROLE_ADMIN']	},
                    { label	: '_COMPANY_DRIVERS', 		icon: 'fa fa-fw fa-id-card-o',	routerLink: ['/company_drivers'],		roles:  ['ROLE_ADMIN']	},
					{ label	: '_LODGINGS', 				icon: 'fa fa-fw fa-table', 		routerLink: ['/hotels'],				roles:  ['ROLE_ADMIN']},
					
                ]
			},
			{ 	label	  : '_COMPANY_PROVIDERS',   	
				icon      : 'fa fa-fw fa-cogs', 		
				routerLink: ['/company_providers'],  	
				roles     :  ['ROLE_ADMIN']	
			},                    
            { 
                label	  : '_COMPANY_TRANSPORTERS',
                modules   : ['transport_service'], 	
                icon      : 'fa fa-fw fa-building', 	
                routerLink: ['/company_transporters'],	
                roles     : ['ROLE_ADMIN']	
			},            
			{
				label       : '_INVOICING',	        
				icon        : 'fa fa-fw fa-dollar', 
				routerLink  : ['/invoicing_company']
            },                        
            {
                label   : '_RESELLER', 
                roles   : ['ROLE_ADMIN'],
                icon    : 'fa fa-fw fa-area-chart',
                items   :  [
                    {   label       :   '_RESELLER_DMCS',
                        icon        :   'fa fa-fw fa-area-chart',
                        routerLink  :   ['/create_destination'],
                        roles       :   ['ROLE_ADMIN',],
                    },
                    {
                        label       :   '_STAFF',
                        icon        :   'fa fa-fw fa-cog',
                        routerLink  :   ['/reseller_main'],
                        roles       :   ['ROLE_ADMIN'],
                    }                    
                ]
            },
            {   label       :   '_RESELLER_DMCS',
                icon        :   'fa fa-fw fa-area-chart',
                routerLink  :   ['/create_destination'],
                roles       :   ['ROLE_RESELLER_ADMIN','ROLE_RESELLER_OPERATOR'],
            },
            {
                label       :   '_RESELLER_STAFF',
                icon        :   'fa fa-fw fa-cog',
                routerLink  :   ['/reseller_main'],
                roles       :   ['ROLE_RESELLER_ADMIN'],
            },            
            {
                label   : '_ADMIN', 
                roles   : ['ROLE_ADMIN'],
                icon    : 'fa fa-fw fa-area-chart',
                items   :  [
                    {
                        label       :   '_DESTINATIONS',
                        icon        :   'fa fa-fw fa-area-chart',
                        routerLink  :   ['/map_generate_destination'],
                        roles       :   ['ROLE_ADMIN']
                        
                    },
                    {
                        label       :   '_ADMIN_DESTINATIONS',
                        icon        :   'fa fa-fw fa-area-chart',
                        routerLink  :   ['/destinations'],
                        roles       :   ['ROLE_ADMIN']
                    },
                    {
                        label       :   '_ADMIN_ENTITIES',
                        icon        :   'fa fa-fw fa-area-chart',
                        routerLink  :   ['/admin_main'],
                        roles       :   ['ROLE_ADMIN']                        
                    },
                    {
                        label       :   '_ADMIN_DMCS',
                        icon        :   'fa fa-fw fa-area-chart',
                        routerLink  :   ['/dmcs'],
                        roles       :   ['ROLE_ADMIN']
                        
                    }
                    // {label : '_COMPONENTS', icon : 'fa fa-fw fa-cog', routerLink:['/test'] },
                    // { label: '_DISTANCES', 	icon: 'fa fa-fw fa-cog', 		routerLink: ['/mapdistances']														},

                ]
            }            
            
            // ,{
            //     roles   :   ['ROLE_ADMIN'],
            //     label: 'Template Pages', 
            //     icon: 'fa fa-fw fa-life-saver',
            //     items: [
            //         {label: 'Empty Page', icon: 'fa fa-fw fa-square-o', routerLink: ['/empty']},
            //         {label: 'Landing Page', icon: 'fa fa-fw fa-globe', url: 'assets/pages/landing.html', target: '_blank'},
            //         {label: 'Login Page', icon: 'fa fa-fw fa-sign-in', url: 'assets/pages/login.html', target: '_blank'},
            //         {label: 'Error Page', icon: 'fa fa-fw fa-exclamation-circle', url: 'assets/pages/error.html', target: '_blank'},
            //         {label: '404 Page', icon: 'fa fa-fw fa-times', url: 'assets/pages/404.html', target: '_blank'},
            //         {label: 'Access Denied Page', icon: 'fa fa-fw fa-exclamation-triangle',
            //             url: 'assets/pages/access.html', target: '_blank'}
            //     ]
            // }

            // {roles   :   ['ROLE_ADMIN'],
            //     disabled: true,
            //     label: 'Layout Colors', icon: 'fa fa-fw fa-magic',
            //     items: [
            //         {
            //             label: 'Flat',
            //             icon: 'fa fa-fw fa-circle',
            //             badge: 7, badgeStyleClass: 'blue-badge',
            //             items: [
            //                 {label: 'Dark', 	 icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('dark'); 		}},
            //                 {label: 'Turquoise', icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('turquoise'); }},
            //                 {label: 'Green', 	 icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('green'); 	}},
            //                 {label: 'Blue', 	 icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('blue'); 		}},
            //                 {label: 'Rose', 	 icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('rose'); 		}},
            //                 {label: 'Teal', 	 icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('teal'); 		}},
            //                 {label: 'Blue Grey', icon: 'fa fa-fw fa-paint-brush', command: (event) => { this.changeLayout('bluegrey'); 	}}
            //             ]
            //         },
            //         {
            //             label: 'Special',
            //             icon: 'fa fa-fw fa-fire',
            //             badge: 8, badgeStyleClass: 'blue-badge',
            //             items: [
            //                 {label: 'Cosmic', icon: 'fa fa-fw fa-paint-brush',
            //                   command: (event) => {this.changeLayout('cosmic'); }},
            //                 {label: 'Lawrencium', icon: 'fa fa-fw fa-paint-brush', command: (event) => {this.changeLayout('lawrencium'); }},
            //                 {label: 'Couple', icon: 'fa fa-fw fa-paint-brush', command: (event) => {this.changeLayout('couple'); }},
            //                 {label: 'Stellar', icon: 'fa fa-fw fa-paint-brush', command: (event) => {this.changeLayout('stellar'); }},
            //                 {label: 'Beach', icon: 'fa fa-fw fa-paint-brush', command: (event) => {this.changeLayout('beach'); }},
            //                 {label: 'Flow', icon: 'fa fa-fw fa-paint-brush',
            //                   command: (event) => {this.changeLayout('flow'); }},
            //                 {label: 'Fly', icon: 'fa fa-fw fa-paint-brush', command: (event) => {this.changeLayout('fly'); }},
            //                 {label: 'Nepal', icon: 'fa fa-fw fa-paint-brush', command: (event) => {this.changeLayout('nepal'); }}
            //             ]
            //         }
            //     ]
            // },
            // { 	
			// 	roles   : ['ROLE_ADMIN'],
            //     disabled: true,
			// 	label   : 'Themes', 
			// 	icon    : 'fa fa-fw fa-paint-brush', 
			// 	badge   : 7, badgeStyleClass: 'amber-badge',
            //     items: [
            //         {label: 'Green Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('green'); }},
            //         {label: 'Teal Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('teal'); }},
            //         {label: 'Blue Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('blue'); }},
            //         {label: 'Amber Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('amber'); }},
            //         {label: 'Purple Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('purple'); }},
            //         {label: 'Turquoise Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('turquoise'); }},
            //         {label: 'Blue Grey Theme', icon: 'fa fa-fw fa-diamond', command: (event) => {this.changeTheme('bluegrey'); }},
            //     ]
            // },
            // {
            //     roles   :   ['ROLE_ADMIN'],
            //     disabled: true,
            //     label   : 'Components', 
            //     icon    : 'fa fa-fw fa-sitemap',
            //     items   : [
            //         {label: 'Sample Page', icon: 'fa fa-fw fa-columns', routerLink: ['/sample']},
            //         {label: 'Forms', icon: 'fa fa-fw fa-code', routerLink: ['/forms']},
            //         {label: 'Data', icon: 'fa fa-fw fa-table', routerLink: ['/data']},
            //         {label: 'Panels', icon: 'fa fa-fw fa-list-alt', routerLink: ['/panels']},
            //         {label: 'Overlays', icon: 'fa fa-fw fa-square', routerLink: ['/overlays']},
            //         {label: 'Menus', icon: 'fa fa-fw fa-minus-square-o', routerLink: ['/menus']},
            //         {label: 'Messages', icon: 'fa fa-fw fa-circle-o-notch', routerLink: ['/messages']},
            //         {label: 'Charts', icon: 'fa fa-fw fa-area-chart', routerLink: ['/charts']},
            //         {label: 'File', icon: 'fa fa-fw fa-arrow-circle-o-up', routerLink: ['/file']},
            //         {label: 'Misc', icon: 'fa fa-fw fa-user-secret', routerLink: ['/misc']}
            //     ]
            // }
            // {roles   :   ['ROLE_ADMIN'],
            //     disabled: true,
            //     label: 'Template Pages', icon: 'fa fa-fw fa-life-saver',
            //     items: [
            //         {label: 'Empty Page', icon: 'fa fa-fw fa-square-o', routerLink: ['/empty']},
            //         {label: 'Landing Page', icon: 'fa fa-fw fa-globe', url: 'assets/pages/landing.html', target: '_blank'},
            //         {label: 'Login Page', icon: 'fa fa-fw fa-sign-in', url: 'assets/pages/login.html', target: '_blank'},
            //         {label: 'Error Page', icon: 'fa fa-fw fa-exclamation-circle', url: 'assets/pages/error.html', target: '_blank'},
            //         {label: '404 Page', icon: 'fa fa-fw fa-times', url: 'assets/pages/404.html', target: '_blank'},
            //         {label: 'Access Denied Page', icon: 'fa fa-fw fa-exclamation-triangle',
            //           url: 'assets/pages/access.html', target: '_blank'}
            //     ]
            // }
            // {roles   :   ['ROLE_ADMIN'],
            //     disabled: true,
            //     label: 'Menu Hierarchy', icon: 'fa fa-fw fa-gg',
            //     items: [
            //         {
            //             label: 'Submenu 1', icon: 'fa fa-fw fa-sign-in',
            //             items: [
            //                 {
            //                     label: 'Submenu 1.1', icon: 'fa fa-fw fa-sign-in',
            //                     items: [
            //                         {label: 'Submenu 1.1.1', icon: 'fa fa-fw fa-sign-in'},
            //                         {label: 'Submenu 1.1.2', icon: 'fa fa-fw fa-sign-in'},
            //                         {label: 'Submenu 1.1.3', icon: 'fa fa-fw fa-sign-in'},
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 1.2', icon: 'fa fa-fw fa-sign-in',
            //                     items: [
            //                         {label: 'Submenu 1.2.1', icon: 'fa fa-fw fa-sign-in'},
            //                         {label: 'Submenu 1.2.2', icon: 'fa fa-fw fa-sign-in'}
            //                     ]
            //                 },
            //             ]
            //         },
            //         {
            //             label: 'Submenu 2', icon: 'fa fa-fw fa-sign-in',
            //             items: [
            //                 {
            //                     label: 'Submenu 2.1', icon: 'fa fa-fw fa-sign-in',
            //                     items: [
            //                         {label: 'Submenu 2.1.1', icon: 'fa fa-fw fa-sign-in'},
            //                         {label: 'Submenu 2.1.2', icon: 'fa fa-fw fa-sign-in'},
            //                         {label: 'Submenu 2.1.3', icon: 'fa fa-fw fa-sign-in'},
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 2.2', icon: 'fa fa-fw fa-sign-in',
            //                     items: [
            //                         {label: 'Submenu 2.2.1', icon: 'fa fa-fw fa-sign-in'},
            //                         {label: 'Submenu 2.2.2', icon: 'fa fa-fw fa-sign-in'}
            //                     ]
            //                 },
            //             ]
            //         }
            //     ]
            // },
            // ,{
            //     disabled: true,
            //     label: 'Documentation', icon: 'fa fa-fw fa-book',
            //     items: [
            //         {label: 'Setup', icon: 'fa fa-fw fa-gear', routerLink: ['/documentation']},
            //         {label: 'Utils', icon: 'fa fa-fw fa-wrench', routerLink: ['/utils']}
            //     ]
            // }
        ];
    }

    ngAfterViewInit() {
        // setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }
    changeLayout(layout) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
    }

    onMenuClick() {
        if (!this.app.isHorizontal()){ setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 450); }
        this.app.onMenuClick();
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
           
            <li  *ngIf="hasPermission(child) && hasModule(child)" [ngClass]="{'active-menuitem': isActive(i)}">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target" (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span [innerHTML]="child.label | translate"></span>
                    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="child?.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{child.badge}}</span>
                </a>
                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                    [queryParams]="child.queryParams ? child.queryParams : null"
                    [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                    (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span [innerHTML]="child.label | translate"></span>
                    <i class="fa fa-fw fa-angle-down" *ngIf="child?.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" [roles]="roles" [modules]="modules" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="isActive(i) ? 'visible' : 'hidden'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
			        trigger('children', [
			            state		('visible', 			style({ height: '*' })							),
			            state		('hidden', 				style({height: '0px'})							),
			            transition	('visible => hidden', 	animate('100ms cubic-bezier(0.86, 0, 0.07, 1)')	),
			            transition	('hidden => visible', 	animate('100ms cubic-bezier(0.86, 0, 0.07, 1)')	)
			        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item		: MenuItem;
    @Input() root		: boolean;
    @Input() visible	: boolean;
    @Input() roles      : any [] = [] || [];
	@Input() modules    : any [] = [] || [];
	
    _reset: boolean;
    _parentActive: boolean;
    activeIndex: number;
    hover: boolean;

    constructor(
		public app		: AppComponent, 
		public router	: Router, 
		public location	: Location, 
		public appMenu	: AppMenuComponent
	){}

    /**
     * Check if the current user has the permissions to enter
     * @param child item menu
     */
    hasPermission(child)    {	// const findAdmin =   this.roles.find(roles => roles == 'ROLE_ADMIN');
                            	// if(findAdmin)	{	return true;	}
                                if(!child.roles){	return false;	}
                                return child.roles.some(role => this.roles.find(myRole => myRole == role));                                
                            }

    /**
     *  Check if the company has the module and section hired
     * @param child item menu
     */
    hasModule(child)        {	const findAdmin     =   this.roles.find(roles => roles == 'ROLE_ADMIN');
						        if(findAdmin || !child.modules || !this.modules)   { return true;      } //if no module needed hired or admin profile, return true
						        const moduleHired   =   this.modules.find(module => child.modules.some(myModule => myModule == module.value));
						        console.log('MODULE HIRED', moduleHired);
						        if(!moduleHired)    {   return false;   };
						        if(!child.sections) {   return true     };
						        const moduleSectionHired    =  moduleHired.sections.some(section => child.sections.some(childSection => childSection == section.value));
						        return moduleSectionHired;
						    }
    getChilds(items)        {    return items;            								}
    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) 		{	this.app.menuHoverActive = !this.app.menuHoverActive;	}
        // avoid processing disabled items
        if (item.disabled) 	{	event.preventDefault();	return true;					}

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex === index) ? null : index;
        }

        // execute command
        if (item.command) 	{	item.command({originalEvent: event, item: item});		}

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            // setTimeout(() => {	this.appMenu.layoutMenuScrollerViewChild.moveBar();	}, 450 );
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
			this.app.resetMenu = this.app.menuMode === 'horizontal' ? true: false;
            if (this.app.isMobile() || this.app.menuMode === 'overlay' || this.app.menuMode === 'popup') {
                this.app.menuActive = false;
            }
            this.app.menuHoverActive = false;
        }
    }

    onMouseEnter(index: number) {
		if (this.root 					&&
			this.app.menuHoverActive 	&& 
			this.app.isHorizontal()		&&
			!this.app.isMobile() 		&& 
			!this.app.isTablet()
		) {	this.activeIndex = index;		}
    }

    isActive(index: number)	: boolean 			{	return this.activeIndex === index;			}
    @Input() 	get reset()	: boolean			{	return this._reset;							}
    			set reset(val: boolean) 		{	this._reset = val; if (this._reset && (this.app.menuMode === 'horizontal')) { this.activeIndex = null;	}}
    @Input() 	get parentActive(): boolean 	{	return this._parentActive;					}
    			set parentActive(val: boolean) 	{	this._parentActive = val; if (!this._parentActive) { this.activeIndex = null; }}
}
