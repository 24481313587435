// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  	production: false,
  	// firebase: {
    // 	apiKey					: "AIzaSyBHaAkjwqnRHC3tilci32mtOzJFjcYUxHE",
	//     authDomain				: "trasmedtour2018.firebaseapp.com",
	//     databaseURL				: "https://trasmedtour2018.firebaseio.com",
	//     projectId				: "trasmedtour2018",
	//     storageBucket			: "trasmedtour2018.appspot.com",
	// 	messagingSenderId		: "101234599785"
	// },
	// firebaseSecondary :	{
	// 	apiKey					: "AIzaSyBHaAkjwqnRHC3tilci32mtOzJFjcYUxHE",
	//     authDomain				: "trasmedtour2018.firebaseapp.com",
	//     databaseURL				: "https://trasmedtour2018.firebaseio.com",
	//     projectId				: "trasmedtour2018",
	//     storageBucket			: "trasmedtour2018.appspot.com",
	// 	messagingSenderId		: "101234599785"
	// }
	firebase : {
		apiKey					: "AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM",
		authDomain				: "tourinia.firebaseapp.com",
		databaseURL				: "https://tourinia.firebaseio.com",
		projectId				: "tourinia",
		storageBucket			: "tourinia.appspot.com",
		messagingSenderId		: "29132584927",
		appId					: "1:29132584927:web:f81909c8b14cc88348f66a",
		measurementId			: "G-NJKQXK3P2L"
	}
};

export const serviceAccount = {
	
}


