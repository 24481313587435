export const menus 	= {
	providers 	:  	{ 	label	: "_PROVIDERS",
						icon	: "file",
						items	:[]
            		},
	
	filters 	:  	{ 	
		label	: "_FILTERS",
		icon	: "file",
		items	:[ 
			{ 	label	: "_FILTER_TRANSPORT", 	
				icon	: 'file', 
				items 	: [
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_PRIVATE", icon: 'file', selected: true },
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_SHUTTLE", icon: 'file', selected: true }
				]
			},
			{ 	label	: "_FILTER_DIRECTION", 	
				icon	: 'file', 
				items 	: [
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_PRIVATE", icon: 'file', selected: true },
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_SHUTTLE", icon: 'file', selected: true }
				]
			},
			{ 	label	: "_FILTER_VERIFY", 	
				icon	: 'file', 
				items 	: [
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_PRIVATE", icon: 'file', selected: true },
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_SHUTTLE", icon: 'file', selected: true }
				]
			},
			{ 	label	: "_FILTER_STATUS", 	
				icon	: 'file', 
				items 	: [
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_PRIVATE", icon: 'file', selected: true },
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_SHUTTLE", icon: 'file', selected: true }
				]
			},
			{ 	label	: "_FILTER_ERRORS", 	
				icon	: 'file', 
				items 	: [
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_PRIVATE", icon: 'file', selected: true },
					{ name: 'filter_transport_private', label: "_FILTER_TRANSPORT_SHUTTLE", icon: 'file', selected: true }
				]
			}
		]
	},

    bookings 	:  	{ 	label	: "_BOOKINGS",
						icon	: "file",
						items	:[ 
							{ 	name: 'import_bookings', 	label: "_IMPORT_BOOKINGS", 		icon: 'file' 	},
							{ 	name: 'check_bookings', 	label: "_CHECK_BOOKINGS", 		icon: 'file' 	},
							{ 	name: 'check_flights',		label: "_CHECK_FLIGHTS", 		icon: 'plane' 	},
							{ 	name: 'autoverify_bookings',label: "_AUTOVERIFY_BOOKINGS", 	icon: 'file' 	},
							{ 	name: 'new_booking', 		label: "_NEW_BOOKING", 			icon: 'file' 	},
							{ 	name: 'clean_bookings', 	label: "_CLEAN_BOOKINGS", 		icon: 'file' 	},
							{ 	name: 'external_reload', 	label: "_EXTERNAL_RELOAD", 		icon: 'file' 	},
							{ 	name: 'local_reload', 		label: "_LOCAL_RELOAD", 		icon: 'file' 	},
							{ 	name: 'clear_pending', 		label: "_CLEAR_PENDING", 		icon: 'file' 	},
							{ 	name: 'autoverify_forced',	label: "_AUTOVERIFY_FORCED", 	icon: 'file' 	},
							{	name: 'extract_mappings',	label: "_EXTRACT_MAPPINGS",		icon: 'file'	},
							{	name: 'persist_all',		label: "_PERSIST_BOOKINGS",		icon: 'file'	},
							{ 	label	: "_EXPORT",
								icon	: 'file',
								items : [
									{ name: 'export_pdf', 		label: "_EXPORT_PDF", 		icon: 'file' },
									{ name: 'export_csv', 		label: "_EXPORT_CSV", 		icon: 'file' }
								]
							}
						]
                	},

	resorts		:  	{ 	label	: "_RESORTS",
						icon	: "file",
						items	:[ 
							{ 	name: 'import_resorts', 	label: "_IMPORT_RESORTS", 		icon: 'home' 	},
							{ 	name: 'clone_resorts', 		label: "_CLONE_RESORTS", 		icon: 'home' 	}					
						]
					},

	flights		:  	{ 	label	: "_FLIGHTS",
						icon	: "plane",
						items	:[ 
							{ 	name: 'reload_flights', 	label: "_RELOAD_FLIGHTS", 		icon: 'plane' 	},
							{ 	name: 'check_flights', 		label: "_CHECK_FLIGHTS", 		icon: 'plane' 	},					
							{ 	name: 'reload_airlines', 	label: "_RELOAD_AIRLINES", 		icon: 'plane' 	}					
						]
					},

	fixes 		:  { 	label	: "_FIXES",
						icon	: "file",
						items	:[ 
							{ 	name: 'fixes_flights', 		label: "_FIXES_FLIGHTS", 	icon: 'gear' },
							{ 	name: 'fixes_locations', 	label: "_FIXES_LOCATIONS", 	icon: 'gear' }							
						]
					},
				

	transporters :  { 	label	: "_TRANSPORTERS",
						icon	: "file",
						items	:[ 
							{ 	name: 'transporter_send_assigned', 	label: "_TRANSPORTER_SEND_ASSIGNED", 	icon: 'send' }
						]
					},

	view		:  	{ 	label	: "_VIEW_ITEM",
						icon	: "file",
						items	:[ 
							{ 	name: 'view_filters', 	label: "_VIEW_FILTERS", 	icon: 'eye' }
						]
					},

	exports		:  	{ 	label	: "_EXPORTS",
						icon	: "file",
						items	:[ 
							{ name: 'export_pdf', 		label: "_EXPORT_PDF", 		icon: 'file' },
							{ name: 'export_csv', 		label: "_EXPORT_CSV", 		icon: 'file' }						
						]
					},

	help		:  	{ 	name	: "help",
						label	: "_HELP",
						icon	: "file"
	}
}