import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class EntityService {

    // areas	: any[] 	= [];
    // areaList	: string[]	= [];
	
    entities	: any = {};
	totals      : any = {};
	digests		: any = {};
    lists		: any = {};
    urls		: any = {};
    defaults    : any = {};

    constructor(
        private http: HttpClient
    ) {
        // this.setUrl('signIn',     	            'server',   '/admin/user/multiple/?'                       				);
        this.setUrl('signIn',     	                'server',   '/entity/user/multiple/?'                       			);
        
        this.setUrl('hotels',               		'server',   '/entity/hotel/?format=json&order=name'	                    );
        this.setUrl('hotel_update',                 'server',   '/entity/hotel/?'                                           );
        this.setUrl('lodging_update',               'server',   '/entity/hotel/?'                                           );
        
        this.setUrl('save_booking_log',             'server',   '/dmc/api/v2.0/booking/log?'                                                      );
        this.setUrl('entity',         	  	        'server',   '/entity/{entity}/?format=json'						        );
        this.setUrl('profiles',             		'server',   '/group/list?format=json'	    					        );
		
        this.setUrl('company',             	  		'server',   '/dmc/api/v1.0/dmc/'		    					        );
        this.setUrl('companyInfo',         	  		'server',   '/dmc/api/v1.0/dmc/{id}'    						        );
        this.setUrl('transfer_services',			'server',   '/dmc/api/v1.0/service/'	    			                );
        this.setUrl('invoices',         	  		'server',   '/dmc/api/v1.0/dmc/{id}'        					        );
        this.setUrl('generate_services',        	'server',   '/dmc/api/v1.0/service/'       					            );

        this.setUrl('bank_accounts',         		'server',   '/dmc/api/v1.0/bankaccount/'			    		        );
        
        this.setUrl('generate_transports', 	    	'server',   '/dmc/api/v1.0/booking/calendar/?'             				);

        this.setUrl('bookings_provider_refresh',    'server',   '/dmc/api/v1.0/booking/provider/refresh/?'               	);
        this.setUrl('bookings_send_assigned',     	'server',   '/dmc/api/v1.0/booking/send_assigned/?'               		);
        this.setUrl('bookings_digest',     	    	'server',   '/dmc/api/v1.0/booking/digest/?'               				);
        this.setUrl('booking_validate',     		'server',   '/dmc/api/v1.0/booking/validate/'               			);
		this.setUrl('booking_update',     	    	'server',   '/dmc/api/v1.0/booking/update/'                    			);
		this.setUrl('booking_pickup',     	    	'server',   '/dmc/api/v1.0/booking/pickup/'                    			);
		
        this.setUrl('booking_reject',     		    'server',   '/dmc/api/v1.0/booking/reject/'               				);
		this.setUrl('booking_transporter_assign',   'full',  	'/dmc/api/v1.0/booking/{booking}/transporter/{transporter}' );
		this.setUrl('booking_provider_update',		'server',   '/dmc/api/v1.0/booking/update/'           			        );
		this.setUrl('booking_persist',      		'server',   '/dmc/api/v1.0/booking/'                      				);
        this.setUrl('bookings',             		'server',   '/dmc/api/v1.0/booking/?mode=fastfull'				        );
        this.setUrl('bookings_all',             	'server',   '/dmc/api/v1.0/booking/?mode=fastfull'				        );
        this.setUrl('bookings_pre',             	'server',   '/dmc/api/v1.0/booking/?mode=fastfull'				        );
        this.setUrl('bookings_search',             	'server',   '/dmc/api/v1.0/booking/search/?format=json'				   	);

        this.setUrl('email_exists',             	'server',   '/dmc/api/v1.0/email/{email}'        				        );
        
        this.setUrl('group_solution',       		'server',   '/dmc/api/v1.0/booking/group/solution/?'					);
        this.setUrl('groups',            			'server',   '/dmc/api/v2.0/booking/group/?'								);
        this.setUrl('booking_groups',       		'server',   '/dmc/api/v1.0/booking/group/?'			        			);        
        
        this.setUrl('pov',                      	'server',   '/entity/sight/?format=json'								);
		this.setUrl('routes',               		'assets',	'/assets/demo/data/routes.json'								);
		
		this.setUrl('countries',             		'server',   '/entity/country/?format=json&order=name'				  	);
		// this.setUrl('zones',                		'assets',	'/assets/demo/data/zones.json'								);
        this.setUrl('zones',             			'server',   '/entity/zone/?format=json&order=name'				  		);
        this.setUrl('areas',                		'server',   '/entity/area/?format=json&order=name'		                );
        
        this.setUrl('airlines',	            		'server',   '/entity/airline/?format=json'					            );
        this.setUrl('airports',	            		'server',   '/entity/airport/?format=json'					            );
        
        this.setUrl('flights',	            		'server',   '/entity/flight/?format=json'					);
        this.setUrl('flightsBySkyScanner',     		'full',     'https://gateway.skyscanner.net/arrival-departure-svc/api/airports/{destination}/statuses?locale=es-ES' );

        this.setUrl('transports',	        		'server',   '/entity/transport/?&order_entity=pax&order_dir=DESC'		);
        this.setUrl('fleet',	            		'server',   '/entity/transport/?&order_entity=pax&order_dir=DESC'		);
        
        this.setUrl('transport_companies',			'server',   '/entity/transport/company/?'								);
        this.setUrl('transporters',		        	'server',   '/entity/transport/company/?'								);
        this.setUrl('providers',		        	'server',   '/dmc/api/v1.0/provider/?'				    				);
        
        this.setUrl('drivers',	            		'server',   '/entity/transport/driver/?'		                    	);
        
        this.setUrl('langs',            	    	'server',   '/entity/lang?format=json'					            	);
        
        this.setUrl('sights',	            		'server',   '/entity/sight/?format=json'								);
        this.setUrl('sightId',	            		'server',   '/entity/sight/{id}?format=json'							);
        this.setUrl('sightTypes',	            	'server',   '/entity/sight/type?format=json'							);
        this.setUrl('sightInfo',            		'server',   '/entity/sight/info/{id}?format=json'						);
        this.setUrl('sight_persist',	        	'server',   '/entity/sight/multiple?format=json'						);
        
        this.setUrl('pickups',	            		'server',   '/entity/tour/pickup/?format=json'							);
        this.setUrl('pickup_persist',	      		'server',   '/entity/tour/pickup/multiple?format=json&source=request'	);
        this.setUrl('pickup_types',	            	'server',   '/entity/tour/pickup/types?format=json'						);
        this.setUrl('pickup_zones',	            	'server',   '/entity/tour/pickup/zones?format=json'						);
        
        this.setUrl('resources',	           	 	'server',   '/entity/resource/?format=json'							    );
		this.setUrl('resource',		            	'server',   '/entity/resource/?format=json'							    );
		
		this.setUrl('tour_services',		    	'server',   '/entity/service/?format=json&type=tour'	    			);
        this.setUrl('tour_complements',		    	'server',   '/entity/complement/?format=json&type=tour'	    			);
        
        this.setUrl('tours',	            		'server',   '/entity/tour/?format=json'							    	);
		this.setUrl('tourInfo',	            		'server',   '/entity/tour/info/{id}?format=json'					    );
		this.setUrl('tour_persist',	      	    	'server',   '/entity/tour/multiple?format=json&source=request'			);
        
        this.setUrl('variants',	            		'server',   '/entity/tour/variant/?format=json'					    	);
		this.setUrl('variantInfo',	            	'server',   '/entity/tour/variant/info/{id}?format=json'			    );
		this.setUrl('variant_persist',	      		'server',   '/entity/tour/variant/multiple?format=json&source=request'	);
        
        this.setUrl('htx_update_from_astra',	    'server',   '/dmc/api/v2.0/booking/astra?'			        			);
        this.setUrl('booking_imported_update',      'server',   '/dmc/api/v2.0/booking/update_from_import?'			        );

        this.setUrl("update_bookings_assembly",     'server',   '/dmc/api/v2.0/booking/assembly'                            );
        
        this.setUrl("fomento_proxy",                'server',   '/dmc/api/v2.0/fomento/proxy'                               );
                        

        this.defaults = {    
            defaultCallType		: 'remote',
            _defaultCallType	: 'local',
            servers     		: {
                // remote	: 'https://admin.tourinia.com',
                remote	: 'https://admin.tourinia.com/app_dev.php',
                local	: 'http://tourinia/app_dev.php'
            },
            callType            : {
                'areasmallorca'    		: 'assets',
                'zones'            		: 'assets',
				'routes'           		: 'assets',
                'flightsBySkyScanner'	: 'full',
                // 'group_solution'     	: 'local'
            }
        };
    }

    get		($entity)          								{ return this.entities[$entity]; 																}
    set		($entity, $item)   								{ this.entities[$entity].push($item); 															}
	getTotal($entity)          			 				 	{ return this.totals[$entity];																	}
    setTotal($entity, $total)								{ this.totals[$entity] 		= $total;															}
	getDigest($entity)										{ return this.digests[$entity];																	}
	setDigest($entity,$digest)						    	{ this.digests[$entity] 	= $digest;															}
    clear	($entity)        								{ this.entities[$entity] 	= []; 																}
    getList	($entity)      									{ return this.lists[$entity]; 																	}
    setList	($entity)      									{ this.lists[$entity] = this.entities[$entity].map(item => item.name);  						}
    setUrl	($entity, $type, $url) 							{ this.urls[$entity] = { type: $type, url: $url }; 	    										}
    getUrl	($entity)										{  
        if(undefined===$entity || undefined===this.urls[$entity])	{ console.log("[getUrl] Entity "+$entity+" not found"); return false;							}
        if(undefined===this.urls[$entity].url)                		{ console.log("[getUrl] URL for entity "+$entity+" not defined. Check name!"); return false; 	}
        switch(this.defaults.callType[$entity] || this.defaults.defaultCallType ){
            case 'assets'	:	return this.urls[$entity].url;
            case 'local'	:	return this.defaults.servers['local']+this.urls[$entity].url;
            case 'remote'   :	return this.defaults.servers['remote']+this.urls[$entity].url;
            case 'full'    	:	return this.urls[$entity].url;
        }
    }

    async loadEntity($entity,$params={}) {	
        this.clear($entity);
        let query     	= Object.keys($params).reduce((str,item)=>str+'&'+item+'='+$params[item],this.getUrl($entity));
        let response	= ( await Promise.resolve(this.http.get(query).toPromise()));
        if(undefined==response["data"]){
            return false;
        }
        response['data'].forEach(item => { this.set($entity, item) });
        this.setList($entity);
        this.setTotal($entity, response['total']);
        this.setDigest($entity, response['digest']);
    }

    check($entity, $item) {	
        return this.entities[$entity].some(item => item[$entity] === $item);						
    }
    
    async persistEntity($entity,$params={})	{	
        console.log("POST ENTITY["+$entity+"] URL->"+this.getUrl($entity),"PARAMS->",$params);        
        let res = await this.post(this.getUrl($entity),$params);
        console.log("RESULT->",res);
    }

    /**
     * return request as a promise
     **/                                                           
    getRequest($entity,$params={}) 					        {	
        const url = this.getUrl($entity);
        if(!url){ return null; };
        let query = Object.keys($params).reduce((str,item)=>str+'&'+item+'='+$params[item],this.getUrl($entity));
        // return Promise.resolve(this.http.get(query).toPromise());  
        return this.http.get(query).toPromise();
    }
    
    async getComplexRequest($entity,$tokens={},$params={},$method="GET"){	
        let url		= this.getUrl($entity);
        let uri    	= Object.keys($tokens).reduce((str:string,item)=>str.replace("\{"+item+"\}",$tokens[item]),url);																										
        switch($method){
            default:	
            case "GET"	:	let query	= Object.keys($params).reduce((str,item)=>str+'&'+item+'='+$params[item],uri);
                            return Promise.resolve(this.http.get (query			).toPromise());  
            case "POST"	:	return Promise.resolve(this.http.post(uri,$params	).toPromise()); 
            case "PUT"	:	return Promise.resolve(this.http.put (uri,$params	).toPromise());
        }     
    }
    async post($entity,$params)								{	return Promise.resolve(this.http.post($entity,$params).toPromise());						}
    async postJSON($entity,$params)							{	return Promise.resolve(this.http.post($entity,JSON.stringify($params)).toPromise());		}
    async postXML($entity,$xml)								{	return Promise.resolve(this.http.post($entity,$xml).toPromise());							}
    async delete($entity,$params)                			{	return Promise.resolve(this.http.delete($entity,{ params: $params }).toPromise());        	}
    async deleteById($entity,$tokens={},$params={})			{	
        let uri    	    = Object.keys($tokens).reduce((str:string,item)=>str.replace("\{"+item+"\}",$tokens[item]),this.getUrl($entity));
        let query	    = Object.keys($params).reduce((str,item)=>str+'&'+item+'='+$params[item],uri);
        return Promise.resolve(this.http.delete(query).toPromise());        
    }
}
