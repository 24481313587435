import { StorageService 				} from 'src/app/demo/service/storageservice';
import { EventService 					} from '../../service/eventservice';
import { Component,
		 OnInit,
		 ViewChild,
		 ViewEncapsulation,
		 ChangeDetectorRef,
		 HostListener					} from '@angular/core';
import { MenuItem						} from 'primeng/primeng';
import { MouseEvent 					} from '@agm/core';
import { HttpClient 					} from '@angular/common/http';
import { FirebaseService 				} from '../../service/database/firebase.service';
import { CommonsService 				} from '../../service/commons.service';
import { FlightService 					} from '../../service/flight.service';
import { EntityService 					} from '../../service/entity.service';
import { filters 						} from './data/filters';
import { Router 						} from '@angular/router';
import { tabsInfo 						} from './data/tabs';
import { bookingsCols 					} from '../transports/columns/bookings.columns';

import * as moment 			  			  from 'moment';

interface marker {
	lat				: number;
	lng				: number;
	label?			: string;
	draggable		: boolean;
}

interface Calendar {
	value			: any,
	date			: string,
	last			: string
};

export const servicesGrid	=	{									
	height				: '63vh', 
	border				: '1px solid #f0f0f0', 
	rowExpansionWidth	: '63vw'
};

@Component({
	styleUrls		: [ './checkins2.component.scss' ],
	templateUrl		: './checkins2.component.html',
	encapsulation	: ViewEncapsulation.None
})
export class Checkins2Component implements OnInit  {

	// F2 opens SEARCH
	// HostListener makes some event trigger action
	@HostListener('document:keydown', ['$event'])
    keypress(e: KeyboardEvent) { this.keypressed(e.key); }

	// private inputSearch: ElementRef;
	// @ViewChild("inputsearch") set content(content: ElementRef){ this.inputSearch = content; }
	@ViewChild("search"				) 	searchInput		: any;
	@ViewChild('bookingsGrid'		)	bookingsGrid	: any;

	pageInfo			: any 		= {
		servicesGrid	: {
			height				: '63vh', 
			border				: '1px solid #f0f0f0', 
			rowExpansionWidth	: '63vw'
		}
	};
	userInfo			: any 		= {};
    cols				: any[];
    chartData			: any;
    events				: any[];
    items      			: MenuItem[];
	item				: any 		= {};

	filterCustomer		: string 	= "";

	bookings			: any 		= { cols: bookingsCols, filters: {}, data: [] };
	
	arrivals			: any 		= { list: []};
	departures			: any 		= { list: []};
	services			: any 		= { list: []};
	routes				: any		= { list: []};
	pickups				: any 		= {};
	checkins    		: any 		= {};
	checkouts			: any 		= {};
	issues				: any 		= {};
	notifies			: any 		= {};
	lodgings			: any 		= {};
	sellings			: any 		= {};

	notifications		: any		= { "inbox" : [], "outbox" : [] }

	flights				: any 		= { current: {}};
	groups				: any 		= {};

	trends				: any 		= [];
	alloweds			: any 		= {};
	fleet				: any 		= { cols: [], filters: {} };
	drivers				: any 		= { cols: [], filters: {}, trends: {} };

	searchOpened		: boolean 	= false;

	markers				: marker[] 	= [
		{ lat: 51.673858, lng: 7.815982, label: 'A', draggable: true 	},
		{ lat: 51.373858, lng: 7.215982, label: 'B', draggable: false 	},
		{ lat: 51.723858, lng: 7.895982, label: 'C', draggable: true 	}
	]

	constructor(
		private changeDetector	: ChangeDetectorRef,
		private http			: HttpClient,
        private commons			: CommonsService,
        private flightService	: FlightService,
		private entityService	: EntityService,
		private firebaseService	: FirebaseService,
		private eventService	: EventService,
		private router			: Router,
		private storage			: StorageService
	) {
	}

    async ngOnInit() {
		console.log('on init');
		const rootOutletMap : any = (<any>this.router).outletMap;
		this.init();
		this.loadEntities();
		await this.initFilters();
		this.initMenu();
		this.generateNotifications();
		// this.subscribeToServices({ date: "2023-09-19" });
		// this.subscribeToExternals();
		this.loadBookings();
	}

	/**
	 * load bookings
	 *
	 * @param $entity
	 * @returns
	 */
	async loadBookings()
	{
		if(!this.pageInfo.calendar.date){ return false; }
		let $entity					=	"bookings";
		this.pageInfo.loadingData	=	true;


		this.pageInfo.search.content				=	"";
		this.pageInfo.areas							= this.commons.userInfo.tourinia_resorts || [];
		this.pageInfo.provider_areas_to_tourinia 	= this.commons.userInfo.provider_mappings;
		this.pageInfo.providerAreasMapped			= this.commons.userInfo.provider_mappings;

		if(undefined==this.pageInfo.provider_areas_to_tourinia){
			this.commons.generateToastError("_PROVIDER_AREAS_NOT_FOUND");
			return false;
		}

		this[$entity].data							=	[];

		let params			= {
			offset			: 1
		};

		params["filters"]	= {
			transferType	: [ "private"	, "shuttle" 					],
			direction		: [ "arrivals"	, "departures" 					],
			// verified		: [ "verified"	, "not_verified" 				],
			verified		: [ "verified"	 								],
			status			: [ "original"	, "rectified"	, "ammended"	],
			errors			: [ "errors"	, "not_errors" 					]
		}

		params["filters"]	= JSON.stringify(params["filters"]);

		console.log("PARAMS",params);

		// Clear all the rest
		// this.clearGroups		({ message: false });
		// this.clearServices		({ message: false });
		// this.clearRowVehicles	({ message: false });
		// this.clearDrivers		({ message: false });

		const activeProviders = [ 1 ];

		await Promise.all(activeProviders.map(async provider => {
			switch(provider){
				default	: params["startdate"] = this.pageInfo.calendar.date;	break;
			}
			await this.entityService.loadEntity(
				$entity,
				{
					...params,
					...{
						providers	: [ provider ],
						count		: 1,
						dmc			: this.commons.userInfo.currentDmc.id,
						destination	: this.commons.userInfo.currentDestination.dbid				
					}
				}
			)
		}));

		// Only for 1 provider
		// Change to allow multiple providers
		let providers = {
			1	: "HTX",
			2	: "HOP",
			15	: "WTR"
		};

		const init_service_time		= 	this.getInfo("init_service_time",{});
		let nextDay 				= 	this.commons.nextDay(this.pageInfo.calendar.date).format("YYYY-MM-DD");

		// Get already loaded bookings
		let data 					=	this.entityService.get($entity);
		// data						=	data.slice(0,50);

		// Direction normalizing fields
		this[$entity].data			=	this.normalizeBookings({
											data				: data,
											calendar_date		: this.pageInfo.calendar.date,
											nextDay				: nextDay,
											providers			: providers,
											init_service_time	: init_service_time,
											only_verified		: true,
											show_cancelled		: false
										});

		this[$entity].count			=	(this[$entity].data||[]).length;											
		this[$entity].spinner		=	false;
		this.pageInfo.loadingData	=	false;

		// this.generateFlights(this[$entity].data);
				
		this.commons.generateToast("Bookings","_BOOKINGS_LOADED","info");
	}

	/**
	 * adapt booking upon conditions
	 */
	normalizeBookings($params)
	{
		let bookings 			= $params.data || [];
		let calendar_date		= $params.calendar_date;
		let nextDay				= $params.nextDay;
		let providers			= $params.providers;
		let init_service_time	= $params.init_service_time;
		let only_verified		= $params.only_verified;
		let show_cancelled		= $params.show_cancelled;
		let provider_mappings 	= this.commons.userInfo.provider_mappings 	|| {};
		
		// Remove not verified if needed
		bookings	= 	bookings.filter(b=>{
			switch(only_verified){
				case true	: return b.verified;
			}
			return true;
		});

		// Split bookings with both directions on the same day
		bookings	= this.splitBothBookings({
			bookings		: bookings,
			calendar_date	: calendar_date,
			nextDay			: nextDay
		});

		const moment= this.commons.getMoment();

		bookings 	= bookings.map(item=>{
			
			try {
				let current_provider_mappings = provider_mappings[item.provider];

				if(!item.provider){
					console.log("[normalizeBookings] no provider for item",(item||{}).reference);
					return item;
				}

				item.booking_date = moment(item.date,"YYYYMMDD").format("YYYY-MM-DD");

				switch(item.direction)
				{
					case "arrival"	:
						// item.transporter_location_id	= item.arrival_canaryshuttle_lodging;
						item.date 						= item.arrival_Date;
						item.time						= item.arrival_Time;
						item.driver						= item.arrival_Driver;
						item.vehicle					= item.arrival_Vehicle;
						item.transporter				= item.arrival_Transporter_Name;
						item.transporter_status			= item.arrival_Transporter_Status;
						item.transporter_pending		= item.arrival_Transporter_Pending;
						item.plate						= item.arrival_Plate;
						item.group						= item.arrival_Group;
						item.area						= item.area || item.arrival_Area || item.arrival_To;
						item.initMoment					= this.commons.getDate(item.date+" "+item.time);
						item.init_timestamp				= item.initMoment.unix();
						item.fomento_id					= item.arrival_Fomento_Id;
						item.fomento_status 			= item.arrival_Fomento_Status;
						item.flight						= item.arrival_GatewayInfo;
						item.environment				= item.arrival_Environment;
						// item.location					= item.arrival_Location;

						// Set Tourinia resort if not
						if(undefined==item.arrival_resort_tourinia){
							item.arrival_resort_tourinia = current_provider_mappings[item.area];
						}

						// Set municipality if not
						if(undefined==item.arrival_municipality){
							if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
								item.arrival_municipality 	= this.commons.userInfo.tourinia_resorts_2_areas[item.arrival_resort_tourinia];
							}
						}

						// Set zone if not
						if(item.arrival_municipality){
							item.arrival_Zone			= this.commons.userInfo.tourinia_areas_2_zones[item.arrival_municipality];
							item.zone					= this.commons.userInfo.tourinia_areas_2_zones[item.arrival_municipality];
						}
						break;

					case "departure":
						// item.transporter_location_id	= item.departure_canaryshuttle_lodging;
						item.date 						= item.departure_Date;
						item.time						= item.departure_Time;
						item.driver						= item.departure_Driver;
						item.transporter				= item.departure_Transporter_Name;
						item.transporter_status			= item.departure_Transporter_Status;
						item.transporter_pending		= item.departure_Transporter_Pending;
						item.vehicle					= item.departure_Vehicle;
						item.plate						= item.departure_Plate;
						item.group						= item.departure_Group;
						item.area						= item.area || item.departure_Area || item.departure_From;
						item.initMoment					= this.commons.getDate(item.date+" "+item.time);
						item.init_timestamp				= item.initMoment.unix();
						item.fomento_id					= item.departure_Fomento_Id;
						item.fomento_status 			= item.departure_Fomento_Status;
						item.flight						= item.departure_GatewayInfo;
						item.environment				= item.departure_Environment;
						// item.location					= item.departure_Location;

						// Set Tourinia resort if not
						if(undefined==item.departure_resort_tourinia){
							item.departure_resort_tourinia = current_provider_mappings[item.area];
						}

						// Set municipality if not
						if(undefined==item.departure_municipality){
							if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
								item.departure_municipality = this.commons.userInfo.tourinia_resorts_2_areas[item.departure_resort_tourinia];
							}
						}

						// Set zone if not
						if(item.departure_municipality){
							item.departure_Zone			= this.commons.userInfo.tourinia_areas_2_zones[item.departure_municipality];
							item.zone					= this.commons.userInfo.tourinia_areas_2_zones[item.departure_municipality];
						}
						break;
				}

				item.provider_name		= providers[item.provider];
			}catch(e){
				console.log(`[normalizeBookings] Error ${e}`);
			}

			return item;
		})

		// Filter by date
		bookings =	bookings.filter(item=>{
			switch(item.direction){
				case "arrival":
					item.location = item.location || item.arrival_Location;
					if(item.date == this.pageInfo.calendar.date 	&& item.arrival_Time<init_service_time){ return false;	}
					if(item.date == nextDay			 				&& item.arrival_Time>init_service_time){ return false; 	}
					break;
					
				case "departure":
					item.location = item.location || item.departure_Location;
					if(item.date == nextDay && item.departure_Time>=init_service_time){	return false;	}
					break;
			}

			// Bypass if not verified
			// if(!only_verified && !item.verified){ return true; }

			return true;
		});

		// Get booking area
		bookings.forEach(b=>{

			// Bypass if not verified
			if(!only_verified && !b.verified){ return; }
			
			if(!b.provider){ 
				console.log("[normalizeBookings] Error. booking with no provider");
				return false; 
			}

			switch(b.direction){
				// Is BOTH possible ?????
				case "both"		: break;
				case "arrival"	: b.area = b["arrival_To"	] || b["arrival_Area"	] || b["area"			]; break;
				case "departure": b.area = b["area"			] || b["departure_Area"	] || b["departure_From"	]; break;
			}

			const provider_areas = this.pageInfo.provider_areas_to_tourinia[b.provider];
			if(undefined==provider_areas){
				console.log(`[normalizeBookings] Provider areas not found`);
				return false;
			}
			b.tourinia_area = provider_areas[b.area];
	
			switch(b.shared){
				case "private":
					b.group_pax = b.pax;
					break;
			}
		});

		bookings =	bookings.filter(item=>{
			// Bypass if not verified
			if (only_verified) { return item.verified }
			return true;
		});

		return bookings;
	}

	private splitBothBookings($info):any[]
	{
		let bookings 		= $info.bookings;
		let calendar_date	= $info.calendar_date;
		let nextDay			= $info.nextDay;

		bookings.forEach(item=>{
			item.direction 		= item.direction || item.mode;
			item.real_direction	= undefined;
			switch(item.direction){
				case "arrival"	: return;
				case "departure": return;
				case "both"		:

					item.real_direction	= "both";
					item.has_arrival 	= item.arrival_Date	 == calendar_date || item.arrival_Date	== nextDay;
					item.has_departure	= item.departure_Date== calendar_date || item.departure_Date== nextDay;

					// Only arrival for current day
					if(item.has_arrival && !item.has_departure){
						item.real_reference		= item.reference;
						item.reference			= item.reference+"_arrival";
						item.reference_service	= item.reference;				
						item.direction 			= "arrival";
					}

					// Only departure for current day
					if(!item.has_arrival && item.has_departure){
						item.real_reference		= item.reference;
						item.reference			= item.reference+"_departure";
						item.reference_service	= item.reference;
						item.direction 			= "departure";
					}

					if(!item.has_arrival && !item.has_departure){
						console.log("[splitBothBookings] Booking with both direction but no one valid");
						return false;
					}

					// Arrival and departure for current day
					// Split into arrival and departure
					if(item.has_arrival && item.has_departure){
						// Create new arrival
						let arrival_item 					= JSON.parse(JSON.stringify(item));
						
						arrival_item.real_reference 		= arrival_item.reference;
						arrival_item.direction				= "arrival";
						arrival_item.reference				= arrival_item.reference+"_arrival";
						arrival_item.departure_PickupTime	= undefined;
						
						bookings.push(arrival_item);

						// Create new departure
						let departure_item	= JSON.parse(JSON.stringify(item));
						
						departure_item.real_reference 		= departure_item.reference;
						departure_item.direction			= "departure";
						departure_item.reference			= departure_item.reference+"_departure";
						
						bookings.push(departure_item);

						// Remove original item
						bookings = bookings.filter(b=>b.reference!=item.reference);
					}
					break;
			}
		});
		return bookings;
	}
		
	generateNotifications(){
		this.pageInfo.notifications.push({ type	: "info", 		title	: "_INFO_F2" 		});
		this.pageInfo.notifications.push({ type	: "info", 		title	: "_INFO_ESCAPE" 	});
		this.pageInfo.notifications.push({ type	: "service", 	title	: "_SERVICES_INIT" 	});	
		this.pageInfo.notifications.push({ type	: "flight", 	title	: "_FLIGHTS_INIT" 	});		
	}

	init(){
		this.pageInfo.tabs 			= tabsInfo;
		this.pageInfo.servicesGrid	= servicesGrid;

		this.alloweds = {
			trendsRow		: false,
			trendsCol		: false,
			notifications	: true,
			flights			: false,

			services		: false,	
			customers		: true,

			arrivals		: true,
			departures		: true,		
			
			lodgings		: false,
			drivers			: false,
			fleet			: false,
			

			pickups			: true,
			inbox			: true,
			outbox			: true,
			charts			: false,
			panel			: true,
			table			: true,
			calendar		: true,
			activity		: true,
			map				: true,
			chat			: true,
			contact			: true,

			customer		: {
				overlay : {
					info		: true,
					original	: true,
					relocate	: true,
					upgrades	: false,
					tours		: false,
					activities	: false,
					shows		: false,
					map			: false,
					issues		: false,
					chat		: false
				}
			}
		};

		this.lodgings["list"]       = [
			{ type: "hotel", zone: "Norte", area: "Pto. Alcudia", name: "Elegance Diana", 	pax: 45, arrivals: 15, departures: 26 },
			{ type: "hotel", zone: "Norte", area: "Pto. Alcudia", name: "Beach Inn", 		pax: 32, arrivals: 21, departures: 23 },
			{ type: "hotel", zone: "Norte", area: "Pto. Alcudia", name: "Can Simo", 		pax: 54, arrivals: 32, departures: 22 },
			{ type: "hotel", zone: "Norte", area: "Pto. Alcudia", name: "Villa Barbara", 	pax: 47, arrivals: 18, departures: 18 },
			{ type: "hotel", zone: "Norte", area: "Pto. Alcudia", name: "Long Beach", 		pax: 63, arrivals: 27, departures: 29 },
		];

        this.cols = [
            { field: 'vin', header: 'Vin' },
            { field: 'year', header: 'Year' },
            { field: 'brand', header: 'Brand' },
            { field: 'color', header: 'Color' }
        ];

        this.chartData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: '#FFC107'
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: '#03A9F4'
                }
            ]
        };

        this.items = [
            {label: 'Save', 	icon: 'fa fa-check'},
            {label: 'Update', 	icon: 'fa fa-refresh'},
            {label: 'Delete', 	icon: 'fa fa-trash'}
		];

		this.pageInfo 	= {
			map_services	: [],
			panels			: {},
			selecteds		: {},
			service_id		: 1,
			search 		: 	{
				show	: 	false,
				content	: 	"",
				fields	: 	[
								"reference",
								"customer"
							]
			},
			map				:	{
				// MALLORCA MAP
				zoom		: 10,
				lat			: 39.6083663,
				lng			: 2.8692464
			},
			tabs			:	tabsInfo,
			loadingItems	:	{},
			heights			: { cols: '70vh' },
			upgrade			: {
				reasons	: [
					{ label: "Flight delay", 		value: 1 },
					{ label: "Flight Cancelation", 	value: 2 }
				]
			},
			interval 		:	60000,
			filters			:	{},
			imgs			:	{
				'airport'	:	{
					url			:"assets/demo/images/maps/airportMap.png",
					scaledSize	: {
						width		: 50,
						height		: 50
					}
				},
				'transport'	:	{
					url			:"assets/demo/images/maps/shuttleMap.png",
					scaledSize	: {
						width		: 50,
						height		: 50
					}
				},
				'lodging'	:		{
					url			:"assets/demo/images/maps/hotelMap.png",
					scaledSize	: {
						width		: 50,
						height		: 50
					}
				}
			}
		}

		this.pageInfo.calendar 					= <Calendar>{ last: '', date: this.commons.getToday("YYYY-MM-DD") };
		this.pageInfo.currentVehicles 			= [];
		this.pageInfo.currentExternals 			= [];
		this.pageInfo.currentDrivers			= [];
		this.pageInfo.currentServices			= [];
		this.pageInfo.currentCustomers			= [];
		this.pageInfo.currentFlights			= [];
		this.pageInfo.notifications 			= [];
		this.pageInfo.intervals 				= [];
		this.userInfo							= ( this.storage.getItem('dmcSuite') || {} ).userInfo;
	}

	getInfo($entity, $info){
		switch($entity){
			case "customerForm"	:	switch($info.type){
									case "tabs":	return this.pageInfo.tabs.items.customerForm.items;
								}
								break;

			case "flights"	:	switch($info.type){
									case "tabs":	return this.pageInfo.tabs.items.flights.items;
								}
								break;

			case "vehicles"	:	switch($info.type){
									case "tabs"		:	return this.pageInfo.tabs.items.vehicles.items;
									case "selected"	:	return this.pageInfo.selecteds["vehicle"]==$info.item?'item-selected':'';
								}
								break;

			case "externals"	:	switch($info.type){
									case "tabs"		:	return this.pageInfo.tabs.items.externals.items;
									case "selected"	:	return this.pageInfo.selecteds["external"]==$info.item?'item-selected':'';
								}
								break;

			case "drivers"	:	switch($info.type){
									case "tabs":	return this.pageInfo.tabs.items.drivers.items;
								}
								break;

			case "services"	:	switch($info.type){
									case "tabs"		:	return this.pageInfo.tabs.items.services.items;
									case "selected"	:	return this.pageInfo.selecteds["service"]==$info.item?'item-selected':'';
								}
								break;

			case "customers":	switch($info.type){
									case "tabs":	return this.pageInfo.tabs.items.customers.items;
								}
								break;

			case "customer":	switch($info.type){
									case "tabs"		:	return this.pageInfo.tabs.items.customer.items;
								}
								break;

			case "service"	:	switch($info.field){
									case "avatar":	return "/assets/layout/images/drivers/0.png";
								}
								break;
		}
	}

	async subscribeToExternals($params={}){
		if(undefined==this.commons.userInfo.dmc			){ return false; }
		if(undefined==this.commons.userInfo.destination	){ return false; }

		this.pageInfo.date	= $params["date"]?$params["date"]:this.commons.getNow("YYYY-MM-DD");
		let transporters	= "dmcs/"+this.commons.userInfo.dmc.id+"/destinations/"+this.commons.userInfo.destination.id+"/transporters/";

		this.firebaseService.subscribeEntity(transporters).subscribe(items=>{
			this.pageInfo.currentExternals = [];
			items.forEach(item=>{
				item["name"		] = item["company_name"];
				item["selected"	] = false;
				this.pageInfo.currentExternals.push(item);
			});
		});
	}

	async subscribeToServices($params={}){

		if(undefined==this.commons.userInfo.dmc			){ return false; }
		if(undefined==this.commons.userInfo.destination	){ return false; }

		this.pageInfo.date		= $params["date"]?$params["date"]:this.pageInfo.calendar.value;		
		let servicesDoc			= "dmcs/"+this.commons.userInfo.dmc.id+"/destinations/"+this.commons.userInfo.destination.id+"/solutions/"+this.pageInfo.date+"/services/";

		this.firebaseService.subscribeEntityDoc(servicesDoc+"vehicles_"+this.pageInfo.date).subscribe(info=>{
			if(undefined==info){
				this.commons.generateToast("_ROUTES","_NO_VEHICLES_FOUND","error");
				return false;
			}

			console.log("Vehicle items",info);
			if(this.pageInfo.currentServices){
				console.log("Unsubscribe",this.pageInfo.currentServices);
			}

			this.pageInfo.currentVehicles 			= [];
			this.pageInfo.currentDrivers			= [];
			this.pageInfo.currentServices			= [];
			this.pageInfo.currentCustomers			= [];
			this.pageInfo.currentFlights			= [];

			// FLEET SERVICE
			info["items"].forEach(route=>{
				let vehiclePath = servicesDoc+"vehicle_"+route;
				this.firebaseService.subscribeEntityDoc(vehiclePath).subscribe(vehicleInfo=>{
					console.log("vehicle "+route+" data",vehicleInfo);

					// Add vehicle
					this.pageInfo.currentVehicles.push(vehicleInfo["row"]);

					// this.pageInfo.notifications.push({
					// 	...vehicleInfo["row"],
					// 	...{
					// 		type	: "service",
					// 		title	: "_SERVICE_DETECTED"
					// 	}
					// });

					// Add driver
					let driverExists = this.pageInfo.currentDrivers.some(driver=>driver.name==vehicleInfo["row"].driver);
					if(!driverExists){
						this.pageInfo.currentDrivers.push({
							name: vehicleInfo["row"].driver
						});
					}

					// Process all services
					(vehicleInfo["row"].items||[]).forEach(route=>{
						// Get route info
						route.id				= this.pageInfo.service_id++;
						route.driver 			= vehicleInfo["row"].driver;
						route.vehicle			= vehicleInfo["row"].name;
						route.plate				= vehicleInfo["row"].plate;

						// FAKE !!!
						route.service_status	= "finished";

						switch(route.direction){
							case "arrival"	: 	//route.time = route.firstTime;
												route.time = route.pickupTime;
												break;
							case "departure": 	route.time = route.departure_PickupTime;
												break;
						}
						this.pageInfo.currentServices.push(route);

						let found;

						// Get customer info
						(route.bookingsInfo||[]).forEach(customer=>{
							customer.service_id 		= route.id;
							customer.customer_status	= "_STATUS_UNKNOWN";
							
							// Check for flight
							switch(customer.direction){
								case "arrival"	:
									customer.time  	= customer.arrival_PickupTime;
									found 			= this.pageInfo.currentFlights.some(flight=>flight.code==customer.arrival_GatewayInfo);
									if(!found){
										let flight 	= {
											direction	: "arrival",
											code 		: customer.arrival_GatewayInfo,
											from 		: customer.arrival_GatewayFrom,
											to			: customer.arrival_GatewayTo,
											date 		: customer.arrival_Date,
											time 		: customer.arrival_Time,
											status		: "on time"
										};

										this.pageInfo.currentFlights.push(flight);
										// this.pageInfo.notifications.push({
										// 	...flight,
										// 	...{
										// 		type	: 'flight',
										// 		title	: "_FLIGHT_DETECTED"
										// 	}
										// });
									}
									break;

								case "departure":
									customer.time	= customer.departure_PickupTime;
									found 			= this.pageInfo.currentFlights.some(flight=>flight.code==customer.departure_GatewayInfo);
									if(!found){
										this.pageInfo.currentFlights.push({
											direction	: "departure",
											code 		: customer.departure_GatewayInfo,
											from 		: customer.departure_GatewayFrom,
											to			: customer.departure_GatewayTo,
											date 		: customer.departure_Date,
											// departure_DateTime
											time 		: customer.departure_Time,
											status		: "on time"
										});
									}
									break;

								case "both"		:
									// Check if arrival date is current date
									if(customer.arrival_Date==this.pageInfo.date){
										customer.time  = customer.arrival_PickupTime;
										found = this.pageInfo.currentFlights.some(flight=>flight.code==customer.arrival_GatewayInfo);
										if(!found){
											this.pageInfo.currentFlights.push({
												direction	: "arrival",
												code 		: customer.arrival_GatewayInfo,
												from 		: customer.arrival_GatewayFrom,
												to			: customer.arrival_GatewayTo,
												date 		: customer.arrival_Date,
												time 		: customer.arrival_Time,
												status		: "on time"
											});
										}
									}

									// Check if departure date is current date
									if(customer.departure_Date==this.pageInfo.date){
										customer.time  = customer.departure_PickupTime;
										found = this.pageInfo.currentFlights.some(flight=>flight.code==customer.arrival_GatewayInfo);
										if(!found){
											this.pageInfo.currentFlights.push({
												direction	: "departure",
												code 		: customer.departure_GatewayInfo,
												from 		: customer.departute_GatewayFrom,
												to			: customer.departure_GatewayTo,
												date 		: customer.departure_Date,
												time 		: customer.departute_Time,
												status		: "on time"
											});
										}
									}
									break;
							}
							this.pageInfo.currentCustomers.push(customer);
						})
					});
					// Extract customer info
					// Extract flight info
				});
			})

			// console.log("Vehicles",	this.pageInfo.currentVehicles	);
			// console.log("Routes",	this.pageInfo.currentServices		);
			// console.log("Customers",this.pageInfo.currentCustomers	);
			// console.log("Flights",	this.pageInfo.currentFlights	);
		});
	}

	ngOnDestroy() {
		console.log('on unsubscribe');
		// Subscribe to DMC
		this.firebaseService.unsubscribeDMC();
	}
// --------------------------------------------------------------------------------------
// MENU
// --------------------------------------------------------------------------------------

	initMenu(){
		let me					= this;
		this.pageInfo.menu		= {};
		console.log("FILTERS",this.pageInfo.filters);
		Object.keys(this.pageInfo.filters).forEach(async entity=>{
			// Each entity could have many filters
			let entityFilters	= this.pageInfo.filters[entity];
			console.log("Generation menu for",entity,entityFilters);
			this.pageInfo.menu[entity]	= [];
			entityFilters.forEach( async filter => {
				let item 		= {
					label		: await this.commons.getTranslate(filter.label),
					icon		: filter.icon?('fa fa-'+filter.icon):'',
					style		: { padding: '5px 10px' },
					items		: []
				};
				filter.items.forEach( async current=> {
					let element	= {
						label		: 	await this.commons.getTranslate(current.label),
						icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
						command		: 	($event) => {
											console.log("MENU ITEM SELECTED",$event);
											me.doMenuAction(current.value,filter);
										}
					}
					item["items"].push(element);
				});
				this.pageInfo.menu[entity].push(item);
			});
		});
	}

	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item,$filter){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		if($filter.selected.some(item=>item===$item)){
			$filter.selected = $filter.selected.filter(item=>item!==$item);
		} else {
			$filter.selected = [...$filter.selected,$item];
		}
		// this.checkFilter($filter);		// Execute filtering
		this.initMenu();				// Regenerate menu to show selection
	}

	async loadEntities() {
		await this.load('drivers');
		await this.load('fleet');
		// await this.load('flights');
		// await this.load('groups');
	}

	getArrayFromHash(hash){ return Object.keys(hash).map(key=>hash[key]); }

// --------------------------------------------------------------------------------------
// FILTERING
// --------------------------------------------------------------------------------------

	/**
	 * Init all filters and exec initial check
	 */
	async initFilters()			{
		this.pageInfo.filters = await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
	}

	checkFilter(filter){
		console.log("Checking Entity",filter.entity,"Filter",filter.name);
		switch(filter.type){
			case "multiple":	this.checkMultipleFilter(filter); 	break;
			default:			this.checkSimpleFilter(filter);		break;
		}
	}

    checkMultipleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){
			console.log("OOPS. Invalid filter",filter);
			return false;
		}

		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= {
															field	: filter.field,
															options	: filter.items.map(item=>item.value),
															selected: filter.selected
														};

		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){
			console.log("OOPS. Invalid filter",filter);
			return false;
		}
		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity){
		let data 	= this[$entity].data			|| [];
		let filters = this[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "verified":	data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "status":		data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "direction":	data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "error":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
				}
			});
		}

		console.log("FILTER DATA LENGTH->",data.length);

		this[$entity].filteredData	= data;
		this[$entity].count 		= this[$entity].filteredData ? this[$entity].filteredData.length : 0;
	}

	filterPending(filter){}

	getNotifications(){
		return ( this.pageInfo.notifications || [])
				.slice(0,10);
	}

	getServices(){
		return ( this.pageInfo.currentVehicles || [] )
					.sort((a,b)=>a.name>=b.name?1:-1);
	}

	getExternals(){
		return 	( this.pageInfo.currentExternals || [] )
					.sort((a,b)=>a.name>=b.name?1:-1);
	}

	getCurrentServices($params){
		const 	type			= $params["type"]
		let 	serviceFilters 	= this.pageInfo.filters.service || {}; 
					
		// let		services		= ( this.pageInfo.currentServices || [] )
		let 	services		= (this.bookings.data || [])
			.filter(item=>{
				switch($params["type"]){
					case "all"			: return true;
					case "arrivals"		: return item["direction"] == "arrival";
					case "departures"	: return item["direction"] == "departure";
				}
			})
			.filter(item=>{
				if(this.pageInfo.selecteds["vehicle"]){
					return item.vehicle == this.pageInfo.selecteds["vehicle"].name;
				} else {
					return true;
				}
			})
			.filter(item=>{
				if(this.pageInfo.selecteds["external"]){
					return item.transporter == this.pageInfo.selecteds["external"].name;
				} else {
					return true;
				}
			})
			.filter(item=>{
				// FILTER DIRECTION
				if(undefined!=serviceFilters["direction"]){
					return (serviceFilters["direction"]||[]).some(filter=>filter==item["direction"]);
				}
				return true;
			})
			.filter(item=>{
				// FILTER SHARED
				if(undefined!=serviceFilters["shared"]){
					return (serviceFilters["shared"]||[]).some(filter=>{
						switch(filter){
							case "private": return  item["private"];
							case "shuttle": return !item["private"];
						}
					});
				}
				return true;
			})			
			.sort((a,b)=>a.time>=b.time?1:-1);

		return services;
	}

	getCurrentCustomers(){
		return	( this.pageInfo.currentCustomers || [] )
					.filter(item=>{
						if(this.pageInfo.selecteds["service"]){
							return item.service_id == this.pageInfo.selecteds["service"].id;
						} else {
							return true;
						}
					})
					.filter(item=>{
						if(this.pageInfo.selecteds["vehicle"]){
							return item.vehicle == this.pageInfo.selecteds["vehicle"].name;
						} else {
							return true;
						}
					})
					// Filter by search
					.filter(item=>{
						if(undefined!=this.pageInfo.search_customer){
							let search = (this.pageInfo.search_customer||"").toLowerCase();
							if((item["customer"		]||"").toLowerCase().includes(search)){ return true; }
							if((item["reference"	]||"").toLowerCase().includes(search)){ return true; }
							return false;
						}
						return true;
					})
					.filter(item=>{
						if(this.pageInfo.selecteds["external"]){
							return item.transporter == this.pageInfo.selecteds["external"].name;
						} else {
							return true;
						}
					})
					.sort((a,b)=>a.time>=b.time?1:-1);
	}

	getCurrentMapServices(){
		this.pageInfo.currentMapServices = [
			{ lat: 39.6083663, lng: 2.8692464}
		];
		return this.pageInfo.currentMapServices;
	}

	// getFilteredBookings($info){
	// 	let directions_selected	= 	this.pageInfo.controlPanels.transfers.direction.items
	// 									.filter	(item => item.value	)
	// 									.map	(item => item.name	);

	// 	let bookings = this.bookings.data
	// 		.filter	(item=>{
	// 			let found = directions_selected.some(dir=>dir==item.direction);
	// 			return found
	// 		})
	// 		.map((item,index)=>{
	// 			item.id = index+1;
	// 			return item;
	// 		})
	// 		// .sort((a,b)=>a.group>b.group?1:-1)
	// 		;

	// 	if($info["filters"]){
	// 		if ($info["filters"].some(item=>item=="not_grouped")){
	// 			bookings = bookings.filter(item=>item.group==undefined);
	// 		}
	// 	}

	// 	if(this.pageInfo.bookingsButtons.showErrors){
	// 		bookings = bookings.filter(item=>undefined!=item.errors && item.errors.length>0);
	// 	}

	// 	bookings	= bookings.sort((a,b)=>a.group>b.group?1:-1);

	// 	// Add empty rows
	// 	// const max_empty_lines	= 20;
	// 	// const bookings_length	= (bookings||[]).length;
	// 	// const empty_qty_len		= bookings_length<max_empty_lines?max_empty_lines-bookings_length:0;
	// 	// this.pageInfo.empties.bookings  = [...Object.keys(Array(empty_qty_len))].map(item=>{
	// 	// 	let new_item = {
	// 	// 		is_empty	: true
	// 	// 	}
	// 	// 	return item;
	// 	// });

	// 	// return [ ...bookings, ...this.pageInfo.empties.bookings ];
	// 	return bookings;
	// }

	getFilteredEntity($entity,$info){
		switch($entity){
			// case "bookings"			:	return this.getFilteredBookings();
			case "map_services"		:	return this.getCurrentMapServices();
			case "notifications"	:	return this.getNotifications();
			case "flights"			:	return this.getCurrentFlights();
			case "services"			:	return this.getCurrentServices({ type: "all"		});
			case "arrivals"			:	return this.getCurrentServices({ type: "arrivals" 	});
			case "departures"		:	return this.getCurrentServices({ type: "departures"	});
			case "vehicles"			:	return this.getServices();
			case "externals"		:	return this.getExternals();
			case "customers"		:	return this.getCurrentCustomers();
			case "drivers"			:	return ( this.pageInfo.currentDrivers || [] )
											.sort((a,b)=>a.name>=b.name?1:-1);
		}
	}
// --------------------------------------------------------------------------------------
// MISC
// --------------------------------------------------------------------------------------

	getFields($entity){
		switch($entity){
			case "customerButtonsOverlay"	: return [ "relocate", "notify", "issue", "chat" ];
			case "flight"					:
				return [
					{ col:1, field: "origin", 				label: "origin", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:1, field: "destination", 			label: "destination", 	style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" }
					// { col:2, field: "origin", 				label: "origin", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					// { col:2, field: "arrival", 				label: "arrival", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					// { col:2, field: "destination", 			label: "destination", 	style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					// { col:2, field: "date", 				label: "date", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" }
				];
			case "booking"					:
				return [
					/*
					type		: 'arrival',
								id			: booking["id"],
								provider	: booking["provider"],
								reference	: booking["reference"],
								customer	: booking["customer"],
								area		: booking["area"],
								location	: booking["location"],
								addressInfo	: booking["addressInfo"],
								adults		: booking["adults"],
								children	: booking["children"],
								infants		: booking["infants"],
								pax			: booking["pax"],
								phone		: booking["phone"],
								price		: booking["price"],
								date		: booking["arrival_Date"],
								from		: booking["arrival_GatewayFrom"],
								airportFrom	: booking["arrival_GatewayFrom"],
								flight		: booking["arrival_GatewayInfo"],
								airportTo	: booking["arrival_GatewayTo"],
								pickupDate	: booking["arrival_PickupDate"],
								pickupTime	: booking["arrival_PickupTime"],
								point		: booking["arrival_StartingPointType"],
								time		: booking["arrival_Time"],
								shared		: booking["shared"],
								vehicle		: booking["vehicle"],
								vehicles	: booking["vehicles"],
								verified	: booking["verified"]
					*/
					{ col:3	, field: "reference",	 		label: "reference", 	style: { 'color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:2	, field: "provider",	 		label: "provider", 		style: { 'color': 'green', 'font-weight': '700' } },

					{ col:1	, field: "customer", 			label: "customer", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:2 , field: "phone", 				label: "phone", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "pax", 				label: "pax",	 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:3 , field: "adults", 				label: "adults", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "children", 			label: "children", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "infants", 			label: "infants", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					// { col:3 , field: "pax", 				label: "pax", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ block: 	true },

					{ col:1 , field: "from", 				label: "from",	 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					// { col:3 , field: "airportFrom", 		label: "airport", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:1 , field: "to", 					label: "to",	 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					// { col:3 , field: "airportTo", 			label: "airport", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:3 , field: "flight", 				label: "flight", 		style: { 'color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "date", 				label: "date", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "time", 				label: "time", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:3 , field: "gate", 				label: "gate", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase"},
					{ col:2 , field: "belt", 				label: "belt", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ block: true },

					{ col:3 , field: "vehicles", 			label: "vehicles", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:2 , field: "vehicle",				label: "vehicle type", 	style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:1 , field: "price", 				label: "price", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ block: true },

					{ col:3 , field: "zone", 				label: "zone", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:2 , field: "area", 				label: "area", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ col:1 , field: "location", 			label: "location", 		style: { 'color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:1 , field: "addressInfo", 		label: "address",		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					{ block: true },

					{ col:3 , field: "rep", 				label: "rep", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:2 , field: "company",				label: "company", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },

					// { block: true },

					{ col:3 , field: "doctype", 			label: "doctype", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "document", 			label: "document", 		style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" },
					{ col:3 , field: "lang", 				label: "lang", 			style: { '_color': 'green', 'font-weight': '700' }, decorator: "uppercase" }
				];
		}
	}

	getStatus($type,$entity,$item){
		if(!$item){return 'green'};
		switch($type){
			case "color":
				switch($entity){
					case "customer":
						switch($item["customer_status"]){
							case "_STATUS_UNKNOWN"	:	return "chocolate";
							case "_DEPARTURE_NOSHOW":
							case "_ARRIVAL_NOSHOW"	:	return "crimson";
							default					:	return "green";
						}
				}
				break;
		}
	}

	isCustomerInStep	($steps,$item)	{ return  $steps.some($step=> $step==$item.customer_status); 	}
	isNotCustomerInStep	($steps,$item)	{ return !$steps.some($step=> $step==$item.customer_status);	}

// --------------------------------------------------------------------------------------
// BOOKINGS
// --------------------------------------------------------------------------------------

	/*
		case "INIT"							: item["status"] = "Init";			break;
		case "_ARRIVAL_FINGER"				: item["status"] = "Finger"; 		break;
		case "_ARRIVAL_BELT"				: item["status"] = "Belt"; 			break;
		case "_ARRIVAL_MEETING"				: item["status"] = "Meeting";		break;

		case "arrivalOnBoardDone"			: item["status"] = "Board"; 		break;
		case "arrivalOnRouteDone"			: item["status"] = "Finish"; 		break;
		case "arrivalIncidenceOnBoard"		: item["status"] = "Board Issue"; 	break;
		case "arrivalIncidenceOnRoute"		: item["status"] = "Route Issie"; 	break;
		case "arrivalNoShowOnBoard"			: item["status"] = "No show"; 		break;

		case "arrivalPendingToBoard"		: item["status"] = "Pending board"; break;

		case "arrivalOnRoute"				: item["status"] = "Route"; 		break;
		case "inLodgingArrival"				: item["status"] = "Arrived"; 		break;
		case "departureOnBoardDone"			: item["status"] = "Board"; 		break;
		case "departureOnRouteDone"			: item["status"] = "Finish"; 		break;
		case "departureIncidenceOnBoard"	: item["status"] = "Board Issue"; 	break;
		case "departureIncidenceOnRoute"	: item["status"] = "Route Issue"; 	break;
		case "departureNoShowOnBoard"		: item["status"] = "No show"; 		break;
		case "departurePendingToBoard"		: item["status"] = "Pending board"; break;
		case "departureOnRoute"				: item["status"] = "Route"; 		break;
	*/

	getBookings(type)	{
		switch(type){
			case "arrivals"		:

				let filteredsArr = this.arrivals.list.filter(booking=>this.pageInfo.filters['arrivals'  ][0].selected.some(filter=>filter===booking.status)).sort((a,b)=>a.time>b.time?1:-1);
				this.arrivals.list[0] ? filteredsArr.unshift(this.arrivals.list[0]) : null; // HACK
				return filteredsArr;
				case "departures"	:
				let filteredsDep	=	 this.departures.list.filter(booking=>this.pageInfo.filters['departures'][0].selected.some(filter=>filter===booking.status)).sort((a,b)=>a.time>b.time?1:-1);
				this.departures.list[0] ? filteredsDep.unshift(this.departures.list[0]) : null; // HACK
				return filteredsDep;
		}
	}

	getBookingInfo(type,booking)
	{
		let fields = {
			id			: "id",
			provider	: "provider",
			reference	: "reference",
			customer	: "customer",
			area		: "area",
			location	: "location",
			addressInfo	: "addressInfo",
			adults		: "adults",
			children	: "children",
			infants		: "infants",
			pax			: "pax",
			phone		: "phone",
			price		: "price",
			transfer	: type+"_Transfer",
			gate		: type+"_Gate",
			belt		: type+"_Belt",
			date		: type+"_Date",
			from		: type+"_GatewayFrom",
			airportFrom	: type+"_GatewayFrom",
			flight		: type+"_GatewayInfo",
			to			: type+"_GatewayTo",
			airportTo	: type+"_GatewayTo",
			pickupDate	: type+"_PickupDate",
			pickupTime	: type+"_PickupTime",
			point		: type+"_StartingPointType",
			time		: type+"_Time",
			shared		: "shared",
			vehicle		: "vehicle",
			vehicles	: "vehicles",
			verified	: "verified",
			hasApp		: "hasApp"
		};
		let info = { type: type, original : {}};
		Object.keys(fields).map(field=>{
			info[field] 			= booking[fields[field]];
			info["original"][field]	= booking["original"][fields[field]];
		});
		// Set current status
		info["status"] 			= booking["customer_status"]?booking["customer_status"]:"_STATUS_UNKNOWN";
		info["customer_status"]	= info["status"];
		return info;
	}

	generateBookings($data){
		this.arrivals.list 		= [];
		this.departures.list 	= [];
		this.flights.tmp  		= {};

		$data.forEach( booking => {
			// this.bookings.push(booking);
			[
				"arrival_GatewayInfo",
				"departure_GatewayInfo"
			]
			.forEach(item => {

				// REAL SOLUTION
				// if(undefined===booking[item] || undefined===this.flights.global[booking[item]]){ return false; }
				// let info = this.flights.global[booking[item]];

				//HACK!!! REMOVE
				let keysFlight		=	Object.keys(this.flights.global);
				let randomFlight	=	keysFlight[Math.floor(Math.random() * keysFlight.length -1)];

				let info 			=	{... this.flights.global[randomFlight] };

				switch(item){
					case "arrival_GatewayInfo":
						let arrivalInfo 				= this.getBookingInfo('arrival',booking);
						this.arrivals.list.push(arrivalInfo);
						info.type 						= 'arrival';
						info.arrival					= booking['arrival_Time'];
						info.date						= booking['arrival_Date'];
						info.stamp						= info.date+info.arrival;
						info.pax 						= booking[item].pax;
						this.flights.tmp[booking[item]] = info;
						break;

					case "departure_GatewayInfo":
						let departureInfo 				= this.getBookingInfo('departure',booking);
						this.departures.list.push(departureInfo);
						info.type 						= 'departure';
						info.arrival					= booking['departure_Time'];
						info.date						= booking['departure_Date'];
						info.stamp						= info.date+info.arrival;
						info.pax 						= "2";
						this.flights.tmp[booking[item]] = info;
						break;
				}
			});
		});
	}

	generateCustomers($data){

	}

	generateFlights	($data){}

	generateDrivers	($data){}

	generateFleet	($data){}


// --------------------------------------------------------------------------------------
// services
// --------------------------------------------------------------------------------------

	getTransportInfo($type,$item){
		switch($type){
			case "bookings":
				// let bookings = this[$item.direction+"s"].list.filter(item => undefined!==$item.bookings && $item.bookings.some(transportBooking=>transportBooking==item.reference));
				let bookings = this[$item.direction+"s"].list.filter(item => item.transfer == $item.id);
				// console.log("TRANSFER BOOKINGS",bookings);
				return bookings;

			case "remainingTimeColor":
				if($item.remainingTime==-1){ return 'red'; 		}
				if($item.remainingTime== 0){ return 'chocolate';}
				return 'green';

			case "remainingTime":
				if(undefined===$item.remainingTimestamp){
					$item.remainingTimestamp = Date.now() + Math.random()*15000;
					// return [ -1, "_TIMER_NOT_SET" ];
				}
				let remaining 		= $item.remainingTimestamp - Date.now();
				if(remaining<0)		{ return [ 0, "00:00:00" ]; }
				remaining			= Math.floor(remaining/1000);
				let hours 			= Math.floor(remaining/3600);
				let secs			= remaining % 3600;
				let mins			= Math.floor(secs/60);
				secs				= secs % 60;
				return [ remaining, (hours>9?'':'0')+hours+':'+(mins>9?'':'0')+mins+':'+(secs>9?'':'0')+secs ];
		}
	}

// --------------------------------------------------------------------------------------
// FLIGHTS
// --------------------------------------------------------------------------------------

	getCurrentFlights(){
		let me = this;
		return	(this.pageInfo.currentFlights||[])
					.map(item=>{
						item.selected 	= undefined!==this.pageInfo.flightSelected && this.pageInfo.flightsSelected.code===item.code;
						item.background	= item.selected===false?'white':'#517fa4';
						item.color		= item.selected===false?'':'white';
						return item;
					})
					.sort((a,b)=>a.time>=b.time?1:-1)
					;
	}

// --------------------------------------------------------------------------------------
// ISSUES
// --------------------------------------------------------------------------------------

	// generateIssues(data){
	// 	let currentStatus = {};
	// 	this.notifications["inbox"].forEach(item=>{ currentStatus[item.reference]=item.expanded; });
	// 	this.notifications["inbox"] = [];
	// 	data.forEach( notification => {
	// 		notification.type 		= "issue";
	// 		notification.expanded	= currentStatus[notification.reference];
	// 		this.notifications["inbox"].push(notification);
	// 	});
	// }

// --------------------------------------------------------------------------------------
// FLEET
// --------------------------------------------------------------------------------------

	getActiveFleet(){
		return undefined===this.fleet.draggableSet ? [] : this.fleet.draggableSet.filter(item=>item.qty>0);
	}

// --------------------------------------------------------------------------------------
// SEARCH
// --------------------------------------------------------------------------------------

	openSearch(){
		this.doAction('search',{ 'action':'show' }, {});
	}

	closeSearch(){
		this.searchOpened = false;
		this.changeDetector.detectChanges();
		if(this.searchInput){
			this.searchInput.nativeElement.blur();
		}
		this.pageInfo.search.content = "";
	}

	doSearch($event?){
		// this.commons.generateToast("_INFO",this.pageInfo.search.content,"info");
		// return false;
		
		this.pageInfo.search_customer = this.pageInfo.search.content;
	}

	// ------------------------------------------------------------------------
	// KEY HANDLE
	// ------------------------------------------------------------------------

	keypressed($key){
		switch($key){
			case 'Enter'	: this.doEnter();		break;
			case 'F2'		: this.doF2(); 			break;
			case 'Escape'	: this.doEscape();		break;
		}
	}

	doF2(){
		this.openSearch();
	}

	doEscape(){
		if(this.pageInfo.search.show){
			this.doAction("search",{ action: "hide" },{});
		} else {
			this.clearFilters();
			this.pageInfo.search_customer = undefined;
		}
	}

	doEnter(){
		if(this.pageInfo.search.show){
			this.doSearch();
		}
	}

	// --------------------------------------------------------------------------------------
	// FILTER
	// --------------------------------------------------------------------------------------

	/**
	 * clear all filters
	 */
	clearFilters(){
		Object.keys(this.pageInfo.selecteds).forEach(filter=>{
			this.pageInfo.selecteds[filter] = undefined;
		});
	}

	doFilter($action,$entity,$value){
		switch($action){
			case "clear":
				switch($entity){
					case "customer": this.filterCustomer=""; break;
				}
				break;
			case "filter":
				let reserved = {
					"customer"	: [ "c", "cus", "custom", "cli", "client", "customer "],
					"transport"	: [ "t", "tra", "trans", "transport", "g", "group" ]
				};
				let filterEntity 	= Object.keys(reserved).reduce((found,k)=>{ found = reserved[k].some(item=>item==$value.split(' ')[0])?k:found; return found; },undefined);
				let filterValue		= $value.split(' ').splice(1).join(' ');
				switch(filterEntity){
					case "customer":
						this.filterCustomer = filterValue;
						break;
				}
				break;
		}
	}

	toggleItem(type,item){
		switch(type){
			case "notification":
				item.expanded		= undefined==item.expanded?false:item.expanded;
				item.expanded		= item.expanded==false?true:false;
				console.log("EXPANDED NOTIFICATION->"+item.expanded);
				break;

			case "customer":
				this.item							= item;
				this.pageInfo.selecteds["customer"]	= item;
				this.pageInfo.panels.customer 		= true;
				this.commons.generateToast("_INFO","_SELECTED_CUSTOMER","info");						
				break;

			case "lodging":
				this.item			= item;
				this.lodgings.panel = true;
				break;

			case "driver":
				this.commons.generateToast("_INFO","_DRIVER_SELECTED","info");
				this.item			= item;
				this.drivers.panel = true;
				break;

			case "fleet":
				this.item			= item;
				this.fleet.panel 	= true;
				break;

			case "route":
				this.commons.generateToast("_INFO","_ROUTE_SELECTED","info");
				item.selected		=	true;
				item.type			=	'arrival',
				this.item			=	item;
				if(this.item.stops){
					this.pageInfo.tmpStops	=	JSON.parse(JSON.stringify(this.item.stops.map(stop => stop.lodging.location)))
				}
				this.routes.panel	=	true;
				break;

			case "vehicle":
				this.item			=	item;
				if(undefined!=this.pageInfo.selecteds["vehicle"]){
					if(this.pageInfo.selecteds["vehicle"] == item){
						// this.commons.generateToast("_INFO","_SELECTED_VEHICLE","info");
						this.pageInfo.panels.vehicle 	= true;
					}
				}
				this.pageInfo.selecteds["vehicle"]	= item;
				break;

			case "external":
				this.item			= item;
				if(undefined!=this.pageInfo.selecteds["external"]){
					if(this.pageInfo.selecteds["external"] == item){
						// this.commons.generateToast("_INFO","_SELECTED_VEHICLE","info");
						this.pageInfo.panels.external 	= true;
					}
				}
				this.pageInfo.selecteds["external"]	= item;
				break;

			case "service":
				this.item			= item;
				if(undefined!=this.pageInfo.selecteds["service"]){
					if(this.pageInfo.selecteds["service"] == item){
						this.pageInfo.panels.service		= true;
					}
				}
				this.pageInfo.selecteds["service"]	= item;
				break;
		}
	}


	selectItem($type,$item){
		switch($type){
			case "flight":
				this.flights.selected = (this.flights.selected===$item)?undefined:$item;
				break;
		}
	}

// LOAD ENTITIES 	---------------------------------------------------------------------

	async load($entity) {
		switch ($entity) {
			case 'groups':
				this[$entity].spinner		= true;
				await this.entityService.loadEntity(
					$entity,
					{
						solution	: 1,
						date		: '20180808',
						bookings	: true,
						transports	: true,
						vehicles	: true,
						drivers		: true
					}
				);
				this[$entity].data        	= this.entityService.get($entity);
				this[$entity].spinner		= false;

				// Check solutions
				if(this[$entity].data.length==0){
					this.commons.generateToast('Load groups','No groups found','error');
					this["arrivals"]	 	= {};
					this["departures"]		= {};
					return false;
				}

				let me 						= this;
				let flightStatus 			= [ 'landed', 'ontime', 'delayed', 'cancelled' ];

				this.generateBookings	(this[$entity].data[0].items);
				this.generateFlights	(this[$entity].data[0].items);
				this.generateFleet		(this[$entity].data[0].items);
				this.generateDrivers	(this[$entity].data[0].items);

				console.log("End groups");
				break;

			case 'fleet':
				// this[$entity].spinner		= true;
				// this[$entity].data			= [];
				// await this.entityService.loadEntity($entity);
				// this[$entity].data        	= this.entityService.get($entity);
				// this[$entity].spinner		= false;
				// this[$entity].draggableSet  = this[$entity].data.sort((a,b)=>(a.pax>b.pax)?-1:1);
				// this[$entity].assigned		= [];
				//
				break;

			case 'drivers':
				// this[$entity].spinner		= true;
				// this[$entity].data			= [];
				// await this.entityService.loadEntity($entity);
				// this[$entity].data        	= this.entityService.get($entity);
				// this[$entity].spinner		= false;
				// this[$entity].draggableSet  = this[$entity].data;
				// this[$entity].newSet		= [];
				break;

			case 'flights':
                // await this.entityService.loadEntity($entity);
				// this[$entity].data        	= this.entityService.get($entity);
				// this[$entity].byCode		= {};
				// this[$entity].data.forEach(flight=>{
				// 	this[$entity].byCode[flight.code] = flight;
				// });
				// this[$entity].current		= {};
				break;
		}
		// this.filterEntity($entity);
	}

// MAP ----------------------------------------------------------------------------------

	mapInit(){}

	showMap($item){
		switch($item.status){
			case 'landed'	:
			case 'finger'	:
			case 'belt'		:	return true;
			default			:	return false;
		}
	}

	clickedMarker(label: string, index: number) {
		console.log(`clicked the marker: ${label || index}`)
	}

	mapClicked($event: MouseEvent) {
		this.markers.push({
			lat: $event.coords.lat,
			lng: $event.coords.lng,
			draggable: true
		});
	}

	markerDragEnd(m: marker, $event: MouseEvent) {
		console.log('dragEnd', m, $event);
	}

	relocateUser(customer,transfer){
		let info 						= {};
		info[customer.type+"_Transfer"] = transfer.id;

		if ( transfer.id == customer.transfer ){ return false; }

		// Update customer transfer
		this.firebaseService.updateCustomerBooking(customer.reference, info);

		// Update old transfer PAXES
		this.firebaseService.updateTransfer( customer.transfer, {
			"pax" : {
				"checked"	: 0,
				"validables": 0,
				"total" 	: this[customer.type+"s"].list.filter(item=>customer.transfer==item.transfer).reduce((acc,item)=>acc+parseInt(item.pax),0) }
			}
		);

		// Update new transfer PAXES
		customer.transfer 				= transfer.id;
		this.firebaseService.updateTransfer( customer.transfer, {
			"pax" : {
				"checked"	: 0,
				"validables": 0,
				"total" 	: this[customer.type+"s"].list.filter(item=>customer.transfer==item.transfer).reduce((acc,item)=>acc+parseInt(item.pax),0) }
			}
		);
	}

	validateUser(action,item){
		let status;
		switch(action){
			case 'validate'	:
				status = item.type=="arrival"?"_ARRIVAL_TRANSFER_WAITING":"_DEPARTURE_TRANSFER_WAITING";
				this.firebaseService.updateCustomerBooking(
					item.reference,
					{ customer_status : status }
				);
				this.item.status = status;
				this.commons.generateToast('Validation','Customer '+item.reference+' has been validated','info');
				return true;
			case 'noShow'	:
				status = item.type=="arrival"?"_ARRIVAL_NOSHOW":"_DEPARTURE_NOSHOW";
				this.firebaseService.updateCustomerBooking(
					item.reference,
					{ customer_status : status }
				);
				this.item.status = status;
				this.commons.generateToast('Validation','Customer '+item.reference+' has been set as NOSHOW','error');
				return true;
		}
	}

	doClearFilter($item){
		this.pageInfo.selecteds[$item] = undefined;
	}

	async doAction($type,$info,$extra)
	{
		switch($type){
			case "calendar"				:		{
					this.pageInfo.calendar.value.setHours(12);
					this.pageInfo.calendar.date	= this.pageInfo.calendar.value.toISOString().split('T')[0];
					console.log("CALENDAR",this.pageInfo.calendar);
					if(this.pageInfo.calendar.date!==this.pageInfo.calendar.last){
						this.pageInfo.calendar.last 	= this.pageInfo.calendar.date;
						this.pageInfo.current_timestamp	= undefined;
					}
					this.subscribeToServices();
					this.loadBookings();
				}
				break;
			case "filter"				:
				switch($info.action){
					case "clear":	this.doClearFilter($info.item); break;
				}
				break;

			case "control"				:
				$info.item.value 	= $info.item.value?false:true;
				let selecteds 		= ($info.group.items||[]).filter(item=>item.value) || [];
				
				// If multiple force 1 selected minimum
				if($info.group.multiple){				
					if(selecteds.length==0){
						$info.item.value = $info.item.value?false:true;
					}
				}

				// Get selected for entity
				selecteds			= ($info.group.items||[]).filter(item=>item.value) || [];
				if(undefined==this.pageInfo.filters[$info.entity]){
					this.pageInfo.filters[$info.entity] = {};
				}
				this.pageInfo.filters[$info.entity][$info.name] = selecteds.map(item=>item.name);
				break;

			case "search"				:
				switch($info.action){
					case "autocomplete"	:	this.doSearch($info.item); 		break;
					case "search"		:	this.load("bookings_search"); 	break;
					case "show"			:	this.pageInfo.search.show = true;
											if(this.searchInput){
												this.searchInput.nativeElement.focus();
											}
											// this.searchOpened = true;
											this.changeDetector.detectChanges();
											// this.inputSearch.nativeElement.focus();

											break;
					case "hide"			:	this.pageInfo.search.show = false;
											if(this.searchInput){
												this.searchInput.nativeElement.blur();
											}
											this.pageInfo.search.content = "";
											break;
					case "clear"		:	this.pageInfo.search.content	= "";
											this.doAction("search",{ action: "hide" },{});
											break;
				}
				break;

			case "tab"						:	switch($info["action"])	{
													case "select"			:
														if(!$info["tab"].disabled){
															this.pageInfo.tabs.items[$info.type].selected=$info["tab"].name;
														}
														break;
												}
												break;

			case 'tourItemView'				:	switch($info["item"].type){
													case "viewInTablet"		: 	await this.firebaseService.displayServiceOnTablet(); break;
												}
												break;
			case 'validate'					: 	this.validateUser($type,$info["item"]); break;
			case 'noShow'					: 	this.validateUser($type,$info["item"]); break;
			case 'upgradeItemSellRequest'	:	$info["item"].open = true; 	break;
			case 'upgradeItemReserveRequest':	$info["item"].open = false;
												this.commons.generateToast('Upgrade request','Request for upgrade vehicle sent','info');
												break;
			case 'upgradeItemSellProcess'	:	$info["item"].open = false;
												this.commons.generateToast('Upgrade vehicle','Customer vehicle has been upgraded','info');
												break;
			case 'upgradeItemSellCancel'	: 	$info["item"].open = false;	break;
			case 'relocateCustomer'			: 	this.relocateUser($info["item"],$extra);
												this.commons.generateToast('Relocate vehicle','Customer vehicle has been changed','info');
												break;
		}
	}

	displayInfo(item){ console.log(item); }

	// CHAT ASSISTANT ---------------------------------------
	async getAssistantMessages(refRoom){
		console.log('GETTING MESSAGES', refRoom);
		let data	=	await this.firebaseService.getPromisedMessages(refRoom); // get the list of messages
		let msgs	=	[];
		data.forEach(el => msgs = [...msgs,el.data()]);
		msgs 		= 	msgs.map(el => {return {...el, timestamp : el.timestamp['seconds'] ? el.timestamp['seconds']*1000 : el.timestamp}});
		msgs 		= 	msgs.sort((msg1,msg2) =>  msg1.timestamp - msg2.timestamp)
		return msgs; //return the list ordered and computed

	}

	formatStartTime(){
		let now = new Date();
		let test = moment(now).add('minutes',40);
		return test.format('HH:mm');
	}
	getLabelStatus(status){
		if(!status) return '_STATUS_UNKNOWN';
		switch(status){
			case 'arrival_done'				:	return '_ARRIVAL_DONE';
			case 'vehicle_checkin'			:	return '_VEHICLE_CHECKIN';
			case 'arrival_pickup_route'		:	return '_ARRIVAL_PICKUP_ROUTE';
			case 'arrival_pickup_waiting'	:	return '_ARRIVAL_PICKUP_WAITING';
			case 'arrival_pickup_checkin'	:	return '_ARRIVAL_PICKUP_CHECKIN';
			case 'arrival_route'			:	return '_ARRIVAL_ROUTE';
			case 'arrival_delivery'			:	return '_ARRIVAL_DELIVERY';
			case 'arrival_delivery_route'	:	return '_ARRIVAL_DELIVERY_ROUTE';
			case 'arrival_delivered'		:	return '_ARRIVAL_DELIVERED';
			case 'undo_arrival_delivered'	:	return '_UNDO_ARRIVAL_DELIVERED';
			case 'undo_arrival_check'		:	return '_UNDO_ARRIVAL_CHECK';
			case 'arrival_checked'			:	return '_ARRIVAL_CHECKED';
			case '_ARRIVAL_WAITING_TRANSPORT'	:	return '_ARRIVAL_WAITING_TRANSPORT';
			case 'ARRIVAL_BELT'					:	return '_ARRIVAL_MEETING';
			case '_WAITING_POINT_GROUP'		:	return '_WAITING_POINT_GROUP';
			default							:	return '_STATUS_UNKNOWN'
		}
	}


}