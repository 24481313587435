import { Injectable               	} from '@angular/core';
import { CommonsService             } from 'src/app/demo/service/commons.service';
import { IProviderService 			} from './iproviderservice';

@Injectable()
export class ProviderHTXService implements IProviderService {		
	pageInfo 	= { providerId: 1, nextGroup: 1 };

  	constructor(
		private commons 	: CommonsService
	){
		// this.commons.generateToast("_REGISTER_PROVIDER","_HTX_SERVICE_PROVIDER","info");
	}
    
	init($controller){
		$controller.registerProviderService({ id: this.pageInfo.providerId, service: this });
	}

    // Process bookings into WTR format
    // convert to Tourinia format
    // and generate groups for tripulation

	filterBooking($params):boolean{
		const $booking 			= $params["booking"			];
		const $show_cancelled	= $params["show_cancelled"	];

		if(!$params["show_cancelled"]){
			switch($booking.status){
				case "ACAN":
				case "PCAN":	return false;
			}
		}
		return true;		
	}

	mapBooking($booking):any{
		return $booking;
	}

    processBookings($info){
		let groups		= [];
		let bookingsMap	= [];
		let response;

		($info.bookings||[]).forEach((booking,index) => {
			let data = {};
			
			// Generate booking map from header and data
			($info.header||[]).forEach((field,index)=>{
				data[field]=booking[index];
			});

			switch(booking["to_kind"]){
				case "ELSE"	: response = this.processDeparture	({ booking: data }); break;
				default		: response = this.processArrival	({ booking: data }); break;
			}
			if(response["success"]){
				data = response.data;
			}
			bookingsMap.push(data);
		});

        // Generate groups for tripulations
		//
		// Tripulation rules
		//		1. Name begins with "("
		//		2. Same location
		// 		3. Same flight

        (bookingsMap||[]).forEach(booking=>{
			// Rule 1. Begins with "("
			let rule_1 = booking.customer.trim().match(/^\(/)!=null;
			if(!rule_1){ 
				booking.shared = "private";
				return false;
			}
			// Is a tripulation booking, either has other or not
			// booking.shared = "shuttle";
			booking.shared = "premium";

			// Rule 2
			let group = (groups||[]).find(g=>{
				// Rule 2. Same location
				let rule_2 = g.location==booking.location;
				if(!rule_2){ return false; }
				// Rule 3. Sale flight
				let rule_3 = g.flight == booking.flight;
				if(!rule_3){ return false; }
				// Otherwise, all rules satisfied
				return true;
			})

			if(undefined==group){
				group = {
					location	: booking.location,
					flight		: booking.flight
				};
				switch(booking.shared){
					case "private"	: group["name"] = "GR_PR_"+this.pageInfo.nextGroup++; break;
					default			:
					case "premium"	:
					case "shuttle"	: group["name"] = "GR_TRIP_"+this.pageInfo.nextGroup++; break;
				}
				groups.push(group);
			}

			switch(booking.direction){
				case "arrival"	: booking["arrival_Group"	] = group.name; break;
				case "departure": booking["departure_Group"	] = group.name; break;
			}

			// Not necessary due to direction
			booking.group = group.name;
        });

        return bookingsMap;
	}
	
	processArrival($info):any{
		let data 		= {};	
		let response 	= { success: true };
		const mappings  = {
			"customer_name"		: "customer",
			"number_of_persons"	: "pax",
			"booking_code"		: "reference",
			"pickup_time"		: "PickupTime",
			"date"				: "arrival_Date",
			"flight_nr"			: "arrival_GatewayInfo",
			"note"				: "notes",
			"vehicle"			: "vehicle",
			"price"				: "price",
			"payment_method"	: "payment_method",
			"transfer_type"		: "transfer_type",
			"vehicle_class"		: "vehicle_class",
			"vehicle_type"		: "vehicle_type"
		};

		try {
			Object.keys($info.booking).forEach(field=>{
				data[mappings[field]] = $info.booking[field].replace(/\"/gi,"");
			})
			data["location"	] 	= ($info.booking["to_description"]||"")
									.replace(/.*\((.*)\)/,"$1")
									.replace(/\"/,"")
									;
			data["direction"	]	= "arrival";
			data["shared"		]	= "private";
			data["adults"		]	= data["pax"];
			data["children"		]	= 0;
			data["infants"		]	= 0;
			data["provider"		]	= 15;
			data["arrival_Date"	]	= this.commons.getDateFormat2Format(data["arrival_Date"],"DD/MM/YYYYY","YYYY-MM-DD");

			response["data"	] 	= data;
		} catch(e){
			console.log("[processArrival] error processing booking",$info.booking);
			response["success"] = false;
		}
		return response;
	}

	processDeparture($info):any{
		let data 		= {};
		let response 	= { success: false };
		const mappings  = {
			"customer_name"		: "customer",
			"number_of_persons"	: "pax",
			"booking_code"		: "reference",
			"pickup_time"		: "PickupTime",
			"date"				: "departure_Date",
			"flight_nr"			: "departure_GatewayInfo",
			"note"				: "notes",
			"vehicle"			: "vehicle",
			"price"				: "price",
			"payment_method"	: "payment_method",
			"transfer_type"		: "transfer_type",
			"vehicle_class"		: "vehicle_class",
			"vehicle_type"		: "vehicle_type"
		};

		try {
			Object.keys($info.booking).forEach(field=>{
				data[mappings[field]] = $info.booking[field].replace(/\"/gi,"");
			})
			data["location"			] 	= ($info.booking["to_description"]||"")
											.replace(/.*\((.*)\)/,"$1")
											.replace(/\"/,"")
											;
			data["direction"		]	= "departure";
			data["shared"			]	= "private";
			data["adults"			]	= data["pax"];
			data["children"			]	= 0;
			data["infants"			]	= 0;
			data["provider"			]	= 15;
			data["departure_Date"	]	= this.commons.getDateFormat2Format(data["departure_Date"],"DD/MM/YYYYY","YYYY-MM-DD");

			response["data"	] 	= data;
		} catch(e){
			console.log("[processArrival] error processing booking",$info.booking);
			response["success"] = false;
		}
		return response;
	}

	check4Validation($params):any{
		let $item = $params["item"];

		// NOTAS OBLICATORIAS ( p.e. OTBT-20016798 )
		// 	Si es privado
		// 	Si es PR3, PR4 o PR7
		// 	Si tienen niños o bebés
		// 		Obligatorio mandar nota antes de validar
		// 		Marcar como error o warning
		if($item.shared=="private"){
			switch($item.vehicle){
				case "Private Standard Car 1 - 3"	:
				case "Private Standard Car 1 - 4"	:
				case "Private Standard Car 1 - 7"	:
				case "Private Premium Car 1 - 3"	:
				case "Private Premium Car 1 - 4"	:
				case "Private Premium Car 1 - 7"	:
					if($item.children>0 || $item.infants>0){
						if(undefined==$item.notes || $item.notes==""){
							this.commons.generateToast("_HTX_SERVICE","_CHECK_VALIDATION_FOR_TYPE_1","info");
							return false;
						}
					}
					break;
			}
		}

		if($params["notify"]){
			this.commons.generateToast("_HTX_SERVICE","_CHECK_4_VALIDATION_CORRECT","info");
		}
	}
}