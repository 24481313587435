import { 	AggregatorsService 		} 	from '../../service/database/aggregator.service';
import { 	closeFullscreen 		} 	from '../transports/fn/fullscreen';
import { 	CompanyService 			}	from '../../service/database/company.service';
import { 	StorageService 			}	from '../../service/storageservice';
import { 	FirebaseService		 	}	from 'src/app/demo/service/database/firebase.service';
import { 	MessageService 			}	from 'primeng/components/common/messageservice';
import {
			Component, OnInit,
			ViewEncapsulation,
			ViewChild,
			ChangeDetectionStrategy,
			ChangeDetectorRef,
			NgZone,
			ElementRef,				
			HostListener,			
			SimpleChanges}	from '@angular/core';
import { 	EntityService 			}	from '../../service/entity.service';
import { 	FlightService 			}	from '../../service/flight.service';
import { 	CommonsService 			}	from '../../service/commons.service';

import { 	bookingsCols 			}	from './columns/bookings.columns';
import { 	rejectInfo 				}	from './data/reject.info';
import { 	filters 				}	from './data/filters';
// import { 	GoogleService 			}	from '../../service/google/google.service';
import { 	LodgingService 			}	from '../../service/lodging/lodging.service';
import { 	MenuItem 				}	from 'primeng/api';
import { 	BookingsService 		}	from '../../service/bookings/bookings.service';

import { 	PanelMenuModule 		} 	from 'primeng/panelmenu';

import { 	htxExpandedForm,
			htxExpandedArrivalForm,
			htxExpandedDepartureForm,
			htxEmptyRow 			} 	from './columns/htxExpandedForm';

import { 	hotelbedsExpandedForm,
			hotelbedsExpandedArrivalForm,
			hotelbedsExpandedDepartureForm
									} 	from './columns/hotelbedsExpandedForm';
import { 	hoppaExpandedForm 		} 	from './columns/hoppaExpandedForm';
import { 	testExpandedForm 		} 	from './columns/testExpandedForm';

import { 	TransportService 		} 	from '../../service/transports/transports.service';
import { 	CheckService 			} 	from '../../service/database/check.service';

import * as PDF 						from 'jspdf';
import * as XLSX 						from 'xlsx';
import * as moment 			 			from 'moment';

import { menus						} 	from './data/menus';
import { tabs						}	from './data/tabs';
import { ProviderServiceController 	} from 'src/app/services/providers/controller.service';

// import { ProviderHTXService 		} 	from 'src/app/services/providers/htx.service';
// import { ProviderWTRService 		} 	from 'src/app/services/providers/wtr.service';

import { CS_TF } from './data/1844/TF';
import { CS_LZ } from './data/1844/LZ';
import { CS_FV } from './data/1844/FV';
import { CS_GC } from './data/1844/GC';
import { BreadcrumbModule } from 'primeng/primeng';

interface Calendar {
	value		: any,
	date		: string,
	last		: string,
	offset		: number,
	minOffset	: number,
	maxOffset	: number
};

@Component({
	templateUrl		:	'./bookings.component.html',
	styleUrls		:	['./bookings.component.scss'],
	encapsulation	:	ViewEncapsulation.None,
	providers		:	[MessageService],
	// changeDetection	:	ChangeDetectionStrategy.OnPush
	changeDetection	:	ChangeDetectionStrategy.Default
})

export class BookingsComponent implements OnInit
{
	// @HostListener('document:keydown', ['$event'])
    // keypress(e: KeyboardEvent) { this.keypressed(e.key); }

	@ViewChild("dt") 		dataTable		: any;
	// @ViewChild("dt") 	dataTable_all	: any;
	// @ViewChild("dtpre") 	dataTable_pre	: any;
	@ViewChild("fc") 		flightChecker	: any;
	@ViewChild("lc") 		lodgingChecker	: any;
	@ViewChild("search")	searchInput		: any;


	@ViewChild('searchLodging')    			searchLodging   	: ElementRef;
	@ViewChild('searchArrivalLodging')    	searchArrivalLodging   	: ElementRef;
	@ViewChild('searchDepartureLodging')	searchDepartureLodging	: ElementRef;

	pageInfo		: any 		= { dataLoaded	: 	false,
									search 		: 	{ 	show	: 	false,
														content	: 	"",
														fields	: 	[
																		"reference",
																		"customer"
																	]
													},
									filters		: 	{ 	show	: 	true		}
								};
	userInfo		: any 		= {};
	entities		: any[] 	= [ 
									"bookings", 
									"flights", 
									"areas", 
									"lodgings", 
									"transports", 
									"providers"
								];

	// Bookings types
	bookings		: any 		= { data: [], cols: [], filters: {}, rejects: {}, checks: { flights: {}, hotel: {}, reject: {} } };
	flights			: any 		= { data: [], cols: [] 	};
	airports		: any 		= { data: [], cols: [] 	};
	airlines		: any 		= { data: [], cols: [] 	};
	areas			: any 		= { data: [], cols: [] 	};
	lodgings		: any 		= { data: [], cols: [] 	};

	transports		: any 		= { data: [], cols: [] 	};
	fleet			: any		= { data: []			};
	// zonesDestination: any 		= { data: [], cols: [] };
	destinationInfo	: any 		= {};
	providers		: any 		= {};

	item			: any 		= {};
	rowData			: any 		= {};
	calendar		: Calendar 	= <Calendar>{ last: '', value: new Date(), offset: 0, minOffset: 0, maxOffset: 365 };

	results			: any[];

	menu: any = {
		options: [
			{ label: 'reload', 		icon: 'fa fa-plus' 		},
			{ label: 'download', 	icon: 'fa fa-download' 	}
		]
	};

	items: MenuItem[];

	constructor(
		private commons				: CommonsService,
		public 	flightService		: FlightService,
		private entityService		: EntityService,
		private messageService		: MessageService,
		// private googleService		: GoogleService,
		private lodgingService		: LodgingService,
		private bookingsService		: BookingsService,
		private companyService		: CompanyService,
		private providerService		: AggregatorsService,
		private transportService	: TransportService,
		private checkService		: CheckService,
		private changeDetector		: ChangeDetectorRef,
		private firebaseService		: FirebaseService,
		private providerServiceCtrl	: ProviderServiceController,
		private ngZone				: NgZone
	) {
		this.init();
		// this.loadEntities();
	}


	keypressed($key){
		switch($key){
			case 'Enter'	: this.doEnter();		break;
			case 'F2'		: this.doF2(); 			break;
			case 'Escape'	: this.doEscape();		break;
		}
	}

	doF2(){	
		if(!this.pageInfo.search.show){
			this.doAction('search', { action: 'show' });
		}
	}

	doEscape(){
		if(this.pageInfo.search.show){
			this.doAction("search",{ action: "hide" });
		}
	}

	doEnter(){
		if(this.pageInfo.search.show){
			this.doAction("search",{ action: "search" });
		}
	}

	refresh(){
		this.changeDetector.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges){
		alert("BOOM");
	}

	async ngOnInit() {
		this.pageInfo.loadingData = false;

		this.pageInfo.canaryshuttle_mappings = {
			QGBHKJzKHcGtoiVY5tVU	: CS_TF,
			bHQhbcJkuC0qGfBXPJxp	: CS_LZ,
			FUE 					: CS_FV,
			LPA						: CS_GC
		};

		// Load CS Hotels
		this.commons.entities.mappings = {
			canaryshuttle : {
				lodgings :this.pageInfo.canaryshuttle_mappings[this.commons.userInfo.currentDestination.dbid]
			}
		}

		this.pageInfo.providerExpandedForm	= {
			"1"				: {
				arrival		: htxExpandedArrivalForm,
				departure	: htxExpandedDepartureForm,
				default		: htxExpandedForm,
			},
			"HTX"			: {
				arrival		: htxExpandedArrivalForm,
				departure	: htxExpandedDepartureForm,
				default		: htxExpandedForm,
			},
			"2"				: {
				arrival		: hoppaExpandedForm,
				departure	: hoppaExpandedForm,
				default		: hoppaExpandedForm
			},
			"Hoppa"			: {
				arrival		: hoppaExpandedForm,
				departure	: hoppaExpandedForm,
				default		: hoppaExpandedForm
			},
			"3"				: {
				arrival		: testExpandedForm,
				departure	: testExpandedForm,
				default		: testExpandedForm
			},
			"test"			: {
				arrival		: testExpandedForm,
				departure	: testExpandedForm,
				default		: testExpandedForm
			},
			"4"				: {
				arrival		: hotelbedsExpandedArrivalForm,
				departure	: hotelbedsExpandedDepartureForm,
				default		: hotelbedsExpandedForm
			},
			"Hotelbeds"		: {
				arrival		: hotelbedsExpandedArrivalForm,
				departure	: hotelbedsExpandedDepartureForm,
				default		: hotelbedsExpandedForm
			},
			"15"			: {
				arrival		: testExpandedForm,
				departure	: testExpandedForm,
				default		: testExpandedForm
			},
			"WTR"			: {
				arrival		: testExpandedForm,
				departure	: testExpandedForm,
				default		: testExpandedForm
			}
		}

		this.pageInfo.aliases = {
			lodgings 	: [],
			flights		: []
		};

		await this.init();
		// set 1 second timeout in order to have all
		// needed entities preloaded
		setTimeout(async () => {
			console.log("[loadEntities] waited 1 second");
			await this.loadEntities();
		}, 1000);

		this.items = [
			{ label: 'New', 	icon: 'pi pi-fw pi-plus' 		},
			{ label: 'Open', 	icon: 'pi pi-fw pi-download'	},
			{ label: 'Undo', 	icon: 'pi pi-fw pi-refresh' 	}
		];

		this.checkService.init({
			flightChecker 	: this.flightChecker,
			// checks			: this.bookings.checks,
			base 			: this.pageInfo.base
		});

		this.pageInfo.loadingData	= false;
	}

	ngAfterViewInit()				{
    }

	onUpload($type,$info) 				{
		switch($info.type){
			case "audios"		:
			case "pictures"		:
			case "services"		:
			case "bookings"		:	for(let file of $info.event.files) {
										this.processFile({ info: $info, file: file });
									}
									break;
			default				:	alert("OnUpload " + $info.type); break;
		}
	}

	async processFile($info)					{
		let type = $info.format || $info.file.type;
		switch(type){
			case "text/csv"	:
			case "csv"		:
				let fileReader		= new FileReader();
				fileReader.onload 	= (() => {
					switch($info.info.type){
						case "bookings":
							this.processBookingsCSV({
								type	: $info.info.type,
								mode	: $info.info.mode,
								info	: <string>fileReader.result
							});
							break;
					};
				});

				fileReader.onerror = (error) => {
					this.pageInfo.importTrace.push({	type	:	"error",
														label	:	this.commons.getTranslate("_FILE"),
														content	: 	this.commons.getTranslate("_PROCESSING_FILE_ERROR")
													})
				}
				fileReader.readAsText($info.file);
				break;
		}
	}


// ----------------------------------------------------------------------------
// BOOKINGS
// ----------------------------------------------------------------------------

	async processBookingsCSV($params){
		let		$type			= $params.type;
		let 	$info			= $params.info;
		let 	$mode			= $params.mode;
		let 	header;
		let		entries;
		let		data;

		// const 	delimiter		= ";";
		const 	delimiter		= ",";

		let 	wrongDelimiter 	= false;
		let 	lines;
		let 	bookings 		= [];

		lines = $info.split('\n') || [];

		lines.forEach(($line,$index)=>{
			if(wrongDelimiter){ return; }
			data = $line.split(delimiter) || [];
			if(data.length<2){
				switch(data[0]){
					case ""	:	console.log("Últtma linea",$index);	break;
					default	:	wrongDelimiter = true;
								this.pageInfo.importTrace.push({ type: "error", label: "error", content: this.commons.getTranslate("_CSV_WRONG_DELIMITER") });
								break;
				}
			} else {
				switch($index){
					case 0	:	header = data; 		break;
					default	:	bookings.push(data);break;
				}
			}
		});

		let response;
		let processedBookings = [];

		switch($mode){
			case "import"	:
				if(undefined==this.pageInfo.currentProvider){
					this.commons.generateToast("_BOOKINGS_IMPORTER","_NO_PROVIDER_FOUND","error");
					return false;
				}
				response 	= this.processProviderBookings({ provider: this.pageInfo.currentProvider.id, header: header, bookings: bookings });
				if(!response["success"]){
					return false;
				}

				processedBookings = response["processedBookings"];
				this.pageInfo.imported_bookings_response = await this.persistBookings({ bookings: processedBookings });
				break;

			case "check"	:
				this.pageInfo.checked_bookings_response = { bookings: bookings };
				(bookings||[]).forEach(item=>{
					if(!this.pageInfo.currentBookings.some(b=>b.reference==item[0])){
						this.pageInfo.currentBookings.push({
							reference 	: item[0],
							customer	: item[1],
							dirty		: true
						})
					}
				})
				break;
		}

		return this.pageInfo.imported_bookings_response;
	}

	/**
	 * process bookings depending upon provider
	 * @param $params
	 * @returns
	 */
	processProviderBookings($params){
		let $provider 			= $params.provider;
		let $header				= $params.header;
		let $bookings			= $params.bookings;
		let response			= { success: true };
		let providerService 	= this.providerServiceCtrl.getProviderService(this.pageInfo.currentProvider.id);

		if(!providerService){
			this.commons.generateToast("_ERROR","_PROVIDER_NOT_FOUND","error");
			response.success = false;
			return response;
		}
		response["processedBookings"] = providerService.processBookings({ header: $header, bookings: $bookings });
		console.log("[processBookingsCSV] processedBookings",response["processedBookings"]);

		return response;
	}

	async persistBookings($info){
		this.pageInfo.imported_bookings_loading = true;
		let responseAll = await Promise.all($info.bookings.map(booking=>this.persistBooking(booking)));
		this.pageInfo.imported_bookings_loading = false;
		// let responseAll = await Promise.resolve(this.persistBooking($info.bookings[0]));
		return responseAll;
	}

	/**
	 * Persist Booking
	 * @param $item
	 */
	async persistBooking($item) 		{
		let params		= {
			dmc				: this.commons.userInfo.dmc.id,
			destination		: this.commons.userInfo.destination.id,
			agent			: "WTR",
			provider		: this.pageInfo.currentProvider.id
		};

		let response 	= await this.entityService.postJSON(
			this.entityService.getUrl("booking_imported_update"),
			{ ...params, ...$item }
		)
		return response;
	}

	/**
	 * load all entities needed to handle bookings
	 */
	async loadEntities() {
		// FOREACH IS NOT WORKING
		// Study how to use streaming to perform all requests together
		// this.entities.forEach(async item=> await this.load(item) );

		// SO UNROLL LOOP
		await this.load('providers');
		// await this.load('fleet');
		// await this.load('destinationInfo');
		await this.load('airports');
		await this.load('airlines');
		// await this.load('flights');
		await this.load('areas');
		await this.load('lodgings');

		// await this.googleService.loadApi();	// ????

		this.calendarChange();

		this.pageInfo.dataLoaded = true;
	}

	async init()
	{
		this.pageInfo.editionMode	= 	false;
		this.pageInfo.tableRows 	=	200;
		this.pageInfo.table 		=	{ height: '65vh', border: '1px solid #f0f0f0', rowExpansionWidth: '85vw' };

		this.pageInfo.statusToken	= 	{
											"OR" : this.commons.getTranslate("_STATUS_OR"),
											"CX" : this.commons.getTranslate("_STATUS_CX"),
											"AM" : this.commons.getTranslate("_STATUS_AM")
										};

		this.pageInfo.uploadedFiles	= 	[];
		this.pageInfo.importTrace	=	[];

		this.pageInfo.digests		= 	[
			{
				cols 	: 2,
				title	: "_TOTAL",
				entity  : "total",
				items	: [
					{ icon		: "check", 		item: "total",			default: 0 }
				]
			},
			{
				cols	: 3,
				title	: "_VERIFIED",
				entity  : "verified",
				items	: [
					{ icon		: "check", 		item: "verified",		default: 0 },
					{ icon		: "times", 		item: "not_verified",	default: 0 }
				]
			},
			{
				cols	: 3,
				title	: "_ERRORS",
				entity  : "errors",
				items	: [
					{ icon		: "check",	 	item: "bookings_with_errors",		default: 0 },
					{ icon		: "times", 		item: "bookings_with_no_errors",	default: 0 },
					{ icon		: "times", 		item: "bookings_total_errors",		default: 0 }
				]
			},
			{
				cols	: 4,
				title	: "_DIRECTION",
				entity  : "direction",
				items	: [
					{ label		: "_ARRIVAL", 	item: "arrival",		default: 0 },
					{ label		: "_DEPARTURE", item: "departure",		default: 0 },
					{ label		: "_BOTH",		item: "both",			default: 0 }
				]
			}
		];

		// Generate digests for all booking types
		// Also reference for init purposes
		["bookings"].forEach(type=>{
			this.generateBookingsEntity({ type: type, bookingsCols: bookingsCols });
		});

		this.pageInfo.filterButtons = filters.map(filter=>{
			const len   			= 	(filter.items||[]).length<=0?1:filter.items.length;

			filter["title"		]	=	filter["label"];
			filter["cols"		] 	= 	12 / len;
			filter["format"		]	= 	"vertical";
			filter["showTitle"	] 	=	true;
			filter["showLabel"	]	=	true;
			filter["cols"		]	= 	6;

			filter["items"		]	=	filter.items.map(item=>{
				item["name"		]	= 	item["value"];
				item["icon"		]	=	"user";
				return item;
			});

			return filter;
		});

		this.pageInfo._filterButtons = [
			{
				name		: 'transferType',
				title		: "_TRANSFER_TYPE",
				format		: 'vertical',
				showTitle	: true,
				showLabel	: true,
				cols		: 6,
				items: [
					{ name: 'private', 	label: '_PRIVATE', 			icon: 'user', 		value: true },
					{ name: 'shared', 	label: '_SHARED', 			icon: 'users', 		value: true }
				]
			}
			, {
				name		: 'direction',
				title		: "_DIRECTION",
				format		: 'vertical',
				showTitle	: true,
				showLabel	: true,
				cols		: 6,
				items		: [
					{ name: 'arrivals', 	label: '_ARRIVALS', 	icon: 'plane', 		value: true, reverse: true },
					{ name: 'departures', 	label: '_DEPARTURES', 	icon: 'plane', 		value: true }
				]
			}
			, {
				name		: 'verified',
				title		: "_VERIFIED",
				format		: "vertical",
				showTitle	: true,
				showLabel	: false,
				cols		: 6,
				items		: [
					{ name: 'verified', 	label: '_VERIFIED', 	icon: 'check', 		value: true 	},
					{ name: 'not_verified', label: '_NOT_VERIFIED',	icon: 'times', 		value: true 	}
				]
			}
			, {
				name		: 'status',
				title		: "_STATUS",
				format		: 'horizontal',
				showTitle	: true,
				showLabel	: true,
				items		: [
					{ name: 'original', 	label: '_ORIGINAL', 	icon: 'question',	value: true },
					{ name: 'rectified',	label: '_RECTIFIED', 	icon: 'exchange',	value: true },
					{ name: 'ammended',		label: '_AMMENDED', 	icon: 'check', 		value: true },
					{ name: 'cancelled',	label: '_CANCELLED', 	icon: 'times', 		value: true }
				]
			}
			, {
				name		: 'errors',
				title		: "_ERRORS",
				format		: 'vertical',
				showTitle	: true,
				showLabel	: false,
				cols		: 6,
				items		: [
					{ name: 'errors', 		label: '_ERRORS', 		icon: 'exclamation',value: true },
					{ name: 'not_errors', 	label: '_NOT_ERRORS',	icon: 'check', 		value: true }
				]
			}
		];

		this.pageInfo.noDataImage = "assets/layout/images/img/no-data.png";

		this.pageInfo.parityFields = {
			location: {
				arrival_AddressInfo		: 'departure_AddressInfo',
				departure_AddressInfo	: 'arrival_AddressInfo',
				arrival_Location		: 'departure_Location',
				departure_Location		: 'arrival_Location'
			}
		}
		this.pageInfo.providers = [
			{ source: 'Aviation Edge', 		field: 'aviation_edge' 		},
			{ source: 'AENA', 				field: 'aena' 				},
			{ source: 'SkyScanner', 		field: 'skyscanner' 		},
			{ source: 'FlightStats', 		field: 'flightstats' 		},
			{ source: 'FlightRadar24', 		field: 'flightradar24' 		},
			{ source: 'FlightWise', 		field: 'flightwise' 		},
			{ source: 'ViewTrip', 			field: 'viewtrip' 			}
		];

		this.flights.cols = [
			{ field: 'arrival_GatewayInfo', header: 'Flight', 		width: '100px', renderer: this.getRendererType('flight') 	},
			{ field: 'arrival_GatewayFrom', header: 'Origin', 		width: '120px'												},
			{ field: 'arrival_Date', 		header: 'Date', 		width: '100px', align: 'center' 							},
			{ field: 'arrival_Time', 		header: 'Time', 		width: '100px', align: 'center' 							},
			{ field: 'realFlight', 			header: 'Real Flight', 	width: '100px' 												},
			{ field: 'realGatewayFrom', 	header: 'Origin', 		width: '120px' 												},
			{ field: 'RealDate', 			header: 'Real date', 	width: '100px', align: 'center' 							},
			{ field: 'RealTime', 			header: 'Real Time', 	width: '100px', align: 'center' 							},
			{ field: 'info_aena', 			header: 'AENA', 		width: '120px', align: 'center' 							},
			{ field: 'info_opensky', 		header: 'OpenSky',	 	width: '120px', align: 'center' 							}
		];

		this.areas.cols = [
			{ field: 'destination', 		header: 'Destination', 	width: '100px',	align: 'center' 							},
			{ field: 'code', 				header: 'Code', 		width: '50px', 	align: 'center', 	editable: true			},
			{ field: 'name', 				header: 'Name', 		width: '100px', align: 'center', 	editable: true 			},
			{ field: 'transfer_time', 		header: 'Transfer', 	width: '50px', 	align: 'center', 	editable: true 			},
			{ field: 'alias', 				header: 'Name', 		width: '200px', align: 'center', 	editable: true 			}
		];

		this.lodgings.cols = [
			{ field: 'name', 				header: 'Name', 		width: '300px'	},
			{ field: 'category',			header: 'Cat', 			width: '50px' 	},
			{ field: 'rooms', 				header: 'Rooms', 		width: '50px' 	},
			{ field: 'area', 				header: 'Area', 		width: '100px' 	},
			{ field: 'address', 			header: 'Address', 		width: '200px' 	},
			{ field: 'latitude',			header: 'Lat', 			width: '100px' 	},
			{ field: 'longitude', 			header: 'Long',			width: '100px' 	},
		];

		this.generateMenuCols('flights');
		this.generateMenuCols('areas');
		this.generateMenuCols('lodgings');

		await this.initFilters();
		await this.initTabs();
		await this.initMenus();
		// this.loadEntities();
	}

	async generateBookingsEntity($params){

		const $type 		= $params["type"];
		const $bookingsCols	= $params["bookingsCols"];

		this.generateDigests(
			$type,
			{	total			: { total	: 0				 							},
				verified		: { verified: 0, not_verified	: 0						},
				errors			: {
					bookings_with_errors		: 0,
					bookings_with_no_errors		: 0,
					bookings_total_errors		: 0
				},
				status			: { original: 0, rectified		: 0, ammended	: 0 	},
				direction 		: { arrivals: 0, departures		: 0, both		: 0		}
			}
		);

		this[$type].checks = {
			flight: {
				panel: false,
				cols: [
					{ field: 'source', 		label: "Source", 		align: "left", 		width: "100px" 			},
					{ field: 'flight', 		label: "Flight", 		align: "center", 	width: "100px" 			},
					{ field: 'time', 		label: "Time", 			align: "center", 	width: "100px" 			},
					{ field: 'origin', 		label: "Origin", 		align: "center", 	width: "100px" 			},
					{ field: 'destination', label: "Destination", 	align: "center", 	width: "100px" 			}
				],
				items: []
			},
			hotel: {
				panel: false,
				cols: [
					{ field: 'source', 		label: "Source", 		align: "left", 		width: "100px" 			},
					{ field: 'flight', 		label: "Flight", 		align: "center", 	width: "100px" 			},
					{ field: 'time', 		label: "Time", 			align: "center", 	width: "100px" 			},
					{ field: 'origin', 		label: "Origin", 		align: "center", 	width: "100px" 			},
					{ field: 'destination', label: "Destination", 	align: "center", 	width: "100px" 			}
				],
				items: []
			},
			reject: rejectInfo
		}

		this[$type].cols = await Promise.resolve(this.commons.translateRecursively($bookingsCols, { label: 'header' }));

		this.generateMenuCols($type);

		this[$type].expanderForm = {
			ready		: false,
			entities	: this.pageInfo.entities,
			buttons		: {
				items:	[
					{ name: 'save', label: '_SAVE',	action: 'save',  icon: 'fa fa-fw fa-save'}
				]
			}
		};
	}

	// -----------------------------------------------------------------------------------
	// LOAD METHODS
	// -----------------------------------------------------------------------------------

	getDigest($entity,$digest,$item)			{
		return (!this[$entity].digests && !this[$entity].digests[$digest])?[]:this[$entity].digests[$digest][$item];
	}

	generateDigests($entity,$digests)		{
		this[$entity].digests =	$digests;
	}

	getButtonFilters()				{	return this.pageInfo.filterButtons.reduce((o,f)=>{
											o[f.name]=f.items.filter(i=>i.value).map(j=>j.name);
											// o[f.name]=JSON.stringify(f.items.filter(i=>i.value).map(j=>j.name));
											return o;
										},{});
									}

	/**
	 * persist log
	 * @param $info
	 */
	async saveLog($info)			{
		await this.entityService.postJSON(
			this.entityService.getUrl("save_booking_log"),
			$info
		).then(response => {
			console.log("[saveLog] response success",response);
			return true;
		}).catch(response => {
			console.log("[saveLog] response error",response);
		})
	}

	/**
	 * generate extra info for bookings and digests
	 *
	 */
	generateExtraBookingsInfo($entity,me){

		this[$entity].data			=	this[$entity].data.map(item=>{

			item["statusToken"]				=	this.pageInfo.statusToken[item["status"]];
			item["original_statusToken"]	=	item["original_status"];

			item["date_Calendar"]			=	new Date(moment(item["date"],'YYYYMMDD').format());
			item["original_date_Calendar"]	=	new Date(moment(item["date"],'YYYYMMDD').format());

			["arrival","departure"].forEach(direction=>{
				if(item[direction+"_extras"]){
					item[direction+"_extras_str"] = "";
					Object.keys(item[direction+"_extras"]).forEach(k=>{
						item[direction+"_extras_str"] += item[direction+"_extras"][k]+"x "+k+"\n";
					});
				}
			});

			if(item["arrival_Date"] && item["arrival_Time"]){
				const arrival_DateTime							=	new Date(moment(item["arrival_Date"]+" "+item["arrival_Time"],'YYYY-MM-DD hh:mm').format());
				item["arrival_Date_Calendar"]					=	arrival_DateTime;
				item["arrival_Time_Calendar"]					=	arrival_DateTime;

				const arrival_DateTime_original					=	new Date(moment(item["original_arrival_Date"]+" "+item["original_arrival_Time"],'YYYY-MM-DD hh:mm').format());
				item["original_arrival_Date_Calendar"]			=	arrival_DateTime_original;
				item["original_arrival_Time_Calendar"]			=	arrival_DateTime_original;
			}

			if(item["arrival_Pickup_Date"] && item["arrival_Pickup_Time"]){
				const arrival_Pickup_DateTime					=	new Date(moment(item["arrival_Pickup_Date"]+" "+item["arrival_Pickup_Time"],'YYYY-MM-DD hh:mm').format());
				item["arrival_Pickup_Date_Calendar"]			=	arrival_Pickup_DateTime;
				item["arrival_Pickup_Time_Calendar"]			=	arrival_Pickup_DateTime;

				const arrival_Pickup_DateTime_original			=	new Date(moment(item["original_arrival_Pickup_Date"]+" "+item["original_arrival_Pickup_Time"],'YYYY-MM-DD hh:mm').format());
				item["original_arrival_Pickup_Date_Calendar"]	=	arrival_Pickup_DateTime_original;
				item["original_arrival_Pickup_Time_Calendar"]	=	arrival_Pickup_DateTime_original;
			}

			if(item["departure_Date"] && item["departure_Time"]){
				const departure_DateTime						=	new Date(moment(item["departure_Date"]+" "+item["departure_Time"],'YYYY-MM-DD hh:mm').format());
				item["departure_Date_Calendar"]					=	departure_DateTime;
				item["departure_Time_Calendar"]					=	departure_DateTime;

				const departure_DateTime_original				=	new Date(moment(item["original_departure_Date"]+" "+item["original_departure_Time"],'YYYY-MM-DD hh:mm').format());
				item["original_departure_Date_Calendar"]		=	departure_DateTime_original;
				item["original_departure_Time_Calendar"]		=	departure_DateTime_original;
			}

			if(item["departure_Pickup_Date"] && item["departure_Pickup_Time"]){
				const departure_Pickup_DateTime					=	new Date(moment(item["departure_Pickup_Date"]+" "+item["departure_Pickup_Time"],'YYYY-MM-DD hh:mm').format());
				item["departure_Pickup_Date_Calendar"]			=	departure_Pickup_DateTime;
				item["departure_Pickup_Time_Calendar"]			=	departure_Pickup_DateTime;

				const departure_Pickup_DateTime_original		=	new Date(moment(item["original_departure_Pickup_Date"]+" "+item["original_departure_Pickup_Time"],'YYYY-MM-DD hh:mm').format());
				item["original_departure_Pickup_Date_Calendar"]	=	departure_Pickup_DateTime_original;
				item["original_departure_Pickup_Time_Calendar"]	=	departure_Pickup_DateTime_original;
			}

			switch(item["verified"]){
				case "1":
				case 1:
				case true:
				case "yes"	:	item["verified"] = "yes";	break;
				default		:	item["verified"] = "no";	break;
			}
			// item["verified"]				=	item["verified"] || "no";

			// Persist last value to detect changes
			Object.keys(item).forEach(e=>{
				if(e.includes("original_")){ return; }
				item["last_"+e] = item[e];
			})

			// Save log for testing
			// this.saveLog({
			// 	reference	: item["reference"],
			// 	profile		: "admin",
			// 	author		: "Carlos Tous",
			// 	type		: "test",
			// 	value		: 'test de log sobre reserva'
			// })

			// FAKE !!
			// NOTIFICATION SENT TO PROVIDER ?
			// item.arrival_notification_pending	= true;
			item.notification_pending 			= item.arrival_notification_pending || item.departure_notification_pending;

			// FORCE not verified
			// item.verified				= false;

			return item;
		});

		// DIGEST
		//this[$entity].digest		=	this.entityService.getDigest($entity);
		this[$entity].digest		=	{
			verified 	: 0,
			not_verified: 0,
			bookings_with_errors	: 0,
			bookings_with_no_errors	: 0,
			bookings_total_errors	: 0,
			original	: 0,
			rectified	: 0,
			ammended	: 0,
			arrivals	: 0,
			departures	: 0,
			both		: 0
		};

		this[$entity].data.forEach(item=>{
			this[$entity].digest["verified"]	+= item.verified	==	"yes"		?1:0;
			this[$entity].digest["not_verified"]+= item.verified	==	"no"		?1:0;
			this[$entity].digest["original"]	+= item.status		==	"OR"		?1:0;
			this[$entity].digest["rectified"]	+= item.status		==	"CX"		?1:0;
			this[$entity].digest["ammended"]	+= item.status		==	"AM"		?1:0;
			this[$entity].digest["arrivals"]	+= item.direction	==	"arrival"	?1:0;
			this[$entity].digest["departures"]	+= item.direction	==	"departure"	?1:0;
			this[$entity].digest["both"]		+= item.direction	==	"both"		?1:0;
		})

		this.generateDigests($entity,
			{
				total			: 	{
										total			: this[$entity].total,
										count			: this[$entity].count
									},
				verified		: 	{
										verified		: this[$entity].digest["verified"],
										not_verified	: this[$entity].digest["not_verified"],
									},
				errors			: 	{
										bookings_with_errors		: this[$entity].digest["bookings_with_errors"	],
										bookings_with_no_errors		: this[$entity].digest["bookings_with_no_errors"],
										bookings_total_errors		: this[$entity].digest["bookings_total_errors"	],
									},
				status			: 	{
										original		: this[$entity].digest["original"],
										rectified		: this[$entity].digest["rectified"],
										ammended		: this[$entity].digest["ammended"],
								},
				direction 		: 	{
										arrivals		: this[$entity].digest["arrivals"],
										departures		: this[$entity].digest["departures"],
										both			: this[$entity].digest["both"],
								}
			}
		);
	}

	private async bookingsSearch(){
		let $entity 			= "bookings";
		this[$entity].spinner 	=	true;

		await this.entityService.loadEntity( "bookings_search", 
			{ 
				token: this.pageInfo.search.content 
			}
		);

		// Override bookings with search results
		this[$entity].data 		= 	this.entityService.get("bookings_search");
		this[$entity].count 	= 	this[$entity].data ? this[$entity].data.length : 0;
		this[$entity].total 	= 	this.entityService.getTotal("bookings_search");

		this[$entity].data 		= 	this.normalizeBookings(this[$entity].data);
		this[$entity].spinner	= 	false;

		if(this[$entity].count==0){
			this.commons.generateToast("_INFO","_NO_RESULTS","info");
			return false;
		}

		// Sort by date and time
		this[$entity].data = this.sortEntity($entity,this[$entity].data);

		this.generateExtraBookingsInfo($entity,this);

		this.pageInfo.search.show	= 	false;
		this.pageInfo.loadingData	=	false;

		this.filterData($entity);

		this.pageInfo.currentBookingsClean = false;	// Regenerate bookings
	}

	/**
	 * sort entity
	 */
	sortEntity($entity,$data){
		switch($entity){
			case "bookings":	return this.sortBookings({ "data" : $data });
		}
	}

	private sortBookings($params){
		let $data = $params["data"] || [];

		$data.sort((a,b)=>{
			let v = false;
			switch(a.direction){
				case "both"		:
				case "arrival"	:
					switch(b.direction){
						case "both"		:
						case "arrival"	:	v = (a.arrival_Date>b.arrival_Date 	 	||( a.arrival_Date==b.arrival_Date   	&& a.arrival_Time >= b.arrival_Time		)); break;
						case "departure":	v = (a.arrival_Date>b.departure_Date 	||( a.arrival_Date==b.departure_Date 	&& a.arrival_Time >= b.departure_Time 	)); break;
					}
					break;
				case "departure":
					switch(b.direction){
						case "both"		:
						case "arrival"	:	v = (a.departure_Date>b.arrival_Date 	||( a.departure_Date==b.arrival_Date 	&& a.departure_Time >= b.arrival_Time 	)); break;
						case "departure":	v = (a.departure_Date>b.departure_Date 	||( a.departure_Date==b.departure_Date 	&& a.departure_Time >= b.departure_Time )); break;
					}
					break;
			}
			return v?1:-1;
		});

		return $data;
	}

	private async loadLodgings(){
		const $entity				=	"lodgings";
		let response 				=	await Promise.resolve(this.entityService.getRequest("hotels", { destination: this.commons.userInfo.destination.id }));
		this[$entity].data 			= 	response["data"].map(item=>{
			// Generate Hotel Alias hashmap
			// let self = this;
			(item.aliases||[]).forEach((alias)=>{
				this.pageInfo.aliases["lodgings"][alias.name+"#"+alias.area] = {
					name		: 	item.name,
					alias_name	:	alias.name,
					alias_area 	:	alias.area
				}
			});
			return item;
		});

		console.log("LODGINGS"	,this[$entity].data);
		console.log("ALIASES"	,this.pageInfo.aliases["lodgings"]);

		this.lodgingService.init({
			aliases	: this.pageInfo.aliases["lodgings"],
			lodgings: this[$entity].data
		});
	}

	/**
	 * load fleet
	 */
	private async loadFleet(){
		const $entity			= 	"fleet";
		let response 			= 	await this.transportService.getFleet(
										this.commons.userInfo.dmc.id, 
										this.commons.userInfo.destination.id
									);
		this[$entity].data		= 	response["data"].map(item=>{
											item.label = (item.name||"_NO_NAME").toLowerCase();
											item.value = item.name;
											return item;
										});
		console.log("FLEET",this[$entity].data);
	}

	/**
	 * load flights
	 */
	private async loadFlights(){
		const $entity		= 	"flights";
		const date			=	moment(this.calendar.value).format("YYYY/MM/DD");
		const isocode		= 	this.commons.userInfo.currentDestination.isocode;

		if(undefined==date || undefined==isocode){
			this.commons.generateToastError("_NO_DESTINATION_FOR_FLIGHTS");
		}

		let response 		= 	await this.flightService.loadFlights({ 
									date		: date, 
									offset		: this.calendar.offset,
									destination	: isocode
								});

		this[$entity].data 	= response.map(item=>{
			item.flightNumber = item.code;
			return item;
		});
		console.log("FLIGHTS",this[$entity]);		
	}

	async load($entity, $params={}) {
		let response;
		let filterAfter = true; 
		switch ($entity) {
			case 'flights'				:	await this.loadFlights();		break;
			case 'fleet'				:	this.loadFleet();				break;
			case 'lodgings'				:	this.loadLodgings(); 			break;

			case 'destinationInfo'		:	this.companyService.subscribeDestination(this.commons.userInfo.dmc.id, this.commons.userInfo.destination.id).subscribe(data => {
												this.bookingsService.setInfo('destinationRef', 	data.payload.ref);
												this.bookingsService.setInfo('lodging_alias', 	data.payload.data()['lodging_alias'] || {})
												// this.pageInfo.destinationRef	=	data.payload.ref;
												this.pageInfo.lodging_alias = data.payload.data()['lodging_alias'] || {};
											})
											break;

			case 'bookings_search'		:	this.bookingsSearch();	
											filterAfter = false;
											break;


			case 'airports': 				this[$entity].data 			= 	[];
											this[$entity].loading 		= 	true;
											await this.entityService.loadEntity($entity);
											this[$entity].data 			= 	this.entityService.get("airports");
											// this.paginate($entity, null);
											this[$entity].paginate		=	this[$entity].data;
											this[$entity].count 		= 	this[$entity].data ? this[$entity].data.length : 0;
											this[$entity].loading 		= 	false;
											break;

			case 'flights': 				this[$entity].data 			= 	[];
											this[$entity].loading 		= 	true;
											await this.entityService.loadEntity($entity);
											this[$entity].data 			= 	this.entityService.get("flights");
											// this.paginate($entity, null);
											this[$entity].paginate		=	this[$entity].data;
											this[$entity].count 		= 	this[$entity].data ? this[$entity].data.length : 0;
											this[$entity].loading 		=	false;
											break;

			case "areas": 					response = await Promise.resolve(this.entityService.getRequest("areas", {}));
											this[$entity].data 			= 	response["data"];
											break;

			case 'transports': 				this[$entity].data 			= 	[];
											await this.entityService.loadEntity($entity);
											this[$entity].data 			= 	this.commons.getEntity($entity);
											break;

			case "providers": 				this.loadProviders({ entity: $entity });
											break;

			// case 'zonesDestination': 		this[$entity].data 			= 	[];
			// 								console.log('loading zones', this.commons.userInfo);
			// 								response 					= 	await this.firebaseCtrl.getPromisedZonesByDestination(this.commons.userInfo.dmc.id, this.commons.userInfo.destination.id);
			// 								this[$entity].data 			= 	response || null;
			// 								this.bookingsService.setInfo('zonesDestination', this[$entity]);
			// 								console.log('List Zones', response);
			// 								break;
		}

		// Filtering data after load any entity
		if(filterAfter){
			this.filterData($entity);
		}
	}

	async loadBookings($params={}){
		let $entity	= "bookings";

		this.loadFlights();
		
		if(undefined==this.pageInfo.currentProvider){
			this.commons.generateToast("_LOAD_BOOKINGS","_NO_PROVIDER_FOUND","error");
			return false;
		}

		this[$entity].paginateInfo 	= 	{ 	
			first		: 0,
			rows		: this.pageInfo.tableRows,
			page		: 1,
			pageCount	: Math.ceil(length / this.pageInfo.tableRows)
		};

		// Clear search
		this.pageInfo.search.content=	"";

		// this[$entity].data = Array.from(Array(100).keys()).map((item, index) => ({id: index} ));
		$params["filters"]			= 	this.getButtonFilters();

		// Check parameters
		if(undefined==this.pageInfo.currentProvider){
			// this.commons.generateToast("_INFO","_SELECT_																																																																																																																																																															","info");
			return false;
		}

		// $params["providers"]		= 	this.providers.data.filter(i=>i.selected).map(j=>j.id);
		$params["providers"]		=	[ this.pageInfo.currentProvider.id ];

		// Check calendar date
		this.calendar.date 			=	moment(this.calendar.value).format('YYYY-MM-DD');

		// this.pageInfo.loadingData	=	true;
		this[$entity].spinner 		=	true;
		this[$entity].data 			=	[];
		$params["filters"]			=	JSON.stringify($params["filters"]);

		await this.entityService.loadEntity( $entity, {	...$params,
			...{
				startdate	: 	this.calendar.date, offset: this.calendar.offset,
				dmc			:	this.commons.userInfo.currentDmc.id,
				destination	:	this.commons.userInfo.currentDestination.dbid
			}
		});

		this[$entity].data 			= 	this.entityService.get($entity);

		this[$entity].count 		= 	this[$entity].data ? this[$entity].data.length : 0;
		this[$entity].total 		= 	this.entityService.getTotal($entity);

		this[$entity].data 			= 	this.normalizeBookings(this[$entity].data);
		this[$entity].spinner	 	= 	false;
		// this.pageInfo.loadingData	= 	false;

		if(this[$entity].count==0){
			this.commons.generateToast("_INFO","_NO_RESULTS","info");
		}

		// Sort by date and time
		this[$entity].data 			= 	this.sortEntity($entity,this[$entity].data);

		// GENERATE EXTRA INFO
		this.generateExtraBookingsInfo($entity,this);

		this.pageInfo.currentBookingsClean = false;

		this.filterData($entity);

		// Finally set pointer to current booking type to handle bookings easily
		this.bookings = this[$entity];
	}

	async loadProviders($params){
		let $entity = $params["entity"];
		this[$entity].data 			= 	[];
		// response							= await Promise.resolve(this.entityService.getComplexRequest("companyInfo",{id:1}));
		// this[$entity].data					= response["data"];
		this[$entity].data 			= 	(await this.providerService.getProviders() || []).map(item => {
			item["logo"] 			= 	"/assets/layout/icons/providers/" + item["thumbnail"] + ".png";
			item["cancelable"]		=	undefined==item["cancelable"]?true:item["cancelable"];
			return item;
		}).sort((a:any,b:any)=>parseInt(a.id)>=parseInt(b.id)?1:-1);

		console.log("PROVIDERS",this[$entity].data);

		// Mark error if no providers selected
		if(this[$entity].data.length==0){
			this.commons.generateToast("_ERROR","_NO_PROVIDERS_FOUND_CONTACT_AGENT","error");
			return false;
		}

		// If only 1 item select it by default
		if(this[$entity].data.length==1){ this[$entity].data[0].selected = true;	}

		// this.bookings.filters[0].items		= this[$entity].data.map(item=>({ label: item.name, value: item.id }));
		// this.bookings.filters[0].selected	= this[$entity].data.map(item=>item.id);
		this.generateProvidersMenu({ providers: this[$entity].data });
	}

	paginate($entity, $event?) 			{
		this.paginateLocal($entity, $event);
		//this.paginateRemote($entity,$event);
	}

	/**
	* Paginate Entity Grid
	* @param $entity
	* @param $event
	*/
	paginateLocal($entity, $event?) 	{	// First, rows, page, pageCount

		const length = !this[$entity].filteredData ? 0 : this[$entity].filteredData.length;
		this[$entity].paginateInfo 	= $event || { 	first		: 0,
													rows		: this.pageInfo.tableRows,
													page		: 1,
													pageCount	: Math.ceil(length / this.pageInfo.tableRows)
												};

		// this[$entity].paginate 		= !this[$entity].filteredData
		// 								? []
		// 								: this[$entity].filteredData.slice(
		// 									this[$entity].paginateInfo.first,
		// 									this[$entity].paginateInfo.first + this[$entity].paginateInfo.rows
		// 								);

		// this[$entity].paginate 		= !this[$entity].filteredData
		// 								? []
		// 								: this[$entity].filteredData.slice(
		// 									$event.page*this.pageInfo.tableRows,
		// 									($event.page+1)*this.pageInfo.tableRows
		// 								);
	}

	/**
	* Paginate Entity Grid
	* @param $entity
	* @param $event
	*/
	paginateRemote($entity, $event?) 	{	this.loadBookings({ page_offset: $event.first, page_limit: $event.rows });	}

	translate($token)					{	return this.commons.getTranslate($token); }

	getCols($entity, $property, $item) 	{
		switch ($property) {
			case "qty": switch ($entity) {
							case "filterButton": return this.pageInfo.filterButtons;
						}
			}
	}

	handleOption($type,$event) 			{
		switch($type){
			case "expander":
				switch($event.action){
					case "flight"		:
						switch($event.item.name){
							case "create_alias"		:	this.createFlightAlias($event); break;
						}								
						break;

					case "autocomplete":	
						this.doAutocomplete({
							type	: 'select',
							item	: $event.item,
							rowData	: $event.rowData
						});						
						break;
				}				
				break;

			case "flight":
				switch ($event.action) {
					case "cancel"		: this.bookings.checks.flight.panel = false; break;
					case "accept"		: this.bookings.checks.flight.panel = false; break;
					case "reject"		: this.bookings.checks.flight.panel = false; break;
				}
				break;
			case "lodging":
				switch ($event.action) {
					// case "cancel"		: this.bookings.checks.flight.panel = false; break;
					// case "accept"		: this.bookings.checks.flight.panel = false; break;
					// case "reject"		: this.bookings.checks.flight.panel = false; break;
					case 'accept'		: 	console.log("EVENT",$event);
											this.bookingsService.updateLodgingInfo({
												field 	: $event.data.field,
												booking : $event.data.booking,
												data 	: $event.data
											});
											this.bookings.checks.hotel.panel = false;
											break;

					case 'reject'		: 	this.bookings.checks.hotel.panel 	= false;
											this.bookings.checks.reject.panel 	= true;
											break;
				}
				break;
		}
	}

	assignFlight(item) {
		alert("Assigning Flight " + item.flight);
		this.messageService.add({
			severity: 'success',
			summary: 'Message',
			detail: "Assigning Flight " + item.flight
		});
	}

	async calendarChange(propagation: boolean = true, forced = false) {
		this.calendar.value.setHours(12);
		this.calendar.date = this.calendar.value.toISOString().split('T')[0];

		// FORCE DATE
		// this.calendar.date 	= "2019-08-01";
		// console.log("FORCED CALENDAR DATE", this.calendar.date);

		if (forced || this.calendar.date !== this.calendar.last) {
			this.calendar.last = this.calendar.date;
		}
	}

	generateMenuCols($entity)	{
		this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
	}

	// -----------------------------------------------------------------------------------
	// ACTION METHODS
	// -----------------------------------------------------------------------------------

	checkIfEditable($item,$row)	{	return $row["newItem"]?!($item["newBlocked"]?true:false):$item["editable"];			}

	getFilteredEntity($type,$info?)	{
		switch($type){
			case "lodgings"	:
				let response = (this.lodgings||{}).data||[];
				return response;
				break;

			case "flights"	:	return (this.flights||{}).data||[];

			case "logs"		:	return undefined==$info?[]:($info["rowData"]||{}).logs;

			case "imported"	:	return (this.pageInfo.imported_bookings_response||[]).map(response=>{
									let info = response.data || {};
									switch(response.success){
										case "true"	:
										case true	:	info.status = "_STATUS_OK";		break;
										default		:	info.status = "_STATuS_ERROR";	break;
									}
									info.action = "update";
									return info;
								});
								break;

			case "imported"	:	return (this.pageInfo.checked_bookings_response||[]).map(response=>{
									let info = response.data || {};
									switch(response.success){
										case "true"	:
										case true	:	info.status = "_STATUS_OK";		break;
										default		:	info.status = "_STATuS_ERROR";	break;
									}
									info.action = "update";
									return info;
								});
								break;

			case "bookings_to_check":
			case "bookings"			:
			case "checked"			: return this.getBookings({ type: $type, info: $info});
		}
	}

	getBookings($params){
		const $type 	= $params["type"];
		const $info 	= $params["info"];

		let bookings 	= [];

		switch($type)	{

			case "bookings_to_check":
				return this.bookings.filteredData.map((b,index)=>{
					if(index%3==0){ b.found='y'; }
					if(index%3==1){ b.found='n'; }
					if(index%3==2){ b.found='a'; b.action='request'; }
					return b;
				});

			case "bookings"	:
				switch($info.type){
					case "premount"	:
						if(!this.pageInfo.currentBookingsClean){
							this.pageInfo.currentBookings = this.generateBookings({ type: $info.type });
						}
						break;
					case "all"		:
					default			:
						if(!this.pageInfo.currentBookingsClean){
							this.pageInfo.currentBookings = this.generateBookings({ type: $info.type });
						}
						break;
				}

				this.bookings.paginate = this.filterBookings({ type: $info.type, bookings: this.pageInfo.currentBookings })
											.slice(
												this.bookings.paginateInfo.first,
												this.bookings.paginateInfo.first + this.bookings.paginateInfo.rows
											);
				return this.bookings.paginate;

			case "checked"	: return this.pageInfo.currentBookings;
		}
	}

	filterBookings($params){
		let $bookings = $params["bookings"];

		$bookings = $bookings.filter(booking=>{
			let every = true;
			// let some = filter.items.some(item=>booking[filter.field] == item.value);
			// 	return some;
			// })
			return every;
		});

		switch($params["type"]){
			case "premount":	$bookings = $bookings.slice(0,2); break;
		}

		return $bookings;
	}

	generateBookings($params)	{
		// Filter by fields value of search content
		let content 	= 	this.pageInfo.search.content;
		let fields		=	this.pageInfo.search.fields;

		this.bookings.digests.bookings_with_errors 		= 0;
		this.bookings.digests.bookings_with_no_errors 	= 0;
		this.bookings.digests.bookings_total_errors 	= 0;

		let local_items	=	(this.bookings.filteredData||[])
							.filter(item=>{
								let found = false;
								if(content!=""){
									found = fields.reduce((o,token)=>{
										return o || (item[token]||"").toLowerCase().includes(content.toLowerCase());
									},false);
								} else {
									found = true;
								}
								return found;
							})
							.map((item,index)=>{
								item["index"] = this.bookings.paginateInfo.first + index + 1;
								this.bookings.digests.bookings_with_errors 		+= item.errors.length>0?1:0;
								this.bookings.digests.bookings_with_no_errors 	+= item.errors.length>0?0:1;
								this.bookings.digests.bookings_total_errors 	+= item.errors.length;
								return item;
							})
							;

		let remote_items = [];

		this.pageInfo.currentBookings 		= [ ...local_items, ...remote_items ];
		this.pageInfo.currentBookingsClean	= true;

		return this.pageInfo.currentBookings;
	}

	getInfo($type,$info)		{
		switch($type){
			case "tab"			:
				switch($info.type){
					case "is_selected"	: return $info.panel.selected==$info.tab.name?'selected':'';
					case "expander"		:
						switch($info.rowData.direction){
							case "arrival"	: return $info.tab.name!="departure";
							case "departure": return $info.tab.name!="arrival";
						}
						return true;
						break;
				}
				break;

			case "flight"		:
				switch($info.type){
					case "has_errors":	return $info.rowData.errors.some(error=>error==$info.item.check_field);
				}
				break;

			case "filter"		:	switch($info.type){
										case "is_selected"		:	return $info.filter.selected.some(selected=>selected==$info.item.value);
									}
									break;

			case "bookings"		:	switch($info.type){
										case "no_zone_error"	: 	return $info.rowData.zone==undefined;
										case "errors"			:	return this.bookings.digests.bookings_with_errors; 		break;
										case "no_errors"		:	return this.bookings.digests.bookings_with_no_errors;	break;
										case "total_errors"		:	return this.bookings.digests.bookings_total_errors;		break;
									}
									break;

			case "checks"		:	switch($info.type){
										case "check"			:	let response = this.checkService.checkField({
																		verify	: false,
																		checks	: this.bookings.checks,
																		type	: "check",
																		item	: $info.item,
																		data	: $info.rowData
																	});
																	this.bookings.checks = response;
																	break;

										case "displayFixAction"	:	this.checkService.displayFixAction($info.item,$info.rowData); break;
									}
									break;

			case "field"		:	switch($info.type){
										case "background"	: return $info.item.autoFixIcon?'green':'transparent';
										case "color"		: return $info.item.autoFixIcon?'green':'transparent';
									}
									break;

			case "expanderForm"	: 	
				if(undefined==this.bookings.expanderForm){ return []; 	}
				if(undefined==$info.item.provider		){ return [];	}

				switch($info.item.direction){
					case "arrival"	:
						this.bookings.expanderForm.form = this.pageInfo.providerExpandedForm[this.pageInfo.currentProvider.id]["arrival"];
						break;
					case "departure":
						this.bookings.expanderForm.form = this.pageInfo.providerExpandedForm[this.pageInfo.currentProvider.id]["departure"];
						break;
					default			:
					case "both"		:
						switch(this.pageInfo.tabs.rowexpander.selected){
							case "arrival":
								this.bookings.expanderForm.form = this.pageInfo.providerExpandedForm[this.pageInfo.currentProvider.id]["arrival"];
								break;
							case "departure":
								this.bookings.expanderForm.form = this.pageInfo.providerExpandedForm[this.pageInfo.currentProvider.id]["departure"];
								break;
						}
						break;
				}
				return this.bookings.expanderForm.form;

			case "rowData"		: 	
				switch($info.type){
					case "statusColor"				:	
						if($info.rowData["assigned"]		){ return "purple"; }
						if($info.rowData['verified']=='yes'	){ return 'green';	}
						return 'gray';

					case "statusTransporterColor"	:	
						return this.getTransporterInfo({ type: 'color', item: $info });
					
					case "verifiedColor"			:
						switch($info.rowData["status"]){
							default					:	return "gray";
							case "PCAN"				:
							case "ACAN"				:	return "crimson";
						}
				}
				break;
		}
	}

	/**
	 * get info related to transporter
	 * @param $data
	 * @returns
	 */
	getTransporterInfo($data){
		let $info = $data.item;
		switch($info.rowData["transporter_status"]){
			case "sent"		:	return "blue";
			case "cancelled":	return "crimson";
			case "rejected"	:	return "crimson";
			case "accepted"	:	return "orange";
			case "done"		:	return "green";
			case "pending"	:
			default			: 	return "gray";
		}
	}

	/**
	 * request concrete reference from provider
	 * @param $params
	 * @returns
	 */
	async requestFromProvider($params?)
	{
		this.commons.generateToast("_REQUEST", "_REQEUST_REFERENCE_FROM_PROVIDER", "error");
		return false;

		if(undefined==this.pageInfo.currentProvider){
			this.commons.generateToast("Error", "_SELECT_PROVIDER", "error");
			return false;
		}

		this["bookings"].spinner = true;

		await this.entityService.postJSON(
			this.entityService.getUrl("bookings_provider_refresh"),
			{ 	provider	: this.pageInfo.currentProvider.id,
				destination : this.commons.userInfo.destination.id,
				dmc			: this.commons.userInfo.dmc.id,
				date		: moment(this.calendar.value).format('YYYY-MM-DD'),
				offset		: this.calendar.offset || 0
			}
		).then(response => {
			this["bookings"].spinner = false;
			this.commons.generateToast("Booking refresh", "Booking refreshed correctly", "success");
			return true;

		}).catch(response => {
			this["bookings"].spinner = false;
			let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
			this.commons.generateToast("Booking assignation", "Booking could not be refreshed from provider", "error");
		})
	}

	async refreshFromProvider($params?){
		if(undefined==this.pageInfo.currentProvider){
			this.commons.generateToast("Error", "_SELECT_PROVIDER", "error");
			return false;
		}

		this["bookings"].spinner = true;
		await this.entityService.postJSON(
			this.entityService.getUrl("bookings_provider_refresh"),
			{ 	provider	: this.pageInfo.currentProvider.id,
				destination : this.commons.userInfo.destination.id,
				dmc			: this.commons.userInfo.dmc.id,
				date		: moment(this.calendar.value).format('YYYY-MM-DD'),
				offset		: this.calendar.offset || 0
			}
		).then(response => {
			this["bookings"].spinner = false;
			this.commons.generateToast("Booking refresh", "Booking refreshed correctly", "success");
			return true;

		}).catch(response => {
			this["bookings"].spinner = false;
			let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
			this.commons.generateToast("Booking assignation", "Booking could not be refreshed from provider", "error");
		})
	}

	importBookings(){
		if(undefined==this.pageInfo.currentProvider){
			this.commons.generateToast("_BOOKINGS_IMPORTER","_NO_PROVIDER_FOUND","error");
			return false;
		}
		this.pageInfo.overlay_import_bookings = this.pageInfo.overlay_import_bookings?false:true;
	}

	async sendAssignedBookings($items){
		if(undefined==this.pageInfo.currentProvider){
			this.commons.generateToast("Booking assignation","_SELECT_PROVIDER","error");
			return false;
		}
		this["bookings"].spinner 	= true;
		let filters					= this.getButtonFilters();
		console.log("FILTERS",filters);

		await this.entityService.postJSON(
			this.entityService.getUrl("bookings_send_assigned"),
			{ 	provider	: this.pageInfo.currentProvider.id,
				destination : this.commons.userInfo.destination.id,
				dmc			: this.commons.userInfo.dmc.id,
				date		: moment(this.calendar.value).format('YYYY-MM-DD'),
				offset		: this.calendar.offset,
				directions	: filters.direction
			}
		).then(response => {
			this["bookings"].spinner = false;
			// this.commons.generateToast("Booking assignation", "Booking assinged "+response["response"], "success");
			// this.commons.generateToast("Booking assignation", response["response"], "success");
			if(response["success"]==false){
				// this.commons.generateToast("Booking assignation", "_BOOKINGS_ASSIGNATION_ERROR", "error");
				this.commons.generateToast("Booking assignation", response["error"], "error");
			} else {
				this.commons.generateToast("Booking assignation", "_BOOKINGS_ASSIGNATION_SUCCESS", "success");
			}
			return true;

		}).catch(response => {
			this["bookings"].spinner = false;
			let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
			// this.commons.generateToast("Booking assignation", "_BOOKINGS_ASSIGNATION_ERROR", "error");
			this.commons.generateToast("Booking assignation", response["error"], "error");
		})
	}

	selectProvider($info){
		this.toggle('provider', $info);
		this.pageInfo.currentProvider = this.providers.data.find(i=>i.selected);
		console.log("CURRENT PROVIDER",this.pageInfo.currentProvider);
		// if(this.pageInfo.currentProvider){
		// 	this.bookings.expanderForm.form = this.pageInfo.providerExpandedForm[this.pageInfo.currentProvider.shortname]["default"];
		// 	if(undefined==this.bookings.expanderForm.form){
		// 		this.commons.generateComplexToast({
		// 			title	: 	"_SELECT_PROVIDER",
		// 			content	: 	[ 	"_EXPANDED_FORM_NOT_FOUND_FOR",
		// 							this.pageInfo.currentProvider.shortname
		// 						],
		// 			type	:	"error"
		// 		});
		// 		return false;
		// 	}
		// }
	}

	fixesFlights(){
		this.commons.generateToast("_FIXES_FLIGHTS","_FIXES_FLIGHTS_TITLE","info");
	}

	fixesLocations(){
		this.commons.generateToast("_FIXES_LOCATIONS","_FIXES_LOCATIONS_TITLE","info");
	}

	checkBookings(){
		this.commons.generateToast("_CHECK_BOOKINGS","_CHECK_BOOKINGS_TITLE","info");
		this.pageInfo.overlay_check_bookings = true;
	}

	checkBookingsFromProvider(){
		this.pageInfo.bookings_check_provider_found = [];
		if(this.pageInfo.bookings_check_provider==''){
			return false;
		}
		this.pageInfo.bookings_check_provider_found = ['AAA','BBB'];
		this.commons.generateToast("_CHECK_BOOKINGS_FROM_PROVIDER","_CHECK_BOOKINGS_FROM_PROVIDER_TITLE","info");
		console.log(this.pageInfo.bookings_check_provider);
	}

	requestBooking($params){
		this.commons.generateToast("_REQUEST_BOOKING","_REQUEST_BOOKING_TITLE","info");
		console.log("[RequestBooking] booking",$params);
	}

	autoverifyBookings($params){
		this.commons.generateToast("_AUTOVERIFY","_AUTOVERIFYING_BOOKINGS","info");
		let cleanBookings 	= this.pageInfo.currentBookings
								.filter(b=>{
									switch($params["forced"]){
										case true	: 	return true;
										default		:	return (b.errors||[]).length==0;
									}
								});

		if(!$params["all_bookings"]){
			cleanBookings	= (cleanBookings||[]).filter(b=>b.verified=="no");
		} else {
			this.commons.generateToast("_AUTOVERIFY","_AUTOVERIFYING_ONLY_NOT_VERIFIED","info");
		}
		let length			= (cleanBookings||[]).length;
		this.commons.generateComplexToast({
			title	: "_FOUND",
			content	: [ "_FOUND", length, "_BOOKINGS" ],
			type	: "info"
		});

		let verifieds		= 0;
		let errors			= 0;

		cleanBookings.forEach(async b=>{
			// Just change Booking status and verify status
			b.verified 		= "yes";
			const response 	= await this.acceptBooking(b,false);
			verifieds		+= response?1:0;
			errors			+= !response?1:0;
		}) 

		this.commons.generateComplexToast({ 
			title	: "_INFO",
			content : [ "_BOOKING_UPDATES", verifieds ], 
			type	: "info" 
		});

		this.commons.generateComplexToast({ 
			title	: "_INFO",
			content : [ "_BOOKING_ERRORS", errors ], 
			type	: "error" 
		});
	}

	getPendingBookings(){
		this.commons.generateToast("PENDING","_GET_PENDING_BOOKINGS_FROM_PROVIDER","info");
	}

	clearPendingBookings(){
		this.pageInfo.currentBookings = this.pageInfo.currentBookings.filter(b=>!b.dirty);
	}

	/**
	 * get resorts from current provider bookings
	 * map current provider to tourinia_resorts
	 */
	async importResorts(){
		if(undefined==this.pageInfo.currentProvider){
			this.commons.generateToast("_BOOKINGS_IMPORTER","_NO_PROVIDER_FOUND","error");
			return false;
		}

		let duplicated_resorts = [];
		this.pageInfo.currentBookings.forEach(booking=>{
			switch(booking.direction){
				case "arrival"	: 	duplicated_resorts.push(booking.arrival_To); 		break;
				case "departure": 	duplicated_resorts.push(booking.departure_From);	break;
				case "both"		: 	duplicated_resorts.push(booking.arrival_To);
									duplicated_resorts.push(booking.departure_From);	break;
			}
		})

		let resorts = new Set(duplicated_resorts);
		console.log("RESORTS",resorts);

		// persist mapping into provider
		let docPath = "";
		let data 	= {};
		let destinationData 	= await this.firebaseService.getDataByRef(this.commons.userInfo.currentDestination.refDestination);

		if(undefined==destinationData){
			this.commons.generateToast("_ERROR","_DESTINATION_DATA_NOT_FOUND","error");
			return false;

		}
		let providerMappings	= destinationData["provider_mappings"] || {};
		let providerId			= this.pageInfo.currentProvider.id;
		let newMappings			= {};
		let touriniaResorts		= destinationData["tourinia_resorts"] || [];

		resorts.forEach(resort=>{
			newMappings[resort] = resort;
			if(!touriniaResorts.some(tourinia_resort=>tourinia_resort==resort)){
				touriniaResorts.push(resort);
			}
		})

		if(undefined==providerMappings[providerId]){
			providerMappings[providerId] = {};
		}

		providerMappings[providerId] = { ...providerMappings[providerId], ...newMappings };
		
		// Persistible information into Firebase
		destinationData["tourinia_resorts"	] = touriniaResorts;
		destinationData["provider_mappings"	] = providerMappings;
		
		// Made it accessible without reload
		if(!this.commons.userInfo.provider_mappings){
			this.commons.userInfo.provider_mappings = {};
		}
		this.commons.userInfo.provider_mappings[providerId] = providerMappings[providerId];
		this.commons.userInfo.tourinia_resorts 				= touriniaResorts;
		
		if(!this.commons.userInfo.tourinia_areas_2_zones){
			this.commons.userInfo.tourinia_areas_2_zones	= providerMappings[providerId];
		}

		// Update destination info
		this.firebaseService.updateItemByRef(
			this.commons.userInfo.currentDestination.refDestination,
			destinationData
		);

		this.commons.generateComplexToast({
			title	: "_IMPORT_RESORTS",
			content	: [ "_FOUND", (touriniaResorts||[]).length, "_RESORTS" ],
			type	: "info"
		});

		// Reload bookings to get changes applied
		this.loadBookings();
	}

	cloneResorts(){

	}

	do_map_vehicle($info){
		let type, pax, seats, vehicle, dmc, destination;

		type		= $info.rowData[$info.item.field];
		pax 		= parseInt($info.rowData["pax"]);
		seats 		= parseInt(type["pax"]);
		vehicle		= $info.rowData["vehicle"];
		dmc			= this.commons.userInfo.dmc.id;
		destination = this.commons.userInfo.destination.id;

		// Set vehicles quty
		$info.rowData["vehicles"] 	= Math.ceil(pax/seats);

		// If vehicle assigned is empty set to vehicle mapped
		if(undefined == $info.rowData["vehicle_assigned"]){
			$info.rowData["vehicle_assigned"		] = type;
			$info.rowData["vehicle_assigned_qty"	] = $info.rowData["vehicles"];
		}

		// Add alias to type
		type["aliases"]				= type["aliases"] || [];

		if(!type["aliases"].some(item=>item==vehicle)){
			type["aliases"].push(vehicle);
		}

		this.transportService.saveAlias(dmc,destination,type["id"],vehicle);
	}

	do_assign_vehicle($info){
		let type,pax,seats;

		type		= $info.rowData[$info.item.field];
		pax 		= parseInt($info.rowData["pax"]);
		seats 		= parseInt(type["pax"]);

		// Update qty
		$info.rowData["vehicle_assigned_qty"] 	= Math.ceil(pax/seats);
	}

	do_municipality($info){
		let municipality	= ($info.rowData[$info.item.field]||{})["id"];
		let mappings		= this.commons.userInfo.tourinia_areas_2_zones || {};

		if(undefined==municipality){
			this.commons.generateToast("_ERROR","_NO_MUNICiPALITY_FOUND","error");
			return false;
		}

		if(undefined==mappings[municipality]){
			this.commons.generateToast("_ERROR","_NO_ZONE_FOUND_FOR_AREA","error");
			return false;
		}

		if(undefined==this.commons.userInfo.tourinia_resorts_2_areas){
			this.commons.userInfo.tourinia_resorts_2_areas = {}
		}

		let value;
		switch($info.item.field){
			case "arrival_municipality_dropdown"	:
				value	 						= $info.rowData["arrival_resort_tourinia"];
				$info.rowData["arrival_Zone"] 	= mappings[municipality];
				$info.rowData["zone"		] 	= mappings[municipality];
				if(undefined==this.commons.userInfo.tourinia_resorts_2_areas[value]){
					this.commons.userInfo.tourinia_resorts_2_areas[value] = municipality;
				}
				break;
			case "departure_municipality_dropdown"	:
				value	 						= $info.rowData["departure_resort_tourinia"];
				$info.rowData["arrival_Zone"] 	= mappings[municipality];
				$info.rowData["zone"		] 	= mappings[municipality];
				if(undefined==this.commons.userInfo.tourinia_resorts_2_areas[value]){
					this.commons.userInfo.tourinia_resorts_2_areas[value] = municipality;
				}
				break;
		}

		// Persist tourinia resorts to municipality mapping
		const docPath 		= "/dmcs/"+this.commons.userInfo.currentDmc.id+"/destinations/"+this.commons.userInfo.currentDestination.id;
		let response:any 	= this.firebaseService.updateItemByRef(docPath, { tourinia_resorts_2_areas : this.commons.userInfo.tourinia_resorts_2_areas });
		if(response.success){
			this.commons.generateToast("_INFO","_MAPPING_RESORT_TO_MUNICIPALITY_OK","info");
		}

		// Update all bookings with same tourinia_resort
		// 	- Assign municipality and zone
		this.pageInfo.currentBookings.forEach(booking=>{
			switch(booking.direction){
				case "arrival"		:	if(booking.arrival_resort_tourinia==value){
											booking.arrival_municipality 	= municipality;
											booking.arrival_Zone			= $info.rowData["zone"];
											booking.zone					= $info.rowData["zone"];
										}
										break;
				case "departure"	:	if(booking.departure_resort_tourinia==value){
											booking.departure_municipality 	= municipality;
											booking.departure_Zone			= $info.rowData["zone"];
											booking.zone					= $info.rowData["zone"];
										}
										break;
			}
		})
	}

	do_mapping($params){
		let $entity			= $params["entity"];
		let $info			= $params["info"];

		let municipality	= ($info.rowData[$info.item.field]||{})["id"];
		let mappings		= this.commons.userInfo.tourinia_areas_2_zones || {};

		let value;
		let persist = false;

		switch($info.item.field){

			case "arrival_canaryshuttle_lodging_dropdown"	:

				value = $info.rowData["arrival_canaryshuttle_lodging_dropdown"];
				if(undefined==value || undefined==value.id){
					this.commons.generateToastError("_CANARYSHUTTLE_MAPPING_ERROR");
					return false;
				}
				$info.rowData["arrival_canaryshuttle_lodging"	] = value.id;
				$info.rowData["canaryshuttle_id"				] = value.id;
				persist = true;
				break;

			case "departure_canaryshuttle_lodging_dropdown"	:

				value = $info.rowData["departure_canaryshuttle_lodging_dropdown"];
				if(undefined==value || undefined==value.id){
					this.commons.generateToastError("_CANARYSHUTTLE_MAPPING_ERROR");
					return false;
				}
				$info.rowData["departure_canaryshuttle_lodging"	] = value.id;
				$info.rowData["canaryshuttle_id"				] = value.id;
				persist = true;
				break;
		}

		if(persist){
			// Persist tourinia resorts to municipality mapping
			let persistables = this.pageInfo.currentBookings
				.filter(booking=>{
					// bypass current booking
					if(booking.id==$info.rowData.id){ 
						console.log("SAME");
						// return true; 
					}

					let found = false;					
					switch(booking.direction){
						case "arrival"	: 
							if(booking["arrival_Location"] && $info.rowData["arrival_Location"]){
								found = booking["arrival_Location"].toLowerCase() == $info.rowData["arrival_Location"].toLowerCase();
							}
							break;
						case "departure": 
							if(booking["departure_Location"] && $info.rowData["departure_Location"]){							
								found = booking["departure_Location"].toLowerCase()== $info.rowData["departure_Location"].toLowerCase();
							}
							break;
						case "both"		: 
							if(booking["arrival_Location"] && $info.rowData["arrival_Location"]){
								found = booking["arrival_Location"].toLowerCase()==$info.rowData["arrival_Location"].toLowerCase();
							}
							if(!found && booking["departure_Location"] && $info.rowData["departure_Location"]){
								found = booking["departure_Location"].toLowerCase()== $info.rowData["departure_Location"].toLowerCase();
							};
							break;
					}
					return found;					
				});

			persistables.forEach(booking=>{
				switch(booking.direction){
					case "arrival"	: 
						booking["arrival_canaryshuttle_lodging"		] = value.id;
						booking["canaryshuttle_id"					] = value.id;
						booking["transporter_location_id"			] = value.id;						
						break;
					case "departure":
						booking["departure_canaryshuttle_lodging"	] = value.id;
						booking["canaryshuttle_id"					] = value.id;
						booking["transporter_location_id"			] = value.id;
						break;
					case "both"		:
						booking["arrival_canaryshuttle_lodging"	] = value.id;
						booking["departure_canaryshuttle_lodging"	] = value.id;
						booking["canaryshuttle_id"					] = value.id;
						booking["transporter_location_id"			] = value.id;
						break;
				}	
				this.saveBooking(booking);
			});
		}
	}

	async extractMappings($params){
		const entity 	= $params.entity;
		const bookings 	= this.pageInfo.currentBookings || [];
		let mappings 	= this.commons.userInfo.currentDestination[entity] || {};
		
		let location;
		let data;

		bookings.forEach(b=>{
			if(!b.canaryshuttle_id){ return false; }
			switch(b.direction){
				case "both"			:
				case "arrival"		: location = b.arrival_Location; 	break;
				case "departure"	: location = b.departure_Location; 	break;
			}
			mappings[location] = b.canaryshuttle_id;
		});

		switch(entity){
			case "canary_shuttle_mappings":
				data = {
					canary_shuttle_mappings: mappings
				};
				break;
			default:
				this.commons.generateToastError("_EXTRACT_MAPPING_UNKNOWN");
				return;
		}

		const docPath	= "/dmcs/"+this.commons.userInfo.currentDmc.id+"/destinations/"+this.commons.userInfo.currentDestination.id;
		let response	= await this.firebaseService.updateDoc(docPath, data);

		if(response["success"]){
			this.commons.generateToast("_INFO","_HOTEL_MAPPINGS_CREATED","info");
		} else {
			this.commons.generateToast("_ERROR","_HOTEL_MAPPINGS_ERROR","error");
		}
	}

	doAutocomplete($info){
		switch($info.type){
			case "select":
				switch($info.item.field){
					case "vehicle_mapped"							: 	this.do_map_vehicle($info); 		break;
					case "vehicle_assigned"							: 	this.do_assign_vehicle($info);		break;
					case "arrival_municipality_dropdown"			:
					case "departure_municipality_dropdown"			: 	this.do_municipality($info);		break;

					// 1844 special case
					// Should be moved to mappings section
					case "arrival_canaryshuttle_lodging_dropdown"	: 	
					case "departure_canaryshuttle_lodging_dropdown"	: 	
						this.do_mapping({ 
							entity	: "canary_shuttle_lodging", 
							info	: $info
						});			
						break;
				}
				break;
		}
	}

	
	/**
	 * 
	 * 
	 * @param $info 
	 * @returns 
	 */
	private async createFlightAlias($info)
	{
		let real_flight;
		let rowData = $info.rowData || {};
		let item	= $info.item	|| {};

		this.commons.generateToast("_INFO","_FLIGHT_ALIAS_CREATING","info");

		switch(item.check_field){
			case "arrival_GatewayInfo"	:
				real_flight = rowData[item.check_field+"_alias"];
				break;
			case "departure_GatewayInfo":
				real_flight = rowData[item.check_field+"_alias"];
				break;
		}

		if(undefined==real_flight || real_flight ==""){
			this.commons.generateToast("_ERROR","_EMPTY_ALIAS","error");
			return false;
		}

		let flights_aliases 					= this.commons.pageInfo.flights_aliases || {};
		let trimmed_field_value					= ( rowData[item.check_field] || "" ).trim();
		flights_aliases[trimmed_field_value]	= real_flight;
		this.commons.pageInfo.flights_aliases	= flights_aliases; 

		// Persist flight alias
		let docPath				= "/dmcs/"+this.commons.userInfo.currentDmc.id+"/destinations/"+this.commons.userInfo.currentDestination.id;
		let response			= await this.firebaseService.updateDoc(docPath, { flights_aliases: flights_aliases });

		if(response["success"]){
			this.commons.generateToast("_INFO","_FLIGHT_ALIAS_CREATED","info");
			// Remove booking current field error
			rowData.errors 		= (rowData.errors||[]).filter(error=>error!=item.check_field);
		} else {
			this.commons.generateToast("_ERROR","_FLIGHT_ALIAS_NOT_CREATED","error");
		}
	}

	async doAction($type, $info) {
		switch ($type) {
			case 'filters'				:
				switch($info.action){
					case "open"			:	this.pageInfo.overlay_filters = true; break;
				}
				break;

			case 'tab'					:
				switch($info.action){
					case "select"		:
						$info.panel.selected=$info.tab.name;
						break;
				}
				break;

			case 'check'				:
				switch($info.action){
					case "request"					:	this.getPendingBookings();						break;
					case "close"					:	this.pageInfo.overlay_check_bookings = false;	break;
					case "bookings_from_provider"	:	this.checkBookingsFromProvider(); 				break
				}
				break;

			case 'command'				:
				switch($info.item.name){
					case "reload_flights"		: 	this.loadFlights(); break;
					case "check_flights"		: 	break;
					case "reload_airlines"		: 	break;
					case "import_resorts"		:	this.importResorts();							break;
					case "clone_resorts"		:	this.cloneResorts();							break;
					case "import_bookings"		:	this.importBookings(); 							break;
					case 'check_bookings'		: 	this.checkBookings();							break;
					case 'fixes_flights'		: 	this.fixesFlights();							break;
					case 'fixes_locations'		: 	this.fixesLocations();							break;
					case "select_provider"		: 	this.selectProvider($info.item);				break;
					case "new_booking"			: 	this.newBooking(); 								break;
					case "clean_bookings"		: 	this.clearBookings();							break;
					case "external_reload"		: 	this.refreshFromProvider();						break;
					case "local_reload"			: 	this.loadBookings();							break;
					case "export_csv"			: 	this.export('csv');								break;
					case "export_pdf"			: 	this.export('pdf');								break;
					case "autoverify_bookings"	: 	this.autoverifyBookings({ forced: false	});		break;
					case "autoverify_forced"	:	this.autoverifyBookings({ forced: true	});		break;
					case "clear_pending"		: 	this.clearPendingBookings();					break;
					case "extract_mappings"		:	this.extractMappings({ entity: "canary_shuttle_mappings" }); break;
					case "persist_all"			:	this.saveAllBookings();							break;
				}
				break;

			case 'booking'				:
				switch($info.action){
					case "request"		:	this.requestBooking($info); break;
					case "paginate"		:	switch($info.type){
												case "remote"	: this.paginateRemote	('bookings', $info.event);	break;
												case "local"	: this.paginateLocal	('bookings', $info.event);	break;
											}
											break;
					case "new"			:	this.newBooking(); 								break;
					case "clear"		:	this.clearBookings();							break;
					case "request"		:	this.requestFromProvider($info.item);			break;
					case "refresh"		:	this.refreshFromProvider();						break;
					case "reload"		:	this.loadBookings();							break;
					case "local_save"	:	this.saveBooking	($info.item);				break;
					case "accept"		:	await this.acceptBooking($info.item,true);		break;
					case "close"		:
					case "cancel"		:	this.closeBooking	($info.item);				break;
					case "verify"		:	this.verifyBooking	($info.item,true);			break;
					case "unverify"		: 	this.unverifyBooking($info.item);				break;
				}
				switch($info.after){
					case "prevent"	:	if($info.event){
											$info.event.preventDefault();
										}
										break;
				}
				break;

			case "row"					:	switch($info.action){
												case "toggle":	this.toggleRow($info.rowData);
											}					break;

			case "checks"				:	switch($info.type){
												case "check"		:	this.checkService.checkField({
																			verify	: false,
																			checks	: this.bookings.checks,
																			type	: "check",
																			item	: $info.item,
																			data	: $info.rowData
																		});
																		this.pageInfo.currentField 						= $info.item.field;
																		this.bookings.checks[$info.item.check].panel 	= true;
																		break;

												case "autoFixIcon"	:	this.checkService.checkField({
																			verify	: false,
																			checks	: this.bookings.checks,
																			type	: "check",
																			item	: $info.item,
																			data	: $info.rowData
																		});
																		break;
											}
											break;

			case "fix"					:	switch($info.type){
												case "handle":	this.checkService.handleFixAction({
																	item	: $info.item,
																	rowData	: $info.rowData,
																	action	: $info.action
																})
																// this.pageInfo.lodging_alias = this.checkService.aliases.lodging;
																break;
											}
											break;

			case "autocomplete"			:	this.doAutocomplete($info);
											break;

			case "filters"				:	switch($info.action){
												case "show"		:	this.pageInfo.filters.show = true;	break;
												case "hide"		:	this.pageInfo.filters.show = false;	break;
											}
											break;

			case "search"				:	
				switch($info.action){
					case "autocomplete"	:	this.doSearch($info.item,$info.event);	break;

					case "search"		:	this.load("bookings_search");			break;

					case "show"			:	this.pageInfo.search.show = true;
											if(this.searchInput){
												this.searchInput.nativeElement.focus();
											}
											break;
					case "hide"			:	this.pageInfo.search.show = false;
											if(this.searchInput){
												this.searchInput.nativeElement.blur();
											}
											break;
					case "clear"		:	this.pageInfo.search.content	= "";
											this.doAction("search",{ action: "hide" });
											break;
				}
				break;

			case "import_bookings"		:	this.importBookings(); break;
			case "sendAssignedBookings"	:	this.sendAssignedBookings($info); break;

			case 'showVideo'			:	this.commons.generateToast("_VIDEO","_SHOW_VIDEO","info"); break;
			case 'changeDate'			:	break;
			case 'changeTime'			:	break;
			case "autoFixAll"			: 	this.autoFix('all'); 				break;
			case "autoFixRow"			: 	this.autoFix('row', $info); 		break;
			case "toggleProvider"		: 	this.selectProvider($info);			break;
			case "filterButton"			:
				this.toggle('filterButton', $info);
				// if (!this.doAction('execRequest', $info)) {
				// 	this.toggle('filterButton', $info);
				// 	this.commons.generateToast("_ERROR", "_FILTERING_BOOKINGS_ERROR", "error");
				// }
				break;

			case "execRequest"			: 	this.loadBookings();			return true;
			case "generateTransports"	:	this.generateTransports(); 		break;

			case "getExpanderForm"		:	return this.bookings.expanderForm;

			case "toggle"				:	//this.commons.generateToast("_ERROR","error","error");
											break;

		}
	}

	/**
	 * create a new booking for current provider
	 *
	 */
	private newBooking(){
		if(!this.pageInfo.currentProvider){
			this.commons.generateToast("_ERROR","_NO_AGGREGATOR_SELECTED","error");
			return false;
		}
		this.pageInfo.editingMode 		= 	true;
		let testRow						= {
			'newItem'					: true,
			'fixes'						: {},
			'errors'					: [],
			'reference'					: '',
			'date'						: new Date(),
			'status'					: 'OR',
			'statusToken'				: this.commons.getTranslate("_STATUS_OR"),
			'company'					: this.pageInfo.currentProvider["thumbnail"],
			'customer'					: '',
			'bookingDate'				: '',
			'phone'						: '',
			'vehicles'					: '1',
			'vehicle'					: '',
			'transferType'				: 'RETURN',
			'location'					: '',
			'addressInfo'				: '',
			'pax'						: '',
			'adults'					: '',
			'children'					: '',
			'infants'					: '',
			'price'						: '',
			'bikes'						: '',
			'surf'						: '',
			'golf'						: '',
			'babychairs'				: '',
			'verified'					: 'no',
			'arrival_StartingPointType'	: 'AP',
			'arrival_From'				: '',
			'arrival_To'				: '',
			'arrival_Date'				: '',
			'arrival_Time'				: '',
			'arrival_GatewayFrom'		: '',
			'arrival_GatewayTo'			: '',
			'arrival_GatewayInfo'		: '',
			'arrival_PickupDate'		: '',
			'arrival_PickupTime'		: ''
		};

		// testRow = this.pageInfo.testRow;
		// testRow	= htxEmptyRow;
		this.bookings.data.unshift(htxEmptyRow);

		this.filterData('bookings');
		// this.paginateLocal(this.bookings,null);
	}

	/**
	 * clear bookings
	 *
	 */
	private clearBookings(){
		this.bookings.data 		= [];
		this.bookings.paginate	= [];
	}

	/**
	 * Try to fix all bookings or just one
	 *
	 * @param type
	 * @param items
	 */
	autoFix(type, items?) 					{	switch (type) 	{	case "all": this.fixAllRows(this.bookings.paginate); 	break;
																	case "row": this.fixRow(items); 						break;
																}
											}

	/**
	 * Fix all bookings
	 *
	 * @param items
	 */
	async fixAllRows(items) 				{	console.log("Fixing all rows");
												this.pageInfo.fixing = true;
												items.forEach(item => this.fixRow(item));
												this.pageInfo.fixing = false;
												console.log('After fixing all rows', items);
											}
	/**
	 * Fix booking
	 *
	 * @param item
	 * @returns
	 */
	async fixRow(item) 						{	if (item.verified == "yes") { console.log("Booking already verified", item.reference); return false; }
												item.verifying = true;
												let paramsToSolve = (item.direction == 'both' || item.direction == 'arrival') ? ['arrival_Location'] : ['departure_Location'];
												for (let param of paramsToSolve) {
													switch (true) {
														default: return;
														case param == 'arrival_Location'	:
														case param == 'departure_Location'	: await this.checkService.fixField({ field: param }, item);
													}
												}
												item.verifying = false;
												}

	// Save all current bookings
	saveAllBookings() {

		const bookings = this.pageInfo.currentBookings || [];

		let message = {
			success : true,
			total	: bookings.length,
			dones	: 0,
			errors	: 0
		}

		this.pageInfo.currentBookings.forEach(b=>{
			const response = this.saveBooking(b,false);
			message["dones"] += response?1:0;
			message["errors"]+= response?0:1;
		});

		this.commons.generateComplexToast({
			title 	: "_PERSIST_BOOKINGS",
			content	: [ "_SAVED_BOOKINGS", message["dones"], "_ERROR_BOOKINGS", message["errors"] ], 
			type	: "info"
		});
	}
								
	/**
	 * Persist Booking
	 *
	 * @param $item
	 */
	async saveBooking($item,showMessage=true) {

		this["bookings"].spinner = true;
		console.log("[saveBooking] booking", $item);

		let set_to_zero			= [ "bikes", "golf", "surf", "babychairs" ];
		set_to_zero.forEach(item=>{
			if(item.includes("last_")){ return; }
			$item[item] 		= $item[item]==undefined || $item[item]==null?"0":$item[item];
		});

		// Remove originals
		let items				= Object.keys($item)
										.filter(currentItem => !currentItem.includes("original_"))
										.filter(currentItem => !currentItem.includes("last_"))
										.reduce((o, item) => { o[item] = $item[item]; return o; }, {})
										;

		// Get calendar value and convert back to date string
		["arrival_Date","departure_Date"].forEach(token=>{
			if(undefined!==items[token+"_Calendar"]){
				$item[token]	= moment(items[token+"_Calendar"]).format('YYYY-MM-DD');
			}
		});

		["arrival_Time","departure_Time"].forEach(token=>{
			if(undefined!==items[token+"_Calendar"]){
				$item[token]	= moment(items[token+"_Calendar"]).format('HH:mm');
			}
		});

		// Convert municipality
		switch($item["direction"]){
			case "arrival":
				if(undefined==$item["arrival_municipality_dropdown"]){
					this.commons.generateToast("_ERROR","_NO_ARRIVAL_MUNICIPALITY","error");
					return false;
				}
				$item["arrival_municipality"] = $item["arrival_municipality_dropdown"]["id"];
				break;
			case "departure":
				if(undefined==$item["departure_municipality_dropdown"]){
					this.commons.generateToast("_ERROR","_NO_DEPARTURE_MUNICIPALITY","error");
					return false;
				}
				$item["arrival_municipality"] = $item["departure_municipality_dropdown"]["id"];
				break;
		}

		console.log("items", items);

		// Get only updated fileds
		let removable_items		= [ 
			"error",
			"errors",
			"fixes",
			"warnings",
			"newItem",
			"locationBinded",
			"statusToken",
			"arrival_Date_Calendar",
			"departure_Date_Calendar",
			"date_Calendar",
			"arrival_Time_Calendar",
			"departure_Time_Calendar",
			"departure_canaryshuttle_lodging_dropdown",
			"arrival_canaryshuttle_lodging_dropdown"
		];

		let updated_items		= Object.keys(items)
										.filter(e => !removable_items.some(ri=>ri==e))
										.filter(current=>{
											return $item[current]!==$item["last_"+current];
										})
										.filter(ue => $item[ue]!=undefined )
										.reduce((o, item) => {
											o[item] = $item[item];
											return o;
										}, {});


		// Unified fields
		let unifiedFields		= [ "Location", "AddressInfo" ];

		unifiedFields.forEach(token=>{
			if(undefined!==updated_items["arrival_"+token] || undefined!==updated_items["departure_"+token]){
				updated_items[token.toLowerCase()] = updated_items["arrival_"+token] || updated_items["departure_"+token];
			}
		});

		[ 	
			"reference", 
			"transporter_location_id" 
		].forEach(token=>{
			updated_items[token] = items[token];
		})
		console.log("Updated items", updated_items);

		// this["bookings"].spinner = false; return false;

		await this.entityService.postJSON(
			this.entityService.getUrl('booking_persist'),
			{
				type	: "multiple",
				// items: [ items ]
				items	: [ updated_items ]
			}
		).then(response => {
			this["bookings"].spinner = false;
			if (response["success"] != true) { 
				if(showMessage){
					this.commons.generateToast("Booking saving", "Error persisting booking", "error");
				}
				return false; 
			}

			if(showMessage){
				this.commons.generateToast("Booking", "Booking saved correctly", "success");
			}

			// Save log for testing
			// this.saveLog({
			// 	reference	: $item["reference"],
			// 	profile		: "admin",
			// 	author		: "Carlos Tous",
			// 	type		: "test",
			// 	action		: 'guardado local',
			// 	value		: "test"
			// })

			this.collapseRow($item);
			return true;

		}).catch(response => {
			this["bookings"].spinner = false;
			if(showMessage){
				this.commons.generateToast("Booking saving", "Error persisting booking", "error");
			}
			return false;
		});
	}

	/**
	 * apply booking rules
	 *
	 * @param $item
	 */
	 async applyBookingRules($item) {

		let rules	= this.commons.userInfo.destination.rules || [];
		let provider= this.pageInfo.currentProvider.id;
		let rule 	= rules.find(item=>{
			return	item.type 		== $item["shared"] &&
					item.provider	== provider
		});

		if(!rule){ return false; }

		switch(rule.action){
			case "assign_booking": this.assignBooking($item,rule["transporter"]); break;
		}
	}

	/**
	 * Updatea Booking
	 * @param $item
	 */
	async assignBooking($booking,$transporter) {

		await this.entityService.getComplexRequest(
			'booking_transporter_assign',
			{
				booking		: $booking["id"],
				transporter	: $transporter
			}
		).then(response => {
			if (response["success"] != true) {
				this.commons.generateToast("Error", response["error"], "error");
				return false;
			}
			$booking["assigned"] = true;
		}).catch(response => {
			this.commons.generateToast("Error", response["error"], "error");
		});
	}

	getBookingFieldsUpdated($item){
		let $updates = [];
		(Object.keys($item)||[]).forEach($field=>{
			if(undefined!==$item["original_"+$field]){
				// console.log("Checking for field ",$field);
				// console.log("Field value   : ",$item[$field]);
				// console.log("Original value: ",$item["original_"+$field]);
				if($item[$field]!=$item["original_"+$field]){
					$updates.push($field);
				}
			}
		})
		return $updates
	}

	/**
	 * accept booking
	 *
	 * insert or update booking if necessary
	 *
	 * Insert 	when no REFERENCE
	 * Update 	if any field has changed
	 * None		otherwise
	 * @param $item
	 */
	async acceptBooking($item,$notify=true):Promise<boolean> {

		let providerService 	= this.providerServiceCtrl.getProviderService(this.pageInfo.currentProvider.id);
		if(!providerService){
			this.commons.generateToast("_ERROR","_NO_PROVIDER_SELECTED","error");
			return false;
		}

		let updates 			= this.getBookingFieldsUpdated($item);

		providerService.check4Validation({ item: $item, notify: $notify });

		// this["bookings"].spinner = true;
		// this["bookings"].spinner = false;

		// this.commons.generateToast("_ACCEPT_BOOKING","_ACCEPT_BOOKING","info");
		console.log("Booking update",$item);
		// return true;

		// this.collapseRow($item);

		await this.entityService.postJSON(
			this.entityService.getUrl("booking_update"),
			{ 	
				reference	: $item["reference"],
				destination : $item["destination"],
				request		: $item,
				booking		: $item,
				updates		: updates,
				provider	: this.pageInfo.currentProvider.id,
				dmc			: this.commons.userInfo.dmc.id,
				rules		: this.commons.userInfo.destination.rules
			}
		).then(response => {
			this["bookings"].spinner = false;
			let currentRow = this.bookings.paginate.find(item=>item.id==$item.id);
			switch(response["verified"]){
				case "no"	: 	currentRow.assigned = false;	break;
				case "yes"	:	// If booking is verified apply booking rules
								// to assign transporter or another rule
								this.applyBookingRules($item);
								if(currentRow){
									if(response["verified"	]){ currentRow.verified = response["verified"	]; }
									if(response["status"	]){ currentRow.status 	= response["status"		]; }
								}
			}
			let errorStr;

			// Call error
			if (response["success"]!=true) {
				errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
				if($notify){			
					this.commons.generateToast("_BOOKING_UPDATE", errorStr, "error");
				}
				return false;
			}

			// Logic error
			if (undefined != response["data"] && response["data"]["errors"]){
				errorStr = "";
				Object.keys(response["data"]["errors"]).forEach(token=>{
					errorStr += response["data"]["errors"][token]+". ";
				});
				if($notify){
					this.commons.generateToast("_BOOKING_UPDATE", errorStr, "error");
				}
			}

			if($notify){
				this.commons.generateToast("_BOOKING_VALIDATION", "Booking updated", "success");
			}

			return true;

		}).catch(response => {
			this["bookings"].spinner = false;
			let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
			this.commons.generateToast("Booking validation", errorStr, "error");
			return false;
		});
	}

	async unverifyBooking($item){
		$item.verified = "no";
		// $item.verified = false;
	}

	/**
	 * Updatea Booking
	 * @param $item
	 */
	 async verifyBooking($item,$verified) {

		this["bookings"].spinner = true;

		await this.entityService.postJSON(
			this.entityService.getUrl("booking_update"),
			{ 	reference	: $item["reference"],
				action		: 'verify',
				verified	: $verified,
				request		: $item,
				booking		: $item,
				provider	: this.pageInfo.currentProvider.id,
				destination : this.commons.userInfo.destination.id,
				dmc			: this.commons.userInfo.dmc.id,
			}
		).then(response => {
			this["bookings"].spinner = false;
			if(response["success"])	{
				// If verifed apply rules
				// If not verified unassigned from transporter;
				switch($verified){
					case true	:	this.applyBookingRules($item);	break;
					case false	:	$item.assigned 				= false;
									$item.transporter_status 	= "pending";
									break;
				}

				let currentRow = this.bookings.paginate.find(item=>item.id==$item.id);
				if(currentRow){
					if(response["verified"	]){ currentRow.verified = response["verified"	]; }
					if(response["status"	]){ currentRow.status 	= response["status"		]; }
				}
			} else {
				let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
				this.commons.generateToast("Booking update", errorStr, "error");
				return false;
			}

			this.commons.generateToast("Booking validation", "_BOOKING_UPDATEDBooking updated", "success");
			// this.collapseRow($item);

		}).catch(response => {
			this["bookings"].spinner = false;
			let errorStr = response["error"] || this.commons.getTranslate("_UPDATE_BOOKING_ERROR");
			this.commons.generateToast("Booking validation", errorStr, "error");
		});
	}

	/**
	 * Reject booking action
	 * @param item
	 */
	async rejectBooking($type, $item = {}) {
		switch ($type) {
			case 'open'		:	this.rowData = $item;
								this.bookings.checks["reject"].panel = true;
								this.bookings.checks.reject.items.forEach(item => {
									item.selected = false;
									item.expanded = false;
									item.comment = undefined;
								});
								if (this.rowData.errors) {
									this.rowData.errors.forEach(error => {
										console.log(error);
										if (!error.errorCode) { return; }
										let itemError = this.bookings.checks.reject.items.find(el => el.code == error.errorCode)
										if (!itemError) { return; }
										itemError.selected = true;
										itemError.expanded = true;
										itemError.comment = error.type;
									})
								}
								$item["expanded"] = false;
								this.pageInfo.countRejectedItems = this.bookings.checks.reject.items.filter(booking => booking.selected).length;
								break;

			case 'send'		:
								let errors 	= [];
								let rowData = this.rowData;
								let items = this.bookings.checks.reject.items
									.filter(item => item.selected == true)
									.map(item => {
										let message = { reference: rowData.reference, code: item.code };
										if (undefined !== item.comment) {
											message["comment"] = item.comment;
										}
										switch (item.commentStatus) {
											case "_MANDATORY":
												if (undefined === item.comment || item.comment == "") {
													errors.push({ title: "Mandatory comment", code: item.code, message: item.message });
												}
												break;
											case "_OPTIONAL": break;
										}
										return message;
									});
								if (errors.length > 0) {
									errors.forEach(error => { this.commons.generateToast(error.title, "[" + error.code + "] " + error.message, "error"); });
									return false;
								}
								if (items.length == 0) {
									this.commons.generateToast("Error", "_SELECT_AT_LEAST_ONE_REJECT", "error");
									return false;
								}

								// Send to PROVIDER
								this["bookings"].spinner = true;
								await this.entityService.postJSON(this.entityService.getUrl("booking_reject"),{ items: items }
								).then(response => {
									this["bookings"].spinner = false;
									if (response["success"] != true) {
										this.commons.generateToast("Booking reject", "[" + response["error"]["code"] + "] " + response["error"]["message"], "error");
										return false;
									}
									this.commons.generateToast("Booking reject", "Booking reject", "success");
									this.collapseRow($item);
									return true;
								}).catch(response => {
									this["bookings"].spinner = false;
									this.commons.generateToast("Booking reject", "Error rejecting booking", "error");
								});

								this.bookings.checks.reject.items.forEach(item => {
									item.selected = false;
									item.expanded = false;
									item.comment = undefined;
								});

								this.bookings.checks["reject"].panel = false;

								break;

			case 'select'	: 	$item["selected"] = true; 						break;
			case 'unselect'	: 	$item["selected"] = false; 						break;
			case 'toggle'	: 	$item["selected"] = $item["selected"] != true ? true : false;
								$item["expanded"] = $item["selected"];
								this.pageInfo.countRejectedItems = this.bookings.checks.reject.items.filter(booking => booking.selected).length;
								break;
			case 'cancel'	: 	this.bookings.checks["reject"].panel = false; 	break;
		}

		this.collapseRow($item);
	}

	export($format, $mode = 'full') {
		this.commons.generateToast("Export", "Generating Bookings in " + $format + " format");
		switch ($format) {
			case 'csv'		: this.dataTable.exportCSV({ selectionOnly: $mode == 'full' ? false : true }); 	break;
			case 'xls'		: this.exportExcel(); 															break;
			case 'pdf'		: this.exportPdf(); 															break;
		}
	}

	async generateTransports(){
		let response = await Promise.resolve(	this.entityService.getRequest("generate_transports",
												{ 	dmc			: this.commons.userInfo.dmc.id,
													providers	: this.providers.data.filter(item=>item.selected),
													date		: this.calendar.date
												}));
	}

	exportPdf() {
		// jspdf.then(jsPDF => {
		//     import("jspdf-autotable").then(x => {
		//         const doc = new jsPDF.default(0,0);
		//         doc.autoTable(this.bookings.selectedColumns, this.bookings.paginate);
		//         doc.save('primengTable.pdf');
		//     })
		// })
	}

	exportExcel() {
		// import("xlsx").then(xlsx => {
		//     const worksheet 		= xlsx.utils.json_to_sheet(this.bookings.paginate);
		//     const workbook 			= { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		//     const excelBuffer: any 	= xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
		//     this.saveAsExcelFile(excelBuffer, "primengTable");
		// });
		// const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		// const worksheet 		= XLSX.utils.json_to_sheet(this.bookings.paginate);
		// const workbook 			= { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		// const excelBuffer: any 	= XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		// this.saveAsExcelFile(excelBuffer, "primengTable");

		// const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.bookings.paginate);
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.bookings.paginate);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Bookings');
		XLSX.writeFile(wb, 'Bookings.xlsx');
	}

	saveAsExcelFile(buffer: any, fileName: string): void {
		// import("file-saver").then(FileSaver => {
		//     let EXCEL_TYPE 		= 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		//     let EXCEL_EXTENSION = '.xlsx';
		//     const data: Blob 	= new Blob([buffer], { type: EXCEL_TYPE });
		//     FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
		// });
	}

	/**
	 * Cancel Booking
	 * @param $item
	 */
	closeBooking($item)				{ this.collapseRow($item); 						}
	cancelBooking($item)			{ this.collapseRow($item); 						}
	expandRow($item) 				{ this.toggleDT($item); 						}
	collapseRow($item) 				{ 
		// this.toggleDT($item); 						
		this.pageInfo.overlay_booking = false;			
	}
	toggleRow($item) 				{ this.toggleDT($item); 						}

	/**
	 * actions to take once we open row with expander
	 *
	 * @param $item
	 */
	 toggleDT($item) {
		if(this.pageInfo.overlay_booking){
			this.pageInfo.overlay_booking = false;
			return;
		}
		this.pageInfo.overlay_booking = true;

		if (undefined === $item) { return false; }

		this.rowData = $item;
		// ["hotel","flight"].forEach(panel=>{
		// 	this.bookings.checks[panel].panel 	= false;
		// });

		this.pageInfo.testRow 			= $item;

		// Select provider from booking
		const provider = $item["provider"];
		if(undefined==provider){
			this.commons.generateToastError("_BOOKING_WITH_NO_PROVIDER");
			return;
		}
		this.toggle("provider",{ id: provider });

		switch($item.direction){
			case "arrival"	:
			case "both"		:	this.pageInfo.tabs.rowexpander.selected = "arrival"; 	break;
			case "departure":	this.pageInfo.tabs.rowexpander.selected = "departure";	break;
		}
	}

	toggle($entity, $item) 						{
		switch ($entity) {
			case "provider"		: 	
				this.providers.data 			= this.providers.data.map(item => { item.selected = item.id == $item.id; return item; });
				this.pageInfo.currentProvider 	= this.providers.data.find(i=>i.selected);
				// this.commons.generateToast("_TOGGLE_PROVIDER","_TOGGLE_PROVIDER_TITLE","info");
				break;

			case "filterButton"	: 	
				//$item.value = $item.value ? false : true;
				let found = $item.filter.selected.some(selected=>selected==$item.item.value);
				if(found){
					$item.filter.selected = $item.filter.selected.filter(selected=>selected!=$item.item.value);
				} else {
					$item.filter.selected.push($item.item.value);
				}
				break;
		}
	}

	toggleSuggestionsMenu(menu, $event, field) 	{	this.pageInfo.currentFieldSuggestions = field;
													menu.toggle($event);
												}
	getCurrentSuggestions() 					{
		if (!this.rowData || !this.rowData.suggestions || !this.pageInfo.currentFieldSuggestions || !this.rowData.suggestions[this.pageInfo.currentFieldSuggestions]) { return [] }
		return this.rowData.suggestions[this.pageInfo.currentFieldSuggestions].map(item => { return { label: item.description } });
	}

	async selectSuggestedLocation($event) 		{
		alert("SELECT SUGGESTED LOCATION");
		// this.rowData[this.pageInfo.currentFieldSuggestions] = $event.target.innerText;
		// await this.bookingsService.fixLocation({ field: this.pageInfo.currentFieldSuggestions }, this.rowData, true, false);
	}


	private getCountInfo($booking, $type) 		{	return $booking[$type] ? $booking[$type].length : 0;	}

	getInfoClass(booking, field) 				{	
		if (!booking.errors && !booking.warnings) { return {}; }
		let fieldError = (booking.errors || []).find(el => el.field == field);
		if (fieldError) { return fieldError }
		let fieldWarning = (booking.warnings || []).find(el => el.field == field);
		return fieldWarning ? fieldWarning : {};
	}

	/**
	 * check if entity has specific value
	 * @param $entity
	 * @param $value
	 */
	checkEntity($entity, $value) 				{	return true;	}

	checkFlight(item) 							{	
		alert("Checking Flight from " + item.source);
		this.messageService.add({
			severity: 'success',
			summary: 'Message',
			detail: "Checking Flight from " + item.source
		});
	}

	normalizeBookings(data) 					{
		// Split both directions bookings
		let bookings 	= [];
		let errors		= [];

		data.forEach(booking=>{ bookings.push(booking); });

		let mappings 					= {};
		let tourinia_resorts_2_areas	= this.commons.userInfo.tourinia_resorts_2_areas;
		let tourinia_areas_2_zones		= this.commons.userInfo.tourinia_areas_2_zones;
		let hotel_mappings				= this.commons.userInfo.currentDestination.canary_shuttle_mappings || {};
		
		if(undefined==tourinia_resorts_2_areas){
			this.commons.generateToastError("_NOT_FOUND_TOURINIA_RESORTS_2_AREAS");
			return false;
		}

		if(undefined==tourinia_areas_2_zones){
			this.commons.generateToastError("_NOT_FOUND_TOURINIA_AREAS_2_ZONES");
			return false;
		}

		bookings = bookings.map(row => {

			// This should not happen
			switch(row.status){
				case null:	row.status="ACON"; break;
			}

			if(	undefined!=this.commons.userInfo.provider_mappings){
				// if(undefined!= this.commons.userInfo.provider_mappings[this.pageInfo.currentProvider.id]){
				// 	mappings = this.commons.userInfo.provider_mappings[this.pageInfo.currentProvider.id];
				// }
				if(undefined!= this.commons.userInfo.provider_mappings[row.provider]){
					mappings = this.commons.userInfo.provider_mappings[row.provider];
				}
			}

			row.fixes 	= {};
			row.newItem	= false;
			row 		= this.bookingsService.normalizeLocationFields		( row );
			row 		= this.bookingsService.applyBookingProviderActions	( row );

			this.checkBookingErrors(row);
			let currentGoogleAlias;

			// Assign destination
			row.destination			= this.commons.userInfo.currentDestination.id;

			// Mappings to transporter hotel id
			// CANARY SHUTTLE SHOULD NOT BE USED
			row.canaryshuttle_id			= row.arrival_canaryshuttle_lodging || row.departure_canaryshuttle_lodging;
			row.transporter_location_id		= row.transporter_location_id || row.canaryshuttle_id;

			if(row.transporter_location_id){ 
				row.transporter_location_found		= true;
			}
			
			switch(row.direction){
				case "arrival":
					row.direction_label		= "_ARRIVAL";
					
					row.arrival_Location	= row.arrival_Location || row.location;
					row.area 				= row.arrival_Area 	|| row.arrival_To;
					row.zone				= row.arrival_Zone;
					row.location			= row.arrival_Location;
					row.date				= row.arrival_Date;
					row.time				= row.arrival_Time;

					// Mappings hotel transporter id
					if(undefined==row.canaryshuttle_id){
						row.canaryshuttle_id 			= hotel_mappings[row.arrival_Location];
						row.transporter_location_id		= row.transporter_location_id || row.canaryshuttle_id;			
					}

					row.arrival_canaryshuttle_lodging	= row.transporter_location_id;
					row.arrival_canaryshuttle_lodging_dropdown = {
						id	: row.transporter_location_id
					}

					// Set tourinia resort from provider resort
					if(undefined==row.arrival_resort_tourinia){
						if(undefined!=mappings[row.arrival_To]){
							row.arrival_resort_tourinia = mappings[row.arrival_To];
						} else {
							if(undefined==errors.some(error => error == "_TOURINIA_RESORT_NOT_FOUND")){
								errors.push("_TOURINIA_RESORT_NOT_FOUND")
							}
						}
					}

					// Set municipality
					if(undefined!=tourinia_resorts_2_areas){
						row.arrival_municipality 			= tourinia_resorts_2_areas[row.arrival_resort_tourinia];
						row.arrival_municipality_dropdown	= { 
							id		: row.arrival_municipality,
							name	: row.arrival_municipality,
							label	: row.arrival_municipality
						};
					}

					// Set zone
					if(row.arrival_municipality){
						row.arrival_Zone			= tourinia_areas_2_zones[row.arrival_municipality];
						row.zone					= tourinia_areas_2_zones[row.arrival_municipality];
					}

					// Trim flights
					row.arrival_flightnoquery		= (row.arrival_flightnoquery	|| "").trim();
					row.arrival_GatewayInfo			= (row.arrival_GatewayInfo		|| "").trim();
					row.flight						= row.arrival_GatewayInfo;
					
					// Vehicles
					row.arrival_Vehicles			= row.arrival_Vehicles 		|| row.vehicles;
					row.arrival_VehicleType			= row.arrival_VehicleType	|| row.vehicle_type;
					row.arrival_Vehicle				= row.arrival_Vehicle		|| row.vehicle;

					// row.pickup						= row.arrival_PickupTime;
					break;

				case "departure":

					row.direction_label		= "_DEPARTURE";
					
					row.departure_Location	= row.departure_Location || row.location;					
					row.area 				= row.departure_Area || row.departure_From;
					row.zone				= row.departure_Zone;
					row.location			= row.departure_Location;
					row.date				= row.departure_Date;
					row.time				= row.departure_Time;					

					// Mappings hotel transporter id
					if(undefined==row.canaryshuttle_id){
						row.canaryshuttle_id 			= hotel_mappings[row.departure_Location];
						row.transporter_location_id		= row.transporter_location_id || row.canaryshuttle_id;			
					}

					row.departure_canaryshuttle_lodging	= row.transporter_location_id;
					row.departure_canaryshuttle_lodging_dropdown = {
						id	: row.transporter_location_id
					}
					
					// Set tourinia resort from provider resort
					if(undefined==row.departure_resort_tourinia){
						if(undefined!=mappings[row.departure_From]){
							row.departure_resort_tourinia = mappings[row.departure_From];
						} else {
							if(undefined==errors.some(error => error == "_TOURINIA_RESORT_NOT_FOUND")){
								errors.push("_TOURINIA_RESORT_NOT_FOUND")
							}
						}
					}

					// Set municipality
					if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
						row.departure_municipality 			= this.commons.userInfo.tourinia_resorts_2_areas[row.departure_resort_tourinia];
						row.departure_municipality_dropdown	= { id		: row.departure_municipality,
																name	: row.departure_municipality,
																label	: row.departure_municipality
															};
					}

					// Set zone
					if(row.departure_municipality){
						row.departure_Zone			= this.commons.userInfo.tourinia_areas_2_zones[row.departure_municipality];
						row.zone					= this.commons.userInfo.tourinia_areas_2_zones[row.departure_municipality];
					}

					// Trim flight
					row.departure_flightnoquery		= (row.departure_flightnoquery	|| "").trim();
					row.departure_GatewayInfo		= (row.departure_GatewayInfo	|| "").trim();
					row.flight						= row.departure_GatewayInfo;
					
					// Vehicles
					row.departure_Vehicles			= row.departure_Vehicles	|| row.vehicles;
					row.departure_VehicleType		= row.departure_VehicleType	|| row.vehicle_type;
					row.departure_Vehicle			= row.departure_Vehicle		|| row.vehicle;

					row.pickup						= row.departure_PickupTime;
					break;

				case "both"		:
					console.log("[normalizeBookings] Both direction",row);

					row.direction_label		= "_RETURN"
					row.arrival_Location	= row.arrival_Location 	|| row.location;
					row.departure_Location	= row.departure_Location|| row.location;					

					// Mappings hotel transporter id
					if(undefined==row.canaryshuttle_id){
						row.canaryshuttle_id 			= hotel_mappings[row.arrival_Location];
						row.transporter_location_id		= row.transporter_location_id || row.canaryshuttle_id;							
					}

					row.arrival_canaryshuttle_lodging	= row.transporter_location_id;
					row.arrival_canaryshuttle_lodging_dropdown = {
						id	: row.transporter_location_id
					}

					row.departure_canaryshuttle_lodging	= row.transporter_location_id;
					row.departure_canaryshuttle_lodging_dropdown = {
						id	: row.transporter_location_id
					}
					
					// Trim flights
					row.arrival_flightnoquery		= (row.arrival_flightnoquery	|| "").trim();
					row.departure_flightnoquery		= (row.departure_flightnoquery	|| "").trim();

					row.arrival_GatewayInfo			= (row.arrival_GatewayInfo		|| "").trim();
					row.departure_GatewayInfo		= (row.departure_GatewayInfo	|| "").trim();

					// Vehicles
					row.arrival_Vehicles			= row.arrival_Vehicles 		|| row.vehicles;
					row.arrival_VehicleType			= row.arrival_VehicleTYpe	|| row.vehicle_type;
					row.arrival_Vehicle				= row.arrival_Vehicle		|| row.vehicle;

					row.departure_Vehicles			= row.departure_Vehicles	|| row.vehicles;
					row.departure_VehicleType		= row.departure_VehicleTYpe	|| row.vehicle_type;
					row.departure_Vehicle			= row.departure_Vehicle		|| row.vehicle;

					// Set tourinia resort from provider resort
					if(undefined==row.arrival_resort_tourinia){
						if(undefined!=mappings[row.arrival_To]){
							row.arrival_resort_tourinia = mappings[row.arrival_To];
						} else {
							if(undefined==errors.some(error => error == "_TOURINIA_RESORT_NOT_FOUND")){
								errors.push("_TOURINIA_RESORT_NOT_FOUND")
							}
						}
					}

					// Set municipality
					if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
						row.arrival_municipality 			= this.commons.userInfo.tourinia_resorts_2_areas[row.arrival_resort_tourinia];
						row.arrival_municipality_dropdown	= { id		: row.arrival_municipality,
																name	: row.arrival_municipality,
																label	: row.arrival_municipality
															};
					}

					// Set zone
					if(row.arrival_municipality){
						row.arrival_Zone			= this.commons.userInfo.tourinia_areas_2_zones[row.arrival_municipality];
						row.zone					= this.commons.userInfo.tourinia_areas_2_zones[row.arrival_municipality];
					}

					// Set tourinia resort from provider resort
					if(undefined==row.departure_resort_tourinia){
						if(undefined!=mappings[row.departure_From]){
							row.departure_resort_tourinia = mappings[row.departure_From];
						} else {
							if(undefined==errors.some(error => error == "_TOURINIA_RESORT_NOT_FOUND")){
								errors.push("_TOURINIA_RESORT_NOT_FOUND")
							}
						}
					}

					// Set municipality
					if(undefined!=this.commons.userInfo.tourinia_resorts_2_areas){
						row.departure_municipality 			= this.commons.userInfo.tourinia_resorts_2_areas[row.departure_resort_tourinia];
						row.departure_municipality_dropdown	= { id		: row.departure_municipality,
																name	: row.departure_municipality,
																label	: row.departure_municipality
															};						
					}

					// Set zone
					if(row.departure_municipality){
						row.departure_Zone			= this.commons.userInfo.tourinia_areas_2_zones[row.departure_municipality];
						row.zone					= this.commons.userInfo.tourinia_areas_2_zones[row.departure_municipality];
					}

					row.pickup						= row.departure_PickupTime;
					break;
			}
			return row;
		});

		// Display all errors found
		errors.forEach(error=>{
			this.commons.generateToast("_ERROR",error,"error");
		});

		return bookings;
	}

	/**
	 * check booking errors
	 * @param row
	 */
	checkBookingErrors($booking){
		// Flights check
		let flightError;

		$booking.errors = $booking.errors || [];
		switch($booking.direction){
			case "arrival"	: 	
				flightError = this.flightService.checkFlight($booking["arrival_GatewayInfo"	]);
				if(flightError["error"]){
					$booking.errors.push("arrival_GatewayInfo");
				}
				break;

			case "departure": 	
				flightError = this.flightService.checkFlight($booking["departure_GatewayInfo"	]);
				if(flightError["error"]){
					$booking.errors.push("departure_GatewayInfo");
				}
				break;

			case "both"		: 	
				flightError = this.flightService.checkFlight($booking["arrival_GatewayInfo"	]);
				if(flightError["error"]){
					$booking.errors.push("arrival_GatewayInfo");
				}
				flightError = this.flightService.checkFlight($booking["departure_GatewayInfo"	]);
				if(flightError["error"]){
					$booking.errors.push("departure_GatewayInfo");
				}
				break;
		}

		// (flightErrorFields||[]).forEach(field=>{
		// 	// $booking["field"] = ...
		// 	this.bookingsService.setInfoToBooking('errors', $booking, field, gatewayChecked.type, gatewayChecked.errorCode);
		// })

		// Lodging check
		// let lodgingError	= this.lodgingService.checkBooking(row);
		// if(flightError){
		// 	this.bookingsService.setInfoToBooking('errors', row, field, gatewayChecked.type, gatewayChecked.errorCode);
		// }
	}

	/**
	 * check for errors in booking
	 * Flight and location errors for the moment
	 * TODO !
	 * 		Vehicle
	 * 		Price
	 *
	 * @param row
	 * @returns
	 */
	_checkBookingErrors(row) 					{
		if(undefined==row){ return; }

		let validators 		= [];

		let flightCheckers 	= [
			'arrival_GatewayInfo',
			'departure_GatewayInfo'
		];

		let addressCheckers = [
			'arrival_Location',
			// 'arrival_AddressInfo',
			'departure_Location',
			// 'departure_AddressInfo'
		];

		validators 	= this.assignValidators(row);
		row.error 	= false;
		row.errors 	= row.errors || [];

		validators.forEach(field => {
			switch (true) {
				// Flights
				case (flightCheckers.some(validator => validator == field)):
					let gatewayChecked = this.flightService.checkFlight(row[field]);
					gatewayChecked.error ? this.bookingsService.setInfoToBooking('errors', row, field, gatewayChecked.type, gatewayChecked.errorCode) : null;
					break;

				// Location
				case (addressCheckers.some(validator => validator==field)):
					let locationChecked = this.lodgingService.checkLocation(row,field);
					locationChecked.error ? this.bookingsService.setInfoToBooking('errors', row, field, locationChecked.type, locationChecked.errorCode) : null;
					break;

				default: break;
			}
		})
	}

	/**
	 * Fields to be validated
	 * @param booking booking to check
	 */
	assignValidators(booking) 			{
		if (!booking) { return [] };

		const arrivalFieldsToCheck 	= 	[
			'arrival_GatewayInfo',
			'arrival_Location',
			'arrival_AddressInfo'
		];

		const departureFieldsToCheck = 	[
			'departure_GatewayInfo',
			'departure_Location',
			'departure_AddressInfo'
		];

		switch (booking.direction) {
			default				: return [];
			case 'arrival'		: return arrivalFieldsToCheck;
			case 'departure'	: return departureFieldsToCheck;
			case 'both'			: return [...arrivalFieldsToCheck, ...departureFieldsToCheck];
		}
	}

	/**
	 * Determine if set to disabled the column
	 * @param booking booking
	 * @param col col from form => INFO | ARRIVAL | DEPARTURE
	 */
	checkIfDisableCol(booking, col) 	{	if (col.type == 'info' || !booking) { return false; }
											switch (true) {
												default										: return false;
												case (booking.direction == 'both'		)	: return false;
												case (booking.direction == 'arrival'	)	: return !(booking.direction == col.type);
												case (booking.direction == 'departure'	)	: return !(booking.direction == col.type);
											}
										}
	getColorIconRow(rowData) 			{	let warnings = this.getCountInfo(rowData, 'warnings');
											let errors = this.getCountInfo(rowData, 'errors');
											switch (true) {
												case (errors > 0): return 'tomato';
												case (warnings > 0 && errors == 0): return 'yellow';
												default: return 'green';
											}
										}

	// -----------------------------------------------------------------------------------
	// MENU METHODS
	// -----------------------------------------------------------------------------------

	initTabs(){
		this.pageInfo.tabs	= tabs;
	}

	initMenus() 						{
		this.pageInfo.menus = {};
		Object.keys(menus).forEach(menuName=>{
			let current 	= this.generateMenuItem(menus[menuName]);
			this.pageInfo.menus[menuName] = [ current ];
		},this);
		console.log("[initMenu] menus",this.pageInfo.menus);
	}

	generateMenuItem(menu)				{
		menu.label 	= this.commons.getTranslate(menu.label);
		menu.icon	= "fa fa-"+menu.icon;
		menu.items	= (menu.items||[]).map(item=>{
			item.label 	= this.commons.getTranslate(item.label);
			if(item.items){
				item.items = [ this.generateMenuItem(item.items) ];
			}
			if(item.name){
				item.command = ($event) => { this.doAction('command',$event); };
			}
			return item;
		})
		return menu;
	}

	generateProvidersMenu($params)				{
		let providerList 	= $params["providers"];
		let providers		= providerList.map(provider=>{
			let item = {
				name		: "select_provider",
				label		: provider.name,
				selected	: provider.active,
				id			: provider.id,
				logo		: provider.logo,
				shortname	: provider.shortname,
				command		: ($event) => { this.doAction('command', $event) },
				icon		: 'map'
			};
			return item;
		})
		this.pageInfo.menus.providers[0].items = this.generateMenuItem(providers);
	}


	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item, $filter) 		{	if (undefined === $filter.selected) { return false; }
											if (undefined === $item) { return false; }
											$filter.selected = $filter.selected.some(item => item === $item)
												? $filter.selected.filter(item => item !== $item)
												: [...$filter.selected, $item];
											this.checkFilter($filter);		// Execute filtering
										}

	// -----------------------------------------------------------------------------------
	// FILTERING METHODS
	// -----------------------------------------------------------------------------------

	/**
	 * Init all filters and exec initial check
	 */
	async initFilters() 				{	
		this.bookings.filters = await this.commons.translateRecursively(filters, { label: "label", children: "items" });
		this.entities.forEach(entity => {
			this[entity].filters = this[entity].filters || [];
			this[entity].filters.forEach(filter => this.checkFilter(filter));
		});
	}

	checkFilter(filter) 				{	
		switch (filter.type) {
			case "multiple": this.checkMultipleFilter(filter); break;
			default: this.checkSimpleFilter(filter); break;
		}
	}

	checkMultipleFilter(filter) 		{	
		if (undefined === filter.entity 		||
			undefined === this[filter.entity] 	||
			undefined === filter.name
		) { return false; }

		this[filter.entity].activeFilters = this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] = {
			field: filter.field,
			options: filter.items.map(item => item.value),
			selected: filter.selected
		};

		this.filterData(filter.entity);
	}

	checkSimpleFilter(filter) 			{	
		if (undefined === filter.entity 		||
			undefined === this[filter.entity] 	||
			undefined === filter.name 			||
			undefined === filter.status
		) { return false; }

		this[filter.entity].activeFilters = this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] = filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity) 				{
		let data 	= this[$entity].data 			|| [];
		let filters = this[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if (Object.keys(filters).length > 0) {
			Object.keys(filters).forEach(item => {

				//alert("FILTER["+$entity+"]="+item);
				let selected 	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item => selected.indexOf(item) < 0);
				let field 		= filters[item].field;

				switch (item) {
					// case "verified"		: data = data.filter(item => !inverted.some(value => value == item[field])); break;
					// case "status"		: data = data.filter(item => !inverted.some(value => value == item[field])); break;
					// case "direction"	: data = data.filter(item => !inverted.some(value => value == item[field])); break;
					// case "error"		: data = data.filter(item => !inverted.some(value => value == item[field])); break;
					// case "shared"		: data = data.filter(item => !inverted.some(value => value == item[field])); break;
				}
			});
		}

		this[$entity].filteredData 		= data;
		this[$entity].count 			= this[$entity].filteredData ? this[$entity].filteredData.length : 0;

		this.paginateLocal($entity, null);
	}

	filterPending(filter) { }

	// -----------------------------------------------------------------------------------
	// SEARCH METHODS
	// -----------------------------------------------------------------------------------
	doSearch($item, $event) 			{
		switch($item.field){
			case "arrival_municipality_dropdown"	:
			case "departure_municipality_dropdown"	:

				this.pageInfo.results = (this.commons.userInfo.tourinia_areas||[])
					.filter(area=>{
						if(undefined==$item.value){ return true; }
						let item = (undefined!=$item.value && undefined==$item.value.id)
									?$item.value
									:$item.value.id;
						// Empty
						if(typeof item == 'object'		){ return true;  }
						if(undefined==item|| item ==""	){ return true;  }
						
						// less than 3 letters
						if((item||"").length<3			){ return false; }
						// some value
						return (area.toLowerCase()).includes(item.toLowerCase());
					})
					.map(area=>{
							let area_item = {
							id		: area,
							name	: area,
							value	: area,
							label	: area
						};
						return area_item;
					})
					.sort((a,b)=>a.label>=b.label?1:-1);
				break;
		}

		switch($item.entityType){
			case "data"		:
				if(undefined == $item.entityList){
					this.pageInfo.results = [];
					return false;
				}
				if(undefined == this[$item.entityList]){
					this.pageInfo.results = [];
					return false;
				}
				this.results = (this[$item.entityList].data||[]).filter(item => {
					return (item.label||"_ALL_RESULTS").includes($event.query);
				});
				break;
			default			:
			case "entity"	:
				if (undefined == $item.entityList) { this.results = []; return false; }
				this.pageInfo.results = (this.commons.getEntity($item.entityList)||[]).filter(item => item.includes($event.query));
				break;
		}
	}

	// getMinDate(item)					{	if(item.options.minDate['field'])	{	return this.info.item[item.options.minDate.field];			}
	// 										else								{	return item.options.minDate.date;							}
	// 									}

	// -----------------------------------------------------------------------------------
	// RENDERING METHODS
	// -----------------------------------------------------------------------------------

	areaEditor($me, $type, $col, $items){	alert('AREA EDITOR'); }

	getFieldEditor($col) 				{ 	return $col.editor ? $col.editor : 'input'; }

	getRendererType($type) 				{
		switch ($type) {
			case 'transporter_location'	: return (type, col, items) => this.transporterLocationRenderer	(this, type, col, items);
			case 'area_inner'			: return (type, col, items) => this.innerAreaRenderer	(this, type, col, items);
			case 'company'				: return (type, col, items) => this.companyRenderer		(this, type, col, items);
			case 'treatment'			: return (type, col, items) => this.treatmentRenderer	(this, type, col, items);
			case 'customer'				: return (type, col, items) => this.customerRenderer	(this, type, col, items, 'retail');
			case 'customer_full'		: return (type, col, items) => this.customerRenderer	(this, type, col, items, 'full');
			case 'price'				: return (type, col, items) => this.priceRenderer		(this, type, col, items);
			case 'arrival'				: return (type, col, items) => this.arrivalRenderer		(this, type, col, items);
			case 'departure'			: return (type, col, items) => this.departureRenderer	(this, type, col, items);
			case 'pax'					: return (type, col, items) => this.paxRenderer			(this, type, col, items);
			case 'flight'				: return (type, col, items) => this.flightRenderer		(this, type, col, items);
		}
	}

	getRenderer($type, $col, $items, $info?) 	{
		return $col.renderer
			// ? $col.renderer($type, $col, $items)
			? this.getRendererType($col.renderer)($type, $col, $items)
			: this.defaultRenderer($type, $col, $items, $info);
	}

	defaultRenderer($type, $col, $items, $info?){
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	:
				if(undefined!=$info){
					if($info["both"]){
						return $items[$col.fields[$info.index]];
					}
				}
				return $items[$col.field];
		}
	}

	defaultStyleRenderer($col) 			{
		return {
			'width'		: $col.width ? $col.width : '',
			'text-align': $col.align ? $col.align : ''
		};
	}

	innerAreaRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return { ...this.defaultStyleRenderer($col) };
			case 'content'	: return $items[$col.field];
		}
	}

	flightRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return {
				...this.defaultStyleRenderer($col),
				// 'color'			: !this.flightService.checkFlight($items[$col.field]).error ? 'gray' : 'red',
				'color'			: 'gray',
				'font-weight'	: 700
			};
			case 'expander'	: return {
				// 'color'			: !this.flightService.checkFlight($items[$col.field]).error ? 'gray' : 'red',
				'color'			: 'gray',
				'font-weight'	: 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	arrivalRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return {
				...this.defaultStyleRenderer($col),
				'color': this.checkEntity('areasList', $items[$col.field]) ? 'green' : 'red',
				'font-weight': 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	departureRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return {
				...this.defaultStyleRenderer($col),
				'color'			: this.checkEntity('areasList', $items[$col.field]) ? 'green' : 'red',
				'font-weight'	: 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	transporterLocationRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style': return {
				...this.defaultStyleRenderer($col),
				'color'			: $items['transporter_location_found']? 'green' : 'red',
				'font-weight'	: 700
			};
			case 'content'	: return $items[$col.field];
		}
	}

	companyRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'class'	: return "company-logo";
			case 'content'	: return '/assets/layout/icons/providers/' + $items[$col.field] + '.png';
		}
	}

	treatmentRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: return $me.toCamelCase($items[$col.field].match(/\w+/)[0]);
		}
	}

	customerRenderer($me, $type, $col, $items, $flavour = 'retail') {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: return ( $items[$col.field].match(/\w+/g) || [] )
										.filter((item, index) => index > ($flavour === 'full' ? -1 : 0))
										.map(item => this.toCamelCase(item))
										.join(' ')
										;
		}
	}

	priceRenderer($me, $type, $col, $items) {
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: return $me.floatRenderer($items[$col.field], 2) + ' €';
		}
	}

	paxRenderer($me, $type, $col, $items) 	{
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			// tslint:disable-next-line:radix
			// case 'content': return parseInt($items[$col.field]);
			case 'content'	: return $items['pax'] + ' pax (' + $items['adults'] + '/' + $items['children'] + '/' + $items['infants'] + ')';
		}
	}

	toCamelCase($value) 					{ return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase(); }
	floatRenderer($value, $precision = 2) 	{ return parseFloat($value).toFixed($precision); 											}

	// onYearChange(event, dt) {
	//     if (this.yearTimeout) {
	//         clearTimeout(this.yearTimeout);
	//     }
	//     this.yearTimeout = setTimeout(() => {
	//         dt.filter(event.value, 'year', 'gt');
	//     }, 250);
	// }

	// -----------------------------------------------------------------------------------
	// SORTING METHODS
	// -----------------------------------------------------------------------------------

	onSort() 								{ }
}
