import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, OnInit, ViewEncapsulation, 
		 ViewChild, 
		 Input, 
		 Output, 
		 EventEmitter   						} from '@angular/core';
import { ConfirmationService 					} from 'primeng/primeng';
import { StorageService 						} from './../../../service/storageservice';
import { detailsVehicleForm, entities, 
		 externalFleetForm, 
		 sections, 
		 typesForm,						
		 vehiclesForm,							
		 vehiclesInfoForm						} from './data/items';
import { TransportService 						} from 'src/app/demo/service/transports/transports.service';

@Component({
	selector    	: 'app-manage-transport',
    styleUrls		: ['./manage-transports.component.scss'],
	templateUrl		: './manage-transports.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class ManageTransportsComponent implements OnInit
{
	@Input() 	fromWizard		: 	Boolean 	= false;
	@Input()	mode			:	any;
	@Input()	stations		:	any;
	@Input()	dmc				:	any;
	@Input()	areas			:	any;
	@Input()	destination		:	any;
	@Input()	dmcFleet		:	any;
	@Input()	drivers			:	any;
	@Input()	transporters	:	any;

	@Output()	emitter	= new EventEmitter<any>();	

	@ViewChild('externalTransporters')	externalTransporters	:	any;
    locale      		: string 	= 'es';    
	pageInfo 			: any 		= {};
	
	constructor(
		private entityService 		: EntityService, 
		private commons 			: CommonsService, 
		private storage 			: StorageService,
		private confirmCtrl     	: ConfirmationService,
		private transportService	: TransportService
    ){}
    
	async ngOnInit() 	{   this.init();
							this.initForms();
							await this.loadEntities();	
							this.pageInfo.dataLoaded = true;
                        }

    init(){
        this.pageInfo.currentViewIndex		=	1,
		this.pageInfo.sections				=	sections;

        this.pageInfo.entities				= { types: {}, vehicles: {}, dmcFleet : {}, transporters : {}};
		this.pageInfo.externalTransporters	=	{
			settings	:	{	tabs		:	['transporters','areas", "prices", "fleet', "services", "conciliation", "invoices" ],	
								colFilters	:	[],}
		}
		this.pageInfo.wizardData			=	{};
    }

    initForms(){
        this.pageInfo.entities["types"].table	= {
			ready	: false,
			cols	: [
				{ field: 'active',     		header: '',			    	width: '20px', 	type: 'check', renderer: 'check', align: 'center'		},
				{ field: 'id',     			header: '_ID', 		    	width: '50px', 	align: 'center'		},
				{ field: 'name',     		header: '_NAME', 	    	width: '200px', align: 'center'		},
				{ field: 'code',			header: '_CODE',		  	width: '50px', 	align: 'center'		},
				{ field: 'seats',			header: '_SEATS',  			width: '50px', 	align: 'center'		}
			],
			options	: { rowExpander: false, scrollHeight: '70vh' },
			filters	: []
		};

		this.pageInfo.entities["types"].form 	= { 
			ready			: false,
			entities		: this.pageInfo.entities,	
			displayButtons	:	false,		
			form			: typesForm,
			buttons			:	[
				{ id: 1, name: 'save', label: '_SAVE', action: 'save'	}
			]
		};

		this.pageInfo.entities["vehicles"].table	= {
			ready		: false,
			cols		: [
				{ field: 'active',     		header: '',			    	width: '20px', 	type: 'check', renderer: 'check', align: 'center'		},
				{ field: 'id',     			header: '_ID', 		    	width: '50px', 	align: 'center'		},
				{ field: 'name',     		header: '_NAME', 	    	width: '200px', align: 'center'		},
				{ field: 'code',			header: '_CODE',		  	width: '50px', 	align: 'center'		},
				{ field: 'seats',			header: '_SEATS',  			width: '50px', 	align: 'center'		}
			],
			options		: { rowExpander: false, scrollHeight: '70vh' },
			filters		: []
		};

		this.pageInfo.entities["vehicles"].infoForm	=	{
			ready		:	false,
			entities	:	this.pageInfo.entities,
			form		:	vehiclesInfoForm
		}

		this.pageInfo.entities["vehicles"].detailsVehicleForm	=	{
			ready		:	false,
			entities	:	this.pageInfo.entities,
			form		:	detailsVehicleForm
		}

		this.pageInfo.entities["vehicles"].form 	= { 
			ready		: 	false,
			entities	: 	this.pageInfo.entities,			
			form		: 	vehiclesForm
		};

		this.pageInfo.entities["transporters"].externalFleetForm	=	{
			ready		:	false,
			entities	:	this.pageInfo.entities,
			form		:	externalFleetForm
		};

		// Translate forms
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);			
		});
    }

    async loadEntities(){	await this.load("types");
							await this.load("vehicles");
							await this.load('dmcFleet');
							await this.load('transporters');
							await this.load('licenses')
    }
    
	private getInfo($entity,$params={}){
		switch($entity){
			case "sections":	return this.pageInfo.sections;
		}
	}

    async load($entity)	{
		console.group("manageTransport::load");
		console.log("Entity",$entity);

		let response;
		this.pageInfo.entities[$entity]	=	this.pageInfo.entities[$entity] || {};
        
		switch($entity){
			case "types"	:	response 									= 	await Promise.resolve(this.entityService.getRequest("fleet",{}));
								this.pageInfo.entities[$entity].data		= 	response["data"];
								this.pageInfo.entities["vehicleTypeList"]	= 	response["data"];
								this.pageInfo.entities[$entity].count 		= 	response["data"] ? response["data"].length : 0;
								this.paginate($entity, null);
								this.pageInfo.entities[$entity].data.forEach(item => item.qty = 0);
								this.pageInfo.entities[$entity].table.ready	=	true;				
								// this.pageInfo.entities[$entity].form.info	= 	response["data"];
								this.pageInfo.entities[$entity].form.ready	=	true;		
								break;

			case "vehicles"	:	response 									= 	await Promise.resolve(this.entityService.getRequest("fleet",{}));
								this.pageInfo.entities[$entity].data		= 	response["data"];
								this.pageInfo.entities[$entity].count 		= 	response["data"] ? response["data"].length : 0;
								this.paginate($entity, null);
								this.pageInfo.entities[$entity].table.ready	=	true;				
								this.pageInfo.entities[$entity].form.info	= 	response["data"];
								this.pageInfo.entities[$entity].form.ready	=	true;		
								break;

			case 'dmcFleet':	// Load fleet for current destination
								if(this.fromWizard)	{	response = { success : true, data : this.dmcFleet || []} 	}
								else 				{	response = await Promise.resolve( this.transportService.getFleet(this.commons.userInfo.idDmc, this.commons.userInfo.currentDestination.id )); }
														
								this.pageInfo.entities[$entity].data		=	response.success ? ( response["data"] || [] ) : [];
								
								this.pageInfo.entities[$entity].data.forEach(type => {
									// if(!type.vehicles){ return };
									// console.log("vehicles",type.vehicles);
									// (type.vehicles||[]).forEach(vehicle => { 
									// 	vehicle.selected = false;
									// })
									Object.keys(type.vehicles||{}).forEach(id=>{
										type.vehicles[id].selected = false;
									})
								});

								this.pageInfo.entities[$entity].count		=	this.pageInfo.entities[$entity].data.length;
								this.paginate($entity,null);
								break;
			
			case 'transporters':
								response = this.fromWizard	?	{success : true, data : this.transporters || []}
															:	await Promise.resolve(this.transportService.getTransporters(this.commons.userInfo.idDmc,this.commons.userInfo.currentDestination.id))
								this.pageInfo.entities[$entity].data		= 	response.success ? response["data"] : [];	
								this.pageInfo.entities[$entity].data.forEach(el => el.selected = false);
								if(this.externalTransporters){
									setTimeout(async ()=>{await this.externalTransporters.addTransporter();},300)
									
								}
								
								console.log('MY TRANSPORTERS',this.pageInfo.entities[$entity].data);
								// response 									= 	await Promise.resolve(this.entityService.getRequest($entity));
								// this.pageInfo.entities[$entity].data		= 	response["data"];	
								// console.log('MY TRANSPORTERS',this.pageInfo.entities[$entity].data);
								break;
			
			case 'licenses': 
								this.pageInfo.entities[$entity]	=   [	
										{	value : 'B1', 
											label : 'B1'
										},
										{	value : 'B2', 
											label : 'B2'
										},
										{	value : 'B3', 
											label : 'B3'
										},
										{	value : 'B4', 
											label : 'B4'
										},
									];
								break;
		};
		console.groupEnd();
    }

	clickSection(index)		{	this.pageInfo.currentViewIndex	=	index;												}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length 								= !this.pageInfo.entities[$type].data ? 0 : this.pageInfo.entities[$type].data.length;
        this.pageInfo.entities[$type].paginateInfo 	= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this.pageInfo.entities[$type].paginate 		= !this.pageInfo.entities[$type].data
            ? []
            : this.pageInfo.entities[$type].data.slice(
                this.pageInfo.entities[$type].paginateInfo.first,
                this.pageInfo.entities[$type].paginateInfo.first + this.pageInfo.entities[$type].paginateInfo.rows
            );
    }
    
    getFilteredEntity($entity){
		switch($entity){
			case "types"		:	return (this.pageInfo.entities[$entity].data||[]).map(item=>{ item.url="/assets/layout/images/fleet/"+item.type+".png"; return item; });
			case "vehicles"		:	return (this.pageInfo.entities[$entity].data||[]).filter(item=>{ return true; });
			case "dmcFleet"		:	return (this.pageInfo.entities[$entity].data||[]);
			case "transporters"	:	return (this.pageInfo.entities[$entity].data||[]);	
			case "drivers"		:	return (this.pageInfo.entities[$entity].data||[]);
			default				:	return [];
		}
    }
	
	
	async handleFleetAction($event){
		console.log('USER INFO IS',this.commons.userInfo);
		
		if(this.fromWizard){
			if(this.mode == 'edit'){
				switch($event.action){
					case 'deleteTypeTransport'	:	await this.transportService.deleteTypeTransport	(	this.dmc,	this.destination, 	$event.data.typeTransport); 	return ;
					case 'saveTypeTransport'	:	await this.transportService.saveTypeTransport	(	this.dmc,	this.destination,	$event.data.typeTransport);		return ;
				}
			}else{
				this.pageInfo.wizardData.dmcFleet	=	this.pageInfo.entities['dmcFleet'].data;
				this.emitter.emit({type : 'dmcFleet', data : this.pageInfo.wizardData.dmcFleet});
			}
			console.log('EVENT IS', $event);
			console.log('FILTERED ENTITIES', this.pageInfo.entities);	
		}else{
			switch($event.action){
				case 'deleteTypeTransport'	:	await this.transportService.deleteTypeTransport(this.commons.userInfo.idDmc,this.commons.userInfo.currentDestination.id, $event.data.typeTransport); 	return ;
				case 'saveTypeTransport'	:	await this.transportService.saveTypeTransport(this.commons.userInfo.idDmc,this.commons.userInfo.currentDestination.id,$event.data.typeTransport);		return ;
			}
		}
		
	}
	/**
	 * Handle list drivers to create just if we come from wizard, otherwise it create the drivers outside the process
	 * @param $event List users to create once dmc || destination is created 
	 */
	handleDriversAction($event){
		console.log($event);
		switch($event.type){
			case 'manageDrivers'	:	return this.emitter.emit({type : 'manageDrivers', data : $event.data, ref: $event.data.info.ref });
			default					:	return;
		}
	}
	/**
	 * Handle list transporters to create just if we come from wizard, otherwise it create the drivers outside the process
	 * @param $event Transports and its own fleet to save/delete on the wizardProcess 
	 */
	async handleTransporterAction($event){
		console.log("[handleTransporterAction]",$event);
		switch($event.action){
			case 'reloadTransporters'	:	if($event.currentTransporter){this.pageInfo.currentTransporter = $event.currentTransporter}
											return await this.load('transporters');
			default						:	return this.emitter.emit({type : 'manageTransporters', data : $event});
		}
		
	}
}
